import React from 'react'
import { HomeBlock } from '../displayType/display.jsx'
import {} from './style.css'
import * as DetailPayment from './servicepackage/detailpackage'
import * as PaymentPackage from './servicepackage/paymentpackage'
import { renderNotificationPaymentSuccess, renderNotificationPaymentError } from './servicepackage/notificationpackage'

export const renderComponent = (vm) => {
  return (
    <div>
      {G.zu.array(vm.state.datahome).map((pack, i) => <HomeBlock key={i} data={pack} type={pack.display_type} event={vm.onClickPackage} />)}
      {G.zu.get(vm.state.form, 'detailpayment') && DetailPayment.renderDetailPayment(vm)}
      {G.zu.get(vm.state.form, 'payment') && PaymentPackage.renderPaymentPackage(vm)}
      {G.zu.get(vm.state.form, 'noti.success') && renderNotificationPaymentSuccess(vm)}
      {G.zu.get(vm.state.form, 'noti.error') && renderNotificationPaymentError(vm)}
    </div>
  )
}
