import React from 'react'
import { Link } from 'react-router-dom'
import DatePicker, { registerLocale } from 'react-datepicker'
import vi from 'date-fns/locale/vi'
import 'react-datepicker/dist/react-datepicker.css'
import {} from './styles.css'
import ChannelTabs from '../channel/index'
import { } from './../others/controlVideo/styleControlVideo.css'

registerLocale('vi', vi)

const zget = G.zu.get
const imgDefault = '/static/img/logo.svg'
export const renderComponent = (vm) => {
  return (
    <div>
      {zget(vm.state, 'videoData.name') &&
        <div className='livetv'>
          <div className='flex_block'>
            <div className='flex_left custom_control_video custom_control_video_viewtv'>
              <div id='videoPlayer' />
              {vm.state.isClickRegister && (
                <div className='pp_registerEpg'>
                  {vm.state.isRegister ?
                    <div className='pp_registerEpgItem'>
                      <img src='static/img/registerSuccess.png' alt='registerSuccess' />
                      <span>Thêm nội dung vào Lịch cá nhân thành công.</span>
                    </div> :
                    <div className='pp_registerEpgItem'>
                      <img src='static/img/registerFail.png' alt='registerFail' />
                      <span>Thêm nội dung vào Lịch cá nhân thất bại.</span>
                    </div>}
                </div>
              )}
            </div>
            <div className='flex_right'>
              <div className='egps_wrap'>
                <div className='egps_title'>Lịch phát sóng</div>
                {vm.renderEPG(vm)}
                <div />
              </div>
            </div>
          </div>
          <div className='vod_infomation_box tvBox'>
            <div className='tvTitle'>
              <div className='imgThAvatar'>
                <img src={vm.state.videoData.featured_image.thumbnail} alt='Icon Truyền Hình' />
              </div>
              <div className='tvName'>{vm.state.videoData.name}</div>
            </div>
            <div className='vod_action'>
              {vm.state.videoData.is_favourite ?
                <div className='vod_action_item'>
                  <img onClick={() => vm.onclickFavouritesDelete(vm.state.videoData.id)} src='/static/img/like_video_active.svg' alt='like' />
                  <div className='label_txt'>Bỏ thích</div>
                </div> :
                <div className='vod_action_item'>
                  <img onClick={() => vm.onclickFavouritesPost(vm.state.videoData.id)} src='/static/img/like_video.svg' alt='like' />
                  <div className='label_txt'>Thích</div>
                </div>}
              <div className='vod_action_item vod_action_item_share'>
                <img onClick={() => vm.onClickPopupShare()} src='/static/img/share_video.svg' alt='share' />
                <div className='label_txt'>Chia sẻ</div>
                {vm.state.isShare &&
                  <div className='popup_wrap popup_wrap_custom'>
                    <div className='popup_curtain' />
                    <div className='popup_inner pp_share'>
                      <img className='pp_close' src='static/img/icon_close.svg' alt='close' onClick={() => vm.onClickClosePopupShare()} />
                      <div className='pp_share_tt'>Chia sẻ</div>
                      <div className='pp_share_content'>
                        <div className='pp_share_list'>
                          <div className='pp_share_item' onClick={() => vm.onClickClosePopupShare()}>
                            <a href={'https://www.facebook.com/sharer/sharer.php?u=' + location.href} target='__blank'>
                              <img src='static/img/icon_fb.svg' alt='icon share' />
                              <span>Facebook</span>
                            </a>
                          </div>
                          <div className='pp_share_item'>
                            <div className='zalo-share-button' data-href={location.href} data-oaid='861700571076591645' data-layout='4' data-color='blue' data-customize='true'>
                              <img src='static/img/icon_zalo.svg' alt='icon share' />
                            </div>
                            <span>Zalo</span>
                          </div>
                        </div>
                        <div className='share_href_div'>
                          <input className='shareHref' type='text' readOnly value={location.href} />
                          <div className='share_href_btn' onClick={() => vm.onClickCoppyHref()}>Copy</div>
                        </div>
                      </div>
                    </div>
                  </div>}
              </div>
              <div className='vod_action_item'>
                <img src='/static/img/view_more.svg' alt='view_more' />
                <div className='label_txt'>Xem thêm</div>
              </div>
            </div>
          </div>
          <ChannelTabs />
          {vm.renderRelatives()}
        </div>}
    </div>
  )
}

export const renderVideoData = (vm) =>
  <div className='clearfix'>
    <div className='vod_avatar'><img src={zget(vm.state, 'videoData.featured_image.thumbnail')} /></div>
    <div className='vod_titletv'>{zget(vm.state, 'videoData.name')} <span>{zget(vm.state, 'videoData.year')}</span></div>
    {!!zget(vm.state, 'videoData.quality') && <div className='vod_quality'>{zget(vm.state, 'videoData.quality')} </div>}
  </div>

export const renderRelatives = (vm) => {
  const relations = G.zu.array(zget(vm.state, 'videoData.relations'))
  return (
    <div>
      {relations.length > 1 &&
        <div>
          <div className='vod_bigtext'> Kênh liên quan </div>
          <div className='clearfix listVodRelation'>
            {relations.map((e, i) =>
              <div key={i} className='relation_info'>
                <div className='relation_info_item'>
                  <Link to={'/truyen-hinh/' + e.slug + '-' + e.id}>
                    <img src={zget(e, 'featured_image.thumbnail') || imgDefault} onError={(e) => { e.target.onerror = null; e.target.src = imgDefault }} />
                    <div className='relation_name'>{e.name}</div>
                  </Link>
                </div>
              </div>)}
          </div>
        </div>}
    </div>
  )
}

export const backup = (vm) =>
  <div>
    <div className='vod_slug'>({zget(vm.state, 'videoData.slug')})</div>
  </div>

export const renderEPG = (vm) => {
  const epgs = G.zu.array(vm.state.epgs)
  const isPlaying = (epg) => {
    return (vm.state.playing.url_cdn === epg.url_cdn) ? 'playing' : ''
  }
  //  ((vm.state.videoData.url_cdn === epg.url_cdn) ? 'playing' : ''
  return (
    <div className='epgs_container'>
      <div className='epgs_time'>
        <div className='epgs_week'>
          <div className='epgs_week_selected' onClick={() => vm.toggleListChannel()}>{vm.state.channelName.name}</div>
          <div className='epgs_week_list' style={{ display: vm.state.toggle }}>
            {vm.state.listChannels.map((channel, index) => {
              return (
                <div key={index} className='epgs_week_inline'>
                  <Link to={'/truyen-hinh/' + G.zu.rxChangeSlug(channel.name) + '-' + channel.id} onClick={() => vm.handleSelectChannel(channel)}>
                    {channel.name}
                  </Link>
                </div>
              )
            })}
          </div>
        </div>
        <div className='epgs_date'>
          {/* <div className='epgs_previous icon-arrow-left nav-icon' onClick={() => vm.onBlurDate(vm.state.dateTime - 86400 * 1000)} /> */}
          <div className='epgs_datepicker'>
            <DatePicker
              selected={vm.state.dateTime}
              onChange={(date) => vm.onBlurDate(date)}
              locale='vi'
              customInput={<span>{G.zu.dateConvert(vm.state.dateTime / 1000)}</span>}
            />
          </div>
          {/* <div className='epgs_next icon-arrow-right nav-icon' onClick={() => vm.onBlurDate(vm.state.dateTime + 86400 * 1000)} /> */}
        </div>
      </div>
      <div className='epgs_list'>
        {(epgs.length > 0 && (epgs.length === 1 && epgs[0].isLive)) ? <></> :
          <>
            {epgs.map((epg, index) => {
              const time = epg.start_time ? epg.start_time.split(' ')[1] : ''
              return (
                <div className={`epg ${epg.isLive ? 'live' : isPlaying(epg)} ${vm.compareFuture(epg.start_time) ? 'epgNext' : ''}`} key={index}>
                  <div className='epgItem'>
                    <div className='epgItemEpgs' onClick={() => (epg.url_cdn) && vm.onClickEPGs(epg.url_cdn)}>
                      <div className='egp_time'><div>{epg.start_time ? time : 'Update'}</div>{epg.isLive && <div>TRỰC TIẾP</div>}</div>
                      <div className='egp_name'>
                        <span className='egp_name_title'>{epg.name}</span>
                        <span className='egp_name_des'>{epg.description ? epg.description.replace('<p>', '').replace('</p>', '') : ''}</span>
                      </div>
                    </div>
                    {/*{(epg.registered || !vm.compareFuture(epg.start_time)) ?*/}
                    {/*  <div className='egp_iconAdd'>*/}
                    {/*    <img src='static/img/addListHide.png' alt='egp_iconAdd' />*/}
                    {/*  </div> :*/}
                    {/*  <div className='egp_iconAdd' onClick={() => vm.registerEpg(epg.id)}>*/}
                    {/*    <img src='static/img/addListActive.png' alt='egp_iconAdd' />*/}
                    {/*  </div>}*/}
                  </div>
                </div>
              )
            })}
          </>}
      </div>
    </div>
  )
}
