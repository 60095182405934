import * as Jsx from './view.jsx'
import RxComponent from '/app/zcore/zcomponent' // eslint-disable-line

const apiHostStreamData = 'https://stream.mcvgo.vn'
const apiHostStream = 'https://cdnlive.mcvgo.vn'
const defaultStream = 'https://mcvgo280621.cdn.vnns.io/htv7.cat.720p.tms/playlist.m3u8'

const videoElement = 'videoPlayer'
class component extends RxComponent {
  constructor (props, context) {
    super(props, context, Jsx)
    this.state = {
      slug: '',
      videoID: '',
      videoData: {},
      hlsList: [],
      dataList1: [],
      dataList2: [],
      dateCurr: {}
    }
  }

  componentDidMount () {
    this.slug = G.zu.get(this.props, 'match.params.slug')
    this.initVideo()
    this.fetchDataInit()
    this.timeLoadLive = setInterval(() => {
      this.setState({ slug: this.slug })
      this.fetchData()
    }, 5000)
  }

  componentWillUnmount () {
    clearInterval(this.timeLoadLive)
    G.zu.runSafe(() => global.jwplayer(videoElement).remove())
  }

  componentWillReceiveProps (nextProps) {
    if (G.zu.get(nextProps, 'match.params.slug') !== G.zu.get(this.props, 'match.params.slug')) {
      this.slug = G.zu.get(nextProps, 'match.params.slug')
      if (this.slug) {
        this.setState({ slug: this.slug })
      }
      this.initVideo()
    }
  }

  fetchData () {
    this.fetchDataFilm()
    this.fetchDataList()
  }

  fetchDataInit () {
    this.fetchDataFilm()
    this.fetchDataList()
  }

  fetchDataList () {
    G.zq.get(`${apiHostStreamData}/cms/record/index`, { search: '{"show":{"$regex":"1"}}', pagin: '{"size":6}' }, (json) => {
      if (json.status === 1) {
        this.setState({ dataList1: json.data })
      }
    })

    G.zq.get(`${apiHostStreamData}/cms/record/index`, { search: '{"show":{"$regex":"2"}}', pagin: '{"size":6}' }, (json) => {
      if (json.status === 1) {
        this.setState({ dataList2: json.data })
      }
    })
  }

  fetchDataFilm () {
    G.zq.get(`${apiHostStreamData}/cms/live/live`, {}, (json) => {
      if (json.status === 1) {
        const hlsList = G.zu.array(zget(json, 'data.live'), [])
        if (!this.slug && zget(hlsList, [0])) {
          this.slug = zget(hlsList, [0, 'key'])
          setTimeout(() => { try { this.refLinkHotFirst.click() } catch (e) {} }, 500)
        }

        if (G.zu.array(hlsList).length === 0 && this.slug.indexOf('rec-') === -1) {
          setTimeout(() => { try { this.refLinkHot.click() } catch (e) {} }, 500)
        }

        this.setState({ hlsList })
      }
    })
  }

  initVideo () {
    let videoOptions = { case: 'hotlive', url: '' } // name series duration type // 'live' or 'on-demand'

    // CASE : record view
    if (this.slug.indexOf('rec') !== -1) {
      const _m3u8 = `${this.slug.replace('rec-', '')}`
      let parentFolder = _m3u8.split('-')
      parentFolder.pop()
      parentFolder = parentFolder.join('-')

      let ajaxResponse = $.ajax({ url: `${apiHostStreamData}/cms/record/index?search={"key": "${_m3u8}"}`, type: 'GET', async: false })
      ajaxResponse = G.zu.json(ajaxResponse.responseText)

      let ajaxResponseLive = $.ajax({ url: `${apiHostStreamData}/cms/live/index?search={"key": "${parentFolder}"}`, type: 'GET', async: false })
      ajaxResponseLive = G.zu.json(ajaxResponseLive.responseText)

      videoOptions = {
        ...videoOptions,
        case: 'record',
        type: 'on-demand',
        series: zget(ajaxResponseLive, ['data', 0, 'name']),
        name: zget(ajaxResponse, ['data', 0, 'name']),
        url: `${apiHostStreamData}/statics/fmp4/hlsrec/live/${parentFolder}/${_m3u8}/index.m3u8`
      }

    // CASE : view livetv breaking news
    } else {
      let ajaxResponseLive = $.ajax({ url: `${apiHostStreamData}/cms/live/index?search={"key": "${this.slug}"}`, type: 'GET', async: false })
      ajaxResponseLive = G.zu.json(ajaxResponseLive.responseText)

      videoOptions = {
        ...videoOptions,
        case: 'hotlive',
        type: 'live',
        series: zget(ajaxResponseLive, ['data', 0, 'name']),
        name: zget(ajaxResponseLive, ['data', 0, 'name']),
        url: `${apiHostStream}/${this.slug}/playlist.m3u8`
      }
    }

    // CASE : Empty send HTV7
    if (!this.slug) {
      videoOptions = {
        ...videoOptions,
        case: 'empty',
        type: 'live',
        series: 'HTV7',
        name: 'HTV7',
        url: defaultStream
      }
    }

    // https://stream.mcvgo.vn/cms/record/index?search={%22_id%22:%20%226G3LzWBhv0g6tlSJ%22}
    // https://stream.mcvgo.vn/cms/record/index?search={%22_id%22:%20%226G3LzWBhv0g6tlSJ%22}
    if (videoOptions.url) {
      setTimeout(() => {
        const sources = [{
          file: videoOptions.url,
          overrideNative: true,
          autostart: true // onXhrOpen: function (xhr, url) {}
        }]

        const defaultOptions = { // liveSyncDuration: 5,
          playlist: [{ sources: sources }],
          aspectratio: '16:9',
          width: '100%',
          captions: { color: '#ffb800', fontSize: 30, backgroundOpacity: 0 }
        }

        global.zinitPlayer(defaultOptions, videoElement, videoOptions)
      }, 500)
    }
  }

  render () { return this.renderComponent() }
}

export default component
