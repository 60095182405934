
export const renderFormEditProfile = (vm) => {
  let message = ''
  if (vm.state.success && vm.state.success[0]) { message = vm.state.success[0].message }
  return (
    <div className='edit_profile'>
      <div>
        <div className='input__group_edit'>
          <input placeholder='Nhập số điện thoại' className='edit_input' disabled type='text' value={vm.state.editingData.profile.phone} onChange={(e) => vm.onBlurData(e, 'phone')} />
          <label className='edit_label'>Số điện thoại</label>
        </div>
        <div className='input__group_edit'>
          <input placeholder='Nhập họ và tên' className='edit_input' type='text' value={vm.state.editingData.profile.name} onChange={(e) => vm.onBlurData(e, 'name')} />
          <label className='edit_label'>Họ tên</label>
        </div>
        <div className='input__group_edit'>
          <input placeholder='Nhập email' className='edit_input' type='text' value={vm.state.editingData.profile.email} onChange={(e) => vm.onBlurData(e, 'email')} />
          <label className='edit_label'>Email</label>
          {vm.state.emailErr && <div className='emailErr'>Email không hợp lệ</div>}
        </div>
        <div className='input__group_edit'>
          <input placeholder='Nhập địa chỉ' className='edit_input' type='text' value={vm.state.editingData.profile.address} onChange={(e) => vm.onBlurData(e, 'address')} />
          <label className='edit_label'>Địa chỉ</label>
        </div>
        {message && <span className='account_noti'>{message || ''}</span>}
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '3%' }}>
        <div className='adform__btns--back' onClick={(e) => vm.onClickDataEdit(e, {}, 'profile')}>Hủy</div>
        <div className={`adform__btns--create ${vm.state.isUpdate ? 'active' : ''}`} onClick={(e) => vm.state.isUpdate && vm.onClickUpdateProfile()}>Xác nhận</div>
      </div>
    </div>
  )
}
