import React from 'react'
import {} from './style/selectmember.css'

export const renderSelectMember = (vm) => {
  return (
    <div className='popup'>
      {renderSelectMemberForm(vm)}
    </div>
  )
}

const renderSelectMemberForm = (vm) => {
  const nameTab = 'Chọn thành viên'
  const tooltip = 'Vui lòng chọn thành viên để hoàn tất đăng nhập'
  const member = G.zq.localLoad('member').length ? G.zq.localLoad('member') : G.zu.array(G.zu.clone(vm.state.member))
  const memberSelected = vm.state.editingDataMember.member_id
  return (
    <div className='popup_selectmember_second_modal selectmember_second_modal'>
      <div className='selectmember_second_top'>
        <span className='selectmember_second_cancel_btn' onClick={() => vm.handleCloseForm()} />
      </div>
      <div className='selectmember_second_header'>{nameTab}</div>
      <div className='selectmember_second_tooltip'>{tooltip}</div>
      <div className='selectmember_second_body'>
        <div className='selectmember_second_list'>
          <div className='selectmember_second_grid'>
            {member.map((mem, index) => {
              const color = { background: (mem.age ? mem.age.background_color : '#000000') }
              if (mem.age) {
                return (
                  <div key={index} className={`selectmember_second_member ${memberSelected === mem.id ? 'selected' : ''}`} onClick={(e) => vm.onClickSelectMember(e, mem)}>
                    <div className={`selectmember_second_member_circle ${memberSelected === mem.id ? 'selected' : ''}`} style={color}>
                      <span className='selectmember_second_member_age'>{mem.age ? mem.age.name.replace('tuổi', '') : ''}</span>
                    </div>
                    <span className='selectmember_second_member_name'>{mem.name}</span>
                  </div>
                )
              }
            })}
            <div className='selectmember_second_member'>
              <div className='selectmember_second_member_circle add' onClick={() => vm.onClickOpenAddMember()}>
                <span className='selectmember_second_member_age icomoon-plus' />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='selectmember_second_button_group'>
        <div className='selectmember_second_button' onClick={() => vm.onClickBack('openLogin')}>Trở về</div>
        <div className={`selectmember_second_button ${memberSelected ? 'active' : 'disable'}`} onClick={() => memberSelected && vm.onClickLoginMember()}>Xác nhận</div>
      </div>
    </div>
  )
}
