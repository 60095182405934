import * as Jsx from './checkout.jsx'
// eslint-disable-next-line
import RxComponent from '/app/zcore/zcomponent'
import { connect } from 'react-redux'
import { actionPayment } from '../../../zcore/zredux.js'
import {} from './checkout.css'

class component extends RxComponent {
  constructor (props, context) {
    super(props, context, Jsx)
    this.state = {
      noti: {
        success: false,
        error: false,
        expired: false
      },
      errorCode: {}
    }
    this.props.actionPayment(this.state.errorCode)
  }

  componentDidMount () {
    const checkSlug = G.zu.get(this.props, 'location.search').replaceAll('?', '')
    const params = checkSlug.split('&')
    const par = {}
    params.forEach((item, i) => {
      const el = item.split('=')
      par[el[0]] = el[1]
    })
    const optionParams = {
      errorCode: par.errorCode,
      orderInfo: par.orderInfo,
      message: par.message
    }
    this.setState({ errorCode: optionParams }, () => {
      G.zq.localSave('parLocal', optionParams)
      this.props.history.push(G.zq.localLoad('hisPath').split('/')[1])
    })
  }

  showNotification () {
    this.setState({ noti: { ...this.state.noti, success: true } })
  }

  render () { return this.renderComponent() }
}

const mapStateToProps = (state, ownProps) => ({
  errorCode: state.errorCode
})

const mapDispatchToProps = { actionPayment }

const ComponentPayment = connect(
  mapStateToProps,
  mapDispatchToProps
)(component)

export default ComponentPayment
