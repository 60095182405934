import React from 'react'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import {} from './style/register.css'
import {GoogleLogin} from "@react-oauth/google";

export const renderRgisterPopup = (vm) => {
  const nameTab = 'Đăng ký'
  return (
    <div className='popup'>
      <div className='register_modal register_modal'>
        <div className='register_top'>
          <span className='register_cancelbtn register_btn_SSO' onClick={() => vm.handleCloseForm()} />
        </div>
        <div className='register_header'>{nameTab}</div>
        {renderRegisterForm(vm)}
        <div className='register_footer'>
          {renderSocialButtons(vm)}
        </div>
      </div>
    </div>
  )
}

export const renderRegisterForm = (vm) => {
  const validate = vm.state.validate
  const isPhoneValid = validate.isPhoneValid
  const message = validate.msgErr.phone
  const phone = zget(vm.state, ['editingDataLogin', 'phone'], '')
  return (
    <div className='register_body'>
      <div className={`register_form ${isPhoneValid ? '' : 'error'}`}>
        <label>Số điện thoại</label>
        <input onKeyPress={(e) => (isPhoneValid && e.key === 'Enter') && vm.handleRegister()} type='text' placeholder='Nhập số điện thoại' value={phone} onChange={(e) => vm.onBlurDataLogin(e, 'phone')} />
        <label className='error show'>{isPhoneValid ? '' : message}</label>
      </div>
      <div className='conditions'>
        <span className='conditions_title'>Bằng việc nhấn nút đăng ký, bạn đã đồng ý với </span><span className='condition_link' onClick={() => vm.linkTopage()}>các điều khoản sử dụng</span>
      </div>
      <div
        className={`register_button ${isPhoneValid ? 'active' : ''}`}
        onClick={() => vm.handleRegister()}
      >
        Đăng ký
      </div>
    </div>
  )
}

export const renderSocialButtons = (vm) => {
  const by = 'Đăng ký bằng'
  return (
    <div className='register_social'>
      <span className='register_social_tooltip'>{by}</span>
      <div className='register_socialgroup'>
          <div className='popup__google button__loginSSO'>
              <GoogleLogin
                  theme='outline'
                  type='icon'
                  shape='circle'
                  size='large'
                  onSuccess={vm.responseGoogle}
                  onError={vm.responseGoogle}
              />
          </div>
        <FacebookLogin
        // appId='425330095473217'
          // appId='506723214371123'
          // appId Test
          appId='1085705229014628'
          render={renderProps => (
            <span onClick={renderProps.onClick} disabled={renderProps.disabled} className='popup__facebook button__loginSSO' />
          )}
          fields='name,email,picture'
          callback={vm.responseFacebook}
          textButton=''
          className='abc'
          authType='reauthenticate'
        />
      </div>
      <div className='register_social_smalltooltip'>Bạn đã có tài khoản?<span className='register_open_register_form' onClick={(e) => vm.handleOpenFormName('openLogin')}> Đăng nhập ngay</span></div>
    </div>
  )
}
