import React from 'react'
import { } from './style/style.css'
import { Link } from 'react-router-dom'
import MultiCarousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import * as displayUlti from '../displayType/displayUlti.jsx'
import Zimg from './../others/zimg'

export const renderComponent = (vm) => {
  return (
    <div>
      {renderTabs(vm)}
    </div>
  )
}

const renderTabs = (vm) => {
  const options = G.zu.clone(vm.state.options)
  const tabs = options.channel ? G.zu.clone(vm.state.channels) : G.zu.clone(vm.state.types)
  return (
    <div className='tv_tabs'>
      {displayTVChannelCarousel(vm)}
      <div className='tv_tabs_container'>
        {/* <select className='tv_tabs_selector' onChange={(e) => vm.onChange(e.target.value)}>
          <option value='channel'>Kênh truyền hình</option>
          {/* <option value='type'>Thể loại</option> */}
        {/* </select> */}
        <div id='tablist' className='tv_tabs_list'>
          {G.zu.array(tabs).map((tab, index) => {
            return (
              <div key={index} className={(vm.state.id === tab.id) ? 'active' : ''} onClick={() => vm.getListTab(tab.id)}>{tab.name}</div>
            )
          })}
        </div>
        {tabs.length >= 9 &&
          <div className='tv_tabs_slide_btn'>
            <span id='btnprev' className='tv_tabs_arrow icon-arrow-left' />
            <span id='btnnext' className='tv_tabs_arrow icon-arrow-right active' />
          </div>}

      </div>
      <div className='tv_tabs_title'> Danh sách kênh </div>
    </div>
  )
}

const displayTVChannelCarousel = (vm) => {
  const child = vm.helperCount(vm.state.children)
  return (
    <div className='tv_tabs_slide'>
      {types.channel(child)}
    </div>
  )
}

const displayTVTypeCarousel = (vm) => {
  const child = vm.helperCount(vm.state.children)
  return (
    <div className='tv_tabs_slide'>
      {types.type(child)}
    </div>
  )
}

const types = {
  channel: (datas) =>
    <div key={G.zu.get(datas, 'id')} className='tv_tab_blocktype bt3'>
      <MultiCarousel
        {...defaultCarouselConfig}
        responsive={{
          desktop1920: { breakpoint: { max: 9999, min: 1920 }, items: 6, partialVisibilityGutter: 40 },
          desktop1400: { breakpoint: { max: 1919, min: 1400 }, items: 6, partialVisibilityGutter: 40 },
          desktop1280: { breakpoint: { max: 1399, min: 1280 }, items: 6, partialVisibilityGutter: 40 },
          desktop1080: { breakpoint: { max: 1279, min: 1080 }, items: 6, partialVisibilityGutter: 40 },
          desktop992: { breakpoint: { max: 1079, min: 992 }, items: 4, partialVisibilityGutter: 40 },
          desktop768: { breakpoint: { max: 991, min: 768 }, items: 4, partialVisibilityGutter: 40 },
          desktop580: { breakpoint: { max: 767, min: 0 }, items: 4, partialVisibilityGutter: 40 }
        }}
      >
        {G.zu.array(datas.topChild).map((data, index) => {
          return (
            <div key={index}>
              <div className='blocktype bt3_item tv_tabs_top'>{getTitle(data)}</div>
              {datas.bottomChild[index] && <div className='blocktype bt3_item'>{getTitle(datas.bottomChild[index])}</div>}
            </div>
          )
        }
        )}
      </MultiCarousel>
    </div>,
  type: (datas) =>
    <div key={G.zu.get(datas, 'id')} className='tv_tab_blocktype bt12'>
      <MultiCarousel
        {...defaultCarouselConfig}
        responsive={{
          desktop1920: { breakpoint: { max: 90000, min: 1400 }, items: 6, partialVisibilityGutter: 40 },
          desktop1400: { breakpoint: { max: 1400, min: 1200 }, items: 6, partialVisibilityGutter: 40 },
          desktop1200: { breakpoint: { max: 1200, min: 992 }, items: 6, partialVisibilityGutter: 40 },
          desktop992: { breakpoint: { max: 992, min: 768 }, items: 6, partialVisibilityGutter: 40 },
          desktop768: { breakpoint: { max: 768, min: 580 }, items: 4, partialVisibilityGutter: 40 },
          desktop580: { breakpoint: { max: 580, min: 0 }, items: 4, partialVisibilityGutter: 40 }
        }}
      >
        {G.zu.array(datas.children).map((data, index) => <div key={index}>{displayUlti.getTitle(data)}</div>)}
      </MultiCarousel>
    </div>
}

export const getTitle = (data, options) => {
  return (
    <Link to={getLink(data)} className='bitem_link'>
      {getThumb(data)}
      <div className='bitem_info'>
        <div className='bitem_infoin'>
          <div className='bitem_title'>{data.name}</div>
          <div className='bitem_btn-trailer'>Xem trailer</div>
          <div className='bitem_btn'>Xem ngay</div>
          {G.zu.runSafe(() => { return options.after() })}
        </div>
      </div>
    </Link>
  )
}

export const getLink = (data) => {
  if (!data.object_type_id) return '/'
  const linkMap = {
    0: '/no-route',
    1: '/truyen-hinh',
    2: '/xem-phim',
    9: '/mua-goi'
  }
  return `${G.zu.get(linkMap, [data.object_type_id], '/no-route')}/${G.zu.rxChangeSlug(G.zu.get(data, 'name', ''))}-${data.id}`
}

export const getBlockTitle = (datas) => <div className='b_title'>{G.zu.get(datas, 'name', '')} <Link to='/tim-kiem' className='btitle_viewall'>Tất cả</Link></div>
export const getThumb = (data) =>
  <Zimg src={G.zu.get(data, 'photo.thumb_640x368')} thumb={G.zu.get(data, 'photo.tiny')} alt={data.name} />

export const getThumbBig = (data) =>
  <Zimg src={G.zu.get(data, 'photo.thumb_1300x481')} thumb={G.zu.get(data, 'photo.tiny')} alt={data.name} />

export const defaultCarouselConfig = {
  arrows: true,
  centerMode: false,
  infinite: true,
  showDots: false,
  slidesToSlide: 2,
  fade: false,
  swipeable: false,
  itemClass: '',
  sliderClass: '',
  dotListClass: '',
  renderButtonGroupOutsidedotListClass: ''
}
