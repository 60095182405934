G.zd.vi = { ...G.zd.vi,
  'name': 'tên',
  'cate': 'danh mục',
  'tag': 'thẻ',
  'home': 'trang chủ',
  'blog post': 'bài viết',
  'action': 'thao tác' 
}

export default {}
