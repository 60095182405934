import * as Jsx from './view.jsx'
import { connect } from 'react-redux'
import { actionSearch } from '../../../zcore/zredux.js'
// eslint-disable-next-line
import RxComponent from '/app/zcore/zcomponent'

class component extends RxComponent {
  constructor (props, context) {
    super(props, context, Jsx)
    this.state = {
      keyword: '',
      // keyword: decodeURI(`${new URL(window.location.href).searchParams.get('query')}`).replaceAll('-', ' ') || '',
      baseURL: '/tim-kiem'
    }
    // NOTE: SEARCH init search action by query param
    this.props.actionSearch(this.state.keyword)
  }

  onChangeSearch (e) {
    this.setState({ keyword: e.target.value }, () => {
      // NOTE: SEARCH
      this.props.actionSearch(this.state.keyword)
    })

    if (e.target.value === '') {
      window.history.pushState({}, null, this.state.baseURL)
    } else {
      const nextURL = this.state.baseURL + '?query=' + e.target.value.replaceAll(' ', '-')
      window.history.pushState({}, null, nextURL)
    }
  }

  onClickRefreshInput () {
    setTimeout(() => {
      $('input.search_toggle').val('')
    }, 100)
    this.setState({ keyword: '' }, () => {
      // NOTE: SEARCH
      this.props.actionSearch(this.state.keyword)
    })
    window.history.pushState({}, null, this.state.baseURL)
  }

  onKeyPressSearch (e) {
    if (e.key === 'Enter') {
      if (e.target.value === '') {
        const nextURL = this.state.baseURL
        window.history.pushState({}, null, nextURL)
      } else {
        const nextURL = this.state.baseURL + '?query=' + e.target.value.replaceAll(' ', '-')
        window.history.pushState({}, null, nextURL)
        // NOTE: SEARCH
        this.props.actionSearch(this.state.keyword)
        e.preventDefault()
      }
    }
  }

  render () { return this.renderComponent(this) }
}

const mapStateToProps = (state, ownProps) => ({
  keyword: state.keyword
})

const mapDispatchToProps = { actionSearch }

const ComponentSearch = connect(
  mapStateToProps,
  mapDispatchToProps
)(component)

export default ComponentSearch
