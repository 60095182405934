// import React from 'react'
// import {} from './style.css'

export const renderComponent = (vm) => {
  return (
    <div>
      <div className='textCenter'>
        <p><strong>GIỚI THIỆU</strong></p>
        <p><em>&nbsp;</em></p>
      </div>
      <p data-pm-slice="1 1 []">BẢN QUYỀN &copy; 2008 - 2020 BỞI Đ&Agrave;I PH&Aacute;T THANH - TRUYỀN H&Igrave;NH TỈNH
        TH&Aacute;I NGUY&Ecirc;N.</p>
      <p>Gi&aacute;m đốc - Tổng Bi&ecirc;n tập - Chịu tr&aacute;ch nhiệm nội dung: Nguyễn Thị Vũ Anh</p>
      <p>Giấy ph&eacute;p hoạt động số: 24/GP - TTĐT, do Cục Ph&aacute;t thanh, Truyền h&igrave;nh v&agrave; Th&ocirc;ng
        tin điện tử cấp ng&agrave;y 01/04/2015</p>
      <p>Giấy ph&eacute;p sửa đổi bổ sung số: 198/GPSĐBS - TTĐT, ng&agrave;y 11/11/2016 của Cục Ph&aacute;t thanh,
        Truyền h&igrave;nh v&agrave; Th&ocirc;ng tin điện tử</p>
      <p>Địa chỉ: Số 226 Đường Bến O&aacute;nh - Phường Trưng Vương - TP.Th&aacute;i Nguy&ecirc;n</p>
      <p>Điện thoại: (02083) 855.439 * FAX: (02083) 655.724.</p>
      <p>Email: <a href="mailto:truyenhinhthainguyen@gmail.com">truyenhinhthainguyen@gmail.com</a>; <a href="mailto:daiptth@thainguyen.gov.vn">daiptth@thainguyen.gov.vn</a>; <a href="mailto:baodientutntv@thainguyen.gov.vn ">baodientutntv@thainguyen.gov.vn </a> </p>
      <p>Website: <a href="https://thainguyentv.vn/">https://thainguyentv.vn</a></p>
    </div>
  )
}
