export const renderPaymentPackage = (vm) => {
  const typeMethodPayment = [
    { id: 1, name: 'Ví điện tử' },
    { id: 2, name: 'Thẻ cào' },
    { id: 3, name: 'Chuyển khoản' }
  ]
  const methodPayment = [
    { name: 'momo', id: 1 },
    { name: 'visa', id: 2 },
    { name: 'vtpay', id: 3 },
    { name: 'zalopay', id: 4 }
  ]
  return (
    <div>
      <div className='packpopup_curtain' onClick={e => vm.onClickClosePopup()} />
      <div className='packpopup_outer'>
        <div className='packpopup_wrap'>
          <img className='pp_close' src='static/img/icon_close.svg' alt='close' onClick={() => vm.onClickClosePopup()} />
          <div className='packpopup_head'>Thanh Toán</div>
          <div className='packpopup_body'>
            <div className='packprice_list'>
              <div className='pack_title'>{vm.state.curPackage.name}</div>
              <div className='pack_desc'>Vui lòng chọn mức giá</div>
              <div className='pack_price_wrap'>
                {G.zu.array(vm.state.curPackage.prices).map((e, i) =>
                  <div key={i} className={`pack_price ${vm.state.curEditing.priceID === e.id ? 'active' : ''}`} onClick={ex => vm.onClickPackPrice(e)}>
                    <div className='pack_period'>{e.months} tháng</div>
                    <div className='pack_pricenum'>{G.zu.strToMoney(e.price)} đ</div>
                  </div>)}
              </div>
            </div>
            <div className='pack_promotion'>
              <div className='pack_title'>Giảm giá</div>
              {vm.state.promotion.applied.code ?
                <div className='pack_promo_applied'>
                  {vm.state.promotion.applied.code}
                  <img className='pp_close' src='static/img/icon_close.svg' alt='close' onClick={() => vm.onClickCancelPromo()} />
                </div> :
                <input
                  type='text' name='' placeholder='Nhập mã' value={vm.state.promotion.code}
                  onChange={(e) => vm.handleApplyPromotion(e)}
                  onKeyPress={(e) => (e.key === 'Enter' && vm.handleApplied())} id='input_promotion_popup'
                />}
              {!!vm.state.promotion.code &&
                <div className='pack_promotion_list'>
                  {!!vm.state.promotion.applied.code &&
                    <div className='pack_promotion_inline'>
                      <div className='pack_promotion_code'>{vm.state.promotion.applied.code}</div>
                      <div className='pack_promotion_desc'>{vm.state.promotion.applied.description}</div>
                    </div>}
                  <div className='pack_title_info'>Gợi ý</div>
                  {vm.state.promotion.list.map((e, i) =>
                    <div key={i} className={`pack_promotion_inline ${e.id === 1 ? 'pack_promotion_inline--active' : ''}`} onClick={() => vm.onClickApplyPromo(e)}>
                      <div className='pack_promotion_code'>{e.code}</div>
                      <div className='pack_promotion_desc'>{e.description}</div>
                    </div>)}
                </div>}
            </div>
            <div className='pack_sum_price'>
              <div className='pack_title'>Tính thành tiền</div>
              <div className='pack_price_line'>
                <div className='pack_title_info'>Giá gói</div>
                <div className='pack_price_vnd'>{G.zu.strToMoney(vm.state.curEditing.price)} đ</div>
              </div>
              {vm.state.promotion.applied.code &&
                <div className='pack_price_line'>
                  <div className='pack_title_info'>Giảm giá</div>
                  <div className='pack_price_vnd'>- {G.zu.strToMoney(vm.state.promotion.applied.price)} đ</div>
                </div>}

              <div className='pack_price_line'>
                <div className='pack_title'>Tổng tiền</div>
                <div className='pack_price_sum'>{G.zu.strToMoney(vm.state.curEditing.totalPrice)} đ</div>
              </div>
            </div>
            <div className='pack_payment_method'>
              <div className='pack_title'>Phương thức thanh toán</div>
              <div className='pack_payment_method_type_wrap'>
                {typeMethodPayment.map((e, i) =>
                  <div key={i} className={`pack_payment_method_type ${e.id === 1 ? 'pack_payment_method_type--active' : ''}`}>
                    {e.name}
                  </div>)}
              </div>
              <div className='pack_payment_method_wrap'>
                {methodPayment.map((e, i) =>
                  <div key={i} className={`pack_payment_method ${e.id === vm.state.paymentMethodID ? 'pack_payment_method--active' : ''}`} onClick={ex => vm.onClickSelectMethod(e)}>
                    <img src={'/images/static/payment/' + e.name + '.svg'} alt='payment method' />
                  </div>)}
              </div>
            </div>
            {!!vm.state.curEditing.priceMSG && <div className='pack_errors'>{vm.state.curEditing.priceMSG}</div>}
            <div
              className={`pack_item ${vm.state.isActive ? 'active' : ''}`}
              onClick={() => vm.handleActive()}
            >
              <div className='pack_item_title'>
                <div className='pack_item_name'>Ví momo</div>
              </div>
              <div className='pack_item_info styleCheckbox'>
                <input id='autoExtend' className='checkbox-custom' name='autoExtend' type='checkbox' />
                <label for='autoExtend' className='checkbox-custom-label'>Tiện ích tự động gia hạn</label>
              </div>
            </div>
            <div className='pack_btns'>
              <div className='pack_submit' onClick={e => vm.processCheckout()}>Thanh Toán</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
