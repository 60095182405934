import * as Jsx from './view.jsx'
// eslint-disable-next-line
import RxComponent from '/app/zcore/zcomponent'
import { actionScrollComment } from '../../../zcore/zredux.js'
import { connect } from 'react-redux'

class component extends RxComponent {
  constructor (props, context) {
    super(props, context, Jsx)
    this.state = {
      listNotifications: [],
      meta: {
        current_page: 0,
        from: 0,
        last_page: 0,
        per_page: 0,
        to: 0,
        total: 0
      }
    }
  }

  componentDidMount () {
    if (G.zq.localLoad('loginby') === 'logout') {
      $(() => {
        document.getElementById('rmenu_item_user').click()
      })
    } else {
      this.fetchDataNoitificationInApp()
    }
  }

  onClickDeleteNoti (id) {
    G.zq.delete('/api/v1/messages/' + id, {}, (json) => {
      if (json.success) {
        this.fetchDataNoitificationInApp()
      }
    })
  }

  onClickCommentNotification () {
    this.props.actionScrollComment(1)
  }

  fetchDataNoitificationInApp (changepage) {
    const body = {
      per_page: 10,
      page: changepage || this.state.meta.current_page
    }
    G.zq.get('/api/v1/messages', body, (json) => {
      if (json.success) {
        this.setState({ listNotifications: json.data, meta: { ...G.zu.clone(json.meta) } })
      }
    })
  }

  handleChangePage (page) {
    const meta = G.zu.clone(this.state.meta)
    meta.current_page = page
    this.setState({ meta })
    this.fetchDataNoitificationInApp(page)
  }

  render () { return this.renderComponent() }
}

const mapStateToProps = (state, ownProps) => ({
  scrollComment: state.scrollComment
})

const mapDispatchToProps = {
  actionScrollComment
}

const ViewNotificationComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(component)

export default ViewNotificationComponent
