import { DebounceInput } from 'react-debounce-input'
import { Link } from 'react-router-dom'
import Paging from './../others/paging'

export const renderHistory = (vm) => {
  return (
    <div>
      {/* <div className='inner__popup' onClick={(e) => vm.onClickChangeTab(e, 'about')}></div> */}
      <div className='account__support content__block'>
        <div className='account__support--block'>
          <div className='account__support--title'>Lịch sử giao dịch</div>
          <div className='account__support--body account_history'>
            <div className='account_history_search'>
              <div className='search_input'>
                <DebounceInput
                  onKeyPress={e => vm.onKeyPressSearch(e)}
                  onChange={e => vm.onChangeSearch(e)}
                  debounceTimeout={800}
                  id='' maxLength='200' type='text' className='search_toggle' placeholder='Tìm kiếm giao dịch ...'
                />
                {vm.state.keyword === '' ? <span className='icomoon-search icon_toggle' /> : <span onClick={() => vm.onClickRefreshInput()} className='icomoon-close icon_toggle' />}
              </div>
            </div>
            {zarray(vm.state.historyTransactions).length === 0 ?
              <div className='account_history_list_empty'>
                <img src='static/img/accountHistoryEmpty.png' alt='accountHistoryEmpty' />
                <div className='account_history_list_empty_txt'>Chưa có thông tin lịch sử giao dịch</div>
                <Link to='/mua-goi' className='btn_muagoi'>Mua gói</Link>
              </div> :
              <div className='account_history_list'>
                {vm.state.historyTransactions.map((e, i) =>
                  <div key={e.id} className='account_history_item'>
                    {(() => {
                      switch (e.payment_gate) {
                        case 'Momo':
                          return (
                            <>{e.status === 'Thành công' ? <img className='icon_device' src='static/img/momoSuccess.png' alt='momoSuccess' /> : <img className='icon_device' src='static/img/momoFail.png' alt='momoFail' />}</>
                          )
                        case 'In App':
                          return (
                            <>{e.status === 'Thành công' ? <img className='icon_device' src='static/img/inappSuccess.png' alt='inappSuccess' /> : <img className='icon_device' src='static/img/inappFail.png' alt='inappFail' />}</>
                          )
                        default:
                          return (
                            <></>
                          )
                      }
                    })()}
                    <div className='account_history_info'>
                      <div to='/mua-goi' className='account_history_title'>{e.package != null && e.package.name}</div>
                      <div className='account_history_text'>{e.created_at.replaceAll(' ', ' | Lúc ')} qua [{e.os_id === null ? 'Khác' : e.os_id}]</div>
                      <div className='account_history_text'>Mã giao dịch: <input className='codeTransaction' type='text' readOnly value={e.transaction_id_gateway ? e.transaction_id_gateway : 'Đang cập nhật'} /></div>
                    </div>
                    <div className='account_history_price'>
                      <div className='account_history_total'>{G.zu.strToMoney(e.total)}VND</div>
                      <div className='account_history_coppy' onClick={() => vm.handleCoppyCode()}>Sao chép mã</div>
                    </div>
                  </div>
                )}
              </div>}
            {G.zu.clone(vm.state.meta).last_page > 1 && <Paging meta={G.zu.clone(vm.state.meta)} onPage={(page) => vm.handleChangePage(page)} />}
          </div>
        </div>
      </div>
    </div>
  )
}
