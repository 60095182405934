import React from 'react'
import { Link } from 'react-router-dom'
import {} from './style.css'

const imgDefault = '/static/img/logo.svg'
export const renderComponent = (vm) =>
  <div className='actor_wrap' onClick={e => vm.onClickActor()}>
    <div className='actor_avatar'>
      <i className='fa fa-user-o' aria-hidden='true' />
      <img src={zget(vm.props, 'data.featured_image.thumbnail')} onError={(e) => { e.target.onerror = null; $(e.target).remove() }} />
    </div>
    <div className='actor_name'>{zget(vm.props, 'data.name')}</div>
    {!!zget(vm.state, 'curData.id') &&
      <div className='popup_wrap popup_wrap_custom'>
        <div className='popup_curtain'/>
        <div className='popup_inner'>
          <img className='close_popup_actor' src='static/img/icon_close.svg' alt='close' onClick={e => vm.onClickCurtain(e)} />
          {/* <div className='popup_head'>Thông tin diễn viên</div> */}
          <div className='popup_body clearfix'>
            <div className='popup_actor_left'>
              <div className='clearfix'>
                <div className='actor_wrap-popup'>
                  <div className='actor_info_item clearfix'>
                    <div className='actor_avatar'>
                      <i className='fa fa-user-o' aria-hidden='true' />
                      <img src={zget(vm.state, 'curData.featured_image.thumbnail')} onError={(e) => { e.target.onerror = null; $(e.target).remove() }} />
                    </div>
                    <div className='actor_info_detail'>
                      <div className='actor_name'>{zget(vm.state, 'curData.name')}</div>
                      <div className='actor_info_txt'>Diễn viên</div>
                      <div className='actor_info_txt'>{zget(vm.state, 'curData.dob')}</div>
                      <div className='actor_info_txt'>{zget(vm.state, 'curData.place_of_birth')}</div>
                    </div>
                  </div>
                  <div className='actor_desc' dangerouslySetInnerHTML={{ __html: zget(vm.state, 'curData.biography') }} />
                </div>
              </div>
            </div>
            <div className='popup_actor_right'>
              <div className='popup_title'>Phim tham gia <Link to='/tim-kiem' className='btitle_viewall'>Tất cả</Link></div>
              <div className='actor_film'>
                <div className='clearfix'>
                  {zarray(zget(vm.state, 'curData.videos')).map((ex, i) =>
                    <div key={i} className='relation_info'>
                      <Link to={'/xem-phim/' + ex.slug + '-' + ex.id}>
                        <img src={zget(ex, 'photo_portrait.thumb_390x561') || imgDefault} onError={(e) => { e.target.onerror = null; e.target.src = imgDefault }} />
                        <div className='relation_name'>{ex.name}</div>
                      </Link>
                    </div>)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
  </div>
