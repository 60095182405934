// import React from 'react'
// import {} from './style.css'

export const renderComponent = (vm) => {
  return (
    <div>
      <div className='textCenter'>
        <p><strong>HỖ TRỢ</strong></p>
        <p><em>&nbsp;</em></p>
      </div>
      <p data-pm-slice="1 1 []">Điện thoại: (02083) 855.439 * FAX: (02083) 655.724.</p>
      <p>Email: <a href="mailto:truyenhinhthainguyen@gmail.com">truyenhinhthainguyen@gmail.com</a></p>
      <p>Website: <a href="https://thainguyentv.vn/">https://thainguyentv.vn/</a></p>
    </div>
  )
}
