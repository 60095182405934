
export const renderNavMenu = (vm) => {
  const cls = (name) => `toggle__item ${vm.state.section === name ? 'active' : ''} ${name}`
  const inputForm = [
    { name: 'Cá nhân', id: 'account', svg: 'user' },
    // { name: 'Lịch cá nhân', id: 'calendar', svg: 'calendar' },
    // { name: 'Dịch vụ đã mua', id: 'package', svg: 'dollar' },
    // { name: 'Lịch sử giao dịch', id: 'history', svg: 'history' },
    { name: 'Quản lý thiết bị', id: 'device', svg: 'tv' },
    { name: 'Đổi mật khẩu', id: 'password', svg: 'password' }
    // { name: 'Giới thiệu', id: 'about', svg: 'self' },
    // { name: 'Khuyến mãi', id: 'sale', svg: 'sale' }
  ]
  return (
    <div>
      {inputForm.map((form, index) => {
        return (
          <div
            key={index}
            className={cls(`${form.id}`)}
            onClick={(e) => vm.onClickChangeTab(e, `${form.id}`)}
          >
            <img
              style={form.style || {}}
              src={`/images/static/${vm.state.section === `${form.id}` ? `${form.svg}_active.svg` : `${form.svg}.svg`}`}
              className='icon--toggle'
            />
            <img
              style={form.style || {}}
              src={`/images/static/${form.svg}_active.svg`}
              className='icon--toggle active'
            />
            <div className='toggle__name' style={{ display: G.zu.get(vm.state.sider, 'showName') }}>{form.name}</div>
          </div>
        )
      })}
    </div>
  )
}
