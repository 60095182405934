// import React from 'react'
// import {} from './style.css'

export const renderComponent = (vm) => {
  return (
    <div>
      <div className='textCenter'>
          <p><strong>TH&Aacute;I NGUY&Ecirc;N OTT CH&Iacute;NH S&Aacute;CH BẢO MẬT</strong></p>
        <p><em>&nbsp;</em></p>
      </div>

        <p><span style={{fontWeight: 400}}>Ch&agrave;o mừng bạn đến với OTT Th&aacute;i Nguy&ecirc;n (&ldquo; </span><strong>Nền tảng </strong><span style={{fontWeight: 400}}>&rdquo;) do C&ocirc;ng ty Cổ phần Tập đo&agrave;n MCV hoặc một trong c&aacute;c chi nh&aacute;nh của C&ocirc;ng ty (&ldquo; </span><strong>Th&aacute;i Nguy&ecirc;n OTT </strong><span style={{fontWeight: 400}}>&rdquo; hoặc &ldquo; </span><strong>ch&uacute;ng t&ocirc;i </strong><span style={{fontWeight: 400}}>&rdquo;) cung cấp.</span></p>
        <p><span style={{fontWeight: 400}}>Th&aacute;i Nguy&ecirc;n OTT cam kết sẽ t&ocirc;n trọng những th&ocirc;ng tin mang t&iacute;nh ri&ecirc;ng tư của bạn. Ch&iacute;nh s&aacute;ch n&agrave;y được thiết kế để gi&uacute;p bạn hiểu c&aacute;ch ch&uacute;ng t&ocirc;i thu thập, sử dụng v&agrave; / hoặc xử l&yacute; dữ liệu c&aacute; nh&acirc;n m&agrave; bạn đ&atilde; cung cấp cho ch&uacute;ng t&ocirc;i v&agrave; / hoặc lưu giữ về bạn, cho d&ugrave; hiện tại hay trong tương lai, cũng như gi&uacute;p bạn th&ocirc;ng b&aacute;o quyết định trước khi cung cấp bất kỳ dữ liệu c&aacute; nh&acirc;n n&agrave;o. Bạn c&oacute; thể t&igrave;m th&ecirc;m th&ocirc;ng tin v&agrave; c&ocirc;ng cụ trong </span><strong>C&agrave;i đặt OTT Th&aacute;i Nguy&ecirc;n </strong><span style={{fontWeight: 400}}>.</span></p>
        <p><span style={{fontWeight: 400}}>Đối với c&aacute;c mục đ&iacute;ch của Ch&iacute;nh s&aacute;ch n&agrave;y, c&aacute;c thuật ngữ &ldquo; </span><strong>bạn </strong><span style={{fontWeight: 400}}>&rdquo; v&agrave; &ldquo; </span><strong>của bạn </strong><span style={{fontWeight: 400}}>&rdquo; c&oacute; nghĩa l&agrave; bạn với tư c&aacute;ch l&agrave; người d&ugrave;ng Dịch vụ.</span></p>
        <p><span style={{fontWeight: 400}}>Bằng c&aacute;ch sử dụng c&aacute;c dịch vụ, đăng k&yacute; t&agrave;i khoản với ch&uacute;ng t&ocirc;i hoặc truy cập v&agrave;o Nền tảng, bạn x&aacute;c nhận v&agrave; đồng &yacute; rằng bạn chấp nhận c&aacute;c phương ph&aacute;p, y&ecirc;u cầu v&agrave; / hoặc ch&iacute;nh s&aacute;ch được m&ocirc; tả trong ch&iacute;nh s&aacute;ch bảo mật n&agrave;y v&agrave; ch&uacute;ng t&ocirc;i cũng sẽ đảm bảo quyền ri&ecirc;ng tư của bạn trong phạm vi bạn cho ph&eacute;p, m&agrave; kh&ocirc;ng cần phải tự động sử dụng th&ocirc;ng tin của bạn, cụ thể như sau:</span></p>
        <ol>
            <li><strong> Phạm vi sử dụng th&ocirc;ng tin</strong></li>
        </ol>
        <p><span style={{fontWeight: 400}}>Ch&uacute;ng t&ocirc;i sẽ sử dụng th&ocirc;ng tin của bạn để cung cấp cho bạn những lợi &iacute;ch sau:</span></p>
        <ul>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Th&ocirc;ng b&aacute;o cho bạn về những thay đổi đối với dịch vụ của ch&uacute;ng t&ocirc;i;</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Cung cấp cho bạn hỗ trợ người d&ugrave;ng;</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>C&aacute; nh&acirc;n h&oacute;a nội dung bạn nhận được v&agrave; cung cấp cho bạn nội dung c&oacute; li&ecirc;n quan m&agrave; bạn sẽ quan t&acirc;m;</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Cho ph&eacute;p bạn chia sẻ Nội dung Người d&ugrave;ng v&agrave; tương t&aacute;c với những người d&ugrave;ng kh&aacute;c;</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>K&iacute;ch hoạt dịch vụ nhắn tin của ch&uacute;ng t&ocirc;i nếu bạn chọn sử dụng chức năng n&agrave;y;</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Cho ph&eacute;p bạn tham gia v&agrave;o chương tr&igrave;nh vật phẩm ảo;</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Th&ocirc;ng tin li&ecirc;n hệ cho bạn;</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Gi&uacute;p ch&uacute;ng t&ocirc;i ph&aacute;t hiện v&agrave; chống lạm dụng, hoạt động c&oacute; hại, gian lận, gửi thư r&aacute;c v&agrave; hoạt động bất hợp ph&aacute;p tr&ecirc;n nền tảng;</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Đảm bảo an to&agrave;n v&agrave; bảo mật của bạn bao gồm việc xem x&eacute;t nội dung người d&ugrave;ng, tin nhắn v&agrave; si&ecirc;u dữ liệu li&ecirc;n kết xem c&oacute; vi phạm Nguy&ecirc;n tắc cộng đồng của ch&uacute;ng t&ocirc;i v&agrave; nội dung kh&ocirc;ng ph&ugrave; hợp kh&aacute;c kh&ocirc;ng;</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Đảm bảo nội dung được tr&igrave;nh b&agrave;y theo c&aacute;ch hiệu quả nhất cho bạn v&agrave; thiết bị của bạn;</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Cải thiện, quảng b&aacute; v&agrave; ph&aacute;t triển Nền tảng v&agrave; th&uacute;c đẩy c&aacute;c chủ đề v&agrave; chiến dịch phổ biến tr&ecirc;n nền tảng;</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Thực hiện ph&acirc;n t&iacute;ch v&agrave; kiểm tra dữ liệu của Nền tảng để đảm bảo t&iacute;nh ổn định v&agrave; an to&agrave;n của nền tảng;</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Cho ph&eacute;p bạn tham gia v&agrave;o c&aacute;c t&iacute;nh năng tương t&aacute;c của nền tảng;</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Cho ph&eacute;p bạn giao lưu tr&ecirc;n nền tảng;</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Để đảm bảo rằng bạn đủ tuổi sử dụng Nền tảng của ch&uacute;ng t&ocirc;i (theo y&ecirc;u cầu của ph&aacute;p luật);</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Cung cấp cho bạn quảng c&aacute;o được c&aacute; nh&acirc;n h&oacute;a;</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Cung cấp cho bạn c&aacute;c dịch vụ dựa tr&ecirc;n vị tr&iacute; (nếu c&aacute;c dịch vụ đ&oacute; c&oacute; sẵn trong khu vực ph&aacute;p l&yacute; của bạn); r. Cung cấp th&ocirc;ng tin cho c&aacute;c thuật to&aacute;n của ch&uacute;ng t&ocirc;i;</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Thực thi c&aacute;c điều khoản, điều kiện v&agrave; ch&iacute;nh s&aacute;ch của ch&uacute;ng t&ocirc;i;</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Quản l&yacute; Nền tảng bao gồm xử l&yacute; sự cố; v&agrave;</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Tạo điều kiện thuận lợi cho việc thực hiện b&aacute;n h&agrave;ng, quảng c&aacute;o v&agrave; mua h&agrave;ng h&oacute;a v&agrave; dịch vụ, đồng thời cung cấp hỗ trợ người d&ugrave;ng.</span></li>
        </ul>
        <ol>
            <li><strong> Phương tiện v&agrave; c&ocirc;ng cụ m&agrave; bạn c&oacute; thể truy cập v&agrave; sửa dữ liệu c&aacute; nh&acirc;n của m&igrave;nh</strong></li>
        </ol>
        <ul>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Kh&aacute;ch h&agrave;ng c&oacute; quyền tự kiểm tra, cập nhật, sửa chữa hoặc hủy bỏ th&ocirc;ng tin c&aacute; nh&acirc;n của m&igrave;nh bằng c&aacute;ch đăng nhập v&agrave;o t&agrave;i khoản v&agrave; chỉnh sửa th&ocirc;ng tin c&aacute; nh&acirc;n hoặc y&ecirc;u cầu nh&acirc;n vi&ecirc;n OTT Th&aacute;i Nguy&ecirc;n thực hiện việc n&agrave;y.</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Kh&aacute;ch h&agrave;ng c&oacute; quyền gửi khiếu nại về chất lượng dịch vụ v&agrave; thời gian cung cấp dịch vụ đến OTT Th&aacute;i Nguy&ecirc;n. Khi tiếp nhận những phản hồi n&agrave;y, OTT Th&aacute;i Nguy&ecirc;n sẽ x&aacute;c nhận lại th&ocirc;ng tin. Trong trường hợp phản hồi của kh&aacute;ch h&agrave;ng l&agrave; ch&iacute;nh x&aacute;c, t&ugrave;y theo mức độ, OTT Th&aacute;i Nguy&ecirc;n sẽ c&oacute; những biện ph&aacute;p xử l&yacute; kịp thời.</span></li>
        </ul>
        <p><strong>III. Bảo mật dữ liệu c&aacute; nh&acirc;n của bạn</strong></p>
        <p><span style={{fontWeight: 400}}>Ch&uacute;ng t&ocirc;i c&oacute; c&aacute;c biện ph&aacute;p kỹ thuật v&agrave; tổ chức th&iacute;ch hợp để đảm bảo mức độ bảo mật ph&ugrave; hợp với khả năng xảy ra v&agrave; mức độ nghi&ecirc;m trọng kh&aacute;c nhau đối với c&aacute;c quyền v&agrave; tự do của bạn v&agrave; những người d&ugrave;ng kh&aacute;c. Ch&uacute;ng t&ocirc;i duy tr&igrave; c&aacute;c biện ph&aacute;p tổ chức v&agrave; kỹ thuật n&agrave;y v&agrave; sẽ sửa đổi ch&uacute;ng theo thời gian để cải thiện t&iacute;nh bảo mật tổng thể của hệ thống của ch&uacute;ng t&ocirc;i.</span></p>
        <p><span style={{fontWeight: 400}}>Đ&ocirc;i khi, ch&uacute;ng t&ocirc;i sẽ bao gồm c&aacute;c li&ecirc;n kết đến v&agrave; đi từ c&aacute;c trang web của mạng đối t&aacute;c, nh&agrave; quảng c&aacute;o v&agrave; chi nh&aacute;nh của ch&uacute;ng t&ocirc;i. Nếu bạn theo li&ecirc;n kết đến bất kỳ trang web n&agrave;o trong số n&agrave;y, xin lưu &yacute; rằng c&aacute;c trang web n&agrave;y c&oacute; ch&iacute;nh s&aacute;ch bảo mật của ri&ecirc;ng họ v&agrave; ch&uacute;ng t&ocirc;i kh&ocirc;ng chấp nhận bất kỳ tr&aacute;ch nhiệm hoặc nghĩa vụ ph&aacute;p l&yacute; n&agrave;o đối với c&aacute;c ch&iacute;nh s&aacute;ch n&agrave;y. Vui l&ograve;ng kiểm tra c&aacute;c ch&iacute;nh s&aacute;ch n&agrave;y trước khi bạn cung cấp bất kỳ th&ocirc;ng tin n&agrave;o tr&ecirc;n c&aacute;c trang web kh&ocirc;ng phải l&agrave; OTT Th&aacute;i Nguy&ecirc;n.</span></p>
        <p><span style={{fontWeight: 400}}>Ch&uacute;ng t&ocirc;i thực hiện c&aacute;c bước để đảm bảo rằng th&ocirc;ng tin của bạn được xử l&yacute; an to&agrave;n v&agrave; ph&ugrave; hợp với ch&iacute;nh s&aacute;ch n&agrave;y. Ch&uacute;ng t&ocirc;i sẽ bảo vệ dữ liệu c&aacute; nh&acirc;n của bạn, chẳng hạn như bằng m&atilde; h&oacute;a, nhưng ch&uacute;ng t&ocirc;i kh&ocirc;ng thể đảm bảo t&iacute;nh bảo mật cho th&ocirc;ng tin của bạn được truyền qua Nền tảng V&igrave; việc truyền th&ocirc;ng tin qua internet kh&ocirc;ng an to&agrave;n tuyệt đối; Trong một số trường hợp, việc truyền tải th&ocirc;ng tin của bạn l&agrave; rủi ro của ri&ecirc;ng bạn.</span></p>
        <ol>
            <li><strong> C&aacute;c trường hợp c&oacute; thể chia sẻ th&ocirc;ng tin</strong></li>
        </ol>
        <p><span style={{fontWeight: 400}}>Th&ocirc;ng tin của bạn được bảo mật nhưng trong một số trường hợp kh&ocirc;ng mong muốn, ch&uacute;ng t&ocirc;i buộc phải chia sẻ một số th&ocirc;ng tin nhất định với đối t&aacute;c để đưa ra những đ&aacute;nh gi&aacute; ph&aacute;t triển hơn về t&iacute;nh năng, lợi &iacute;ch cho OTT Th&aacute;i Nguy&ecirc;n, cho bạn v&agrave; cho đối t&aacute;c của ch&uacute;ng t&ocirc;i v&agrave; chỉ được chia sẻ trong những trường hợp Ch&uacute;ng t&ocirc;i buộc phải cung cấp, để bạn hiểu đầy đủ, cho OTT Th&aacute;i Nguy&ecirc;n th&ocirc;ng tin của bạn được đảm bảo t&iacute;nh ri&ecirc;ng tư nhất, cụ thể như sau:</span></p>
        <ol>
            <li><span style={{fontWeight: 400}}> Chia sẻ tr&ecirc;n OTT Th&aacute;i Nguy&ecirc;n, c&aacute;c ứng dụng, trang web v&agrave; t&iacute;ch hợp của b&ecirc;n thứ ba c&oacute; sẵn tr&ecirc;n hoặc sử dụng </span><strong>OTT Th&aacute;i Nguy&ecirc;n </strong><strong>.</strong></li>
        </ol>
        <p><span style={{fontWeight: 400}}>Khi bạn chọn sử dụng c&aacute;c ứng dụng, trang web hoặc dịch vụ kh&aacute;c của b&ecirc;n thứ ba sử dụng hoặc được t&iacute;ch hợp với OTT Th&aacute;i Nguy&ecirc;n, c&aacute;c ứng dụng, trang web hoặc dịch vụ đ&oacute; c&oacute; thể nhận được th&ocirc;ng tin về nội dung bạn đăng hoặc chia sẻ. V&iacute; dụ: khi bạn chơi tr&ograve; chơi với bạn b&egrave; tr&ecirc;n OTT Th&aacute;i Nguy&ecirc;n hoặc sử dụng n&uacute;t B&igrave;nh luận hoặc Chia sẻ của OTT Th&aacute;i Nguy&ecirc;n tr&ecirc;n một trang web, nh&agrave; ph&aacute;t triển của tr&ograve; chơi hoặc trang web đ&oacute; c&oacute; thể nhận được th&ocirc;ng tin về hoạt động của bạn trong tr&ograve; chơi hoặc nhận được nhận x&eacute;t hoặc li&ecirc;n kết đ&oacute; bạn chia sẻ từ trang tr&ecirc;n OTT Th&aacute;i Nguy&ecirc;n. Ngo&agrave;i ra, khi bạn tải xuống hoặc sử dụng c&aacute;c dịch vụ của b&ecirc;n thứ ba đ&oacute;, họ c&oacute; thể truy cập hồ sơ OTT Th&aacute;i Nguy&ecirc;n c&ocirc;ng khai của bạn v&agrave; bất kỳ th&ocirc;ng tin n&agrave;o bạn chia sẻ với họ. Th&ocirc;ng tin m&agrave; c&aacute;c dịch vụ của b&ecirc;n thứ ba n&agrave;y thu thập sẽ tu&acirc;n theo c&aacute;c điều khoản v&agrave; ch&iacute;nh s&aacute;ch của ri&ecirc;ng họ, kh&ocirc;ng phải của OTT Th&aacute;i Nguy&ecirc;n.</span></p>
        <ol start="2">
            <li><strong> Chủ mới.</strong></li>
        </ol>
        <p><span style={{fontWeight: 400}}>Nếu ch&uacute;ng t&ocirc;i thực hiện chuyển giao quyền sở hữu hoặc quyền kiểm so&aacute;t to&agrave;n bộ hoặc một phần của OTT Th&aacute;i Nguy&ecirc;n cho một tổ chức kh&aacute;c, ch&uacute;ng t&ocirc;i c&oacute; thể phải chuyển th&ocirc;ng tin của bạn cho chủ sở hữu mới.</span></p>
        <ol start="3">
            <li><strong> Đối t&aacute;c b&ecirc;n thứ ba</strong></li>
        </ol>
        <p><span style={{fontWeight: 400}}>Ch&uacute;ng t&ocirc;i l&agrave;m việc với c&aacute;c đối t&aacute;c b&ecirc;n thứ ba, những người gi&uacute;p ch&uacute;ng t&ocirc;i cung cấp v&agrave; cải tiến Sản phẩm hoặc C&ocirc;ng cụ kinh doanh của m&igrave;nh tr&ecirc;n OTT Th&aacute;i Nguy&ecirc;n để ph&aacute;t triển doanh nghiệp của ch&uacute;ng t&ocirc;i. Mục đ&iacute;ch của ch&uacute;ng t&ocirc;i l&agrave; điều h&agrave;nh c&aacute;c c&ocirc;ng ty của m&igrave;nh v&agrave; cung cấp c&aacute;c dịch vụ miễn ph&iacute; cho mọi người tr&ecirc;n to&agrave;n thế giới. Ch&uacute;ng t&ocirc;i kh&ocirc;ng b&aacute;n bất kỳ th&ocirc;ng tin n&agrave;o của bạn cho bất kỳ ai v&agrave; sẽ kh&ocirc;ng bao giờ b&aacute;n. Ngo&agrave;i ra, ch&uacute;ng t&ocirc;i &aacute;p đặt c&aacute;c hạn chế nghi&ecirc;m ngặt đối với c&aacute;c đối t&aacute;c c&oacute; thể sử dụng v&agrave; tiết lộ dữ liệu m&agrave; ch&uacute;ng t&ocirc;i cung cấp.</span></p>
        <ol start="4">
            <li><strong>Đối t&aacute;c sử dụng dịch vụ ph&acirc;n t&iacute;ch của ch&uacute;ng t&ocirc;i.</strong></li>
        </ol>
        <p><span style={{fontWeight: 400}}>Ch&uacute;ng t&ocirc;i chắc chắn phải cung cấp th&ocirc;ng tin chi tiết v&agrave; số liệu thống k&ecirc; tổng hợp để gi&uacute;p mọi người v&agrave; doanh nghiệp hiểu c&aacute;ch mọi người tương t&aacute;c với video v&agrave; nội dung kh&aacute;c trong v&agrave; ngo&agrave;i Sản phẩm OTT Th&aacute;i Nguy&ecirc;n để phục vụ cho việc cải thiện nền tảng cũng như ph&aacute;t triển nền tảng nhằm mang lại những trải nghiệm th&uacute; vị nhất cho bạn.</span></p>
        <ol start="5">
            <li><strong>Nh&agrave; quảng c&aacute;o.</strong></li>
        </ol>
        <p><span style={{fontWeight: 400}}>Ch&uacute;ng t&ocirc;i kh&ocirc;ng chia sẻ th&ocirc;ng tin nhận dạng c&aacute; nh&acirc;n bạn (th&ocirc;ng tin như t&ecirc;n hoặc địa chỉ email của bạn m&agrave; mọi người c&oacute; thể sử dụng để nhận dạng hoặc li&ecirc;n hệ với bạn) trừ khi bạn cho ph&eacute;p. Tuy nhi&ecirc;n, ch&uacute;ng t&ocirc;i cung cấp cho c&aacute;c nh&agrave; quảng c&aacute;o c&aacute;c b&aacute;o c&aacute;o về những người nh&igrave;n thấy quảng c&aacute;o của họ v&agrave; hiệu quả hoạt động của họ. V&iacute; dụ: ch&uacute;ng t&ocirc;i cung cấp th&ocirc;ng tin nh&acirc;n khẩu học v&agrave; sở th&iacute;ch chung cho c&aacute;c nh&agrave; quảng c&aacute;o (chẳng hạn như một phụ nữ trong độ tuổi từ 25 đến 34 sống ở Madrid đ&atilde; xem quảng c&aacute;o v&agrave; th&iacute;ch c&ocirc;ng nghệ phần mềm) để gi&uacute;p họ hiểu r&otilde; hơn về đối tượng của m&igrave;nh. Ch&uacute;ng t&ocirc;i cũng x&aacute;c nhận c&aacute;c quảng c&aacute;o tr&ecirc;n OTT Th&aacute;i Nguy&ecirc;n đ&atilde; nhắc bạn mua h&agrave;ng hoặc thực hiện h&agrave;nh động với nh&agrave; quảng c&aacute;o.</span></p>
        <ol start="6">
            <li><strong>Đối t&aacute;c đo lường.</strong></li>
        </ol>
        <p><span style={{fontWeight: 400}}>Ch&uacute;ng t&ocirc;i chia sẻ th&ocirc;ng tin về bạn với c&aacute;c c&ocirc;ng ty tổng hợp th&ocirc;ng tin đ&oacute; để cung cấp dữ liệu ph&acirc;n t&iacute;ch v&agrave; b&aacute;o c&aacute;o đo lường cho c&aacute;c đối t&aacute;c của ch&uacute;ng t&ocirc;i.</span></p>
        <ol start="7">
            <li><span style={{fontWeight: 400}}> &nbsp; </span><strong>Đối t&aacute;c cung cấp h&agrave;ng h&oacute;a v&agrave; dịch vụ tại OTT Th&aacute;i Nguy&ecirc;n.</strong></li>
        </ol>
        <p><span style={{fontWeight: 400}}>Khi bạn đăng k&yacute; nội dung cao cấp hoặc mua thứ g&igrave; đ&oacute; từ người b&aacute;n tr&ecirc;n OTT Th&aacute;i Nguy&ecirc;n, người tạo hoặc người b&aacute;n nội dung đ&oacute; c&oacute; thể nhận được th&ocirc;ng tin c&ocirc;ng khai của bạn v&agrave; c&aacute;c th&ocirc;ng tin kh&aacute;c m&agrave; bạn chia sẻ. với họ, cũng như th&ocirc;ng tin cần thiết để ho&agrave;n tất giao dịch, bao gồm chi tiết giao h&agrave;ng v&agrave; li&ecirc;n hệ.</span></p>
        <p><strong>số 8.</strong><span style={{fontWeight: 400}}>&nbsp; </span><strong>C&aacute;c nh&agrave; cung cấp v&agrave; c&aacute;c nh&agrave; cung cấp dịch vụ.</strong></p>
        <p><span style={{fontWeight: 400}}>Ch&uacute;ng t&ocirc;i cung cấp th&ocirc;ng tin v&agrave; nội dung cho c&aacute;c nh&agrave; cung cấp v&agrave; nh&agrave; cung cấp dịch vụ hỗ trợ c&ocirc;ng việc của ch&uacute;ng t&ocirc;i, chẳng hạn bằng c&aacute;ch cung cấp c&aacute;c dịch vụ cơ sở hạ tầng kỹ thuật, ph&acirc;n t&iacute;ch c&aacute;ch mọi người sử dụng OTT Th&aacute;i Nguy&ecirc;n, cung cấp dịch vụ kh&aacute;ch h&agrave;ng, hỗ trợ thanh to&aacute;n hoặc thực hiện khảo s&aacute;t.</span></p>
        <ol start="9">
            <li><strong>C&aacute;c nh&agrave; nghi&ecirc;n cứu v&agrave; học giả.</strong></li>
        </ol>
        <p><span style={{fontWeight: 400}}>Ngo&agrave;i ra, ch&uacute;ng t&ocirc;i cung cấp th&ocirc;ng tin v&agrave; nội dung cho c&aacute;c đối t&aacute;c nghi&ecirc;n cứu v&agrave; c&aacute;c học giả để tiến h&agrave;nh nghi&ecirc;n cứu n&acirc;ng cao nhận thức v&agrave; đổi mới hỗ trợ c&ocirc;ng việc hoặc sứ mệnh của ch&uacute;ng t&ocirc;i, đồng thời th&uacute;c đẩy kh&aacute;m ph&aacute; v&agrave; đổi mới li&ecirc;n quan đến c&aacute;c chủ đề ph&uacute;c lợi x&atilde; hội, tiến bộ c&ocirc;ng nghệ, lợi &iacute;ch c&ocirc;ng cộng, sức khỏe n&oacute;i chung v&agrave; ph&uacute;c lợi.</span></p>
        <ol start="10">
            <li><strong> C&aacute;c y&ecirc;u cầu ph&aacute;p l&yacute; hoặc thực thi ph&aacute;p luật </strong><span style={{fontWeight: 400}}>.</span></li>
        </ol>
        <p><span style={{fontWeight: 400}}>Ch&uacute;ng t&ocirc;i sẽ chia sẻ th&ocirc;ng tin của bạn với c&aacute;c cơ quan thực thi ph&aacute;p luật, cơ quan c&ocirc;ng quyền hoặc c&aacute;c tổ chức kh&aacute;c nếu luật ph&aacute;p y&ecirc;u cầu hoặc nếu cần thiết một c&aacute;ch hợp l&yacute; để l&agrave;m:</span></p>
        <p><span style={{fontWeight: 400}}>một.</span><span style={{fontWeight: 400}}>&nbsp; </span><span style={{fontWeight: 400}}>Tu&acirc;n thủ nghĩa vụ, quy tr&igrave;nh hoặc y&ecirc;u cầu ph&aacute;p l&yacute;;</span></p>
        <ol>
            <li><span style={{fontWeight: 400}}>Thực thi Điều khoản dịch vụ v&agrave; c&aacute;c thỏa thuận, ch&iacute;nh s&aacute;ch v&agrave; ti&ecirc;u chuẩn kh&aacute;c của ch&uacute;ng t&ocirc;i, bao gồm cả việc điều tra mọi vi phạm c&oacute; thể xảy ra đối với Điều khoản dịch vụ v&agrave; c&aacute;c thỏa thuận của ch&uacute;ng t&ocirc;i, c&aacute;c ch&iacute;nh s&aacute;ch v&agrave; ti&ecirc;u chuẩn đ&oacute;;</span></li>
            <li><span style={{fontWeight: 400}}>Ph&aacute;t hiện, ngăn chặn hoặc giải quyết c&aacute;c vấn đề về bảo mật, gian lận hoặc kỹ thuật; hoặc</span></li>
            <li><span style={{fontWeight: 400}}>Bảo vệ quyền, t&agrave;i sản hoặc sự an to&agrave;n của ch&uacute;ng t&ocirc;i, người d&ugrave;ng của ch&uacute;ng t&ocirc;i, b&ecirc;n thứ ba hoặc c&ocirc;ng ch&uacute;ng theo y&ecirc;u cầu hoặc cho ph&eacute;p của ph&aacute;p luật (bao gồm trao đổi th&ocirc;ng tin với c&aacute;c c&ocirc;ng ty v&agrave; tổ chức kh&aacute;c nhằm mục đ&iacute;ch chống gian lận v&agrave; giảm thiểu rủi ro t&iacute;n dụng).</span></li>
            <li><strong> Nh&oacute;m của ch&uacute;ng t&ocirc;i</strong></li>
        </ol>
        <p><span style={{fontWeight: 400}}>Nh&oacute;m của ch&uacute;ng t&ocirc;i được kết nối với nhau, để Ch&uacute;ng t&ocirc;i c&oacute; thể chia sẻ th&ocirc;ng tin của bạn với c&aacute;c th&agrave;nh vi&ecirc;n, c&ocirc;ng ty con hoặc chi nh&aacute;nh kh&aacute;c trong nh&oacute;m của ch&uacute;ng t&ocirc;i, để cung cấp Nền tảng bao gồm cải thiện v&agrave; tối ưu h&oacute;a Nền tảng, ngăn chặn việc sử dụng bất hợp ph&aacute;p v&agrave; hỗ trợ người d&ugrave;ng, mang lại cho bạn những lợi &iacute;ch tốt nhất c&oacute; thể khi trải nghiệm nền tảng của ch&uacute;ng t&ocirc;i.</span></p>
        <ol>
            <li><strong> C&aacute;c loại th&ocirc;ng tin được thu thập</strong></li>
        </ol>
        <p><span style={{fontWeight: 400}}>C&aacute;c loại th&ocirc;ng tin ch&uacute;ng t&ocirc;i thu thập t&ugrave;y thuộc v&agrave;o c&aacute;ch bạn sử dụng OTT Th&aacute;i Nguy&ecirc;n. Th&ocirc;ng tin n&agrave;y bao gồm th&ocirc;ng tin kỹ thuật v&agrave; h&agrave;nh vi về việc bạn sử dụng Nền tảng. Ch&uacute;ng t&ocirc;i cũng thu thập th&ocirc;ng tin về bạn nếu bạn tải xuống ứng dụng v&agrave; tương t&aacute;c với Nền tảng m&agrave; kh&ocirc;ng cần tạo t&agrave;i khoản. Bạn c&oacute; thể t&igrave;m hiểu c&aacute;ch truy cập v&agrave; x&oacute;a th&ocirc;ng tin ch&uacute;ng t&ocirc;i thu thập bằng c&aacute;ch v&agrave;o C&agrave;i đặt OTT Th&aacute;i Nguy&ecirc;n.</span></p>
        <ol>
            <li><strong>C&aacute;ch sử dụng của bạn </strong><span style={{fontWeight: 400}}>. Việc bạn tương t&aacute;c v&agrave; sử dụng OTT Th&aacute;i Nguy&ecirc;n sẽ gi&uacute;p cung cấp cho ch&uacute;ng t&ocirc;i một số th&ocirc;ng tin cần thiết cho sự ph&aacute;t triển ho&agrave;n chỉnh của nền tảng, mang đến cho bạn trải nghiệm tốt hơn. Ch&uacute;ng t&ocirc;i ph&acirc;n t&iacute;ch th&ocirc;ng tin về c&aacute;ch bạn sử dụng OTT Th&aacute;i Nguy&ecirc;n, chẳng hạn như loại nội dung bạn xem hoặc tương t&aacute;c, b&igrave;nh luận; c&aacute;c t&iacute;nh năng bạn sử dụng; h&agrave;nh động bạn thực hiện; những người hoặc t&agrave;i khoản m&agrave; bạn tương t&aacute;c; v&agrave; thời lượng, tần suất v&agrave; thời lượng hoạt động của bạn. V&iacute; dụ: ch&uacute;ng t&ocirc;i ghi lại thời gian bạn sử dụng v&agrave; thời điểm bạn sử dụng OTT Th&aacute;i Nguy&ecirc;n lần cuối, cũng như c&aacute;c video v&agrave; nội dung kh&aacute;c m&agrave; bạn xem tr&ecirc;n OTT Th&aacute;i Nguy&ecirc;n.</span></li>
        </ol>
        <p><span style={{fontWeight: 400}}>Ch&uacute;ng t&ocirc;i c&oacute; thể truy cập nội dung, bao gồm văn bản, h&igrave;nh ảnh v&agrave; video, được t&igrave;m thấy trong bộ nhớ cache của thiết bị của bạn, với sự cho ph&eacute;p của bạn. V&iacute; dụ: nếu bạn chọn bắt đầu chia sẻ nội dung với nền tảng của b&ecirc;n thứ ba hoặc chọn d&aacute;n nội dung từ bộ nhớ cache v&agrave;o Ứng dụng OTT Th&aacute;i Nguy&ecirc;n, ch&uacute;ng t&ocirc;i sẽ truy cập th&ocirc;ng tin đ&atilde; lưu trong bộ nhớ cache để phản hồi y&ecirc;u cầu của bạn. y&ecirc;u cầu của bạn. Ch&uacute;ng t&ocirc;i thu thập th&ocirc;ng tin th&ocirc;ng qua c&aacute;c cuộc khảo s&aacute;t, thử th&aacute;ch v&agrave; cuộc thi m&agrave; bạn tham gia. Ch&uacute;ng t&ocirc;i cũng thu thập th&ocirc;ng tin li&ecirc;n quan đến việc bạn sử dụng Nền tảng, chẳng hạn như c&aacute;ch bạn tham gia v&agrave;o Nền tảng, bao gồm cả c&aacute;ch bạn tương t&aacute;c với nội dung ch&uacute;ng t&ocirc;i cung cấp cho bạn, c&aacute;c quảng c&aacute;o bạn xem, video bạn xem v&agrave; c&aacute;c vấn đề bạn gặp phải, nội dung bạn th&iacute;ch, nội dung bạn lưu trong &ldquo;Danh s&aacute;ch video đ&atilde; lưu&rdquo;. Ch&uacute;ng t&ocirc;i cũng x&aacute;c định sở th&iacute;ch của bạn, bao gồm sở th&iacute;ch, giới t&iacute;nh v&agrave; tuổi t&aacute;c của bạn, cho mục đ&iacute;ch c&aacute; nh&acirc;n h&oacute;a nội dung.</span></p>
        <ol start="2">
            <li><strong>Th&ocirc;ng tin hồ sơ của bạn </strong><span style={{fontWeight: 400}}>. Bạn cung cấp cho ch&uacute;ng t&ocirc;i th&ocirc;ng tin khi bạn đăng k&yacute; tr&ecirc;n Nền tảng, bao gồm t&ecirc;n người d&ugrave;ng, mật khẩu, ng&agrave;y sinh (nếu c&oacute;), địa chỉ email v&agrave; / hoặc số điện thoại của bạn, th&ocirc;ng tin được tiết lộ trong hồ sơ người d&ugrave;ng v&agrave; ảnh hoặc video hồ sơ của bạn.</span></li>
            <li><strong>Mạng v&agrave; kết nối </strong><span style={{fontWeight: 400}}>. Bạn sử dụng c&aacute;c nền tảng của ch&uacute;ng t&ocirc;i bằng c&aacute;ch sử dụng c&aacute;c t&agrave;i khoản mạng x&atilde; hội của m&igrave;nh hoặc li&ecirc;n kết t&agrave;i khoản của bạn tại OTT Th&aacute;i Nguy&ecirc;n với c&aacute;c t&agrave;i khoản mạng x&atilde; hội của bạn hoặc sử dụng bất kỳ t&iacute;nh năng mạng n&agrave;o kết nối ch&uacute;ng. T&ocirc;i vẫn c&oacute; thể c&oacute; quyền truy cập v&agrave;o th&ocirc;ng tin về bạn m&agrave; bạn đ&atilde; tự nguyện cung cấp cho nh&agrave; cung cấp dịch vụ. Ch&uacute;ng t&ocirc;i đảm bảo lu&ocirc;n quản l&yacute; v&agrave; sử dụng dữ liệu c&aacute; nh&acirc;n của bạn theo ch&iacute;nh s&aacute;ch n&agrave;y.</span></li>
        </ol>
        <p><span style={{fontWeight: 400}}>Ch&uacute;ng t&ocirc;i c&oacute; thể thu thập th&ocirc;ng tin về địa chỉ thiết bị di động của bạn bằng c&aacute;c c&ocirc;ng nghệ như GPS, WI-FI, v.v. ch&uacute;ng t&ocirc;i thu thập, sử dụng, tiết lộ v&agrave; / hoặc xử l&yacute; th&ocirc;ng tin n&agrave;y cho một hoặc nhiều mục đ&iacute;ch bao gồm c&aacute;c dịch vụ được cung cấp dựa tr&ecirc;n vị tr&iacute; của bạn cho người kh&aacute;c bạn b&egrave; như một phần của Dịch vụ được cung cấp bởi c&aacute;c ứng dụng di động của ch&uacute;ng t&ocirc;i. Bạn ho&agrave;n to&agrave;n c&oacute; thể r&uacute;t quyền th&ocirc;ng qua c&agrave;i đặt tr&ecirc;n thiết bị của m&igrave;nh.</span></p>
        <ol start="4">
            <li><strong>Th&ocirc;ng tin về c&aacute;c giao dịch thực hiện tr&ecirc;n OTT Th&aacute;i Nguy&ecirc;n </strong><span style={{fontWeight: 400}}>. Nếu bạn sử dụng OTT Th&aacute;i Nguy&ecirc;n để mua v&eacute; Livestream hoặc thực hiện c&aacute;c giao dịch t&agrave;i ch&iacute;nh kh&aacute;c (chẳng hạn như khi bạn mua h&agrave;ng hoặc đ&oacute;ng g&oacute;p), ch&uacute;ng t&ocirc;i sẽ thu thập th&ocirc;ng tin về giao dịch mua hoặc giao dịch đ&oacute;. Th&ocirc;ng tin n&agrave;y bao gồm th&ocirc;ng tin thanh to&aacute;n, chẳng hạn như số thẻ t&iacute;n dụng hoặc thẻ ghi nợ v&agrave; th&ocirc;ng tin thẻ kh&aacute;c; th&ocirc;ng tin đăng nhập v&agrave; th&ocirc;ng tin t&agrave;i khoản kh&aacute;c; cũng như c&aacute;c chi tiết li&ecirc;n hệ, vận chuyển v&agrave; thanh to&aacute;n.</span></li>
            <li><strong>Th&ocirc;ng tin từ c&aacute;c b&ecirc;n thứ ba </strong><span style={{fontWeight: 400}}>. Bạn c&oacute; thể chọn chia sẻ dữ liệu nhất định với ch&uacute;ng t&ocirc;i từ c&aacute;c b&ecirc;n thứ ba hoặc th&ocirc;ng qua việc bạn sử dụng Nền tảng, ch&uacute;ng t&ocirc;i c&oacute; thể tự động thu thập dữ liệu của b&ecirc;n thứ ba đ&oacute;.</span></li>
        </ol>
        <p><span style={{fontWeight: 400}}>Ch&uacute;ng t&ocirc;i cung cấp th&ocirc;ng tin của bạn cho b&ecirc;n thứ 3 trong c&aacute;c trường hợp sau:</span></p>
        <ul>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Th&aacute;i Nguy&ecirc;n OTT Được bạn chấp thuận</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Dịch vụ OTT Th&aacute;i Nguy&ecirc;n cung cấp cần c&oacute; sự tương t&aacute;c với b&ecirc;n thứ ba hoặc do b&ecirc;n thứ ba cung cấp.</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Trong trường hợp bạn sử dụng Trang web li&ecirc;n kết tr&ecirc;n trang web của mạng x&atilde; hội n&agrave;y để truy cập, vui l&ograve;ng đọc kỹ c&aacute;c điều khoản sử dụng v&agrave; ch&iacute;nh s&aacute;ch bảo mật tr&ecirc;n c&aacute;c trang web đ&oacute;.</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>C&aacute;c c&ocirc;ng ty con, c&ocirc;ng ty li&ecirc;n kết v&agrave; c&aacute;c c&ocirc;ng ty li&ecirc;n quan của ch&uacute;ng t&ocirc;i.</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>C&aacute;c nh&agrave; thầu, đại l&yacute;, nh&agrave; cung cấp dịch vụ v&agrave; c&aacute;c b&ecirc;n thứ ba kh&aacute;c m&agrave; ch&uacute;ng t&ocirc;i thu&ecirc; để hỗ trợ hoặc bổ sung hoạt động kinh doanh của ch&uacute;ng t&ocirc;i.</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Người mua hoặc người kế thừa kh&aacute;c trong trường hợp s&aacute;p nhập, tho&aacute;i vốn, t&aacute;i cơ cấu, tổ chức lại, giải thể hoặc b&aacute;n hoặc chuyển nhượng một phần hoặc to&agrave;n bộ t&agrave;i sản của Nh&agrave; cung cấp trong đ&oacute; dữ liệu c&aacute; nh&acirc;n OTT Th&aacute;i Nguy&ecirc;n lưu trữ Dữ liệu người d&ugrave;ng c&oacute; trong t&agrave;i sản được chuyển nhượng.</span></li>
        </ul>
        <ol start="6">
            <li><strong> Đối t&aacute;c kinh doanh</strong></li>
        </ol>
        <p><span style={{fontWeight: 400}}>Nếu bạn chọn đăng k&yacute; hoặc sử dụng Nền tảng bằng c&aacute;ch sử dụng chi tiết t&agrave;i khoản mạng x&atilde; hội của m&igrave;nh (v&iacute; dụ: Facebook, Google, ...) hoặc dịch vụ đăng nhập, bạn sẽ cung cấp cho ch&uacute;ng t&ocirc;i hoặc cho ph&eacute;p cung cấp cho ch&uacute;ng t&ocirc;i t&ecirc;n người d&ugrave;ng, hồ sơ c&ocirc;ng khai của bạn, v&agrave; c&aacute;c th&ocirc;ng tin kh&aacute;c li&ecirc;n quan đến t&agrave;i khoản đ&oacute;. Theo đ&oacute;, ch&uacute;ng t&ocirc;i cũng sẽ chia sẻ một số th&ocirc;ng tin nhất định với mạng x&atilde; hội của bạn, chẳng hạn như ID ứng dụng, m&atilde; th&ocirc;ng b&aacute;o truy cập v&agrave; li&ecirc;n kết li&ecirc;n kết (URL) của bạn.</span></p>
        <ol start="7">
            <li><strong> Nh&agrave; quảng c&aacute;o, Mạng quảng c&aacute;o v&agrave; Nh&agrave; cung cấp dịch vụ Analytics</strong></li>
        </ol>
        <p><span style={{fontWeight: 400}}>Ch&uacute;ng t&ocirc;i sử dụng th&ocirc;ng tin m&agrave; ch&uacute;ng t&ocirc;i thu thập được từ bạn v&agrave; c&aacute;c tương t&aacute;c của bạn với Nền tảng v&agrave; c&aacute;c trang web của b&ecirc;n thứ ba kh&aacute;c, để x&aacute;c định những g&igrave; bạn c&oacute; thể quan t&acirc;m nhằm cung cấp cho bạn những quảng c&aacute;o ph&ugrave; hợp hơn. Th&ocirc;ng tin n&agrave;y cho ch&uacute;ng t&ocirc;i biết về c&aacute;c trang web bạn đ&atilde; truy cập, ứng dụng bạn đ&atilde; tải xuống v&agrave; c&aacute;c giao dịch mua bạn đ&atilde; thực hiện để ch&uacute;ng t&ocirc;i c&oacute; thể dự đo&aacute;n những điều kh&aacute;c m&agrave; bạn c&oacute; thể quan t&acirc;m trong tương lai v&agrave; xem x&eacute;t, đ&aacute;nh gi&aacute; hiệu quả của c&aacute;c quảng c&aacute;o tr&ecirc;n Nền tảng của ch&uacute;ng t&ocirc;i. Ch&uacute;ng t&ocirc;i thu thập th&ocirc;ng tin n&agrave;y bằng c&aacute;ch sử dụng Cookie v&agrave; c&aacute;c c&ocirc;ng nghệ tương tự tr&ecirc;n Nền tảng của ch&uacute;ng t&ocirc;i v&agrave; từ th&ocirc;ng tin tương tự m&agrave; ch&uacute;ng t&ocirc;i nhận được từ c&aacute;c b&ecirc;n thứ ba quảng c&aacute;o tr&ecirc;n Nền tảng của ch&uacute;ng t&ocirc;i v&agrave; c&oacute; c&aacute;c trang web / Ứng dụng m&agrave; bạn truy cập.</span></p>
        <ol start="8">
            <li><strong>Th&ocirc;ng tin kỹ thuật ch&uacute;ng t&ocirc;i thu thập về bạn </strong><span style={{fontWeight: 400}}>. Ch&uacute;ng t&ocirc;i thu thập một số th&ocirc;ng tin nhất định về thiết bị bạn sử dụng để truy cập Nền tảng, chẳng hạn như địa chỉ IP của bạn, số nhận dạng tr&igrave;nh duyệt, nh&agrave; cung cấp dịch vụ, c&agrave;i đặt m&uacute;i giờ, số nhận dạng cho mục ti&ecirc;u quảng c&aacute;o, kiểu thiết bị của bạn, hệ thống thiết bị, loại mạng, ID thiết bị, độ ph&acirc;n giải m&agrave;n h&igrave;nh v&agrave; hệ điều h&agrave;nh, t&ecirc;n v&agrave; loại ứng dụng v&agrave; tệp, kiểu hoặc nhịp g&otilde; ph&iacute;m, trạng th&aacute;i pin, c&agrave;i đặt &acirc;m thanh v&agrave; c&aacute;c thiết bị &acirc;m thanh được kết nối. Khi bạn đăng nhập từ nhiều thiết bị, ch&uacute;ng t&ocirc;i sẽ c&oacute; thể sử dụng th&ocirc;ng tin hồ sơ của bạn để x&aacute;c định hoạt động của bạn tr&ecirc;n c&aacute;c thiết bị n&agrave;y. Ch&uacute;ng t&ocirc;i cũng c&oacute; thể li&ecirc;n kết bạn với th&ocirc;ng tin được thu thập từ c&aacute;c thiết bị kh&aacute;c với những thiết bị bạn sử dụng để đăng nhập v&agrave;o Nền tảng.</span></li>
            <li><strong>Địa điểm. </strong><span style={{fontWeight: 400}}>Ch&uacute;ng t&ocirc;i thu thập th&ocirc;ng tin về vị tr&iacute; gần đ&uacute;ng của bạn, bao gồm th&ocirc;ng tin vị tr&iacute; dựa tr&ecirc;n thẻ SIM v&agrave; / hoặc địa chỉ IP của bạn. Ch&uacute;ng t&ocirc;i cũng c&oacute; thể thu thập dữ liệu vị tr&iacute; ch&iacute;nh x&aacute;c (chẳng hạn như GPS) với sự cho ph&eacute;p của bạn.</span></li>
            <li><strong>Dữ liệu cookie </strong><span style={{fontWeight: 400}}>: dữ liệu từ cookie được lưu trữ tr&ecirc;n thiết bị của bạn, bao gồm c&agrave;i đặt v&agrave; ID cookie. Bạn c&oacute; thể từ chối hoặc tắt Cookie bằng c&aacute;ch điều chỉnh c&agrave;i đặt trong tr&igrave;nh duyệt của m&igrave;nh.</span></li>
            <li><strong>Bằng chứng về danh t&iacute;nh hoặc tuổi của bạn </strong><span style={{fontWeight: 400}}>. Đ&ocirc;i khi, ch&uacute;ng t&ocirc;i y&ecirc;u cầu bạn cung cấp bằng chứng về danh t&iacute;nh hoặc độ tuổi để sử dụng một số t&iacute;nh năng nhất định, chẳng hạn như ph&aacute;t trực tiếp hoặc t&agrave;i khoản đ&atilde; x&aacute;c minh hoặc khi bạn đăng k&yacute; T&agrave;i khoản chuy&ecirc;n nghiệp, h&atilde;y đảm bảo rằng bạn đủ tuổi sử dụng Nền tảng hoặc y&ecirc;u cầu x&aacute;c minh .</span></li>
            <li><strong> Nơi dữ liệu c&aacute; nh&acirc;n của bạn được lưu trữ</strong></li>
        </ol>
        <p><span style={{fontWeight: 400}}>Dữ liệu c&aacute; nh&acirc;n m&agrave; ch&uacute;ng t&ocirc;i thu thập từ bạn c&oacute; thể được lưu trữ tr&ecirc;n m&aacute;y chủ đặt tại Việt Nam hoặc b&ecirc;n ngo&agrave;i quốc gia m&agrave; bạn sinh sống. Ch&uacute;ng t&ocirc;i duy tr&igrave; c&aacute;c m&aacute;y chủ ch&iacute;nh tr&ecirc;n to&agrave;n thế giới để cung cấp cho bạn c&aacute;c dịch vụ của ch&uacute;ng t&ocirc;i tr&ecirc;n to&agrave;n cầu v&agrave; li&ecirc;n tục.</span></p>
        <p><strong>VII. Ch&uacute;ng t&ocirc;i giữ dữ liệu c&aacute; nh&acirc;n của bạn trong bao l&acirc;u</strong></p>
        <p><span style={{fontWeight: 400}}>Ch&uacute;ng t&ocirc;i lưu giữ th&ocirc;ng tin của bạn trong thời gian cần thiết để cung cấp cho bạn dịch vụ. Trong trường hợp ch&uacute;ng t&ocirc;i kh&ocirc;ng cần th&ocirc;ng tin của bạn để cung cấp cho bạn dịch vụ, ch&uacute;ng t&ocirc;i chỉ giữ lại th&ocirc;ng tin đ&oacute; khi ch&uacute;ng t&ocirc;i c&oacute; mục đ&iacute;ch kinh doanh hợp ph&aacute;p để lưu giữ. Tuy nhi&ecirc;n, c&oacute; những trường hợp m&agrave; ch&uacute;ng t&ocirc;i c&oacute; thể lưu giữ dữ liệu đ&oacute; trong thời gian d&agrave;i hơn theo nghĩa vụ ph&aacute;p l&yacute; của ch&uacute;ng t&ocirc;i hoặc khi dữ liệu đ&oacute; cần thiết cho việc thiết lập, thực thi hoặc bảo vệ c&aacute;c khiếu nại ph&aacute;p l&yacute;.</span></p>
        <p><span style={{fontWeight: 400}}>Sau khi bạn chấm dứt việc sử dụng Nền tảng, ch&uacute;ng t&ocirc;i lưu trữ th&ocirc;ng tin của bạn ở định dạng tổng hợp v&agrave; ẩn danh.</span></p>
        <p><strong>VIII. Than phiền</strong></p>
        <p><span style={{fontWeight: 400}}>Mọi khiếu nại ph&aacute;t sinh trong qu&aacute; tr&igrave;nh sử dụng phải được gửi đến OTT Th&aacute;i Nguy&ecirc;n ngay sau khi sự kiện ph&aacute;t sinh khiếu nại. OTT Th&aacute;i Nguy&ecirc;n sẽ căn cứ v&agrave;o từng trường hợp cụ thể để c&oacute; phương &aacute;n giải quyết ph&ugrave; hợp. Khi thực hiện quyền khiếu nại, người khiếu nại c&oacute; nghĩa vụ cung cấp giấy tờ, chứng cứ, căn cứ li&ecirc;n quan đến việc khiếu nại v&agrave; phải chịu tr&aacute;ch nhiệm về nội dung khiếu nại, giấy tờ, chứng cứ, chứng cứ. Do ch&uacute;ng t&ocirc;i cung cấp theo quy định của ph&aacute;p luật. OTT Th&aacute;i Nguy&ecirc;n chỉ hỗ trợ v&agrave; giải quyết khiếu nại, tố c&aacute;o trong trường hợp bạn đ&atilde; ghi đầy đủ, trung thực v&agrave; ch&iacute;nh x&aacute;c c&aacute;c th&ocirc;ng tin khi đăng k&yacute; t&agrave;i khoản.</span></p>
        <ol>
            <li><strong> Biến đổi</strong></li>
        </ol>
        <p><span style={{fontWeight: 400}}>Ch&uacute;ng t&ocirc;i c&oacute; thể cập nhật Ch&iacute;nh s&aacute;ch Bảo mật n&agrave;y theo thời gian. Khi ch&uacute;ng t&ocirc;i cập nhật Ch&iacute;nh s&aacute;ch bảo mật của m&igrave;nh, ch&uacute;ng t&ocirc;i sẽ th&ocirc;ng b&aacute;o cho bạn bằng c&aacute;ch cập nhật ng&agrave;y &ldquo;Cập nhật lần cuối&rdquo; ở đầu ch&iacute;nh s&aacute;ch n&agrave;y v&agrave; đăng Ch&iacute;nh s&aacute;ch bảo mật mới v&agrave; cung cấp bất kỳ th&ocirc;ng b&aacute;o n&agrave;o kh&aacute;c theo y&ecirc;u cầu của ph&aacute;p luật. Việc bạn tiếp tục truy cập hoặc sử dụng Nền tảng sau ng&agrave;y cập nhật ch&iacute;nh s&aacute;ch được coi l&agrave; bạn chấp nhận ch&iacute;nh s&aacute;ch cập nhật.</span></p>
        <ol>
            <li><strong> Li&ecirc;n hệ</strong></li>
        </ol>
        <p><span style={{fontWeight: 400}}>Nếu bạn c&oacute; thắc mắc về ch&iacute;nh s&aacute;ch n&agrave;y, bạn c&oacute; thể li&ecirc;n hệ với ch&uacute;ng t&ocirc;i theo m&ocirc; tả b&ecirc;n dưới.</span></p>
        <p><span style={{fontWeight: 400}}>Bạn c&oacute; thể li&ecirc;n hệ với ch&uacute;ng t&ocirc;i trực tuyến qua:</span></p>
        <ul>
            <li style={{fontWeight: 400}}><strong>Fanpage Facebook </strong><a href="https://www.facebook.com/fanpagethainguyentv/"><strong>https://www.facebook.com/fanpagethainguyentv/</strong></a><strong>&nbsp; </strong><span style={{fontWeight: 400}}>;</span></li>
            <li style={{fontWeight: 400}}><strong>Email: </strong><a href="mailto:truyenhinhthainguyen@gmail.com"><strong>truyenhinhthainguyen@gmail.com</strong></a></li>
            <li style={{fontWeight: 400}}><strong>ĐT </strong><span style={{fontWeight: 400}}>: (02083) 855.439</span></li>
            <li style={{fontWeight: 400}}><strong>FAX </strong><span style={{fontWeight: 400}}>: (02083) 655.724.</span></li>
        </ul>
        <p>&nbsp;</p>
    </div>
  )
}
