
import Paging from './../others/paging'
export const renderDevice = (vm) => {
  return (
    <div>
      {/* <div className='inner__popup' onClick={(e) => vm.onClickChangeTab(e, 'device')}></div> */}
      <div className='account__device content__block'>
        <div className='account__device--block'>
          <div className='account__support--title'>Thiết bị đang đăng nhập</div>
          <div className='account__device--body'>
            {G.zu.get(vm.state, 'device').map((device, index) => {
              const datalength = G.zu.get(vm.state, 'device').length
              const cls = (i, datalength) => {
                if (i === 0) return { borderTop: 'unset' }
                if (i === datalength - 1) return { borderBottom: 'unset' }
              }
              return (
                <>
                  <div className='device__item' key={index} style={cls(index, datalength)}>
                    <div className='device__item--left'>
                      <img className='icon_device' src={'/images/static/' + ((Number(device.platform_id) !== 1 ? 'mobile' : 'desktop') + '.svg')} />
                    </div>
                    <div className='device__item--center'>
                      <div className='device__item--name'>{Number(device.platform_id) !== 1 ? device.device_name : G.zu.getBrowser(device.user_agent)}</div>
                      <div className='device__item--time'>Lần đăng nhập cuối:&nbsp; {device.updated_at.replaceAll('-', '/') || 0}</div>
                    </div>
                    <div className='device__item--right'>
                      <span className='rs-checkbox-small' onClick={(e) => vm.onClickDelDevice(e, device)}>
                        <i className='fa fa-trash' aria-hidden='true' /><b>Xóa</b>
                      </span>
                    </div>
                  </div>
                </>
              )
            })}
            {G.zu.clone(vm.state.meta).last_page > 1 && <Paging meta={G.zu.clone(vm.state.meta)} onPage={(page) => vm.handleChangePage(page)} />}
          </div>
        </div>
      </div>
      {vm.props.showWarning &&
        <div className='showWarning'>
          <div className='packpopup_curtain' onClick={e => vm.handleClosePopupWarning()} />
          <div className='packpopup_outer'>
            <div className='packpopup_wrap'>
              <img className='pp_close' src='static/img/icon_close.svg' alt='close' onClick={() => vm.handleClosePopupWarning()} />
              <div className='packpopup_head'>Cảnh báo đăng nhập</div>
              <div className='packpopup_body packpopup_body_noti'>
                <div className='packpopup_body_description'>Chúng tôi phát hiện một lần đăng nhập bất thường, đó có phải bạn? </div>
                <img className='packpopup_body_image imgWarning' src='/static/img/imgWarning.png' />
                <div className='pack_btns'>
                  <div className='pack_submit notActive' onClick={e => vm.handleClosePopupWarning()}>Không, hãy xác minh tài khoản</div>
                  <div className='pack_submit' onClick={e => vm.handleClosePopupWarning()}>Có, tôi là người thực hiện</div>
                </div>
              </div>
            </div>
          </div>
        </div>}
      {vm.state.deviceWarning &&
        <div className='showWarning'>
          <div className='packpopup_curtain' onClick={e => vm.handleCloseDelDeviceWarning()} />
          <div className='packpopup_outer'>
            <div className='packpopup_wrap'>
              <img className='pp_close' src='static/img/icon_close.svg' alt='close' onClick={() => vm.handleCloseDelDeviceWarning()} />
              <div className='packpopup_head'>Thông báo</div>
              <div className='packpopup_body packpopup_body_noti'>
                <div className='packpopup_body_description'>Bạn không thể xóa vì bạn đang đăng nhập trên thiết bị này</div>
                <div className='pack_btns'>
                  <div className='pack_submit' onClick={e => vm.handleCloseDelDeviceWarning()}>Hủy</div>
                </div>
              </div>
            </div>
          </div>
        </div>}
    </div>
  )
}
