import * as Jsx from './view.jsx'
// eslint-disable-next-line
import RxComponent from '/app/zcore/zcomponent'

class component extends RxComponent {
  constructor (props, context) {
    super(props, context, Jsx)
  }

  componentDidMount () {
    $('body').removeClass('controlHeader')
  }

  render () { return this.renderComponent(this) }
}

export default component
