import React from 'react'
import {} from './style/addpassword.css'

export const renderAdd = (vm) => {
  return (
    <div className='popup'>
      {renderAddPassword(vm)}
    </div>
  )
}

const renderAddPassword = (vm) => {
  const nameTab = 'Đổi mật khẩu'
  const tooltip = 'Cập nhật mật khẩu cho số điện thoại'
  const phone = vm.helperChangePhone(vm.state.editingDataLogin.phone)
  const showPassword = zget(vm.state, ['editingDataLogin', 'showPassword'], false)
  const validate = vm.state.validate
  const isPasswordValid = validate.isPasswordValid
  const isRepasswordValid = validate.isRepasswordValid
  const passwordMessage = validate.msgErr.password
  const repasswordMessage = validate.msgErr.repassword
  const password = vm.state.editingDataLogin.password
  const repassword = vm.state.editingDataLogin.repassword
  return (
    <div className='popup_addpassword_modal addpassword_modal'>
      <div className='addpassword_top'>
        <span className='addpassword_cancel_btn' onClick={() => vm.handleCloseForm()} />
      </div>
      <div className='addpassword_header'>{nameTab}</div>
      <div className='addpassword_tooltip'>{tooltip}</div>
      <div className='addpassword_phone'>{phone}</div>
      <div className='addpassword_body'>
        <div className='addpassword_form'>
          <label>Mật khẩu</label>
          <input className={isPasswordValid ? '' : 'error'} type={`${showPassword ? 'text' : 'password'}`} maxLength='32' placeholder='Ít nhất 8 ký tự' value={password} onChange={(e) => vm.onBlurDataLogin(e, 'password')} />
          <span className='addpassword_eye' onClick={() => vm.handleShowPassword()} />
          {!isPasswordValid && <label toggle className='error show'>{passwordMessage}</label>}
        </div>
        <div className='addpassword_form'>
          <label>Xác nhận mật khẩu</label>
          <input className={isRepasswordValid ? '' : 'error'} type={`${showPassword ? 'text' : 'password'}`} maxLength='32' placeholder='Ít nhất 8 ký tự' value={repassword} onChange={(e) => vm.onBlurDataLogin(e, 'repassword')} />
          <span className='addpassword_eye' onClick={() => vm.handleShowPassword()} />
          {!isRepasswordValid && <label className='error show'>{repasswordMessage}</label>}
        </div>
      </div>
      <div className='addpassword_button_group'>
        <div className='addpassword_button' onClick={() => vm.onClickBack('openLogin')}>Trở về</div>
        <div
          className={`addpassword_button ${(password.length >= 8 && repassword.length >= 8) ? 'active' : 'disable'}`}
          onClick={() => vm.handleCreateNewPassword()}
        >Xác nhận
        </div>
      </div>
    </div>
  )
}
