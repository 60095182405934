
export const renderPassword = (vm) => {
  return (
    <div>
      {/* <div className='inner__popup' onClick={(e) => vm.onClickChangeTab(e, 'about')}></div> */}
      <div className='account__support content__block'>
        <div className='account__support--block'>
          <div className='account__support--title'>Đổi mật khẩu</div>
          <div className='account__support--body' />
        </div>
      </div>
    </div>
  )
}
