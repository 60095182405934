export const closeForm = {
  isLogin: true,
  isRegister: false,
  isOTP: false,
  isOTPSSo: false,
  isOTPForget: false,
  isOTPRegister: false,
  isAddpassword: false,
  isRegisterSuccess: false,
  isUpdatePassword: false,
  isChangePasswordSuccess: false,
  isForget: false,
  isSelectMember: false,
  isTypeMemberPassword: false,
  isNewMember: false,
  isSSOWithPhone: false,
  isSSOSuccess: false
}

export const openLogin = {
  isLogin: true,
  isRegister: false,
  isOTP: false,
  isOTPSSo: false,
  isOTPForget: false,
  isOTPRegister: false,
  isAddpassword: false,
  isRegisterSuccess: false,
  isUpdatePassword: false,
  isChangePasswordSuccess: false,
  isForget: false,
  isSelectMember: false,
  isTypeMemberPassword: false,
  isNewMember: false,
  isSSOWithPhone: false,
  isSSOSuccess: false
}

// =========== MEMBER
export const openSelectMember = {
  isLogin: false,
  isRegister: false,
  isOTP: false,
  isOTPSSo: false,
  isOTPForget: false,
  isOTPRegister: false,
  isAddpassword: false,
  isRegisterSuccess: false,
  isUpdatePassword: false,
  isChangePasswordSuccess: false,
  isForget: false,
  isSelectMember: true,
  isTypeMemberPassword: false,
  isNewMember: false,
  isSSOWithPhone: false,
  isSSOSuccess: false
}

export const openEnterMemberPassword = {
  isLogin: false,
  isRegister: false,
  isOTP: false,
  isOTPSSo: false,
  isOTPForget: false,
  isOTPRegister: false,
  isAddpassword: false,
  isRegisterSuccess: false,
  isUpdatePassword: false,
  isChangePasswordSuccess: false,
  isForget: false,
  isSelectMember: false,
  isTypeMemberPassword: true,
  isNewMember: false,
  isSSOWithPhone: false,
  isSSOSuccess: false
}

export const openAddNewMember = {
  isLogin: false,
  isRegister: false,
  isOTP: false,
  isOTPSSo: false,
  isOTPForget: false,
  isOTPRegister: false,
  isAddpassword: false,
  isRegisterSuccess: false,
  isUpdatePassword: false,
  isChangePasswordSuccess: false,
  isForget: false,
  isSelectMember: false,
  isTypeMemberPassword: false,
  isNewMember: true,
  isSSOWithPhone: false,
  isSSOSuccess: false
}

export const openOTP = {
  isLogin: false,
  isRegister: false,
  isOTP: true,
  isOTPSSo: false,
  isOTPForget: false,
  isOTPRegister: false,
  isAddpassword: false,
  isRegisterSuccess: false,
  isUpdatePassword: false,
  isChangePasswordSuccess: false,
  isForget: false,
  isSelectMember: false,
  isTypeMemberPassword: false,
  isNewMember: false,
  isSSOWithPhone: false,
  isSSOSuccess: false
}

// =========== OTP
export const openOTPRetakePassword = {
  isLogin: false,
  isRegister: false,
  isOTP: false,
  isOTPSSo: false,
  isOTPForget: true,
  isOTPRegister: false,
  isAddpassword: false,
  isRegisterSuccess: false,
  isUpdatePassword: false,
  isChangePasswordSuccess: false,
  isForget: false,
  isSelectMember: false,
  isTypeMemberPassword: false,
  isNewMember: false,
  isSSOWithPhone: false,
  isSSOSuccess: false
}

export const openOTPRegisterForm = {
  isLogin: false,
  isRegister: false,
  isOTP: false,
  isOTPSSo: false,
  isOTPForget: false,
  isOTPRegister: true,
  isAddpassword: false,
  isRegisterSuccess: false,
  isUpdatePassword: false,
  isChangePasswordSuccess: false,
  isForget: false,
  isSelectMember: false,
  isTypeMemberPassword: false,
  isNewMember: false,
  isSSOWithPhone: false,
  isSSOSuccess: false
}

// =========== FORGET
export const openForgetPasswordForm = {
  isLogin: false,
  isRegister: false,
  isOTP: false,
  isOTPSSo: false,
  isOTPForget: false,
  isOTPRegister: false,
  isAddpassword: false,
  isRegisterSuccess: false,
  isUpdatePassword: false,
  isChangePasswordSuccess: false,
  isForget: true,
  isSelectMember: false,
  isTypeMemberPassword: false,
  isNewMember: false,
  isSSOWithPhone: false,
  isSSOSuccess: false
}

export const openTypeNewPasswordForm = {
  isLogin: false,
  isRegister: false,
  isOTP: false,
  isOTPSSo: false,
  isOTPForget: false,
  isOTPRegister: false,
  isAddpassword: false,
  isRegisterSuccess: false,
  isUpdatePassword: true,
  isChangePasswordSuccess: false,
  isForget: false,
  isSelectMember: false,
  isTypeMemberPassword: false,
  isNewMember: false,
  isSSOWithPhone: false,
  isSSOSuccess: false
}

export const openNewPasswordSuccessForm = {
  isLogin: false,
  isRegister: false,
  isOTP: false,
  isOTPSSo: false,
  isOTPForget: false,
  isOTPRegister: false,
  isAddpassword: false,
  isRegisterSuccess: false,
  isUpdatePassword: false,
  isChangePasswordSuccess: true,
  isForget: false,
  isSelectMember: false,
  isTypeMemberPassword: false,
  isNewMember: false,
  isSSOWithPhone: false,
  isSSOSuccess: false
}

// Register
export const openRegisterSuccessForm = {
  isLogin: false,
  isRegister: false,
  isOTP: false,
  isOTPSSo: false,
  isOTPForget: false,
  isOTPRegister: false,
  isAddpassword: false,
  isRegisterSuccess: true,
  isUpdatePassword: false,
  isChangePasswordSuccess: false,
  isForget: false,
  isSelectMember: false,
  isTypeMemberPassword: false,
  isNewMember: false,
  isSSOWithPhone: false,
  isSSOSuccess: false
}

export const openRegisterForm = {
  isLogin: false,
  isRegister: true,
  isOTP: false,
  isOTPSSo: false,
  isOTPForget: false,
  isOTPRegister: false,
  isAddpassword: false,
  isRegisterSuccess: false,
  isUpdatePassword: false,
  isChangePasswordSuccess: false,
  isForget: false,
  isSelectMember: false,
  isTypeMemberPassword: false,
  isNewMember: false,
  isSSOWithPhone: false,
  isSSOSuccess: false
}

export const openRegisterAddPasswordForm = {
  isLogin: false,
  isRegister: false,
  isOTP: false,
  isOTPSSo: false,
  isOTPForget: false,
  isOTPRegister: false,
  isAddpassword: true,
  isRegisterSuccess: false,
  isUpdatePassword: false,
  isChangePasswordSuccess: false,
  isForget: false,
  isSelectMember: false,
  isTypeMemberPassword: false,
  isNewMember: false,
  isSSOWithPhone: false,
  isSSOSuccess: false
}

// ======== OTP
export const openSSOWithPhoneForm = {
  isLogin: false,
  isRegister: false,
  isOTP: false,
  isOTPSSo: false,
  isOTPForget: false,
  isOTPRegister: false,
  isAddpassword: false,
  isRegisterSuccess: false,
  isUpdatePassword: false,
  isChangePasswordSuccess: false,
  isForget: false,
  isSelectMember: false,
  isTypeMemberPassword: false,
  isNewMember: false,
  isSSOWithPhone: true,
  isSSOSuccess: false
}

export const openSSOOTPForm = {
  isLogin: false,
  isRegister: false,
  isOTP: false,
  isOTPSSo: true,
  isOTPForget: false,
  isOTPRegister: false,
  isAddpassword: false,
  isRegisterSuccess: false,
  isUpdatePassword: false,
  isChangePasswordSuccess: false,
  isForget: false,
  isSelectMember: false,
  isTypeMemberPassword: false,
  isNewMember: false,
  isSSOWithPhone: false,
  isSSOSuccess: false
}

export const openSSOSuccessForm = {
  isLogin: false,
  isRegister: false,
  isOTP: false,
  isOTPSSo: false,
  isOTPForget: false,
  isOTPRegister: false,
  isAddpassword: false,
  isRegisterSuccess: false,
  isUpdatePassword: false,
  isChangePasswordSuccess: false,
  isForget: false,
  isSelectMember: false,
  isTypeMemberPassword: false,
  isNewMember: false,
  isSSOWithPhone: false,
  isSSOSuccess: true
}
