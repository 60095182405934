import { Component } from 'react'

class zComponent extends Component {
  // B U I L D I N
  constructor (props, context, Jsx) {
    super(props, context)
    this.data = this.props.data || {}
    this.api = this.data.path || ''
    this.name = this.data.name || ''
    this.typeMeta = G.zu.json(zget(G.zContentTypeMap, [this.name, 'fields', 'tree'], {}))

    this.types = ['String', 'Number', 'Boolean', 'Date', 'Array', 'Option', 'Document', 'OneToOne', 'OneToMany']
    this.filter = { sort: { created_at: 1 }, search: { is_deleted: 0 }, pagin: { page: 1, size: 10 } }
    this.state = { filter: this.filter, data: [], dataEditing: {} }

    // Bind this to Jsx
    for (const i in Jsx) {
      this[i] = (...paramPass) => Jsx[i](...paramPass, this)
    }
  }

  componentWillUnmount () {
    this._isUnmounted = true
  }

  // O V E R R I D E
  setState (...params) {
    if (!this._isUnmounted) {
      super.setState(...params)
    }
  }

  setStateNested (path, value, callback) {
    const paths = path.split('.')
    let layer1State = this.state[paths[0]]
    switch (paths.length) {
      case 1:
        layer1State = value; break
      case 2:
        layer1State[paths[1]] = value; break
      case 3:
        layer1State[paths[1]][paths[2]] = value; break
    }

    const newState = {}; newState[paths[0]] = layer1State
    this.setState(newState, callback)
  }

  // D A T A
  run (name, params) {
    if (params) { params.inthis = this } else { params = this }
    if (typeof this.props[name] !== 'undefined' && typeof this.props[name] === 'function') {
      return this.props[name](params)
    } else if (typeof this[name] !== 'undefined' && typeof this[name] === 'function') {
      return this[name]()
    }
  }

  runsafe (func) {
    if (typeof func === 'function' && !this._isUnmounted) { func() }
  }

  // C L I C K   E V E N T
  // B L U R   E V E N T
  onBlurData (value, name) {
    const editingData = this.state.editingData
    editingData[name] = value
    this.setState({ editingData: editingData })
  }

  onBlurDataFetch (evalue, path, modify) {
    zsafe(() => {
      const paths = path.split('.')
      let layer1State = this.state[paths[0]]

      const valueOrigin = evalue
      let value = evalue
      if (typeof modify === 'function') {
        value = modify(value)
      }

      switch (paths.length) {
        case 1:
          layer1State = value; break
        case 2:
          valueOrigin ? layer1State[paths[1]] = value : delete layer1State[paths[1]]; break
        case 3:
          valueOrigin ? layer1State[paths[1]][paths[2]] = value : delete layer1State[paths[1]][paths[2]]; break
      }

      const newState = {}; newState[paths[0]] = layer1State
      this.setState(newState, this.fetchData())
    })
  }
}

export default zComponent
