import { Link } from 'react-router-dom'
import Zimg from './../others/zimg'
// ./../../../public/images/assets/replay.svg

export const defaultCarouselConfig = {
  arrows: true,
  centerMode: false,
  infinite: true,
  showDots: false,
  slidesToSlide: 1,
  fade: false,
  swipeable: false,
  draggable: false,
  itemClass: '',
  sliderClass: '',
  dotListClass: '',
  renderButtonGroupOutsidedotListClass: ''
}

export const defaultCarouselResponsive = {
  desktop: { breakpoint: { max: 90000, min: 1080 }, items: 3, partialVisibilityGutter: 40 },
  tablet: { breakpoint: { max: 1080, min: 680 }, items: 2, partialVisibilityGutter: 30 },
  mobile: { breakpoint: { max: 680, min: 0 }, items: 2, partialVisibilityGutter: 30 }
}

export const defaultCarouselResponsiveBT1 = {
  desktop: { breakpoint: { max: 90000, min: 0 }, items: 1, partialVisibilityGutter: 40 }
}

export const defaultCarouselResponsiveBT3 = {
  desktop: { breakpoint: { max: 90000, min: 1080 }, items: 3, partialVisibilityGutter: 40 },
  tablet: { breakpoint: { max: 1080, min: 680 }, items: 2, partialVisibilityGutter: 30 },
  mobile: { breakpoint: { max: 680, min: 0 }, items: 1, partialVisibilityGutter: 30 }
}

const isLogin = G.zq.localLoad('loginby')

export const getLink = (data) => {
  if (!data.object_type_id) return '/'
  const linkMap = {
    0: '/no-route',
    1: '/truyen-hinh',
    2: '/xem-phim',
    9: '/mua-goi'
  }
  return `${G.zu.get(linkMap, [data.object_type_id], '/no-route')}/${G.zu.rxChangeSlug(G.zu.get(data, 'name', ''))}-${data.id}`
}

export const getBlockTitle = (datas) => <div className={`b_title ${datas.children.length === 0 ? 'none' : ''}`}>{G.zu.get(datas, 'name', '')} <Link to={G.zu.get(datas, 'deep_link')} className='btitle_viewall'>Tất cả</Link></div>
export const getThumb = (data) => {
  const urlPhotoLandscape = G.zu.get(data, 'photo_landscape')
  const urlPhotoLandscapeDetail = G.zu.get(data, 'photo_landscape.thumb_615x345')
  const urlPhotoDetail = G.zu.get(data, 'photo.thumb_640x368')
  const checkCaption = G.zu.get(data, 'caption')
  return (
    <Link onClick={() => ((isLogin === 'logout' || (typeof isLogin !== 'string')) && openLogin(data))} to={(isLogin !== 'logout' && (typeof isLogin === 'string')) ? getLink(data) : window.location.pathname} className='bitem_link bt5_link'>
      {urlPhotoLandscape != null && checkCaption === null
        ? <Zimg src={urlPhotoLandscapeDetail} thumb={G.zu.get(data, 'photo.tiny')} alt={data.name} />
        : <Zimg src={urlPhotoDetail} thumb={G.zu.get(data, 'photo.tiny')} alt={data.name} />}
    </Link>
  )
}

export const getThumbBartime = (data) => {
  const urlPhotoLandscape = G.zu.get(data, 'video.photo_landscape')
  const urlPhotoLandscapeDetail = G.zu.get(data, 'video.photo_landscape.thumb_615x345')
  const imgDefaultVideo = 'static/img/Landscape_thumb.png'
  const timeWatching = G.zu.get(data, 'time')
  // const duration = G.zu.get(data, 'episode.duration') === null ? '1:00:00' : G.zu.get(data, 'episode.duration')
  // let time = 0
  // if (duration.split(':').length === 3) {
  //   time = Number(duration.split(':')[0]) * 60 * 60 * 1000 + Number(duration.split(':')[1]) * 60 * 1000 + Number(duration.split(':')[2]) * 1000
  // } else {
  //   time = Number(duration.split(':')[0]) * 60 * 1000 + Number(duration.split(':')[1]) * 1000
  // }
  const duration = Number(data.episode.duration) * 1000
  const width = (timeWatching / duration) * 100
  const durationEps = Number(data.episode.duration) * 1000
  let durationConvert = new Date(durationEps).toISOString().slice(11, 19)
  if (durationConvert.split(':').length === 3 && durationConvert.split(':')[0] === '00') {
    durationConvert = durationConvert.substr(3, 5)
  }
  return (
    <Link onClick={() => ((isLogin === 'logout' || (typeof isLogin !== 'string')) && openLogin(data))} to={(isLogin !== 'logout' && (typeof isLogin === 'string')) ? ('/xem-phim/' + data.video.slug + '-' + data.video.id) : window.location.pathname} className='bitem_link bt5_link bartime_item'>
      {urlPhotoLandscape != null
        ? <Zimg src={urlPhotoLandscapeDetail} thumb={urlPhotoLandscapeDetail} alt={data.video.name} />
        : <Zimg src={imgDefaultVideo} thumb={imgDefaultVideo} alt={data.video.name} />}
      <div className='progressBar'><div className='progressBarShow' style={{ width: width + '%' }} /></div>
      {/* <div className='timebarDuration'>{data.episode.duration === null ? 'Update' : data.episode.duration}</div> */}
      <div className='timebarDuration'>{durationConvert}</div>
    </Link>
  )
}

export const getTitleBartime = (data, options) =>
  <Link onClick={() => (isLogin === 'logout' && openLogin(data))} to={isLogin !== 'logout' ? getLink(data) : window.location.pathname} className='bitem_link'>
    {getThumbBartime(data)}
    <div className='bitem_info'>
      <div className='bitem_infoin'>
        <div className='bitem_title bitem_title_bartime'>{data.video.name}</div>
        <div className='title_bartime_epgs'>{data.video.episode < 2 ? 'Full' : data.episode.name}</div>
      </div>
    </div>
  </Link>

export const getThumbPortrait = (data) => {
  const urlPhotoPortrait = G.zu.get(data, 'photo_portrait')
  const urlPhotoPortraitDetail = G.zu.get(data, 'photo_portrait.thumb_435x627')
  const urlPhotoDetail = G.zu.get(data, 'photo.thumb_640x368')
  if (urlPhotoPortrait != null) {
    return <Zimg src={urlPhotoPortraitDetail} thumb={G.zu.get(data, 'photo.tiny')} alt={data.name} />
  } else {
    return <Zimg src={urlPhotoDetail} thumb={G.zu.get(data, 'photo.tiny')} alt={data.name} />
  }
}

export const getThumbBig = (data) =>
  <Zimg src={G.zu.get(data, 'photo.thumb_1300x481')} thumb={G.zu.get(data, 'photo.tiny')} alt={data.name} />

export const getTitle = (data, options) =>
  <Link onClick={() => (isLogin === 'logout' && openLogin(data))} to={isLogin !== 'logout' ? getLink(data) : window.location.pathname} className='bitem_link'>
    {getThumb(data)}
    <div className='bitem_info'>
      <div className='bitem_infoin'>
        <div className='bitem_title'>{data.name}</div>
        <div className='bitem_btn-trailer'>Xem trailer</div>
        <div className='bitem_btn'>Xem ngay</div>
        {G.zu.runSafe(() => { return options.after() })}
      </div>
    </div>
  </Link>

export const getTitleChannel = (data, options) =>
  <Link onClick={() => (isLogin === 'logout' && openLogin(data))} to={isLogin !== 'logout' ? getLink(data) : window.location.pathname} className='bitem_link'>
    {getThumb(data)}
    {data.is_catchup === 1 ? <div className='is_catchup'><img src='images/static/replay.svg' alt='replay' /></div> : <div />}
    <div className='bitem_info'>
      <div className='bitem_infoin'>
        <div className='bitem_title'>{data.name}</div>
        <div className='bitem_btn-trailer'>Xem trailer</div>
        <div className='bitem_btn'>Xem ngay</div>
        {G.zu.runSafe(() => { return options.after() })}
      </div>
    </div>
  </Link>

export const getTitlePortrait = (data, options) =>
  <Link onClick={() => (isLogin === 'logout' && openLogin(data))} to={isLogin !== 'logout' ? getLink(data) : window.location.pathname} className='bitem_link'>
    {getThumbPortrait(data)}
    <div className='bitem_info'>
      <div className='bitem_infoin'>
        <div className='bitem_title'>{data.name}</div>
        <div className='bitem_btn-trailer'>Xem trailer</div>
        <div className='bitem_btn'>Xem ngay</div>
        {G.zu.runSafe(() => { return options.after() })}
      </div>
    </div>
  </Link>

export const getTitleBlock1 = (data, options) =>
  <Link onClick={() => (isLogin === 'logout' && openLogin(data))} to={isLogin !== 'logout' ? getLink(data) : window.location.pathname} className='bitem_link'>
    {getThumbBig(data)}
    <div className='bitem_info'>
      <div className='bitem_infoin'>
        <div className='bitem_title'>{data.name}</div>
        <div className='bitem_desc'>{data.caption}</div>
        <div className='bitem_btn-trailer'>Xem trailer</div>
        <div className='bitem_btn'>Xem ngay</div>
        {G.zu.runSafe(() => { return options.after() })}
      </div>
    </div>
  </Link>

const openLogin = (data) => {
  console.log(getLink(data))
  $(() => {
    document.getElementById('rmenu_item_user').click()
  })
}

// const handleOpenLogin = () => {
//   const member = G.zq.localLoad('member')
//   const user = G.zu.array(member)[0]
//   if (!user) {
//     $(() => {
//       document.getElementById('rmenu_item_user').click()
//     })
//   }
// }
