import * as EditForm from './accountEditForm.jsx'
export const renderAccount = (vm) => {
  const name = G.zu.get(vm.state, 'user.name')
  const avatarUrl = G.zu.get(vm.state, 'user.avatar.thumbnail')
  const avtSSO = vm.state.user.sso_avatar
  const showName = name && name !== null ? name : 'Ẩn danh'
  return (
    <div className='account_profile_wrap'>
      <div className='account__profile'>
        <div>
          <div style={{ display: 'flex' }}>
            <div className='profile_avatar__wrap'>
              {vm.state.user.avatar !== null ?
                <img className='profile_avartar__img' src={!avatarUrl ? '/static/img/avtDefault.png' : avatarUrl} /> :
                <img className='profile_avartar__img' src={(avtSSO === null || G.zq.localLoad('imgSsoFails')) ? '/static/img/avtDefault.png' : avtSSO} />}
              <input type='file' name='' id='inputAvatar' onChange={(e) => vm.handleUploadAvatar(e)} style={{ display: 'none' }} />
              <img onClick={(e) => vm.onClickUploadAvatar()} className='edit__button' src='/images/static/edit_pen.svg' />
            </div>
            <div className='profile__info--wrap'>
              <div className='account__profile--name'>{showName}</div>
              <div className='logout__button' onClick={(e) => vm.onClickLogout()}>Đăng xuất</div>
            </div>
          </div>
        </div>
      </div>
      {EditForm.renderFormEditProfile(vm)}
    </div>
  )
}
