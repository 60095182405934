export const dataList3 = {
  id: 457,
  name: 'HTV',
  display_type: 3,
  deep_link: null,
  children: [
    {
      id: 189,
      object_type_id: 1,
      name: 'HTV7',
      caption: 'HTV7',
      deep_link: null,
      photo: {
        thumbnail: 'https://cdnstatic.mcvgo.vn/11516/conversions/60f1a4aad7cd8_HTV7-thanhphan-thumb.png',
        thumb_103x103: 'https://cdnstatic.mcvgo.vn/11516/conversions/60f1a4aad7cd8_HTV7-thanhphan-103_103.png',
        thumb_1300x481: 'https://cdnstatic.mcvgo.vn/11516/conversions/60f1a4aad7cd8_HTV7-thanhphan-1300_481.png',
        thumb_310x174: 'https://cdnstatic.mcvgo.vn/11516/conversions/60f1a4aad7cd8_HTV7-thanhphan-310_174.png',
        thumb_420x237: 'https://cdnstatic.mcvgo.vn/11516/conversions/60f1a4aad7cd8_HTV7-thanhphan-420_237.png',
        thumb_640x368: 'https://cdnstatic.mcvgo.vn/11516/conversions/60f1a4aad7cd8_HTV7-thanhphan-640_368.png',
        thumb_302x468: 'https://cdnstatic.mcvgo.vn/11516/conversions/60f1a4aad7cd8_HTV7-thanhphan-302_468.png',
        thumb_200x310: 'https://cdnstatic.mcvgo.vn/11516/conversions/60f1a4aad7cd8_HTV7-thanhphan-200_310.png',
        tiny: 'https://cdnstatic.mcvgo.vn/11516/conversions/60f1a4aad7cd8_HTV7-thanhphan-tiny.png'
      },
      photo_portrait: null,
      photo_landscape: null
    },
    {
      id: 186,
      object_type_id: 1,
      name: 'HTV9',
      caption: 'HTV9',
      deep_link: null,
      photo: {
        thumbnail: 'https://cdnstatic.mcvgo.vn/11507/conversions/60f1a2846fe48_HTV9-thanhphan-thumb.png',
        thumb_103x103: 'https://cdnstatic.mcvgo.vn/11507/conversions/60f1a2846fe48_HTV9-thanhphan-103_103.png',
        thumb_1300x481: 'https://cdnstatic.mcvgo.vn/11507/conversions/60f1a2846fe48_HTV9-thanhphan-1300_481.png',
        thumb_310x174: 'https://cdnstatic.mcvgo.vn/11507/conversions/60f1a2846fe48_HTV9-thanhphan-310_174.png',
        thumb_420x237: 'https://cdnstatic.mcvgo.vn/11507/conversions/60f1a2846fe48_HTV9-thanhphan-420_237.png',
        thumb_640x368: 'https://cdnstatic.mcvgo.vn/11507/conversions/60f1a2846fe48_HTV9-thanhphan-640_368.png',
        thumb_302x468: 'https://cdnstatic.mcvgo.vn/11507/conversions/60f1a2846fe48_HTV9-thanhphan-302_468.png',
        thumb_200x310: 'https://cdnstatic.mcvgo.vn/11507/conversions/60f1a2846fe48_HTV9-thanhphan-200_310.png',
        tiny: 'https://cdnstatic.mcvgo.vn/11507/conversions/60f1a2846fe48_HTV9-thanhphan-tiny.png'
      },
      photo_portrait: null,
      photo_landscape: null
    },
    {
      id: 194,
      object_type_id: 1,
      name: 'Thuần Việt',
      caption: 'Thuần Việt',
      deep_link: null,
      photo: {
        thumbnail: 'https://cdnstatic.mcvgo.vn/11535/conversions/60f2920001be5_thuanviet-thanhphan-thumb.png',
        thumb_103x103: 'https://cdnstatic.mcvgo.vn/11535/conversions/60f2920001be5_thuanviet-thanhphan-103_103.png',
        thumb_1300x481: 'https://cdnstatic.mcvgo.vn/11535/conversions/60f2920001be5_thuanviet-thanhphan-1300_481.png',
        thumb_310x174: 'https://cdnstatic.mcvgo.vn/11535/conversions/60f2920001be5_thuanviet-thanhphan-310_174.png',
        thumb_420x237: 'https://cdnstatic.mcvgo.vn/11535/conversions/60f2920001be5_thuanviet-thanhphan-420_237.png',
        thumb_640x368: 'https://cdnstatic.mcvgo.vn/11535/conversions/60f2920001be5_thuanviet-thanhphan-640_368.png',
        thumb_302x468: 'https://cdnstatic.mcvgo.vn/11535/conversions/60f2920001be5_thuanviet-thanhphan-302_468.png',
        thumb_200x310: 'https://cdnstatic.mcvgo.vn/11535/conversions/60f2920001be5_thuanviet-thanhphan-200_310.png',
        tiny: 'https://cdnstatic.mcvgo.vn/11535/conversions/60f2920001be5_thuanviet-thanhphan-tiny.png'
      },
      photo_portrait: null,
      photo_landscape: null
    },
    {
      id: 193,
      object_type_id: 1,
      name: 'HTVC Du lịch',
      caption: 'HTVC Du lịch',
      deep_link: null,
      photo: {
        thumbnail: 'https://cdnstatic.mcvgo.vn/11533/conversions/60f291bb663b2_HVTC-dulich-thanhphan-thumb.png',
        thumb_103x103: 'https://cdnstatic.mcvgo.vn/11533/conversions/60f291bb663b2_HVTC-dulich-thanhphan-103_103.png',
        thumb_1300x481: 'https://cdnstatic.mcvgo.vn/11533/conversions/60f291bb663b2_HVTC-dulich-thanhphan-1300_481.png',
        thumb_310x174: 'https://cdnstatic.mcvgo.vn/11533/conversions/60f291bb663b2_HVTC-dulich-thanhphan-310_174.png',
        thumb_420x237: 'https://cdnstatic.mcvgo.vn/11533/conversions/60f291bb663b2_HVTC-dulich-thanhphan-420_237.png',
        thumb_640x368: 'https://cdnstatic.mcvgo.vn/11533/conversions/60f291bb663b2_HVTC-dulich-thanhphan-640_368.png',
        thumb_302x468: 'https://cdnstatic.mcvgo.vn/11533/conversions/60f291bb663b2_HVTC-dulich-thanhphan-302_468.png',
        thumb_200x310: 'https://cdnstatic.mcvgo.vn/11533/conversions/60f291bb663b2_HVTC-dulich-thanhphan-200_310.png',
        tiny: 'https://cdnstatic.mcvgo.vn/11533/conversions/60f291bb663b2_HVTC-dulich-thanhphan-tiny.png'
      },
      photo_portrait: null,
      photo_landscape: null
    }
  ]
}
