/* global localStorage, */
import { combineReducers, createStore } from 'redux'
import {} from './zglobal'

let initialState = {
  rxnav: { status: false, navs: {} },
  auth: [{ code: 0 }],
  keyword: '',
  section: '',
  errorCode: {},
  test: '',
  scrollComment: 0,
  showWarning: '',
  usernameProfile: '',
  avtProfile: ''
}

const initialStateLate = {
  rxnav: { status: false, navs: {} },
  auth: G.zu.json(G.zq.localLoad('authenticator')),
  keyword: '',
  section: '',
  errorCode: {},
  test: '',
  scrollComment: 0,
  showWarning: '',
  usernameProfile: '',
  avtProfile: ''
}

// A C T I O N S //
// Demo
// Status
export const actionTest = (test) => ({
  type: 'TEST',
  test
})

export const actionScrollComment = (scrollComment) => ({
  type: 'SCROLLCOMMENT',
  scrollComment
})

export const actionShowWaring = (showWarning) => ({
  type: 'SHOWWARNING',
  showWarning
})

export const actionSearch = (keyword) => ({
  type: 'SEARCH',
  keyword
})

export const actionSection = (section) => ({
  type: 'SECTION',
  section
})

export const actionPayment = (errorCode) => ({
  type: 'PAYMENT',
  errorCode
})

export const actionAuth = (auth) => ({
  type: 'AUTH',
  auth
})

export const rxnavToggle = (id, payload) => ({
  type: 'NAV_TOGGLE',
  id
})

export const rxnavChange = (id, payload) => ({
  type: 'NAV_CHANGE',
  id
})

export const rxnavClose = (id, payload) => ({
  type: 'NAV_CLOSE',
  id,
  payload
})

export const actionUsernameProfile = (usernameProfile) => ({
  type: 'USERNAMEPROFILE',
  usernameProfile
})

export const actionAvtProfile = (avtProfile) => ({
  type: 'AVTPROFILE',
  avtProfile
})

// R E D U C E R S //
export const test = (state = initialState.test, action) => {
  switch (action.type) {
    case 'TEST': {
      return action.test
    }

    default: {
      return state
    }
  }
}

export const scrollComment = (state = initialState.scrollComment, action) => {
  switch (action.type) {
    case 'SCROLLCOMMENT': {
      return action.scrollComment
    }

    default: {
      return state
    }
  }
}

export const showWarning = (state = initialState.showWarning, action) => {
  switch (action.type) {
    case 'SHOWWARNING': {
      return action.showWarning
    }

    default: {
      return state
    }
  }
}

export const keyword = (state = initialState.keyword, action) => {
  switch (action.type) {
    case 'SEARCH': {
      return action.keyword
    }

    default: {
      return state
    }
  }
}

export const section = (state = initialState.section, action) => {
  switch (action.type) {
    case 'SECTION': {
      return action.section
    }

    default: {
      return state
    }
  }
}

export const errorCode = (state = initialState.errorCode, action) => {
  switch (action.type) {
    case 'PAYMENT': {
      return action.errorCode
    }

    default: {
      return state
    }
  }
}

export const auth = (state = initialState.auth, action) => {
  switch (action.type) {
    case 'AUTH': {
      return action.auth
    }

    default: {
      return state
    }
  }
}

export const rxnav = (state = initialState.rxnav, action) => {
  switch (action.type) {
    case 'NAV_TOGGLE': {
      state.navs[action.id] = typeof (state.navs[action.id]) === 'undefined' ? 0 : Boolean(state.navs[action.id])
      state.navs[action.id] = !state.navs[action.id]
      return {
        status: !state.status,
        navs: state.navs
      }
    }

    case 'NAV_CHANGE': {
      state.navs[action.id] = true
      return {
        status: true,
        navs: state.navs
      }
    }

    case 'NAV_CLOSE': {
      state.navs[action.id] = 0
      return {
        status: !state.status,
        navs: state.navs
      }
    }

    default: {
      return state
    }
  }
}

export const reset = (state = initialState, action) => {
  switch (action.type) {
    case 'LOG_OUT': {
      const newState = initialState
      return newState
    }

    case 'RESET_STORE': {
      const newState = initialStateLate
      return newState
    }

    default: {
      return state
    }
  }
}

export const usernameProfile = (state = initialState.usernameProfile, action) => {
  switch (action.type) {
    case 'USERNAMEPROFILE': {
      return action.usernameProfile
    }

    default: {
      return state
    }
  }
}

export const avtProfile = (state = initialState.avtProfile, action) => {
  switch (action.type) {
    case 'AVTPROFILE': {
      return action.avtProfile
    }

    default: {
      return state
    }
  }
}

export const reducers = combineReducers({
  rxnav, auth, keyword, section, errorCode, test, scrollComment, showWarning, usernameProfile, avtProfile
})

export const rootReducer = (state, action) => {
  if (action.type === 'RESET_STORE') {
    return reducers(initialStateLate, action)
  } else {
    return reducers(state, action)
  }
}

// S T O R E S //
export function configureStore (initialState = {}) {
  const store = createStore(rootReducer, initialState)
  store.subscribe(() => {})

  if (typeof window !== 'undefined') {
    window.store = store
  }

  return store
}

// Set store
if (typeof window !== 'undefined') {
  window.storeReset = () => {
    initialState = initialStateLate
    window.store.dispatch({ type: 'RESET_STORE' })
  }
}

export function rxsetLocal (cname, cvalue) {
  if (typeof window !== 'undefined') {
    localStorage.setItem(cname, cvalue)
  }
}

export function rxgetLocal (cname, cdefault) {
  if (typeof window !== 'undefined') {
    return localStorage.getItem(cname) || cdefault
  } else {
    return cdefault
  }
}

export const store = configureStore()
