import React from 'react'
import { Link } from 'react-router-dom'
import MultiCarousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { } from './styles/bt1.scss'
import { } from './styles/bt2.scss'
import * as displayUlti from './displayUlti.jsx'
import { display01To10 } from './display01To10.jsx'

const types = {
  ...display01To10,

  // slide 3 phim, hình ngang
  type11: (datas) =>
    <div key={G.zu.get(datas, 'id')} className='blocktype bt11'>
      {displayUlti.getBlockTitle(datas)}
      <MultiCarousel
        {...displayUlti.defaultCarouselConfig}
        responsive={{
          desktop: { breakpoint: { max: 90000, min: 769 }, items: 3, partialVisibilityGutter: 30 },
          mobile: { breakpoint: { max: 768, min: 0 }, items: 2, partialVisibilityGutter: 30 }
        }}
      >
        {G.zu.array(datas.children).map((data, index) =>
          <div key={index}>
            {displayUlti.getTitle(data)}
          </div>)}
      </MultiCarousel>
    </div>,

  // slide 4 phim, không có bartime
  type12: (datas) =>
    <div key={G.zu.get(datas, 'id')} className='blocktype bt12'>
      {displayUlti.getBlockTitle(datas)}
      <MultiCarousel
        {...displayUlti.defaultCarouselConfig}
        showDots={false}
        responsive={{
          desktop2: { breakpoint: { max: 90000, min: 1401 }, items: 4, partialVisibilityGutter: 30 },
          desktop: { breakpoint: { max: 1400, min: 1201 }, items: 4, partialVisibilityGutter: 30 },
          tablet2: { breakpoint: { max: 1200, min: 992 }, items: 4, partialVisibilityGutter: 30 },
          tablet: { breakpoint: { max: 992, min: 769 }, items: 4, partialVisibilityGutter: 30 },
          mobile2: { breakpoint: { max: 768, min: 581 }, items: 3, partialVisibilityGutter: 30 },
          mobile: { breakpoint: { max: 580, min: 0 }, items: 2, partialVisibilityGutter: 30 }
        }}
      >
        {G.zu.array(datas.children).map((data, index) => <div key={index}>{displayUlti.getTitle(data)}</div>)}
      </MultiCarousel>
    </div>,

  // slide 4 phim, không có bartime
  type13: (datas) =>
    <div key={G.zu.get(datas, 'id')} className='blocktype bt7'>
      {displayUlti.getBlockTitle(datas)}
      <MultiCarousel {...displayUlti.defaultCarouselConfig} responsive={displayUlti.defaultCarouselResponsive}>
        {G.zu.array(datas.children).map((data, index) =>
          <div key={index}>
            {displayUlti.getTitle(data, { after: () => <div className='bitem_purchase'>Mua gói</div> })}
          </div>)}
      </MultiCarousel>
    </div>,

  // slide 4 phim có bartime
  type20: (datas) =>
    <div key={G.zu.get(datas, 'id')} className='blocktype bt7'>
      {displayUlti.getBlockTitle(datas)}
      <MultiCarousel
        {...displayUlti.defaultCarouselConfig}
        showDots={false}
        responsive={{
          desktop2: { breakpoint: { max: 90000, min: 1401 }, items: 4, partialVisibilityGutter: 30 },
          desktop: { breakpoint: { max: 1400, min: 1201 }, items: 4, partialVisibilityGutter: 30 },
          tablet2: { breakpoint: { max: 1200, min: 992 }, items: 4, partialVisibilityGutter: 30 },
          tablet: { breakpoint: { max: 992, min: 769 }, items: 4, partialVisibilityGutter: 30 },
          mobile2: { breakpoint: { max: 768, min: 581 }, items: 3, partialVisibilityGutter: 30 },
          mobile: { breakpoint: { max: 580, min: 0 }, items: 2, partialVisibilityGutter: 30 }
        }}
      >
        {G.zu.array(datas.children).map((data, index) =>
          <div key={index}>
            {displayUlti.getTitleBartime(data)}
          </div>)}
      </MultiCarousel>
    </div>,

  renderSale: function (vm) {
    const salelist = vm.state.sale || []
    return (
      <div className='package_list'>
        {salelist && salelist.map((sale, index) =>
          <div id={'item' + (sale.id || 's')} className={'product__item ' + (sale.id || 's')} key={`${index}_${G.zu.get(sale, 'id')}_${Math.random(1000)}`}>
            <Link to={`mua-goi/${G.zu.rxChangeSlug(G.zu.get(sale, 'name', ''))}-${sale.id}`}>
              <div className='rx__espect--img'>
                <img style={{ height: 'unset' }} className='img__landscape' src='https://cdnstatic.mcvgo.vn/11152/conversions/60d6e5f0b3812_deal1-420_237.png' onError={() => G.zu.imgError(index, 'img__landscape')} alt={sale.name} />
              </div>
              <div className='product__info' style={{ textTransform: 'uppercase' }}>
                <div className='product__title' style={{ textTransform: 'uppercase' }}>{sale.name}</div>
              </div>
              <div className='product__desc'><span style={{ textTransform: 'uppercase' }}>{G.zu.get(sale, 'short_description', '')}</span></div>
            </Link>
            <div onClick={(e) => vm.onClickBuyPack(e, sale)} className='button_buy'>
              <div>Mua gói</div>
            </div>
          </div>)}
      </div>
    )
  }
}

export class HomeBlock extends React.Component {
  render () {
    return <div>{G.zu.runSafe(() => types[`type${this.props.type}`](this.props.data))}</div>
  }
}
