import React from 'react'
import {} from '../style/selectmember.css'

export const renderSelectMember = (vm) => {
  return (
    <div className='popup'>
      {renderSelectMemberForm(vm)}
    </div>
  )
}

const renderSelectMemberForm = (vm) => {
  const nameTab = 'Lọc nội dung theo tuổi'
  const member = G.zq.localLoad('member').length ? G.zq.localLoad('member') : G.zu.array(G.zu.clone(vm.state.member))
  const memberSelected = vm.state.editingDataMember.member_id
  return (
    <div className='popup_selectmember_account_modal selectmember_account_modal'>
      <div className='selectmember_account_top'>
        <span className='selectmember_account_cancel_btn' onClick={() => vm.handleOpenFormName('CloseForm')} />
      </div>
      <div className='selectmember_account_header'>{nameTab}</div>
      <div className='selectmember_account_body'>
        <div className='selectmember_account_list'>
          <div className='selectmember_account_grid'>
            {member.map((mem, index) => {
              const color = { background: (mem.age ? mem.age.background_color : '#000000') }
              if (mem.age) {
                return (
                  <div key={index} className={`selectmember_account_member ${memberSelected === mem.id ? 'selected' : ''}`} onClick={(e) => vm.onClickSelectMember(e, mem)}>
                    <div className={`selectmember_account_member_circle ${memberSelected === mem.id ? 'selected' : ''}`} style={color}>
                      <span className='selectmember_account_member_age'>{mem.age ? mem.age.name.replace('tuổi', '') : ''}</span>
                    </div>
                    <span className='selectmember_account_member_name'>{mem.name}</span>
                  </div>
                )
              }
            })}
          </div>
        </div>
      </div>
      <div className={`selectmember_account_form fullwidth ${vm.state.validate.isMemberNameValid ? '' : 'error'}`}>
        <label>Tên thành viên</label>
        {vm.state.notify.isUpdate && <span className='selectmember_account_success'>{vm.state.notify.msg.update}</span>}
        <input type='text' maxLength='32' placeholder='Nhập tên' value={vm.state.editingDataMember.name} onChange={(e) => vm.onBlurDataMemberName(e, 'name')} />
        <label className='error show'>{vm.state.validate.msgErr.member}</label>
      </div>
      <div className={`selectmember_account_form fullwidth ${vm.state.validate.isPasswordValid ? '' : 'error'}`}>
        <input
          className='newmember_radio'
          type='radio'
          value={vm.state.editingDataMember.is_ask_password}
          checked={vm.state.editingDataMember.is_ask_password}
          onClick={(e) => vm.onBlurAskPassword(e)}
        />
        <label className='newmember_radio' onClick={(e) => vm.onBlurAskPassword(e)}>Đặt mật khẩu</label>
        <span className={`selectmember_account_label ${memberSelected ? 'active' : 'disable'}`} onClick={() => vm.onSwitchMember()}>Chuyển thành viên</span>
        {vm.state.editingDataMember.is_ask_password && <input type={vm.state.editingDataLogin.showPassword ? 'text' : 'password'} placeholder='Ít nhất 8 ký tự' value={vm.state.editingDataMember.password} onChange={(e) => vm.onBlurDataMemberPassword(e, 'password')} />}
        {vm.state.editingDataMember.is_ask_password && <span className='selectmember_account_eye' onClick={() => vm.handleShowPassword()} />}
        {vm.state.editingDataMember.is_ask_password && <label className='error show'>{vm.state.validate.msgErr.password}</label>}
      </div>
      <div className='selectmember_account_button_group'>
        <div className='selectmember_account_button' onClick={() => vm.handleOpenFormName('CloseForm')}>Trở về</div>
        <div className={`selectmember_account_button delete ${memberSelected ? 'active' : 'disable'}`} onClick={() => vm.handleDeleteMember()}>Xóa thành viên</div>
        <div className={`selectmember_account_button ${memberSelected ? 'active' : 'disable'}`} onClick={() => memberSelected && vm.onClickUpdateMember()}>Cập nhật</div>
      </div>
    </div>
  )
}
