import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { Switch, BrowserRouter } from 'react-router-dom'
import { renderRoutes } from 'react-router-config'
import ScrollToTop from '/app/zmodules/page/others/scrollToTop.js'

import Layout from './zmodules/page/layout'
import './zcore/zplugin/firebase.js'
import {GoogleOAuthProvider} from "@react-oauth/google";

require('./zcore/styles')
require('./zcore/zglobal')
const store = require('./zcore/zredux').store

G.routes = [{
  path: '/',
  exact: true,
  component: Layout
}]

const req = require.context('./zmodules/', true, /zroute.js$/)
req.keys().forEach(function (key) { req(key) })

render(
    <Provider store={store}>
            <BrowserRouter>
                <GoogleOAuthProvider clientId={G.zc.googleClientId}>

                <ScrollToTop/>
                <Switch>{renderRoutes(G.routes)}</Switch>

                </GoogleOAuthProvider>
            </BrowserRouter>
    </Provider>, document.getElementById('root'))
