import * as Jsx from './view.jsx'
// eslint-disable-next-line
import RxComponent from '/app/zcore/zcomponent'

const api = '/api/v1/actors/detail'
class component extends RxComponent {
  constructor (props, context) {
    super(props, context, Jsx)
    this.actorId = props.data.id
    this.state = {
      curData: {}
    }
  }

  componentDidMount () {
  }

  onClickActor () {
    G.zq.get(api, { actor_id: this.actorId }, (json) => {
      if (json.success) {
        this.setState({ curData: json.data })
      }
    })
    $('.actor_wrap').parents('body').addClass('showPopupActor')
  }

  onClickCurtain (e) {
    e.preventDefault(); e.stopPropagation()
    this.setState({ curData: {} })
    $('body').removeClass('showPopupActor')
  }

  render () { return this.renderComponent() }
}

export default component
