import MultiCarousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

export const renderPackageDetail = (vm) => {
  checkPackageChild()
  return (
    <div>
      {(!G.zu.get(vm.state, 'curPackage.id') && vm.state.buyNeed) &&
        <div>
          <div className='packpopup_curtain' onClick={e => vm.onClickClosePopup()} />
          <div className='packpopup_outer'>
            <div className='packpopup_wrap'>
              <img className='pp_close' src='static/img/icon_close.svg' alt='close' onClick={() => vm.onClickClosePopup()} />
              <div className='packpopup_head'>
                Thông tin chi tiết gói
              </div>
              <div className='packpopup_body'>
                <img className='packpopup_thumbnail' src={vm.state.videoData.photo_landscape.thumb_615x345} />
                <div className='packpopup_name'>{vm.state.videoData.name} | {vm.state.videoData.total_episodes} Tập </div>

                <div className='packprice_list vod_pack_list'>
                  <div className='pack_title'>Gói dịch vụ</div>
                  <MultiCarousel
                    {...defaultCarouselConfig}
                    responsive={{
                      desktop: { breakpoint: { max: 4000, min: 581 }, items: 2, partialVisibilityGutter: 40 },
                      mobile: { breakpoint: { max: 580, min: 0 }, items: 2, partialVisibilityGutter: 40 }
                    }}
                  >
                    {vm.state.buyPackages.map((e, i) =>
                      e.type.includes('WebOS', 'Website') ?
                        <div key={i} className={`pack_price pack_price_video ${vm.state.vodPackages.vodPackageID === e.id ? 'active' : ''}`} onClick={ex => vm.onSelectPackage(e)}>
                          <div className='vod_pack_thumbnail'><img src={e.photo ? e.photo.thumbnail : ''} /></div>
                          <div className='vod_pack_title'>{e.name}</div>
                        </div> : <div className='empty' />
                    )}
                  </MultiCarousel>
                  {!!vm.state.vodPackages.priceMSG && <div className='pack_errors'>{vm.state.vodPackages.priceMSG}</div>}
                  <div className='pack_btns'>
                    <div className='pack_submit' onClick={e => vm.onClickPackCheckout()}>Mua nội dung</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}
    </div>
  )
}

const checkPackageChild = () => {
  $(document).ready(() => {
    $('.react-multi-carousel-item .empty').parent().remove()
  })
}

export const defaultCarouselConfig = {
  arrows: true,
  centerMode: false,
  infinite: true,
  showDots: false,
  slidesToSlide: 1,
  fade: false,
  swipeable: false,
  itemClass: '',
  sliderClass: '',
  dotListClass: '',
  renderButtonGroupOutsidedotListClass: ''
}
