import Paging from './../others/paging'
export const renderPackage = (vm) => {
  return (
    <div className='account__package content__block'>
      <div className='account__package--body'>
        <div className='account__support--title'>Gói dịch vụ đã mua</div>
        <div className='packages_wrap'>
          {zarray(zget(vm.state.package_using, 'package')).map((obj, index) =>
            <div className='package__item' key={index}>
              <div className='package_thumbnail'>
                <img src={(obj.package != null && obj.package.photo) ? obj.package.photo.thumb_420x237 : '/static/img/Landscape_thumb.png'} alt='packpopup head' />
                {obj.expired_at !== null ?
                  <div className='expired' style={{ background: obj.expired ? 'red' : '' }}>{obj.expired ? 'Hết hạn' : `Còn ${obj.dayLeft} ngày`}</div> :
                  <div className='expired' style={{ background: obj.expired ? 'red' : '' }}>Chưa hết hạn</div>}
              </div>

              <div className='package_body'>
                <div className='packpopup_name'>{obj.package != null && obj.package.name}</div>
                {/*<div className='packpopup_desc'>{obj.package.description}</div>*/}
                <div className='packpopup_price'><b>Giá gói: </b> {new Intl.NumberFormat().format(obj.price.price)}đ</div>
                <div className='packprice_list'>
                  <div className='package__item--create'><b>Thời điểm mua:</b> {obj.created_at.split(' ')[0]}</div>
                  {obj.expired_at ? <div className='package__item--expired'><b>Ngày hết hạn:</b> {obj.expired_at.split(' ')[0]}</div> : <div className='package__item--expired' />}
                </div>
                <div className='packpopup_moreinfo' onClick={() => vm.onClickInfoPackage(obj)}><span className='icomoon-Info' />Thông tin chi tiết gói</div>
                <div className='packpopup_moreinfo' onClick={() => vm.onClickTransaction()}><span className='icomoon-Order' />Lịch sử giao dịch</div>
              </div>
              <div className='packpopup_footer'>
                <button className='packpopup__btn' onClick={() => vm.handleRenewPopup('renew')}>Ngừng gia hạn</button>
              </div>
            </div>)}
          {zarray(zget(vm.state.package_using, 'package')).length === 0 &&
            <div className='empty_packages_using'>
              <img src='images/static/packages/empty_package_using.svg' alt='' />
              <div className='empty_packages_using--title'>Chưa có gói dịch vụ nào</div>
            </div>}
        </div>
        {G.zu.clone(vm.state.meta).last_page > 1 && <Paging meta={G.zu.clone(vm.state.meta)} onPage={(page) => vm.handleChangePage(page)} />}
      </div>

      {/* <div className='account__package--body'>
        <div className='account__support--title account__support--title__video'>Video đã mua</div>
        <div className='packages_wrap'>
          {zarray(zget(vm.state.package_using, 'videos')).map((obj, index) =>
            <div className='package__item' key={index}>
              <div className='package_thumbnail'>
                <img src={obj.video.photo_landscape.thumb_570x321} alt='packpopup head' />
              </div>
              <div className='package_body'>
                <div className='packpopup_name'>{obj.video.name}</div>
                <div className='packpopup_price'><b>Giá gói: </b> {new Intl.NumberFormat().format(obj.price.price)}đ</div>
                <div className='packprice_list'>
                  <div className='package__item--create'><b>Thời điểm mua:</b> {obj.created_at.split(' ')[0]}</div>
                </div>
              </div>
              <div className='packpopup_footer'>
                <button className='packpopup__btn' onClick={() => vm.handleRenewPopup('stop')}>Hủy</button>
              </div>
            </div>)}
          {zarray(zget(vm.state.package_using, 'videos')).length === 0 &&
            <div className='empty_packages_using'>
              <img src='images/static/packages/empty_purchased_vod.svg' alt='' />
              <div className='empty_packages_using--title'>Chưa có video nào luôn</div>
            </div>}
        </div>
      </div> */}
    </div>
  )
}
