import React from 'react'
import {} from './style/success.css'

export const renderSuccess = (vm) => {
  return (
    <div className='popup'>
      {renderSuccessForm(vm)}
    </div>
  )
}

const renderSuccessForm = (vm) => {
  const nameTab = 'Đăng ký thành công'
  const tooltip = 'Chào mừng bạn đến với'
  const phone = 'MCVGo'
  return (
    <div className='popup_success_modal success_modal'>
      <div className='success_top'>
        {/* <span className='success_cancel_btn' onClick={() => vm.handleCloseForm()} /> */}
      </div>
      <div className='success_header'>{nameTab}</div>
      <div className='success_tooltip'>
        <span className='success_tooltip_tooltip'>{tooltip} </span>
        <span className='success_tooltip_phone'>{phone}</span>
      </div>
      <div className='success_image_container'>
        <span className='success_image' />
      </div>
      <div className='success_button_group'>
        <div className='success_button active' onClick={() => vm.handleOpenFormName('openLogin')}>Bắt đầu</div>
      </div>
    </div>
  )
}

export const renderSuccessChangePassword = (vm) => {
  return (
    <div className='popup'>
      {renderSuccessChangePasswordForm(vm)}
    </div>
  )
}

const renderSuccessChangePasswordForm = (vm) => {
  const nameTab = 'Cập nhật thành công'
  const tooltip1 = 'Mật khẩu của bạn đã được đổi'
  const tooltip2 = 'Tiếp tục trải nghiệm cùng '
  const tooltip3 = 'nhé!'
  const phone = 'MCVGo'
  return (
    <div className='popup_success_modal success_modal'>
      <div className='success_top'>
        {/* <span className='success_cancel_btn' onClick={() => vm.handleCloseForm()} /> */}
      </div>
      <div className='success_header'>{nameTab}</div>
      <div className='success_tooltip'>
        <span className='success_tooltip_tooltip'>{tooltip1} </span>
      </div>
      <div className='success_tooltip'>
        <span className='success_tooltip_tooltip'>{tooltip2} </span>
        <span className='success_tooltip_phone'>{phone} </span>
        <span className='success_tooltip_tooltip'>{tooltip3} </span>
      </div>
      <div className='success_image_container'>
        <span className='success_image' />
      </div>
      <div className='success_button_group'>
        <div className='success_button active' onClick={() => vm.handleOpenFormName('openLogin')}>Bắt đầu</div>
      </div>
    </div>
  )
}

export const renderSSOSuccess = (vm) => {
  return (
    <div className='popup'>
      {renderSSOSuccessForm(vm)}
    </div>
  )
}

const renderSSOSuccessForm = (vm) => {
  const nameTab = 'Cập nhật thành công'
  const tooltip1 = 'Tài khoản của bạn đã cập nhật thành công'
  const tooltip2 = 'Tiếp tục trải nghiệm cùng '
  const tooltip3 = 'nhé!'
  const phone = 'MCVGo'
  return (
    <div className='popup_success_modal success_modal'>
      <div className='success_top'>
        {/* <span className='success_cancel_btn' onClick={() => vm.handleCloseForm()} /> */}
      </div>
      <div className='success_header'>{nameTab}</div>
      <div className='success_tooltip'>
        <span className='success_tooltip_tooltip'>{tooltip1} </span>
      </div>
      <div className='success_tooltip'>
        <span className='success_tooltip_tooltip'>{tooltip2} </span>
        <span className='success_tooltip_phone'>{phone} </span>
        <span className='success_tooltip_tooltip'>{tooltip3} </span>
      </div>
      <div className='success_image_container'>
        <span className='success_image' />
      </div>
      <div className='success_button_group'>
        <div className='success_button active' onClick={() => vm.handleOpenFormName('openLogin')}>Bắt đầu</div>
      </div>
    </div>
  )
}
