// import G.zu from '@rxframework/G.zulti'
import React from 'react'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
// import * as QR from './qr'
import {} from './style/login.css'
import {GoogleLogin} from "@react-oauth/google";

export const renderLoginPopup = (vm) => {
  return (
    <div className='popup'>
      <div className='login_modal'>
        <span className='login_cancelbtn login_btn_SSO' onClick={() => vm.handleCloseForm()} />
        {renderLogin(vm)}
        {/* <div className='boder_middle' />
        {QR.renderQRLogin(vm)} */}
      </div>
    </div>
  )
}

const renderLogin = (vm) => {
  const nameTab = 'Đăng nhập'
  return (
    <div className='login_leftside'>
      <div className='login_header'>{nameTab}</div>
      {renderLoginForm(vm)}
      <div className='login_footer'>
        {renderSocialButtons(vm)}
      </div>
    </div>
  )
}

const renderLoginForm = (vm) => {
  const validate = vm.state.validate
  const isPhoneValid = validate.isPhoneValid
  const isPasswordValid = validate.isPasswordValid
  const message = zget(vm.state, ['validate', 'msgErr'], { phone: '', password: '', otp: '' })
  const showPassword = zget(vm.state, ['editingDataLogin', 'showPassword'], false)
  const password = zget(vm.state, ['editingDataLogin', 'password'], '')
  const phone = zget(vm.state, ['editingDataLogin', 'phone'], '')
  return (
    <div className='login_body'>
      <div className={`login_form ${isPhoneValid ? '' : 'error'}`}>
        <label>Số điện thoại</label>
        <input id='phoneNumber' type='text' placeholder='Nhập số điện thoại' value={phone} onChange={(e) => vm.onBlurDataLogin(e, 'phone')} />
        {phone !== '' && <span className='login_clear' onClick={() => vm.handleClearPhone()} />}
        <label className='error show'>{isPhoneValid ? '' : message.phone}</label>
      </div>
      <div className={`login_form ${isPasswordValid ? '' : 'error'}`}>
        <label>Mật khẩu</label>
        <input onKeyPress={(e) => vm.handleEnterLogin(e)} type={showPassword ? 'text' : 'password'} maxLength='32' placeholder='Ít nhất 8 ký tự' value={password} onChange={(e) => vm.onBlurDataLogin(e, 'password')} />
        <span className={`login_eye ${showPassword ? 'eyeShow' : ''}`} onClick={() => vm.handleShowPassword()} />
        <label className='error show'>{!message.password ? '' : message.password}</label>
        <span className='login_forget' onClick={() => vm.handleOpenFormName('openForgetPasswordForm')}>Quên mật khẩu?</span>
      </div>
      <div
        className='login_button active'
        onClick={() => vm.handleLogin()}
      >
        Đăng nhập
      </div>
    </div>
  )
}

export const renderSocialButtons = (vm) => {
  const by = 'Đăng nhập bằng'
  return (
    <div className='login_social'>
      <span className='login_social_tooltip'>{by}</span>
      <div className='login_socialgroup'>
          <div className='popup__google button__loginSSO'>
              <GoogleLogin
                  theme='outline'
                  type='icon'
                  shape='circle'
                  size='large'
                  onSuccess={vm.responseGoogle}
                  onError={vm.responseGoogle}
              />
          </div>

        <FacebookLogin
        // appId='425330095473217'
          // appId='506723214371123'
          // appId Test
          appId='1085705229014628'
          render={renderProps => (
            <span onClick={renderProps.onClick} disabled={renderProps.disabled} className='popup__facebook button__loginSSO' />
          )}
          fields='name,email,picture.type(large)'
          callback={vm.responseFacebook}
          textButton=''
          className='abc'
          authType='reauthenticate'
        />
      </div>
      <div className='login_social_smalltooltip'>Bạn chưa có tài khoản?<span className='login_resend_otp' onClick={(e) => vm.handleOpenFormName('openRegisterForm')}> Đăng ký ngay</span></div>
    </div>
  )
}
