import { DebounceInput } from 'react-debounce-input'

export const renderComponent = (vm) =>
  <div id='search_toggle' className='head_smenu'>
    <div className='btn_submit_text'>Tìm kiếm</div>
    <div className='search_input'>
      <DebounceInput
        onKeyPress={e => vm.onKeyPressSearch(e)}
        debounceTimeout={800}
        onChange={e => vm.onChangeSearch(e)}
        id='search_input' maxLength='200' type='text' className='search_toggle' placeholder='Tìm kiếm tại đây ...'
      />
      {vm.state.keyword === '' ?
        <span className='icomoon-search icon_toggle' /> :
        <span onClick={() => vm.onClickRefreshInput()} className='icomoon-close icon_toggle' />}
    </div>
  </div>
