import * as Jsx from './view.jsx'
// eslint-disable-next-line
import RxComponent from '/app/zcore/zcomponent'
import { connect } from 'react-redux'
import { actionSearch, actionTest } from '../../../zcore/zredux.js'

const api = '/api/v1/homepages?parent_id=3&'
const apiPackage = '/api/v1/packages'
class component extends RxComponent {
  constructor (props, context) {
    super(props, context, Jsx)
    this.state = {
      datahome: G.zu.json(G.zq.localLoad(api)),
      dataPackage: G.zu.json(G.zq.localLoad(apiPackage)),
      keywords: [],
      keyword: this.props.keyword,
      test: this.props.test,
      result: [],
      baseURL: '/tim-kiem',
      topSearch: {},
      meta: {
        current_page: 0,
        from: 0,
        last_page: 0,
        per_page: 0,
        to: 0,
        total: 0
      },
      searchParams: {},
      selectedOption: {},
      optionParams: {},
      allParams: {},
      slug: ''
    }
  }

  // NOTE : SEARCH
  static getDerivedStateFromProps (nextProps, prevState) {
    if (nextProps.keyword !== prevState.keyword) {
      return { keyword: nextProps.keyword }
    }
    return null
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.keyword !== this.props.keyword) {
      this.setState({ keyword: this.props.keyword }, () => {
        this.fetchSearch()
      })
    }
  }
  // NOTE : END SEARCH

  handleChange (key, e) {
    const optionParams = { ...this.state.allParams }
    optionParams[key] = e.value
    // NOTE : SEARCH
    this.setState({ allParams: optionParams }, () => {
      this.fetchSearch()
    })
    const clearPrams = JSON.stringify(optionParams).replaceAll('"', '').replaceAll(':', '=').replaceAll('{', '&').replaceAll(',', '&').replaceAll('}', '')
    const nextURL = this.state.baseURL + '?query=' + this.state.keyword + clearPrams
    window.history.pushState({}, null, nextURL)
  }

  handleChangeSort (key, e) {
    const optionParams = { ...this.state.allParams }
    optionParams.sort_col = e.sort_col
    optionParams.sort_type = e.sort_type
    // NOTE : SEARCH
    this.setState({ allParams: optionParams }, () => {
      this.fetchSearch()
    })
    const clearPrams = JSON.stringify(optionParams).replaceAll('"', '').replaceAll(':', '=').replaceAll('{', '&').replaceAll(',', '&').replaceAll('}', '')
    const nextURL = this.state.baseURL + '?query=' + this.state.keyword + clearPrams
    window.history.pushState({}, null, nextURL)
  }

  handleClickKeyTop (e) {
    this.props.actionSearch(e.target.text)
  }

  fetchData () {
    G.zq.get(api, this.state.filter, (json) => {
      if (json.success) {
        this.makeData(json.data)
      }
    })
    G.zq.get(apiPackage, {}, json => {
      if (json.success) {
        const packageMap = {}
        try {
          for (const i of json.data) {
            packageMap[i.id] = i
          }
        } catch (_e) {}
        this.makeDataPackage(packageMap)
      }
    })
  }

  fetchSearch (changepage) {
    const keyword = this.state.keyword
    const api = '/api/v1/search'

    const params = {
      // query: keyword,
      page_size: 1,
      page: changepage || this.state.meta.current_page
    }

    const optionParams = this.state.allParams
    for (const property in optionParams) {
      params[property] = optionParams[property]
    }
    // params.query = keyword || '' || optionParams.query
    params.query = keyword || ''
    G.zq.get(api, params, (json) => {
      if (json.success) {
        this.setState({ result: json.data, meta: { ...G.zu.clone(json.meta) } })
      }
    })
  }

  fetchSearchSuggest () {
    G.zq.get('/api/v1/search/top', { }, (json) => {
      if (json.success) {
        this.setState({ topSearch: json.data })
      }
    })
  }

  fetchDataParams () {
    G.zq.get('/api/v1/search/param', { }, (json) => {
      if (json.success) {
        this.setState({ searchParams: json.data })
      }
    })
  }

  makeData (data) {
    G.zq.localSave(api, JSON.stringify(data))
    this.setState({ datahome: data })
  }

  makeDataPackage (data) {
    G.zq.localSave(apiPackage, JSON.stringify(data))
    this.setState({ dataPackage: data })
  }

  componentWillReceiveProps (nextProps) {
    if (G.zu.get(nextProps, 'location.search') !== G.zu.get(this.props, 'location.search')) {
      const checkSlug = G.zu.get(this.props, 'location.search')
      this.fetchData(checkSlug)
    }
  }

  componentDidMount () {
    this.fetchData()
    this.fetchSearchSuggest()
    this.fetchDataParams()
    // check params search reload
    const checkSlug = G.zu.get(this.props, 'location.search').replaceAll('?', '')
    const params = checkSlug.split('&')
    const par = {}
    // convert params string to object
    params.forEach((item, i) => {
      const el = item.split('=')
      par[el[0]] = el[1]
    })
    const optionParams = { ...par }
    // NOTE : SEARCH
    this.props.actionSearch(optionParams.query || '')
    this.setState({ allParams: optionParams }, () => {
      this.fetchSearch()
    })
  }

  handleChangePage (page) {
    const meta = G.zu.clone(this.state.meta)
    meta.current_page = page
    this.setState({ meta })
    this.fetchSearch(page)
  }

  render () { return this.renderComponent(this) }
}

const mapStateToProps = (state, ownProps) => ({
  keyword: state.keyword,
  test: state.test
})

const mapDispatchToProps = { actionSearch, actionTest }

const ComponentSearch = connect(
  mapStateToProps,
  mapDispatchToProps
)(component)

export default ComponentSearch
