import { DebounceInput } from 'react-debounce-input'
import Paging from './../others/paging'

export const renderCalendar = (vm) => {
  return (
    <div className='account__package content__block'>
      <div className='account__package--body'>
        <div className='calendar_tabs'>
          <div className={`vod_calendar calendar_tabs_item ${vm.state.tabCalendar ? 'active' : ''}`} onClick={() => vm.onClickTabsCalendar()}>VOD</div>
          <div className={`epgs_calendar calendar_tabs_item ${!vm.state.tabCalendar ? 'active' : ''}`} onClick={() => vm.onClickTabsCalendar()}>Lịch phát sóng</div>
        </div>
        <div className='account__support--body account_calendar'>
          <div className='account_history_search'>
            <div className='search_input'>
              <DebounceInput
                onKeyPress={e => vm.onKeyPressSearch(e)}
                onChange={e => vm.onChangeSearch(e)}
                debounceTimeout={800}
                id='' maxLength='200' type='text' className='search_toggle' placeholder='Tìm kiếm tại đây ...'
              />
              {vm.state.keyword === '' ? <span className='icomoon-search icon_toggle' /> : <span onClick={() => vm.onClickRefreshInput()} className='icomoon-close icon_toggle' />}
            </div>
          </div>
          {vm.state.tabCalendar ?
            <>
              {vm.state.accountVodCalendar.length === 0 ?
                <div className='account_history_list_empty'>
                  <img src='static/img/accountVODEmpty.png' alt='accountVODEmpty' />
                  <div className='account_history_list_empty_txt'>Hiện tại chưa có Video nào</div>
                </div> :
                <div className='account_calendar_list account_calendar_list_video'>
                  {vm.state.accountVodCalendar.map((e, i) =>
                    <div key={e.id} className='account_calendar_item itemNext'>
                      {/* <div className='account_calendar_item_time'>
                        Đã sẵn sàng
                      </div> */}
                      <div className='account_calendar_item_title account_link_detail' onClick={() => vm.onClickViewFilm(e)}>
                        <span className='egp_name_title'>{e.videoName[1].name} {e.isSeriesMovie ? <span>| {e.name}</span> : ''}</span>
                        <span className='egp_name_des'>{e.description[1].description !== null ? e.description[1].description.replaceAll('<p>', '').replaceAll('</p>', '') : ''}</span>
                      </div>
                      <img className='moreVertical' src='static/img/moreVertical.png' alt='moreVertical' />
                    </div>
                  )}
                  {G.zu.clone(vm.state.meta).last_page > 1 && <Paging meta={G.zu.clone(vm.state.meta)} onPage={(page) => vm.handleChangePage(page)} />}
                </div>}
            </> :
            <>
              {vm.state.accountCalendar.length === 0 ?
                <div className='account_history_list_empty'>
                  <img src='static/img/accountCalendarEmpty.png' alt='accountCalendarEmpty' />
                  <div className='account_history_list_empty_txt'>Hiện tại chưa có chương trình nào</div>
                </div> :
                <div className='account_calendar_list'>
                  {vm.state.accountCalendar.map((e, i) =>
                    <div key={e.id} className='account_calendar_item itemNext'>
                      <div className='account_calendar_item_time'>
                        <div>{G.zu.formatDate(e.start_time).split(' ')[1].substr(0, 5)}</div>
                        <div>{G.zu.formatDate(e.start_time).split(' ')[0].replaceAll('-', '/')}</div>
                      </div>
                      <div className='account_calendar_item_title account_link_detail' onClick={() => vm.onClickYourChanel(e)}>
                        <span className='egp_name_title'>{e.name}</span>
                        <span className='egp_name_des'>{e.description !== null ? e.description.replaceAll('<p>', '').replaceAll('</p>', '') : ''}</span>
                      </div>
                      <img className='moreVertical' src='static/img/moreVertical.png' alt='moreVertical' />
                    </div>
                  )}
                  {G.zu.clone(vm.state.meta).last_page > 1 && <Paging meta={G.zu.clone(vm.state.meta)} onPage={(page) => vm.handleChangePage(page)} />}
                </div>}
            </>}
        </div>
      </div>
    </div>
  )
}
