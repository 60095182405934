import React from 'react'
import {} from './style/addpassword.css'

export const renderSSOWithPhone = (vm) => {
  return (
    <div className='popup'>
      {renderSSOWithPhoneForm(vm)}
    </div>
  )
}

const renderSSOWithPhoneForm = (vm) => {
  const nameTab = 'Liên kết số điện thoại'
  const phone = zget(vm.state, ['editingDataLogin', 'phone'], '')
  const showPassword = zget(vm.state, ['editingDataLogin', 'showPassword'], false)
  const validate = vm.state.validate
  const isPhoneValid = validate.isPhoneValid
  const isPasswordValid = validate.isPasswordValid
  const passwordMessage = validate.msgErr.password
  const phoneMessage = validate.msgErr.phone
  const password = vm.state.editingDataLogin.password
  return (
    <div className='popup_addpassword_modal addpassword_modal'>
      <div className='addpassword_top'>
        <span className='addpassword_cancel_btn' onClick={() => vm.handleCloseForm()} />
      </div>
      <div className='addpassword_header'>{nameTab}</div>
      <div className='addpassword_body'>
        <div className='addpassword_form'>
          <label>Số điện thoại</label>
          <input className={isPhoneValid ? '' : 'error'} type='text' maxLength='32' placeholder='Nhập số điện thoại' value={phone} onChange={(e) => vm.onBlurDataLogin(e, 'phone')} />
          {!isPhoneValid && <label toggle className='error show'>{phoneMessage}</label>}
        </div>
        <div className='addpassword_form'>
          <label>Mật khẩu</label>
          <input className={isPasswordValid ? '' : 'error'} type={`${showPassword ? 'text' : 'password'}`} maxLength='32' placeholder='Ít nhất 8 ký tự' value={password} onChange={(e) => vm.onBlurDataLogin(e, 'password')} />
          <span className='addpassword_eye' onClick={() => vm.handleShowPassword()} />
          {!isPasswordValid && <label className='error show'>{passwordMessage}</label>}
        </div>
      </div>
      <div className='addpassword_button_group'>
        <div className='addpassword_button' onClick={() => vm.onClickBack('openLogin')}>Trở về</div>
        <div
          className={`addpassword_button ${(password.length >= 8) ? 'active' : 'disable'}`}
          onClick={(e) => vm.onClickLoginSSOSubmit(e)}
        >Xác nhận
        </div>
      </div>
    </div>
  )
}
