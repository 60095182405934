import * as Jsx from './view.jsx'
import RxComponent from '/app/zcore/zcomponent' // eslint-disable-line

class component extends RxComponent {
  constructor (props, context) {
    super(props, context, Jsx)
    this.state = {}
  }

  render () { return this.renderComponent() }
}

export default component
