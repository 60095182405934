import React from 'react'
import {} from '../../layout/auth/style/otp.css'

export const renderOTPPopup = (vm) => {
  return (
    <div className='popup'>
      {renderOTP(vm)}
    </div>
  )
}

const renderOTP = (vm) => {
  const nameTab = 'Xác thực OTP'
  const phone = vm.helperChangePhone(vm.state.editingDataLogin.phone)
  const otpcheck = vm.state.editingDataLogin.otp.length === 6 && vm.state.editingDataLogin.otp.indexOf('') === -1
  const error = vm.state.validate.otpCheck
  return (
    <div className='popup_otp_modal otp_modal'>
      <div className='otp_top'>
        <span className='otp_cancel_btn' onClick={() => vm.handleCloseForm()} />
      </div>
      <div className='otp_header'>{nameTab}</div>
      <div className='otp_tooltip'>Mã xác nhận OTP đã được gửi đến số điện thoại</div>
      <div className='otp_phone'>{phone}</div>
      <div className='otp'>
        {vm.state.editingDataLogin.otp.map((otp, index) => <input className={`otp_number ${otpcheck && error ? 'active' : 'error'}`} key={index} placeholder='-' type='number' value={otp} onChange={(e) => vm.onBlurOtpCode(e, index)} />)}
      </div>
      <label className='otp_error show'>{error ? '' : vm.state.validate.msgErr.otp}</label>
      <div className='otp_smalltooltip'>Không nhận được mã xác nhận ? <span onClick={() => vm.handleResendOTPNewMember()} className='login_resend_otp'>Gửi lại</span></div>
      <div className='otp_button_group'>
        <div className='otp_button' onClick={() => vm.onClickBack('openSelectMember')}>Trở về</div>
        <div className={`otp_button ${otpcheck ? 'active' : 'disable'}`} onClick={() => otpcheck && vm.handleConfirmOTPMain()}>Xác nhận</div>
      </div>
    </div>
  )
}
