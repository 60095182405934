import { Link } from 'react-router-dom'
export const renderFooter = (vm) => {
  const logoSrc = '/static/img/logoMCV.png'
  const facebook = '/static/img/icon-facebook.svg'
  const tiktok = '/static/img/icon-tiktok.svg'
  const youtube = '/static/img/icon-youtube.svg'
  return (
    <div className='page_footer tntv_footer'>
      <div className='container'>
        <div className='row'>
          <div className='col-6'>
            <div className='logoNew'>
              <img className='lmenu_logo' alt='logoimg' src={logoSrc} />
            </div>
            <div>
              <div>BẢN QUYỀN © 2008 - 2020 BỞI ĐÀI PHÁT THANH - TRUYỀN HÌNH TỈNH THÁI NGUYÊN.</div>
              <div>Giám đốc - Tổng Biên tập - Chịu trách nhiệm nội dung: Nguyễn Thị Vũ Anh</div>
              <div>Giấy phép hoạt động số: 24/GP - TTĐT, do Cục Phát thanh, Truyền hình và Thông tin điện tử cấp ngày 01/04/2015</div>
              <div>Giấy phép sửa đổi bổ sung số: 198/GPSĐBS - TTĐT, ngày 11/11/2016 của Cục Phát thanh, Truyền hình và Thông tin điện tử</div>
              <div>Địa chỉ: Số 226 Đường Bến Oánh - Phường Trưng Vương - TP.Thái Nguyên </div>
              <div>Điện thoại: (02083) 855.439 * FAX: (02083) 655.724.</div>
              <div>Email: <a href="mailto:truyenhinhthainguyen@gmail.com">truyenhinhthainguyen@gmail.com</a>; <a href="mailto:daiptth@thainguyen.gov.vn">daiptth@thainguyen.gov.vn</a>; <a href="mailto:baodientutntv@thainguyen.gov.vn ">baodientutntv@thainguyen.gov.vn </a> </div>
              <div>Website: <a href="https://thainguyentv.vn/">https://thainguyentv.vn</a></div>
            </div>
          </div>

          <div className='col-3'>
            <div className='fc_title'>Về chúng tôi</div>
            <Link className='linkFooter' to='/dieu-khoan-su-dung'>Điều khoản sử dụng</Link>
            <Link className='linkFooter' to='/chinh-sach-bao-mat'>Chính sách bảo mật</Link>
            {/*<Link className='linkFooter' to='/mua-goi'>Các gói dịch vụ</Link>*/}
            <Link className='linkFooter' to='/gioi-thieu'>Giới thiệu</Link>
            <Link className='linkFooter' to='/ho-tro'>Hỗ trợ</Link>
          </div>

          <div className='col-3'>
            <div className='fc_title'>Tải ứng dụng</div>
            <div className='clearfix'>
              <img className='fright_img' alt='logoimg' src='/static/img/app_store_download.png' />
              <img className='fright_img' alt='logoimg' src='/static/img/google_play_download.png' />
            </div>
            <div className='fc_title'>Kết nối với chúng tôi</div>
            <div className='foot_social'>
              <a target='_blank' href="https://www.facebook.com/fanpagethainguyentv">
                <img src={facebook} alt="FaceBook" />
              </a>
              <a target='_blank' href="https://www.tiktok.com/@thainguyen.tv">
                <img className='img_tiktok' src={tiktok} alt="Tiktok" />
              </a>
              <a target='_blank' href="https://www.youtube.com/c/ĐàiPTTHTháiNguyênTNTV">
                <img src={youtube} alt="Youtube" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='footer_bot'>
        <div className='container'>
          Bản quyền thuộc về công ty Cổ phần MCVGo. Copyright 2022
        </div>
      </div>
    </div>
  )
}
