const config = {
  webAddr: 'http://localhost',
  webPort: 9999,
  DefaultLang: 'vi',

  serverAddr: 'https://api.tntv.mcvgo.vn',
  serverPort: 8888,
  serverTimeout: 10000,
  serverHello: 'Server running at http://127.0.0.1:',
  PublicPath: './',
  googleClientId: '838990603372-6b7tav80mjjp7kpdktcvd1nrc7sj9rpd.apps.googleusercontent.com'
}
module.exports = config
