import * as Jsx from './view.jsx'
// eslint-disable-next-line
import RxComponent from '/app/zcore/zcomponent'

class component extends RxComponent {
  constructor (props, context) {
    super(props, context, Jsx)
    this.state = {
      meta: {}
    }
  }

  componentDidMount () {
    setTimeout(() => {
      this.setState({ meta: G.zu.clone(this.props.meta) })
    }, 500)
  }

  handleChangePage (page) {
    const meta = G.zu.clone(this.state.meta)
    this.props.onPage(page)
    meta.current_page = page
    this.setState({ meta })
  }

  render () { return this.renderPaging() }
}

export default component
