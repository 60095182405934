import React from 'react'
import {} from './style/search.css'
import { Link } from 'react-router-dom'
import Zimg from './../others/zimg'
import Select from 'react-select'
import Paging from './../others/paging'

const imgDefault = 'static/img/Landscape_thumb.png'
export const renderComponent = (vm) => {
  searchToggle()
  return (
    <div className='page_search'>
      {vm.renderSelect()}
      {vm.renderResult()}
    </div>
  )
}

export const renderEmpty = () => {
  return (
    <div className='resultEmpty'>
      <img src='static/img/resultEmpty.svg' alt='resultEmpty' />
      <p>Không tìm thấy từ khóa</p>
    </div>
  )
}

export const renderSelect = (vm) => {
  const arrayCategories = zarray(vm.state.searchParams.categories)
  const arrayCountries = zarray(vm.state.searchParams.countries)
  const arrayYears = zarray(vm.state.searchParams.year)
  // Type
  const optionsTypes = [
    { key: 0, value: '', label: 'Tất cả phim' },
    { key: 1, value: 1, label: 'Phim bộ' },
    { key: 2, value: 2, label: 'Phim lẻ' }
  ]

  // Category
  const optionsCategories = [
    { key: '', value: '', label: 'Tất cả thể loại' }
  ]
  for (let i = 0; i < arrayCategories.length; i++) {
    optionsCategories.push(
      { key: { i }, value: arrayCategories[i].slug, label: arrayCategories[i].name }
    )
  }
  // Countries
  const optionsCountries = [
    { key: '', value: '', label: 'Tất cả quốc gia' }
  ]
  for (let i = 0; i < arrayCountries.length; i++) {
    optionsCountries.push(
      { key: { i }, value: arrayCountries[i].slug, label: arrayCountries[i].name }
    )
  }
  // Years
  const optionsYears = [
    { key: '', value: '', label: 'Tất cả năm sản xuất' }
  ]
  for (let i = 0; i < arrayYears.length; i++) {
    optionsYears.push(
      { key: { i }, value: arrayYears[i], label: arrayYears[i] }
    )
  }
  // Sort
  const optionsSorts = [
    { value: 1, sort_col: 'created_at', sort_type: -1, label: 'Mới nhất' },
    { value: 2, sort_col: 'created_at', sort_type: 1, label: 'Cũ nhất' },
    { value: 3, sort_col: 'viewed', sort_type: -1, label: 'Xem nhiều nhất' },
    { value: 4, sort_col: 'viewed', sort_type: 1, label: 'Xem ít nhất' }
  ]

  const { selectedOption } = vm.state
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.isSelected ? 'bold' : 'normal',
      fontSize: '15px',
      color: state.isSelected ? '#1EC5F9' : '#1C1C1C',
      backgroundColor: '#fff !important',
      padding: '4px 15px',
      overflow: 'hidden!important',
      whiteSpace: 'nowrap!important',
      textOverflow: 'ellipsis!important'
    }),
    placeholder: defaultStyles => {
      return {
        ...defaultStyles,
        color: '#1C1C1C',
        fontWeight: 'bold',
        fontSize: '16px'
      }
    }
  }
  return (
    <div className='search_select'>
      <div id='slType' className='slCustomCss'>
        <Select
          value={selectedOption.type}
          onChange={(e) => vm.handleChange('type', e)}
          options={optionsTypes}
          placeholder='Loại'
          styles={customStyles}
        />
      </div>
      <div id='slCategoties' className='slCustomCss'>
        <Select
          value={selectedOption.category}
          onChange={(e) => vm.handleChange('category', e)}
          options={optionsCategories}
          placeholder='Thể loại'
          styles={customStyles}
        />
      </div>
      <div id='slCountry' className='slCustomCss'>
        <Select
          value={selectedOption.country}
          onChange={(e) => vm.handleChange('country', e)}
          options={optionsCountries}
          placeholder='Quốc gia'
          styles={customStyles}
        />
      </div>
      <div id='slYears' className='slCustomCss'>
        <Select
          value={selectedOption.year}
          onChange={(e) => vm.handleChange('year', e)}
          options={optionsYears}
          placeholder='Năm sản xuất'
          styles={customStyles}
        />
      </div>
      <div id='slSort' className='slCustomCss'>
        <Select
          value={selectedOption.sort}
          onChange={(e) => vm.handleChangeSort('sort', e)}
          options={optionsSorts}
          placeholder='Sắp xếp'
          styles={customStyles}
        />
      </div>
    </div>
  )
}

export const renderResult = (vm) => {
  const resultCount = vm.state.meta.total
  const keyword = vm.state.keyword
  const checkParams = vm.state.allParams
  return (
    <div> {(keyword === '' && JSON.stringify(checkParams).length === 2) ?
      <div className='search_auto_recommend'>
        <div className='top_search'>
          <div className='search_key_top'>
            <div className='search_title'>Tìm kiếm hàng đầu</div>
            <div className='search_key_list'>
              {G.zu.array(vm.state.topSearch.keywords).map((e, i) => {
                return (
                  <Link key={i} className='top_link' to={`/tim-kiem?query=${e.name.replaceAll(' ', '-')}`} onClick={(e) => { vm.handleClickKeyTop(e) }}>
                    {e.name}
                  </Link>
                )
              })}
            </div>
          </div>
          <div className='search_video_top'>
            <div className='search_title'>Nổi bật gần đây</div>
            <div className='search_result'>
              {G.zu.array(vm.state.topSearch.videos).map((e, i) => {
                return (
                  <div className='search_result_list' key={i}>
                    <Link className='result_link' to={`/xem-phim/${e.slug}-${e.id}`}>
                      {getThumb(e)}
                      <div className='result_title'>{e.name}</div>
                    </Link>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div> :
      <div>
        <div className='search_status'>
          <div>Có <span className='count'> {resultCount === 0 ? 0 : resultCount} </span> kết quả tìm kiếm cho <span className='count'>{keyword || checkParams.category}</span></div>
        </div>
        {resultCount === 0 ? vm.renderEmpty() : ''}
        <div className='search_result'>
          {G.zu.array(vm.state.result).map((result, index) => {
            return (
              <div key={index} className='search_result_list'>
                {getTitle(result)}
              </div>
            )
          })}
        </div>
        {G.zu.clone(vm.state.meta).last_page > 1 && <Paging meta={G.zu.clone(vm.state.meta)} onPage={(page) => vm.handleChangePage(page)} />}
      </div>}
    </div>
  )
}

export const getTitle = (data, options) =>
  <Link to={getLink(data)} className='result_link'>
    {getThumb(data)}
    <div className='result_info'>
      <div className='result_infoin'>
        <div className='result_title'>{data.name}</div>
        {G.zu.runSafe(() => { return options.after() })}
      </div>
    </div>
  </Link>

export const getLink = (data) => {
  return `../xem-phim/${G.zu.rxChangeSlug(G.zu.get(data, 'name', ''))}-${data.id}`
}

export const getThumb = (data) => {
  return (
    <Zimg src={!G.zu.get(data, 'photo_landscape.thumb_615x345') ? imgDefault : G.zu.get(data, 'photo_landscape.thumb_615x345')} thumb={G.zu.get(data, 'photo.tiny')} alt={data.name} />
  )
}

const searchToggle = () => {
  $(document).ready(() => {
    $('#search_toggle').addClass('show')
    $('#menu_toggle').addClass('hide')
    $('.head_rmenu').addClass('hide')
    const href = window.location.href
    $(document).click((event) => {
      if (!$(event.target).closest('#search_toggle').length) {
        if (href !== window.location.href) {
          $('#search_toggle').removeClass('show')
          $('#menu_toggle').removeClass('hide')
          $('.head_rmenu').removeClass('hide')
        }
      }
    })
    if (decodeURI(`${new URL(window.location.href).searchParams.get('query')}`).replaceAll('-', ' ') !== 'null') {
      $('#search_input').val(decodeURI(`${new URL(window.location.href).searchParams.get('query')}`).replaceAll('-', ' '))
    }
  })
}
