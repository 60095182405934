import React from 'react'
import { Link } from 'react-router-dom'
import MultiCarousel from 'react-multi-carousel'
import Zimg from './../others/zimg'

import { } from './styles.css'

const imgDefault = '/static/img/logo.svg'
export const renderComponent = (vm) =>
  <div>
    <Link ref={input => { vm.refLinkHot = input }} to='/hot' />
    <Link ref={input => { vm.refLinkHotFirst = input }} to={`/hot/${zget(vm.state.hlsList, [0, 'key']).replace('.m3u8', '')}`} />
    <div className='livetv'>
      <div className='flex_block hot_block'>
        <div className='flex_left hot_left'>
          <div id='videoPlayer' />
        </div>
        <div className='flex_right hot_right'>
          <div className='egps_wrap'>
            <div className='egps_title'>Phát trực tiếp</div>
            <div className='hot_wrap'>
              {G.zu.array(vm.state.hlsList).map((e, i) =>
                <div key={i} className={`hot_item ${vm.state.slug === e.key.replace('.m3u8', '') ? 'active' : ''}`}>
                  <div className='hot_icon'><Zimg src={e.img} thumb={imgDefault} alt={e.name} /></div>
                  <Link to={`/hot/${e.key.replace('.m3u8', '')}`}>
                    <svg height='20' width='20' className='blinking'><circle cx='10' cy='15' r='5' fill='red' /></svg>
                    {e.name}
                    <div className='hot_desc'>{e.desc}</div>
                  </Link>
                </div>)}
              {G.zu.array(vm.state.hlsList).length === 0 &&
                <div className='hot_notlive'>
                  <div className='notlive_title'>Hiện tại chưa có chương trình nào đang phát sóng trực tiếp</div>
                  <div className='notlive_ico' />
                  <div className='notlive_title'>Bạn có thể xem truyền hình tại đây</div>
                  <div className='notlive_links'>
                    <Link to='/truyen-hinh/htv7-189'><img src='https://cdnstatic.mcvgo.vn/11640/conversions/60f51a3a51736_HTV7-thanhphan-640_368.png' /></Link>
                    <Link to='/truyen-hinh/htv9-186'><img src='https://cdnstatic.mcvgo.vn/11507/conversions/60f1a2846fe48_HTV9-thanhphan-640_368.png' /></Link>
                  </div>
                </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className=''>
      {renderRecordList({ name: 'Đang phát sóng', children: vm.state.streamData, type: 12 }, vm)}
    </div>
  </div>

const renderRecordList = (datas, vm) => {
  const type = datas.type || 3
  return (
    <div className={`blocktype bt${type}`}>
      {getBlockTitle(datas)}
      <MultiCarousel {...defaultCarouselConfig} responsive={defaultCarouselResponsive(datas)}>
        {G.zu.array(datas.children).map((data, index) =>
          <div key={index} className={`bt${type}_item item__hot`}>
            {getTitle(data)}
          </div>)}
      </MultiCarousel>
    </div>
  )
}

const getBlockTitle = (datas) => <div className='b_title'>{G.zu.get(datas, 'name', '')} <Link to='/tim-kiem' className='btitle_viewall'>Tất cả</Link></div>
const getThumb = (data) => <Zimg src={G.zu.get(data, 'photo_landscape.thumb_1920x1080') || G.zu.get(data, 'img')} thumb={G.zu.get(data, 'photo.tiny')} alt={data.name} />
const defaultCarouselConfig = {
  arrows: true,
  centerMode: false,
  infinite: true,
  showDots: false,
  slidesToSlide: 1,
  fade: false,
  swipeable: false,
  itemClass: '',
  sliderClass: '',
  dotListClass: '',
  renderButtonGroupOutsidedotListClass: ''
}
const defaultCarouselResponsive = (datas) => {
  let result = {
    desktop1440: { breakpoint: { max: 9000, min: 1440 }, items: 6, partialVisibilityGutter: 40 },
    desktop: { breakpoint: { max: 1440, min: 1024 }, items: 5, partialVisibilityGutter: 40 },
    tablet: { breakpoint: { max: 1080, min: 680 }, items: 2, partialVisibilityGutter: 30 },
    mobile: { breakpoint: { max: 680, min: 0 }, items: 2, partialVisibilityGutter: 30 }
  }

  switch (datas.type) {
    case 12:
      result = {
        desktop1440: { breakpoint: { max: 9000, min: 768 }, items: 4, partialVisibilityGutter: 40 },
        desktop: { breakpoint: { max: 768, min: 0 }, items: 3, partialVisibilityGutter: 40 }
      }
      break
    case 3:
      result = {
        desktop1440: { breakpoint: { max: 9000, min: 1440 }, items: 6, partialVisibilityGutter: 40 },
        desktop: { breakpoint: { max: 1440, min: 1080 }, items: 5, partialVisibilityGutter: 40 },
        tablet: { breakpoint: { max: 1080, min: 680 }, items: 4, partialVisibilityGutter: 30 },
        mobile: { breakpoint: { max: 680, min: 0 }, items: 4, partialVisibilityGutter: 30 }
      }
      break

    default:
  }

  return result
}
const getTitle = (data, options) =>
  <Link to={getLink(data)} className='bitem_link'>
    {getThumb(data)}
    <div className='bitem_info'>
      <div className='bitem_infoin'>
        <div className='bitem_title'>{data.name}</div>
        <div className='bitem_btn-trailer'>Xem trailer</div>
        <div className='bitem_btn'>Xem ngay</div>
        {G.zu.runSafe(() => { return options.after() })}
      </div>
    </div>
  </Link>

const getLink = (data) => {
  if (!data.object_type_id) {
    return '/hot/rec-' + data.key
  }

  const linkMap = {
    0: '/no-route',
    1: '/truyen-hinh',
    2: '/xem-phim',
    9: '/mua-goi'
  }
  return `${G.zu.get(linkMap, [data.object_type_id], '/no-route')}/${G.zu.rxChangeSlug(G.zu.get(data, 'name', ''))}-${data.id}`
}
