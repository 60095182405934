import TodoList from './todoList'
import TodoDetail from './todoDetail'

G.routes.unshift({
  path: '/todo',
  component: TodoList
}, {
  path: '/tododetail/:id',
  component: TodoDetail
})
