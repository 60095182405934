import * as Jsx from './view.jsx'
// eslint-disable-next-line
import RxComponent from '/app/zcore/zcomponent'

const api = '/api/v1/homepages?parent_id=1&'
const apiWatch = '/api/v1/video/watch'
class component extends RxComponent {
  constructor (props, context) {
    super(props, context, Jsx)
    this.state = {
      datahome: []
    }
  }

  fetchData () {
    G.zq.get(api, this.state.filter, (json) => {
      if (json.success) {
        this.setState({ datahome: json.data }, () => {
          if (G.zq.localLoad('loginby') !== 'logout') {
            setTimeout(() => {
              this.fetchWatch()
            }, 2000)
          } else {
            this.setState({ datahome: json.data.filter(el => el.display_type !== 20) })
          }
        })
      }
    })
  }

  fetchWatch () {
    G.zq.get(apiWatch, {}, (json) => {
      if (json.success) {
        const arr = [...this.state.datahome.map((el) => {
          if (el.display_type === 20) {
            el.children = [...json.data]
            return el
          }
          return el
        })]
        this.setState({ datahome: [...arr] })
      }
    })
  }

  componentDidMount () {
    this.fetchData()
  }

  render () { return this.renderComponent() }
}

export default component
