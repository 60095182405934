import { Link } from 'react-router-dom'
import React from 'react'
import { } from './style.css'
import Paging from './../others/paging'

export const renderComponent = (vm) => {
  const isLogin = G.zq.localLoad('loginby')
  return (
    <div className='pageNoti'>
      <div className='account__support content__block'>
        <div className='account__support--block'>
          <div className='account__support--title pageNotiTitle'>Thông báo</div>
          <div className='account__support--body pageBodyNoti'>
            {(isLogin !== 'logout' && typeof isLogin === 'string') ?
              <div className='notiBody notiInApp'>
                {vm.state.listNotifications.map((item, i) =>
                  <div key={i} className='notiItem'>
                    <div className='notiClose' onClick={() => vm.onClickDeleteNoti(item.id)}><img src='static/img/icon_close.svg' alt='iconClose' /></div>
                    {(() => {
                      switch (item.deeplink.enum) {
                        case 'OTHER':
                          return (
                            <div className='notiItemTitle'>
                              <span className='notiItemName'>{item.title}</span>
                            </div>
                          )
                        case 'COMMENT':
                          return (
                            <Link className='notiItemTitle' to={'/xem-phim/' + item.deeplink.slug + '-' + item.deeplink.video_id} onClick={() => vm.onClickCommentNotification()}>
                              <span className='notiItemName'>{item.title}</span>
                            </Link>
                          )
                        default:
                          return (
                            <></>
                          )
                      }
                    })()}
                    <div className='notiItemBody'>{item.content}</div>
                    <div className='notiItemTime'>{item.created_at}</div>
                  </div>
                )}
              </div> :
              <></>}
          </div>
          {G.zu.clone(vm.state.meta).last_page > 1 && <Paging meta={G.zu.clone(vm.state.meta)} onPage={(page) => vm.handleChangePage(page)} />}
        </div>
      </div>
    </div>
  )
}
