import React from 'react'
import { renderRoutes } from 'react-router-config'
import { Link } from 'react-router-dom'

import {} from './styles/header.css'
import {} from './styles/body.css'
import {} from './styles/footer.css'

import { renderFooter } from './viewFooter.jsx'
import { renderHeader } from './viewHeader.jsx'
import * as Login from './auth/login'
import * as Register from './auth/register'
import * as OTP from './auth/otp'
import * as OTPSSo from './auth/otpsso'
import * as OTPForget from './auth/otpforget'
import * as OTPRegister from './auth/otpregister'
import * as AddPassword from './auth/addpassword'
import * as Success from './auth/success'
import * as Forget from './auth/forget'
import * as ForgetUpdatePassword from './auth/updatepassword'
import * as SelectMember from './auth/selectmember'
import * as MemberPassword from './auth/memberpassword'
import * as NewMember from './auth/newmember'
import * as SSOWithPhone from './auth/ssowithphone'

export const renderComponent = (vm) => {
  const auth = vm.props.auth[0].code !== 0
  const isLogin = vm.state.form.isLogin
  const isRegister = vm.state.form.isRegister
  const isOTP = vm.state.form.isOTP
  const isOTPSSo = vm.state.form.isOTPSSo
  const isOTPForget = vm.state.form.isOTPForget
  const isOTPRegister = vm.state.form.isOTPRegister
  const isAddpassword = vm.state.form.isAddpassword
  const isRegisterSuccess = vm.state.form.isRegisterSuccess
  const isUpdatePassword = vm.state.form.isUpdatePassword
  const isChangePasswordSuccess = vm.state.form.isChangePasswordSuccess
  const isForget = vm.state.form.isForget
  const isSelectMember = vm.state.form.isSelectMember
  const isTypeMemberPassword = vm.state.form.isTypeMemberPassword
  const isNewMember = vm.state.form.isNewMember
  const isSSOWithPhone = vm.state.form.isSSOWithPhone
  const isSSOSuccess = vm.state.form.isSSOSuccess
  const getHours = new Date().getHours()
  const getMinutes = new Date().getMinutes()
  const hours = getHours < 10 ? '0' + getHours : getHours
  const minutes = getMinutes < 10 ? '0' + getMinutes : getMinutes
  const timeNoti = hours + ':' + minutes
  return (
    <div className='page'>
      {(vm.state.isNoti && vm.state.pushNotification) &&
        <div className='notiPush'>
          <div className='notiClose' onClick={() => vm.onClickClosePushNoti()}><img src='static/img/iconClose.png' alt='iconClose' /></div>
          <div className='notiTile'>Thông báo mới</div>
          <div className='notiBody'>
            {vm.state.notifications.map((item, i) =>
              <div key={i} className='notiItem'>
                <div className='notiItemTitle'>
                  {(() => {
                    switch (vm.state.pushNotiData.enum) {
                      case 'EPGS':
                        return (
                          <Link to={'/kenh-cua-ban/' + vm.state.pushNotiData.slug + '-' + vm.state.pushNotiData.channel_id + '/' + vm.state.epgPush.id} className='notiItemName'>{item.title}</Link>
                        )
                      default:
                        return (
                          <span className='notiItemName'>{item.title}</span>
                        )
                    }
                  })()}
                  <span className='notiItemName'>{item.title}</span>
                  <span className='notiItemTime'>{timeNoti}</span>
                </div>
                <div className='notiItemBody'>{item.body}</div>
              </div>
            )}
          </div>
        </div>}
      {renderHeader(vm)}
      {vm.renderBody()}
      {renderFooter(vm)}

      {(auth && isLogin) && Login.renderLoginPopup(vm)}
      {isRegister && Register.renderRgisterPopup(vm)}
      {isOTP && OTP.renderOTPPopup(vm)}
      {isOTPSSo && OTPSSo.renderOTPSSOPopup(vm)}
      {isOTPForget && OTPForget.renderOTPForgetPopup(vm)}
      {isOTPRegister && OTPRegister.renderOTPRegisterPopup(vm)}
      {isAddpassword && AddPassword.renderAdd(vm)}
      {isRegisterSuccess && Success.renderSuccess(vm)}
      {isUpdatePassword && ForgetUpdatePassword.renderAdd(vm)}
      {isChangePasswordSuccess && Success.renderSuccessChangePassword(vm)}
      {isForget && Forget.renderForget(vm)}
      {(auth && isSelectMember) && SelectMember.renderSelectMember(vm)}
      {isTypeMemberPassword && MemberPassword.renderMemberPassword(vm)}
      {isNewMember && NewMember.renderNewMember(vm)}
      {isSSOWithPhone && SSOWithPhone.renderSSOWithPhone(vm)}
      {isSSOSuccess && Success.renderSSOSuccess(vm)}
    </div>
  )
}

export const renderBody = (vm) =>
  <div className='page_body'>
    <div className='container'>
      {renderRoutes(vm.props.route.routes)}
    </div>
  </div>
