import * as Jsx from './view.jsx'
// eslint-disable-next-line
import RxComponent from '/app/zcore/zcomponent'
import {} from './style.css'

class component extends RxComponent {
  constructor (props, context) {
    super(props, context, Jsx)
    this.state = {
      dataWatch: [],
      deleteMessage: false,
      deleteAllMessage: false,
      isDeleteMessage: '',
      deleteAll: false,
      isDeleteAllMessage: '',
      meta: {
        current_page: 0,
        from: 0,
        last_page: 0,
        per_page: 0,
        to: 0,
        total: 0
      }
    }
  }

  componentDidMount () {
    this.fetchDataWatch()
  }

  fetchDataWatch (changepage) {
    const body = {
      per_page: 10,
      page: changepage || this.state.meta.current_page
    }
    G.zq.get('/api/v1/video/watch', body, (json) => {
      if (json.success) {
        this.setState({ dataWatch: json.data, meta: { ...G.zu.clone(json.meta) } })
      }
    })
  }

  deleteVodWatching (videoId, episodeId) {
    const body = {
      video_id: videoId,
      episode_id: episodeId
    }
    G.zq.delete('/api/v1/video/watch/remove', body, (json) => {
      this.setState({ deleteMessage: true, isDeleteMessage: json.success })
      if (json.success) {
        this.fetchDataWatch(this.state.meta.current_page)
      }
      setTimeout(() => {
        this.setState({ deleteMessage: false, isDeleteMessage: '' })
      }, 3000)
    })
  }

  deleteVodWatchingAll () {
    this.setState({ deleteAll: true })
  }

  handleClosePopupWarning () {
    this.setState({ deleteAll: false })
  }

  deleteWatchingAll () {
    G.zq.delete('/api/v1/video/watch/remove', {}, (json) => {
      if (json.success) {
        this.fetchDataWatch(1)
      }
      this.setState({ deleteAll: false, deleteAllMessage: true, isDeleteAllMessage: json.success })
      setTimeout(() => {
        this.setState({ deleteAllMessage: false, isDeleteAllMessage: '' })
      }, 3000)
    })
  }

  handleChangePage (page) {
    const meta = G.zu.clone(this.state.meta)
    meta.current_page = page
    this.setState({ meta })
    this.fetchDataWatch(page)
  }

  render () { return this.renderComponent(this) }
}

export default component
