import React from 'react'
import {} from './style/forget.css'

export const renderForget = (vm) => {
  return (
    <div className='popup'>
      {renderForgetForm(vm)}
    </div>
  )
}

const renderForgetForm = (vm) => {
  const nameTab = 'Quên mật khẩu'
  const tooltip = 'Nhập số điện thoại để nhận mã xác thực'
  const phone = zget(vm.state, ['editingDataLogin', 'phone'], '')
  const phonelength = phone.length === 10
  const validate = vm.state.validate
  return (
    <div className='popup_forget_modal forget_modal'>
      <div className='forget_top'>
        <span className='forget_cancel_btn' onClick={() => vm.handleCloseForm()} />
      </div>
      <div className='forget_header'>{nameTab}</div>
      <div className='forget_tooltip'>{tooltip}</div>
      <div className='forget_body'>
        <div className='forget_form'>
          <label>Số điện thoại</label>
          <input className={validate.isPhoneValid ? '' : 'error'} type='text' placeholder='Nhập số điện thoại' value={vm.state.editingDataLogin.phone} onChange={(e) => vm.onBlurDataLogin(e, 'phone')} />
          {!validate.isPhoneValid && <label toggle className='error show'>{validate.msgErr.phone}</label>}
        </div>
      </div>
      <div className='forget_button_group'>
        <div className='forget_button' onClick={() => vm.onClickBack('openLogin')}>Trở về</div>
        <div className={`forget_button ${phonelength ? 'active' : 'disable'}`} onClick={() => vm.handleSendOTPRetakePassword()}>Xác nhận</div>
      </div>
    </div>
  )
}
