import React from 'react'
// import { Link } from 'react-router-dom'
import MultiCarousel from 'react-multi-carousel'
import * as displayUlti from './displayUlti.jsx'

export const display01To10 = {
  // Type1 banner
  type1: (datas) =>
    <div key={G.zu.get(datas, 'id')} className='blocktype bt1'>
      <MultiCarousel
        {...displayUlti.defaultCarouselConfig}
        responsive={displayUlti.defaultCarouselResponsiveBT1}
        showDots
        autoPlay
        autoPlaySpeed={7000}
      >
        {G.zu.array(datas.children).map((data, index) => <div key={index}>{displayUlti.getTitleBlock1(data)}</div>)}
      </MultiCarousel>
    </div>,

  // slide kênh truyền hình 1 hàng
  type2: (datas) =>
    <div key={G.zu.get(datas, 'id')} className='blocktype bt2'>
      <MultiCarousel {...displayUlti.defaultCarouselConfig} responsive={displayUlti.defaultCarouselResponsive}>
        {G.zu.array(datas.children).map((data, index) => <div key={index}>{displayUlti.getTitleChannel(data)}</div>)}
      </MultiCarousel>
    </div>,

  // slide kênh truyền hình 2 hàng
  type3: (datas) =>
    <div key={G.zu.get(datas, 'id')} className='blocktype bt3'>
      {displayUlti.getBlockTitle(datas)}
      <MultiCarousel
        {...displayUlti.defaultCarouselConfig}
        responsive={{
          desktop2: { breakpoint: { max: 90000, min: 1401 }, items: 6, partialVisibilityGutter: 30 },
          desktop: { breakpoint: { max: 1400, min: 1201 }, items: 6, partialVisibilityGutter: 30 },
          tablet2: { breakpoint: { max: 1200, min: 992 }, items: 6, partialVisibilityGutter: 30 },
          tablet: { breakpoint: { max: 992, min: 769 }, items: 6, partialVisibilityGutter: 30 },
          mobile2: { breakpoint: { max: 768, min: 581 }, items: 4, partialVisibilityGutter: 30 },
          mobile: { breakpoint: { max: 580, min: 0 }, items: 3, partialVisibilityGutter: 30 }
        }}
      >
        {G.zu.array(datas.children).map((data, index) => <div key={index} className='blocktype bt3_item'>{displayUlti.getTitleChannel(data)}</div>)}
      </MultiCarousel>
    </div>,

  // slide 4 phim, có bartime - Trực tiếp
  type4: (datas) =>
    <div key={G.zu.get(datas, 'id')} className='blocktype bt7'>
      {displayUlti.getBlockTitle(datas)}
      <MultiCarousel
        {...displayUlti.defaultCarouselConfig} responsive={{
          desktop2: { breakpoint: { max: 90000, min: 1920 }, items: 4, partialVisibilityGutter: 40 },
          desktop: { breakpoint: { max: 1920, min: 1400 }, items: 4, partialVisibilityGutter: 40 },
          tablet2: { breakpoint: { max: 1400, min: 1200 }, items: 4, partialVisibilityGutter: 30 },
          tablet: { breakpoint: { max: 1199, min: 922 }, items: 4, partialVisibilityGutter: 30 },
          mobile2: { breakpoint: { max: 922, min: 768 }, items: 3, partialVisibilityGutter: 30 },
          mobile: { breakpoint: { max: 765, min: 580 }, items: 2, partialVisibilityGutter: 30 }
        }}
      >
        {G.zu.array(datas.children).map((data, index) => <div key={index}> {displayUlti.getTitle(data)} </div>)}
      </MultiCarousel>
    </div>,

  // list 5 phim, không slide, 1 to, 4 nhỏ
  type5: (datas) =>
    <div key={G.zu.get(datas, 'id')} className='blocktype bt5'>
      {displayUlti.getBlockTitle(datas)}
      <div className='clearfix'>
        {G.zu.array(datas.children).map((data, index) =>
          <div key={index} className={'bitem_bt5 bitem_bt5-index' + index}>
            {displayUlti.getThumb(data)}
            <div className='bitem_info'>
              <div className='bitem_infoin'>
                <div className='bitem_title'>{data.name}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>,

  // slide 6 phim, hình portrait
  type6: (datas) =>
    <div key={G.zu.get(datas, 'id')} className='blocktype bt6'>
      {displayUlti.getBlockTitle(datas)}
      <MultiCarousel
        {...displayUlti.defaultCarouselConfig}
        responsive={{
          desktop2: { breakpoint: { max: 90000, min: 1920 }, items: 6, partialVisibilityGutter: 40 },
          desktop: { breakpoint: { max: 1920, min: 1400 }, items: 6, partialVisibilityGutter: 40 },
          tablet2: { breakpoint: { max: 1400, min: 1200 }, items: 6, partialVisibilityGutter: 30 },
          tablet: { breakpoint: { max: 1199, min: 922 }, items: 4, partialVisibilityGutter: 30 },
          mobile2: { breakpoint: { max: 922, min: 768 }, items: 4, partialVisibilityGutter: 30 },
          mobile: { breakpoint: { max: 765, min: 580 }, items: 3, partialVisibilityGutter: 30 }
        }}
      >
        {G.zu.array(datas.children).map((data, index) => <div key={index}>{displayUlti.getTitlePortrait(data)}</div>)}
      </MultiCarousel>
    </div>,

  // slide hiển thị 4 ưu đãi
  type7: (datas) =>
    <div key={G.zu.get(datas, 'id')} className='blocktype bt7'>
      {displayUlti.getBlockTitle(datas)}
      <MultiCarousel
        {...displayUlti.defaultCarouselConfig}
        responsive={{
          desktop2: { breakpoint: { max: 90000, min: 1920 }, items: 4, partialVisibilityGutter: 40 },
          desktop: { breakpoint: { max: 1920, min: 1400 }, items: 4, partialVisibilityGutter: 40 },
          tablet2: { breakpoint: { max: 1400, min: 1200 }, items: 4, partialVisibilityGutter: 30 },
          tablet: { breakpoint: { max: 1199, min: 922 }, items: 4, partialVisibilityGutter: 30 },
          mobile2: { breakpoint: { max: 922, min: 768 }, items: 3, partialVisibilityGutter: 30 },
          mobile: { breakpoint: { max: 765, min: 580 }, items: 2, partialVisibilityGutter: 30 }
        }}
      >
        {G.zu.array(datas.children).map((data, index) =>
          <div key={index}>
            {displayUlti.getTitle(data, { after: () => <div className='bitem_purchase'>Mua gói</div> })}
          </div>)}
      </MultiCarousel>
    </div>,

  // slide 4 phim, hình portrait
  type8: (datas) =>
    <div key={G.zu.get(datas, 'id')} className='blocktype bt7'>
      {displayUlti.getBlockTitle(datas)}
      <MultiCarousel {...displayUlti.defaultCarouselConfig} responsive={displayUlti.defaultCarouselResponsive}>
        {G.zu.array(datas.children).map((data, index) =>
          <div key={index}>
            {displayUlti.getTitle(data, { after: () => <div className='bitem_purchase'>Mua gói</div> })}
          </div>)}
      </MultiCarousel>
    </div>,

  // danh sách kênh truyền hình
  type10: (datas) =>
    <div key={G.zu.get(datas, 'id')} className='blocktype bt7'>
      {displayUlti.getBlockTitle(datas)}
      <MultiCarousel
        {...displayUlti.defaultCarouselConfig} responsive={{
          desktop2: { breakpoint: { max: 90000, min: 1920 }, items: 4, partialVisibilityGutter: 40 },
          desktop: { breakpoint: { max: 1920, min: 1400 }, items: 4, partialVisibilityGutter: 40 },
          tablet2: { breakpoint: { max: 1400, min: 1200 }, items: 4, partialVisibilityGutter: 30 },
          tablet: { breakpoint: { max: 1199, min: 922 }, items: 4, partialVisibilityGutter: 30 },
          mobile2: { breakpoint: { max: 922, min: 768 }, items: 3, partialVisibilityGutter: 30 },
          mobile: { breakpoint: { max: 765, min: 580 }, items: 2, partialVisibilityGutter: 30 }
        }}
      >
        {G.zu.array(datas.children).map((data, index) =>
          <div key={index}>
            {displayUlti.getTitle(data, { after: () => <div className='bitem_purchase'>Mua gói</div> })}
          </div>)}
      </MultiCarousel>
    </div>
}
