import React from 'react'

export const renderComponent = (vm) =>
  <div>
    <div className='todo_title'>TODODETAIL</div>
    <div className='todo_body'>
      TODOBODY
    </div>
    <div className='todo_footer' />
  </div>
