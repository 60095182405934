/* global jQuery */
(function ($) {
  const defaults = {
    name: 'What is your text ?',
    form: [{
      name: 'Your input'
    }]
  }
  $.fn.zpromt = function (options, preValue, cb) {
    const settings = $.extend({}, defaults, options)
    return this.each(function (e) {
      const result = preValue || {}
      formRender(this, settings, cb, result)
    })
  }

  const html = `<div class='zpromt'>
    <div class='zpromt_curtain' />
    <div class='zpromt_wrap'>
      <div class='zpromt_form'></div>
      <div>
        <button class='zpromt_submit'>Ok</button>
        <button class='zpromt_cancel'>Cancel</button>
        <button class='zpromt_delete'>Delete</button>
      </div>
    </div>
  </div>`

  function formRender (othis, settings, cb, result) {
    const $this = $(othis)
    const $promt = $('<div/>').html(html)

    for (const i in settings.form) {
      const input = settings.form[i]
      let inputEle = ''

      // Check condition show
      if (input.condition) {
        let exit = false
        for (const i in input.condition) {
          const perCondition = input.condition[i]
          if (perCondition.indexOf(result[i]) === -1) {
            exit = true
            break
          }
        }
        if (exit) continue
      }

      switch (input.type) {
        case 'select':
          var options = []
          if (Array.isArray(input.options)) {
            options = input.options
          }

          if (typeof input.optionsApi !== 'undefined' && input.optionsApi) {
            options = []
            $.ajax({
              url: input.optionsApi,
              success: function (json) {
                let jsonObj = {}
                try { jsonObj = JSON.parse(json) } catch (ne) {}
                if (jsonObj.status !== 1 || !Array.isArray(jsonObj.data)) { return }
                let options = jsonObj.data.map(ele => ele.name).filter(ele => {
                  let name = ''
                  try { name = settings.dataEditing.name } catch (e) {}
                  return ele !== name
                })
                result[input.name] = result[input.name] || options[0] || 'String'
                options = options.map((ele) => {
                  const selectedHtml = result[input.name] === ele ? 'selected="selected"' : ''
                  return `<option value='${ele}' ${selectedHtml}>${ele}</option>`
                })
                inputEle = `<div>
                  <div><b class='zpromt_label'>${input.name}</b></div>
                  <div><select class='zpromt_input_${input.name.replace(/ /g, '')}' value='${result[input.name] || ''}'>${options.join('')}</select></div><br/>
                </div>`
                $promt.find('.zpromt_form').append(inputEle)
              }
            })
          } else {
            result[input.name] = result[input.name] || options[0] || 'String'
            options = options.map((ele) => {
              const selectedHtml = result[input.name] === ele ? 'selected="selected"' : ''
              return `<option value='${ele}' ${selectedHtml}>${ele}</option>`
            })
            inputEle = `<div>
              <div><b class='zpromt_label'>${input.name}</b></div>
              <div><select class='zpromt_input_${input.name.replace(/ /g, '')}' value='${result[input.name] || ''}'>${options.join('')}</select></div><br/>
            </div>`
            $promt.find('.zpromt_form').append(inputEle)
          }
          break
        case 'textarea':
          inputEle = `<div>
            <div><b class='zpromt_label'>${input.name}</b></div>
            <div><textarea rows='4' cols='50' class='zpromt_input_${input.name.replace(/ /g, '')}'>${result[input.name] || ''}</textarea></div><br/>
          </div>`
          $promt.find('.zpromt_form').append(inputEle)
          break
        default:
          inputEle = `<div>
            <div><b class='zpromt_label'>${input.name}</b></div>
            <div><input class='zpromt_input_${input.name.replace(/ /g, '')}' value='${result[input.name] || ''}'></div><br/>
          </div>`
          $promt.find('.zpromt_form').append(inputEle)
          break
      }
    }

    $promt.on('click', '.zpromt_curtain', () => {
      $promt.remove()
    })

    $promt.on('click', '.zpromt_cancel', () => {
      $promt.remove()
    })

    $promt.on('click', '.zpromt_submit', () => {
      $promt.remove()
      typeof (cb) === 'function' && cb(result)
    })

    $promt.on('click', '.zpromt_delete', () => {
      $promt.remove()
      result = { ...result, zdelete: 1 }
      typeof (cb) === 'function' && cb()
    })

    $this.append($promt)

    // Event
    setTimeout(() => {
      for (const i in settings.form) {
        const input = settings.form[i]
        $(`.zpromt_form .zpromt_input_${input.name.replace(/ /g, '')}`).change(e => {
          console.log(e)
          result[input.name] = e.target.value
          $promt.remove()
          formRender(othis, settings, cb, result)
        })
      }
    }, 1000)
  }

  var style = document.createElement('style')
  style.innerHTML = `
.zpromt {
  z-index: 10000;
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}
.zpromt_curtain {
  background-color: rgba(0,0,0,0.4);
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}
.zpromt_wrap {
  width: 80%;
  height: 80%;
  top: 10%;
  left: 10%;
  position: fixed;
  background-color: #fff;
  box-sizing: border-box;
  z-index: 10001;
  border-radius: 5px;
  padding: 20px;
}
.zpromt_cancel, .zpromt_delete {
  width: 80px;
  padding: 11px;
  background-color: #f7f8f9;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  border: solid 1px #f8f9fa;
  color: #000;
  border-radius: 3px;
  cursor: pointer;
  box-sizing: border-box;
  line-height: 0px;
  float: left;
  margin-right: 10px;
}
.zpromt_submit {
  width: 80px;
  padding: 11px;
  background-color: #008cff;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  border: solid 1px;
  color: #ffffff;
  border-radius: 3px;
  cursor: pointer;
  box-sizing: border-box;
  line-height: 0px;
  float: left;
  margin-right: 10px;
}
.zpromt_label {
  text-transform: capitalize;
}
  `
  document.head.appendChild(style)
})(jQuery)
