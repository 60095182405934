import * as Jsx from './view.jsx'
import { JSEncrypt } from 'jsencrypt'
import { io } from 'socket.io-client'
import RxComponent from '/app/zcore/zcomponent' // eslint-disable-line

// https://ams.skysocial.club/LiveApp/play.html?name=${streamKey}&autoplay=true&mute=1&enablejsapi=1

const videoElement = 'videoPlayer'
const apiLivestream = 'https://api.mcvgo.vn/api/v1/events'
class component extends RxComponent {
  constructor (props, context) {
    super(props, context, Jsx)
    this.state = {
      slug: '',
      videoID: '',
      videoData: {},
      streamData: {},
      hlsList: [],
      dataList1: [],
      dataList2: [],
      dateCurr: {}
    }
  }

  componentDidMount () {
    this.loadDataStream()
    this.handleWebsocket()
  }

  loadDataStream () {
    G.zq.get(apiLivestream, {}, (json) => {
      if (json.success) {
        this.setState({ streamData: json.data })
        console.log(11112222, this.state.streamData)
      }
    })
  }

  handleWebsocket () {
    const encrypt = new JSEncrypt()
    const publicKey = `-----BEGIN PUBLIC KEY-----
    MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCX2a3rS8QfDph+AwnWORQjGoBQ
    pdTogTuaIini8hXMggwJARwKBk8To0XR+n6ZfiG1EdSeLk5Agf749cXesZAMmuR+
    +GkkPSfD7wx30VRuoujhteUKDSfPcuvunOPhT2/7cwkEbZpxdoapHkEK2T9xBQzH
    +mViAHmQUqlaFyNDfwIDAQAB
    -----END PUBLIC KEY-----`
    const data = {
      id: '1',
      name: 'Dzung Pham'
    }
    encrypt.setPublicKey(publicKey)
    const token = encrypt.encrypt(JSON.stringify(data))
    console.log('Tính Bóng Lòi', token)
    const urlConnect = 'ws://realtime.interactive.skysocial.club?roomId=7d9f31bd-d389-45a1-b908-bcbf5feddb2b&appId=1&token=' + token
    const socket = io(urlConnect)

    console.log('zzz ', socket)

    if (socket.connected) {
      console.log('Connect Success')
    } else {
      console.log('Connect Fails')
    }
    console.log('kien cute xiu', socket.doOpen)
  }

  componentWillUnmount () {
  }

  componentWillReceiveProps (nextProps) {
  }

  render () { return this.renderComponent() }
}

export default component
