import React from 'react'
import {} from './style/memberpassword.css'

export const renderMemberPassword = (vm) => {
  return (
    <div className='popup'>
      {renderMemberPasswordForm(vm)}
    </div>
  )
}

const renderMemberPasswordForm = (vm) => {
  const isPasswordValid = zget(vm.state, ['validate', 'isPasswordValid'], true)
  const message = zget(vm.state, ['validate', 'msgErr'], { phone: '', password: '', otp: '' })
  const nameTab = 'Nhập mật khẩu'
  const showPassword = zget(vm.state, ['editingDataLogin', 'showPassword'], false)
  const password = zget(vm.state, ['editingDataLogin', 'password'], '')
  return (
    <div className='popup_memberpassword_modal memberpassword_modal'>
      <div className='memberpassword_top'>
        <span className='memberpassword_cancel_btn' onClick={() => vm.handleCloseForm()} />
      </div>
      <div className='memberpassword_header'>{nameTab}</div>
      <div className='memberpassword_body'>
        <div className='memberpassword_form'>
          <label>Mật khẩu</label>
          <input className={!isPasswordValid ? 'error' : ''} type={`${showPassword ? 'text' : 'password'}`} placeholder='Ít nhất 8 ký tự' value={password} onChange={(e) => vm.onBlurDataLogin(e, 'password')} />
          <span className='memberpassword_eye' onClick={() => vm.handleShowPassword()} />
          <label toggle className='error show'>{isPasswordValid ? '' : message.password}</label>
        </div>
      </div>
      <div className='memberpassword_button_group'>
        <div className='memberpassword_button' onClick={() => vm.onClickBack('openSelectMember')}>Trở về</div>
        <div className={`memberpassword_button ${password.length >= 8 ? 'active' : 'disable'}`} onClick={() => (password.length >= 8) && vm.onSubmitMember()}>Xác nhận</div>
      </div>
    </div>
  )
}
