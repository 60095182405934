const handler = {
  get: function (target, key) {
    if (typeof target[key] === 'object' && target[key] !== null) {
      return new Proxy(target[key], handler)
    } else {
      return target[key]
    }
  },
  set: function (target, key, value) {
    target[key] = value
    return true
  }
}

global.ProxyHandler = handler
