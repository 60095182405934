import { Link } from 'react-router-dom'

export const renderDetailPayment = (vm) => {
  return (
    <>
      <div className='packpopup_curtain' onClick={() => vm.onClickClosePopup()} />
      <div className='packpopup_outer'>
        <div className='packpopup_wrap'>
          <img className='pp_close' src='static/img/icon_close.svg' alt='close' onClick={() => vm.onClickClosePopup()} />
          <div className='packpopup_head'>Thông tin chi tiết gói</div>
          <div className='packpopup_body'>
            <img className='packpopup_thumbnail' src={vm.state.curPackage.photo.thumbnail} />
            <div className='packfeature_list'>
              <div className='pack_title'>{vm.state.curPackage.name}</div>
              {G.zu.array(vm.state.curPackage.services).map((e, i) => {
                return (
                  <div key={e.id} className='feature_item'>
                    <img className='feature_icon' src='/images/static/checked.svg' alt='' />
                    <div className='feature_name'>{e.name}</div>
                  </div>
                )
              })}
            </div>
            <div className='packprice_list'>
              <div className='pack_title'>Bảng giá</div>
              <div className='pack_price_wrap'>
                {G.zu.array(vm.state.curPackage.prices).map((e, i) =>
                  <div key={i} className={`pack_price ${vm.state.curEditing.priceID === e.id ? 'active' : ''}`} onClick={ex => vm.onClickPackPrice(e)}>
                    <div className='pack_period'>{e.months} tháng</div>
                    <div className='pack_pricenum'>{G.zu.strToMoney(e.price)} đ</div>
                  </div>)}
              </div>
              {!!vm.state.curEditing.priceMSG && <div className='pack_errors'>{vm.state.curEditing.priceMSG}</div>}
              {G.zu.array(vm.state.package_using.package).length > 0 &&
                <div className='package_using'>
                  <div className='pack_title'>Đã mua</div>
                  <div className='pack_using_list'>
                    {G.zu.array(vm.state.package_using.package).map((e, i) => {
                      return (
                        <>
                          <div
                            key={e.i}
                            id={e.id} className={`pack_item ${e.expired_at ? (e.expired_at.split('')[0].replaceAll('-', '/') > G.zu.getDate() ? 'none' : '') : ''} ${vm.state.activeItem === e.id ? 'active' : ''}`}
                            onClick={() => vm.handleActiveItem(e.id)}
                          >
                            <div className='pack_item_title'>
                              <div className='pack_item_name'>{e.package !== null && e.package.name}</div>
                              <div className='pack_item_price'>{G.zu.strToMoney(e.price.price)} đ</div>
                            </div>
                            <div className='pack_item_info'>
                              <div><strong>Thời điểm mua: </strong>{e.created_at.split(' ')[0]}</div>
                              <div><strong>Thời hạn sử dụng: </strong>{e.expired_at ? e.expired_at.split(' ')[0] : 'Đang update'}</div>
                              <Link className='packpopup_moreinfo pp_view_history' onClick={() => vm.onClickTransaction()}><img src='static/img/icon_history.svg' alt='icon history' /> Xem lịch sử giao dịch</Link>
                            </div>
                          </div>
                        </>
                      )
                    })}
                  </div>
                </div>}
              <div className='pack_btns'>
                <div className='pack_submit' onClick={e => vm.onClickPackCheckout()}>Mua gói</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
