export const renderRenewPopup = (vm) => {
  return (
    <>
      <div className='packpopup_curtain' onClick={e => vm.handleClosePopup()} />
      <div className='packpopup_outer'>
        <div className='packpopup_wrap'>
          <div className='packpopup_head'>Bạn muốn gia hạn?</div>
          <div className='packpopup_body packpopup_body_noti'>
            <div className='packpopup_body_description'>Nhấn "đồng ý" để bắt đầu gia hạn gói dịch vụ này.</div>
            <img className='packpopup_body_image' src='/images/static/packages/noti_renew.svg' />
            <div className='pack_btns'>
              <div className='pack_submit' onClick={e => vm.handleClosePopup()}>OK</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export const renderStopRenewPopup = (vm) => {
  return (
    <>
      <div className='packpopup_curtain' onClick={e => vm.handleClosePopup()} />
      <div className='packpopup_outer'>
        <div className='packpopup_wrap'>
          <div className='packpopup_head'>Bạn muốn ngừng gia hạn?</div>
          <div className='packpopup_body packpopup_body_noti'>
            <div className='packpopup_body_description'>Nhấn “đồng ý” để tắt chức năng tự động gia hạn gói dịch vụ này.</div>
            <img className='packpopup_body_image' src='/images/static/packages/noti_stop_renew.svg' />
            <div className='pack_btns'>
              <div className='pack_submit' onClick={e => vm.handleClosePopup()}>OK</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
