import React from 'react'
import { } from './style/account.css'
import { } from './style/account.scss'
import * as Menu from './accountMenu.jsx'
import * as Center from './accountCenter.jsx'
import * as SelectMember from './member/selectmember'
import * as NewMember from './member/newmember'
import * as NewPassword from './member/newmemberpassword'
import * as OTP from './member/otp'
import * as TypePassword from './member/memberpassword'
import { renderRenewPopup, renderStopRenewPopup } from './accountNotiPopup'

export const renderBody = (vm) => {
  const memberlogged = G.zq.localLoad('memberlogged')
  return (
    <div className='account__content clearfix'>
      <div className='account__body'>
        <div className='account__left' style={G.zu.get(vm.state.sider, 'isMobile') === true ? { backgroundColor: G.zu.get(vm.state.sider, 'background') } : {}}>
          <div className='toggle_wrap' style={G.zu.get(vm.state.sider, 'isMobile') === true ? { width: G.zu.get(vm.state.sider, 'width') } : {}}>
            <div className='account__toggle'>
              <div className='account__expand'>
                <button className='account__expand--btn' onClick={() => vm.onClickExpand()}><span className={G.zu.get(vm.state.sider, 'classSider')} /></button>
              </div>
              <div className='account_menubar'>{Menu.renderNavMenu(vm)}</div>

              {G.zu.get(vm.state.sider, 'width') === '52px' && G.zu.get(vm.state.sider, 'isMobile') === true ?
                <div className='account__member'><img src='/images/static/users.svg' className='account_member_icon' /></div> :
                <div className='account__member'>
                  <div className='account__member--title'>Danh sách thành viên</div>
                  <div className='account__member--body account_member_custom'>
                    {G.zu.array(G.zu.get(vm.state, 'member')).map((mem, index) => {
                      if (mem.age) {
                        return (
                          <div className='modal__item' key={index}>
                            <div className={'modal__member--age ' + (memberlogged === mem.id ? 'selected' : '')} style={{ backgroundColor: G.zu.get(mem, 'age.background_color') }} onClick={(e) => vm.onClickDataEdit(e, mem, 'member')}>{G.zu.get(mem, 'age.name').replace('tuổi', '')}</div>
                            <div className={'modal__member--name ' + (memberlogged === mem.id ? 'selected' : '')}>{mem.name}</div>
                          </div>
                        )
                      }
                      return (<div className='something' key={index} />)
                    })}
                    <div className='modal__item'>
                      <div className='modal__member--age add' onClick={() => vm.handleOpenFormName('NewMemberForm')}><span className='icomoon-plus' /></div>
                      <div className='modal__member--name'>Tạo thêm</div>
                    </div>
                  </div>
                </div>}
            </div>
          </div>

        </div>
        {Center.renderAccountCenter(vm)}
      </div>
    </div>
  )
}

export const renderChangePass = (vm) => {
  return (
    <div className='lk-customer-mainnav-inner'>
      <input type='password' value={G.zu.get(vm.state.editingData, 'changePass.current_password', '')} onChange={(e) => vm.onBlurData(e, 'changePass', 'current_password')} placeholder='Nhập mật khẩu hiện tại' />
      <input type='password' value={G.zu.get(vm.state.editingData, 'changePass.new_password', '')} onChange={(e) => vm.onBlurData(e, 'changePass', 'new_password')} placeholder='Nhập mật khẩu hiện mới' />
      <input type='password' value={G.zu.get(vm.state.editingData, 'changePass.renew_password', '')} onChange={(e) => vm.onBlurData(e, 'changePass', 'renew_password')} placeholder='Nhập lại mật khẩu mới' onBlur={(e) => vm.onValidatePass()} />
      <div className='' onClick={(e) => vm.onClickChangePassWord()}>Submit</div>
    </div>
  )
}

export const renderComponent = (vm) => {
  const form = vm.state.form
  const isSelectMember = form.isSelectMember
  const isNewPassword = form.isNewPassword
  const isNewMember = form.isNewMember
  const isOTP = form.isOTP
  const isTypePassword = form.isTypePassword
  const isRenew = vm.state.notiPopup.renew
  const isStop = vm.state.notiPopup.stop
  const loginby = G.zq.localLoad('loginby')
  return (
    <div className='container account__container'>
      {isSelectMember && SelectMember.renderSelectMember(vm)}
      {isNewPassword && NewPassword.renderMemberPassword(vm)}
      {isNewMember && NewMember.renderNewMember(vm)}
      {isOTP && OTP.renderOTPPopup(vm)}
      {isTypePassword && TypePassword.renderMemberPassword(vm)}
      {(loginby !== 'logout') && <div className='account__container__body'> {vm.run('renderBody')} </div>}
      {/* <div className='accout__block--none'>
        <Link to='/login' className='acount__btn adform__btns--create' onClick={() => vm.handleOpenFormName('')}>Đăng nhập</Link>
        <Link to='/login?type=register' className='acount__btn adform__btns--back'>Đăng ký</Link>
      </div> */}
      {isRenew && renderRenewPopup(vm)}
      {isStop && renderStopRenewPopup(vm)}
    </div>
  )
}
