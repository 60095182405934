import * as Jsx from './view.jsx'
// eslint-disable-next-line
import RxComponent from '/app/zcore/zcomponent'
import { actionAuth, actionTest, actionScrollComment } from '../../../zcore/zredux.js'
import { connect } from 'react-redux'

const apiCheckout = '/api/v1/packages/buy'
const videoElement = 'videoPlayer'
const setNumTabs = 24

class component extends RxComponent {
  // LIFE CYCLE
  constructor (props, context) {
    super(props, context, Jsx)
    this.state = {
      buyNeed: false,
      buyAlready: false,
      buyAlert: false,
      test: this.props.test,
      showPackageDetail: false,
      showPackagePayment: false,
      promotion: {
        code: '',
        selected: {},
        applied: {},
        list: [
          { id: 1, code: 'GIAMGIA', price: 1000, description: 'Giảm 1 nghìn để dòng text đẹp hơn' },
          { id: 2, code: 'MCVGO', price: 8000, description: 'Giảm 8 nghìn cho vui' },
          { id: 3, code: 'MCVGO100', price: 100000, description: 'Giảm 100 nghìn vì sếp thích thế' },
          { id: 4, code: 'MCVGO50', price: 50000, description: 'Giảm 50 nghìn vì rãnh' }
        ]
      },
      curPrice: {},
      curPackage: {},
      curEditing: {},
      isSubmit: false,
      actionSubmit: false,
      activeTab: 1,
      setNumTabs: 24,

      slug: '',
      videoID: '',
      episodeId: '' || this.props.match.params.eps,
      content: '',
      lenghtComment: true,
      videoData: {},
      videoDataCurrentEps: {},
      videoEpisodes: [],
      videoRelation: [],
      videoCommentPost: [],
      videoCommentList: [],
      likeData: {},
      idReply: 0,
      user: {},
      page: 1,
      metaEpisodes: {},
      meta: {
        current_page: 0,
        from: 0,
        last_page: 0,
        per_page: 0,
        to: 0,
        total: 0
      },
      isShare: false,
      isRegister: '',
      isClickRegister: false,
      addVodStatus: false,
      vodPackages: {},
      buyPackages: [],
      ppKeepVideo: false,
      log_time: 0
    }

    if (window.performance) {
      if (performance.navigation.type === 1) {
        this.fetchDataVideoViewed()
      }
    }
  }

  componentWillReceiveProps (nextProps) {
    if (G.zu.get(nextProps, 'match.params.slug') !== G.zu.get(this.props, 'match.params.slug') ||
        G.zu.get(nextProps, 'match.params.eps') !== G.zu.get(this.props, 'match.params.eps')) {
      this.slug = G.zu.get(nextProps, 'match.params.slug').split('-')
      this.epsID = G.zu.get(nextProps, 'match.params.eps')
      this.fetchData()
    }
  }

  componentDidMount () {
    this.slug = G.zu.get(this.props, 'match.params.slug').split('-')
    this.epsID = G.zu.get(this.props, 'match.params.eps')
    this.fetchData(this.slug, this.epsID)
    if (this.props.match.url.split('/').length === 4) {
      this.setState({ ppKeepVideo: false }, () => {
        G.zq.get('/api/v1/episodes/detail', { episode_id: this.props.match.url.split('/')[3] }, (json) => {
          // this.setState({ videoDataCurrentEps: json.data })
          this.onClickEpisode(json.data)
          // this.fetchDataFilm(Math.floor(json.data.position/this.state.setNumTabs) + 1)
        })
        this.initVideo()
      })
    }
    this.props.actionTest('xxxx')
    if (this.state.log_time === 0) {
      setTimeout(() => {
        if (this.props.scrollComment === 1) {
          $('html,body').animate({ scrollTop: ($('#vodComment').offset().top - $('.page_header').height()) })
          setTimeout(() => {
            this.props.actionScrollComment(0)
            console.log(456, this.props.scrollComment)
          }, 5000)
        }
      }, 2000)
    }
  }

  componentWillUnmount () {
    G.zu.runSafe(() => global.jwplayer(videoElement).remove())
  }

  // EVENT
  // onClickPackCheckout () {
  //   G.zq.localSave('callback_paid', window.location.pathname)
  //   this.processCheckout()
  // }

  // onClick Buy Package

  onClickShowPopupPackages () {
    this.setState({ showPackageDetail: true })
  }

  onClickClosePopupKeepVideo (status) {
    this.setState({ ppKeepVideo: false }, () => {
      if (!status) {
        this.setState({ log_time: 0 })
      }
      const urlFilm = '/xem-phim/' + G.zu.rxChangeSlug(this.state.videoData.name) + '-' + this.state.videoData.id + '/' + this.state.videoDataCurrentEps.id
      window.history.pushState({}, null, urlFilm)
      this.initVideo()
    })
    setTimeout(() => {
      if (this.props.scrollComment === 1) {
        $('html,body').animate({ scrollTop: ($('#vodComment').offset().top - $('.page_header').height()) })
        setTimeout(() => {
          this.props.actionScrollComment(0)
          console.log(456, this.props.scrollComment)
        }, 5000)
      }
    }, 1000)
  }

  onClickClosePopup () {
    this.setState({ buyAlert: true, showPackageDetail: false })
  }

  onClickClosePopupPayment () {
    this.setState({ buyAlert: true, showPackageDetail: true, showPackagePayment: false })
  }

  onClickPackPrice (item) {
    this.setState({ curEditing: { ...this.state.curEditing, pack: this.state.curPackage, priceID: item.id, price: item.price, totalPrice: item.price, priceMSG: '' } })
  }

  onSelectPackage (item) {
    this.setState({ vodPackages: { ...this.state.vodPackages, vodPackageID: item.id, vodUrl: G.zu.rxChangeSlug(item.name), priceMSG: '' } })
  }

  onClickPackCheckout () {
    if (!this.state.vodPackages.vodPackageID) {
      this.setStateNested('vodPackages.priceMSG', 'Vui lòng chọn gói dịch vụ')
    } else {
      const localHref = this.props.location.pathname
      window.sessionStorage.setItem('videoNeedBuy', localHref)
      window.location.href = 'mua-goi/' + this.state.vodPackages.vodUrl + '-' + this.state.vodPackages.vodPackageID
    }
  }

  onClickCloseMessage (e) {
    e.preventDefault()
    $('#popupMessage').hide()
    this.setState({ isSubmit: false })
  }

  onClickTabsMenu (vm) {
    const body = {
      video_id: this.videoID,
      per_page: this.state.setNumTabs,
      page: vm.page
    }
    this.setState({ activeTab: vm.page })
    G.zq.get('/api/v1/episodes', body, (json) => {
      this.setState({ videoEpisodes: json.data, metaEpisodes: json.meta })
    })
  }

  onClickPopupShare () {
    this.setState({ isShare: true })
  }

  onClickClosePopupShare () {
    this.setState({ isShare: false })
  }

  onClickCoppyHref () {
    $('.shareHref').select()
    document.execCommand('copy')
  }

  // Handle Like Video
  onclickFavouritesPost (episodeId) {
    G.zq.post('/api/v1/favourites', {
      video_id: Number(this.videoID),
      episode_id: episodeId
    }, (json) => {
      const videoDataCurrentEps = this.state.videoDataCurrentEps
      this.setState({ videoDataCurrentEps: { ...videoDataCurrentEps, is_favourite: true } })
    })
  }

  // Handle UnLike Video
  onclickFavouritesDelete (episodeId) {
    G.zq.delete('/api/v1/favourites', {
      video_id: Number(this.videoID),
      episode_id: episodeId
    }, (json) => {
      const videoDataCurrentEps = this.state.videoDataCurrentEps
      this.setState({ videoDataCurrentEps: { ...videoDataCurrentEps, is_favourite: false } })
    })
  }

  // SERVICE
  processCheckout () {
    if (this.state.curPackage.id && this.state.curPrice.id && this.state.videoID) {
      G.zq.post(apiCheckout, { package_id: this.state.curPackage.id, price_id: this.state.curPrice.id, video_id: this.state.videoID, payment_gateway: 1 }, (json) => {
        if (json.success) {
          window.location.href = G.zu.get(json, 'data.payUrl')
          this.onClickClosePopup()
        }
      })
    }
  }

  makeDate (data, name) {
    G.zq.localSave(name, JSON.stringify(data))
    this.setState({ [`${name}`]: data })
  }

  fetchData () {
    this.initVideoFlag = false
    this.videoID = this.slug[this.slug.length - 1]

    this.setState({
      slug: this.slug,
      videoID: this.videoID
    })

    this.fetchDataFilm()
    this.fetchDataRelation()
    this.fetchDataComment()
    this.fetchDataFavourites()

    G.zq.get('/api/v1/users', this.state.filter, (json) => {
      this.makeDate(G.zu.clone(json.data), 'user')
    })
  }

  // fetchDataFilm2 () {
  //   G.zq.get('/api/v1/videos/detail', {
  //     video_id: this.videoID
  //   }, (json) => {
  //     if (json.success) {
  //       const packageNeedBuy = zget(json, ['data', 'price', 0])
  //       if (packageNeedBuy) {
  //         this.setState({ buyNeed: true, curPackage: packageNeedBuy.packages, curPrice: packageNeedBuy })
  //         G.zq.get('/api/v1/packages/using', { video_id: this.videoID }, (json2) => {
  //           // Find package that buy already
  //           if (json2.success && zget(json2, ['data', 0, 'video', 'id'])) {
  //             this.setState({ buyAlready: true, buyAlert: false, buyNeed: false }, () => { this.setState({ videoData: json.data, videoDataCurrentEps: json.data.last_viewed.episode }, this.fetchDataEpisode()) })
  //           } else {
  //             this.setState({ buyAlready: false, buyAlert: true, buyNeed: true }, () => { this.setState({ videoData: json.data, videoDataCurrentEps: json.data.last_viewed.episode }, this.fetchDataEpisode()) })
  //           }
  //         })
  //       } else {
  //         this.episode = json.data.last_viewed.episode.id
  //         this.setState({ videoData: json.data, videoDataCurrentEps: json.data.last_viewed.episode }, this.fetchDataEpisode())
  //       }
  //     } else {
  //       const authchecked = G.zq.checklogin('/api/v1/videos/detail', json)
  //       if (authchecked) {
  //         this.props.actionAuth(authchecked)
  //       }
  //     }
  //   })
  // }

  // Get information detail film
  fetchDataFilm (epgs) {
    G.zq.get('/api/v1/videos/detail', {
      video_id: this.videoID
    }, (json) => {
      if (json.success) {
        // detail video
        this.setState({ videoData: json.data })
        this.fetchDataEpisode(epgs)
        // thanh toan hay chua?
        if (json.data.packageList) {
          const buyPackages = json.data.packageList
          this.setState({ buyNeed: true, buyPackages: buyPackages })
        } else {
          if (json.data.last_viewed) {
            this.episode = json.data.last_viewed.episode.id
            // da xem hay chua?
            if (json.data.last_viewed.episode.log_viewed) {
              if (json.data.last_viewed.episode.log_viewed.time > 10000) {
                this.setState({
                  ppKeepVideo: true,
                  videoDataCurrentEps: json.data.last_viewed.episode,
                  log_time: json.data.last_viewed.episode.log_viewed.time,
                  isFavourites: json.data.last_viewed.episode.is_favourite
                })
              }
            }
          }
        }
      } else {
        const authchecked = G.zq.checklogin('/api/v1/videos/detail', json)
        if (authchecked) {
          this.props.actionAuth(authchecked)
        }
      }
    })
  }
  // cho nay phai check lai là co log_viewed.time không á, rồi mới show popup ---> 2

  // Fetch episode list
  fetchDataEpisode (page) {
    const body = {
      video_id: this.videoID,
      per_page: this.state.setNumTabs,
      page: page || 1,
      loading: false
    }
    G.zq.get('/api/v1/episodes', body, (json) => {
      if (json.success) {
        const data = json.data
        const epi = data.find(el => el.id === parseInt(this.state.episodeId))
        this.setState({
          videoEpisodes: json.data,
          metaEpisodes: json.meta
        }, () => {
          if (!this.state.ppKeepVideo) {
            this.setState({ videoDataCurrentEps: this.state.episodeId ? G.zu.get(epi, [], {}) : G.zu.get(json, ['data', 0], {}) }, () => {
              this.initVideo()
            })
          }
        })
      }
    })
  }

  //  Check Count Video

  fetchDataViewCount (videoId, episodeId) {
    G.zq.post('/api/v1/videos/count-view', { video_id: Number(videoId), episode_id: episodeId })
  }

  fetchDataVideoViewed () {
    this.slug = G.zu.get(this.props, 'match.params.slug').split('-')
    this.videoID = this.slug[this.slug.length - 1]
    const t = $('.jw-text-elapsed').text()
    let time = 0
    if (t.split(':').length === 3) {
      time = Number(t.split(':')[0]) * 60 * 60 * 1000 + Number(t.split(':')[1]) * 60 * 1000 + Number(t.split(':')[2]) * 1000
    } else {
      time = Number(t.split(':')[0]) * 60 * 1000 + Number(t.split(':')[1]) * 1000
    }
    G.zq.post('/api/v1/video-viewed', { video_id: Number(this.videoID), episode_id: this.state.videoDataCurrentEps.id || Number(this.videoID), time: time > 0 ? time : 0 })
  }

  // Get Data like
  fetchDataFavourites () {
    // G.zq.get('/api/v1/favourites', {}, (json) => {
    //   if (json.success) {
    //     const videoDataFavourites = json.data
    //     const videoId = this.videoID
    //     videoDataFavourites.map((item) => {
    //       (item.id = videoId) ? this.setState({ isFavourites: true }) : ''
    //     })
    //   }
    // })
  }

  onClickEpisode (e) {
    setTimeout(() => {
      console.log(1, e)
      this.setState({ videoDataCurrentEps: e, episodeId: e.id, ppKeepVideo: false, log_time: 0, isFavourites: e.is_favourite }, () => { this.initVideo() })
      this.fetchDataViewCount(this.videoID, this.state.episodeId)
      const URL = 'xem-phim/' + G.zu.get(this.props, 'match.params.slug') + '/' + e.id
      window.history.pushState({}, null, URL)
      this.setState({ addVodStatus: false })
      this.fetchDataEpisode(this.state.metaEpisodes.current_page)
    }, 200)
  }

  fetchDataRelation () {
    G.zq.get('/api/v1/videos/relations', {
      video_id: this.videoID,
      per_page: 12,
      page: 1,
      loading: false
    }, (json) => {
      if (json.success) {
        this.setState({ videoRelation: json.data })
      }
    })
  }

  // Regits Vod

  registVod (episodeId) {
    G.zq.post('/api/v1/video/register', {
      episodeId: episodeId
    }, (json) => {
      this.setState({ isRegister: json.success, isClickRegister: true, addVodStatus: json.message })
      setTimeout(() => {
        this.setState({ isClickRegister: false })
      }, 3000)
    })
  }

  // Code Post comment
  fetchCommentPost () {
    const body = { video_id: this.videoID, content: this.state.content }
    if (this.parentId) {
      body.parent_id = this.parentId
    }

    G.zq.post('/api/v1/comments', body, (json) => {
      this.setState({ isSubmit: json.success, actionSubmit: true })
      setTimeout(() => {
        this.setState({ isSubmit: false, actionSubmit: false })
        this.fetchDataComment(this.state.meta.current_page)
      }, 3000)
    })
  }

  onChangeComment (e) {
    if (e.target.value.length > 500) {
      this.setState({ lenghtComment: false })
    } else {
      this.setState({ lenghtComment: true })
    }
    this.setState({ content: e.target.value })
    if (e.target.value.length < 30) {
      $('#boxComment').on('input', function () {
        $(this).css('height', 20 + 'px')
      })
    }
    $('#boxComment').on('input', function () {
      $(this).css('height', this.scrollHeight + 'px')
    })
  }

  onKeyPressComment (e) {
    if (e.key === 'Enter' && !e.shiftKey) {
      zprevent(e)
      if (e.target.value.length > 0 && this.state.lenghtComment) {
        this.fetchCommentPost()
        $('.comment_input').val('')
      }
    }
  }

  onSubmitComment (e) {
    zprevent(e)
    if (e.target[0].value.length > 0 && this.state.lenghtComment) {
      this.fetchCommentPost()
      $('.comment_input').val('')
    }
  }

  // Action Comment
  onClickActionComment (id) {
    G.zq.post('/api/v1/reaction/like', { comment_id: id }, (json) => {
      if (json.success) {
        this.fetchDataComment(this.state.meta.current_page)
      }
    })
  }

  // Code Get Comment
  fetchDataComment (changepage) {
    G.zq.get('/api/v1/comments', {
      video_id: this.videoID,
      per_page: 5,
      page: changepage || this.state.meta.current_page
    }, (json) => {
      if (json.success) {
        this.setState({ videoCommentList: json.data, meta: { ...G.zu.clone(json.meta) } })
      }
    })
  }

  onClickShowFormReply (id) {
    this.setState({ idReply: id === this.state.idReply ? 0 : id })
    setTimeout(() => {
      $('.form_comment_child .comment_form .comment_input').attr('placeholder', 'Phản hồi tại đây nhé')
    }, 10)
  }

  handleChangePage (page) {
    const meta = G.zu.clone(this.state.meta)
    meta.current_page = page
    this.setState({ meta })
    this.fetchDataComment(page)
  }

  // End Code Get Comment
  initVideo () {
    const url = G.zu.get(this.state, 'videoDataCurrentEps.url_cdn') == null ? G.zu.get(this.state, 'videoDataCurrentEps.url_origin') : G.zu.get(this.state, 'videoDataCurrentEps.url_cdn')
    if (url && (!this.state.buyNeed || (this.state.buyNeed && this.state.buyAlready))) {
      this.initVideoFlag = true
      setTimeout(() => {
        const sources = [{
          file: url,
          overrideNative: true,
          mute: false,
          autostart: true
        }]

        this.fetchDataViewCount(this.videoID, this.state.videoDataCurrentEps.id)

        const captions = [
          // {
          //   file: 'https://dl.dropboxusercontent.com/s/utoble457jxeexc/the-legend-of-bruce-lee-S01E01-vn.srt',
          //   label: 'HTVGO',
          //   kind: 'captions',
          //   language: 'test',
          //   default: true
          // },
          {
            file: G.zu.get(this.state, 'videoDataCurrentEps.url_subtitle_srt'),
            label: 'Tiếng Việt',
            language: 'vn',
            kind: 'captions',
            default: true
          },
          {
            file: G.zu.get(this.state, 'videoDataCurrentEps.url_subtitle_srt_en'),
            label: 'Tiếng Anh',
            language: 'en',
            kind: 'captions'
          }
        ]

        const defaultOptions = {
          playlist: [{ sources: sources, captions: captions }],
          aspectratio: '16:9',
          width: '100%',
          captions: {
            color: '#fff',
            fontSize: 12,
            backgroundOpacity: 0,
            fontFamily: 'SVN-Gilroy',
            fontWeight: 600,
            border: '1px solid #000'
          },
          cast: 'Chormecast'
        }

        const videoOptions = {
          type: 'on-demand',
          series: this.state.videoData.name,
          name: this.state.videoDataCurrentEps.name ? this.state.videoData.name + ' ' + this.state.videoDataCurrentEps.name : this.state.videoData.name,
          seek: this.state.log_time
        }
        global.zinitPlayer(defaultOptions, videoElement, videoOptions)
      }, 500)
    }
  }

  render () { return this.renderComponent() }
}

const mapStateToProps = (state, ownProps) => ({
  auth: state.auth,
  test: state.test,
  scrollComment: state.scrollComment
})

const mapDispatchToProps = {
  actionAuth,
  actionTest,
  actionScrollComment
}

const ViewFilmComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(component)

export default ViewFilmComponent
