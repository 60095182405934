// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getMessaging, getToken } from 'firebase/messaging'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: 'AIzaSyAKGQ3Pjj2OQyozSsGI3ENm4E-ne_H0kBc',
  authDomain: 'htvgo-318503.firebaseapp.com',
  projectId: 'htvgo-318503',
  storageBucket: 'htvgo-318503.appspot.com',
  messagingSenderId: '838990603372',
  appId: '1:838990603372:web:540612b37e2de0911b2449'
  // measurementId: '${config.measurementId}'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
const messaging = getMessaging()
const validKey = 'BFEAux1slZioJTgowmui5QMSRhKjPYlv2QOlL-IoNOUBdvjGywmhNSLaf1xizBYXUKtGZcLU7lwI-MtQtsIll_0'
export const pushToken = getToken(messaging, { vapidKey: validKey })

pushToken.then((currentToken) => {
  if (currentToken) {
    console.log('CurrentToken Push Ok', currentToken)
    G.zq.localSave('pushToken', currentToken)
  } else {
    console.log('No registration token available. Request permission to generate one.')
  }
}).catch((err) => {
  console.log('An error occurred while retrieving token. ', err)
})
