import { Link } from 'react-router-dom'
// import * as Search from '../search/view.jsx'
import SearchForm from '../searchform'

export const renderHeader = (vm) => {
  // const member = G.zq.localLoad('member')
  // const user = G.zu.get(G.zu.array(member)[0], 'name', 'Đăng nhập')
  const user = vm.state.user
  const isLogin = G.zq.localLoad('loginby')
  searchToggle()
  const logoSrc = '/static/img/logoMCV.png'
  return (
    <div className='page_header' id='page_header'>
      {vm.state.showBreakingPopup && renderPopup(vm)}
      <div className='container'>
        <div className='head_lmenu'>
          <div className='lmenu_item lmenu_item-logo'>
            <Link className='logoNew' to='/'>
              <img className='lmenu_logo' alt='logoimg' src={logoSrc} />
            </Link>
          </div>
          <div id='menu_toggle' className='head_lmenuitems'>
            <div className={`lmenu_item ${helpActiveExact('/')}`}><Link to='/'>Trang chủ</Link></div>
            {/*<div className={`lmenu_item ${helpActive('/xem-phim')}`}><Link to='/xem-phim'>Phim</Link></div>*/}
            <div className={`lmenu_item ${helpActive('/truyen-hinh')}`}><Link to='/truyen-hinh'>Truyền hình</Link></div>
            {/*<div className={`lmenu_item ${helpActive('/hot')}`}><a href='javascript: void(0)' onClick={() => vm.onClickOpenBreakingPopup()}>Breaking News</a></div>*/}
            {/* <div className={`lmenu_item ${helpActive('/hot')}`}><Link to='/hot'>Breaking News</Link></div> */}
            {/*<div className={`lmenu_item ${helpActive('/mua-goi')}`}><Link to='/mua-goi'>Dịch vụ</Link></div>*/}
            {/* <div className={`lmenu_item ${helpActive('/tai-khoan')}`}><Link to='/tai-khoan'>Cá nhân</Link></div> */}
          </div>
        </div>
        <div className='head_rmenu'>
          <div className='rmenu_item mobile_menu' onClick={e => vm.onClickMobileToggle()}><span className='icomoon-toggle' /></div>
          <div id='open_search' className='rmenu_item'>
            <Link to='/tim-kiem'>
              <span className='icomoon-search' />
            </Link>
          </div>
          <div id='rmenu_item_noti' className='rmenu_item rmenu_notification'>
            <div onClick={() => vm.onClickShowNotification()} className='notify_box'><span className='icomoon-noti' />{vm.state.notiNumber > 0 ? <span className='notiNumber'><span>{vm.state.notiNumber}</span></span> : ''}</div>
            {vm.state.showNoti &&
              <>
                {(isLogin !== 'logout' && typeof isLogin === 'string') ?
                  <>
                    <div className='makeClosePopup' onClick={() => vm.onClickShowNotification()} />
                    <div className='showNotification'>
                      <div className='notify_title'>Thông báo</div>
                      <div className='notiBody notiInApp'>
                        {vm.state.listNotifications.map((item, i) =>
                          <div key={i} className='notiItem'>
                            <div className='notiItemTitle'>
                              {(() => {
                                switch (item.deeplink.enum) {
                                  case 'DEVICE':
                                    return (
                                      <span className='notiItemName' onClick={(item) => vm.onClickWarning(item)}>{item.title}</span>
                                    )
                                  case 'OTHER':
                                    return (
                                      <span className='notiItemName'>{item.title}</span>
                                    )
                                  case 'COMMENT':
                                    return (
                                      <Link
                                        className='notiItemName'
                                        to={'/xem-phim/' + item.deeplink.slug + '-' + item.deeplink.video_id}
                                        onClick={() => vm.onClickCommentNotification()}
                                      >
                                        {item.title}
                                      </Link>
                                    )
                                  default:
                                    return (
                                      <span className='notiItemName'>{item.title}</span>
                                    )
                                }
                              })()}
                              <div className='notiItemTime'>{item.created_at}</div>
                            </div>
                            <div className='notiItemBody'>{item.content}</div>
                          </div>
                        )}
                      </div>
                      <Link className='notify_viewAll' to='/thong-bao' onClick={() => vm.onClickCloseNotification()}>Xem tất cả thông báo</Link>
                    </div>
                  </> :
                  <></>}
              </>}
          </div>
          <div id='rmenu_item_user' className={`rmenu_item user ${(isLogin !== 'logout' && typeof isLogin === 'string') ? '' : 'user_null'}`} onClick={() => vm.handleOpenForm()}>
            <Link to={(isLogin !== 'logout' && typeof isLogin === 'string') ? '/tai-khoan' : window.location.pathname}>
              {(isLogin !== 'logout' && typeof isLogin === 'string') ?
                <>
                  {user.sso_avatar === null ?
                    <>{vm.props.avtProfile === '' ? <img className='profile_avartar__img' src={user.avatar === null ? '/static/img/avtDefault.png' : user.avatar.thumbnail} /> : <img className='profile_avartar__img' src={vm.props.avtProfile} />}</> :
                    <><img className='profile_avartar__img' src={G.zq.localLoad('imgSsoFails') ? '/static/img/avtDefault.png' : user.sso_avatar} /></>}
                  {vm.props.usernameProfile === '' ? <p>{user.name === null ? 'Ẩn danh' : user.name}</p> : <p>{vm.props.usernameProfile}</p>}
                </> : <p>Đăng nhập</p>}
            </Link>
          </div>
        </div>
        <SearchForm />

        <div className={`mobile_menutoggle ${!!vm.state.flagMobileMenuOpen && 'active'}`} onClick={e => vm.onClickMobileToggle()}>
          <div>
            <div className='lmenu_item lmenu_item-logo'>
              <Link className='logoNew' to='/'>
                <img className='lmenu_logo' alt='logoimg' src={logoSrc} />
              </Link>
            </div>
            <div className='mobile_menuclose'><span className='icomoon-close' /></div>
          </div>

          <div className={`lmenu_item ${helpActiveExact('/')}`}><Link to='/'>Trang chủ</Link></div>
          {/*<div className={`lmenu_item ${helpActive('/xem-phim')}`}><Link to='/xem-phim'>Phim</Link></div>*/}
          <div className={`lmenu_item ${helpActive('/truyen-hinh')}`}><Link to='/truyen-hinh'>Truyền hình</Link></div>
          {/*<div className={`lmenu_item ${helpActive('/hot')}`}><Link to='/hot'>Breaking News</Link></div>*/}
          {/*<div className={`lmenu_item ${helpActive('/mua-goi')}`}><Link to='/mua-goi'>Dịch vụ</Link></div>*/}
          <div className={`rmenu_item user ${(isLogin !== 'logout' && typeof isLogin === 'string') ? '' : 'user_null'}`} onClick={() => vm.handleOpenForm()}>
            <Link to={(isLogin !== 'logout' && typeof isLogin === 'string') ? '/tai-khoan' : window.location.pathname}>
              {(isLogin !== 'logout' && typeof isLogin === 'string') ?
                <>
                  {user.sso_avatar === null ?
                    <>{vm.props.avtProfile === '' ? <img className='profile_avartar__img' src={user.avatar === null ? '/static/img/avtDefault.png' : user.avatar.thumbnail} /> : <img className='profile_avartar__img' src={vm.props.avtProfile} />}</> :
                    <><img className='profile_avartar__img' src={G.zq.localLoad('imgSsoFails') ? '/static/img/avtDefault.png' : user.sso_avatar} /></>}
                  {vm.props.usernameProfile === '' ? <p>{user.name === null ? 'Ẩn danh' : user.name}</p> : <p>{vm.props.usernameProfile}</p>}
                </> : <p>Đăng nhập</p>}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
export const renderPopup = (vm) => {
  return (
    <>
      <div className='packpopup_curtain' onClick={() => vm.onClickCloseBreakingPopup()} />
      <div className='packpopup_outer'>
        <div className='packpopup_wrap' style={{padding: '40px'}}>
          <img className='pp_close' src='static/img/icon_close.svg' alt='close' onClick={() => vm.onClickCloseBreakingPopup()} />
          <div className='packpopup_head'>Tính năng đang bảo trì</div>
          <div className='packpopup_body'>
            <div className='pack_desc' style={{textAlign: 'center'}}>
              Tính năng này đang trong thời gian bảo trì.
              Cảm ơn bạn đã muốn sử dụng dịch vụ, 
              hãy chờ cho đến khi bảo trì kết thúc
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
const helpActive = function (path, activeClass) {
  activeClass = activeClass || 'active'
  if (window.location.pathname.indexOf(path) !== -1) {
    return activeClass
  } else {
    return ''
  }
}

const helpActiveExact = function (path, activeClass) {
  activeClass = activeClass || 'active'
  if (window.location.pathname === path) {
    return activeClass
  } else {
    return ''
  }
}

const searchToggle = () => {
  $(document).ready(() => {
    $('#open_search').on('click', function () {
      $('#search_toggle').addClass('show')
      $('#menu_toggle').addClass('hide')
      $('.head_rmenu').addClass('hide')
    })
    if (window.location.pathname !== '/tim-kiem') {
      $('#search_toggle').removeClass('show')
      $('#menu_toggle').removeClass('hide')
      $('.head_rmenu').removeClass('hide')
    }
  })
}
