import * as Jsx from './view.jsx'
// eslint-disable-next-line
import RxComponent from '/app/zcore/zcomponent'

class component extends RxComponent {
  constructor (props, context) {
    super(props, context, Jsx)
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    this.state = {
      options: {
        channel: true
        // type: false
      },
      channels: G.zu.json(G.zq.localLoad('/api/v1/homepages?parent_id=3')),
      types: [
        { id: 442, name: 'Tin tức' },
        { id: 7, name: 'Giải trí mới nhất' },
        { id: 48, name: 'Sự kiện' }
      ],
      width: 0,
      height: 0,
      children: zget(G.zu.json(G.zq.localLoad('/api/v1/homepages?parent_id=3')), [0, 'children']),
      id: zget(G.zu.json(G.zq.localLoad('/api/v1/homepages?parent_id=3')), [0, 'id'])
    }
  }

  componentDidMount () {
    this.fetchTVChannels()
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions () {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }

  fetchTVChannels () {
    const api = '/api/v1/homepages?parent_id=3'
    G.zq.get(api, {}, (json) => {
      if (json.success) {
        G.zq.localSave('/api/v1/homepages?parent_id=3', JSON.stringify(json.data))
        this.setState({ channels: json.data })
      }
    })
  }

  getListTab (id) {
    const options = this.state.options
    if (options.channel) {
      const channels = G.zu.clone(this.state.channels)
      const findchannel = channels.find(channel => channel.id === id)
      if (findchannel) {
        const child = findchannel.children
        this.setState({ id, children: child })
      }
    }
  }

  onChange (name) {
    const options = G.zu.clone(this.state.options)
    if (name === 'channel') {
      options[name] = true
      options.type = false
    } else {
      options[name] = true
      options.channel = false
    }
    this.setState({ options })
  }

  helperCount (children) {
    const width = this.state.width
    let child = {
      topChild: [],
      bottomChild: []
    }
    // console.log('children: ', children)
    if (width >= 1440 && width <= 90000) {
      child = { ...this.helperForCount(6, children) }
    }
    if (width >= 1200 && width <= 1440) {
      child = { ...this.helperForCount(6, children) }
    }
    if (width >= 992 && width <= 1200) {
      child = { ...this.helperForCount(6, children) }
    }
    if (width >= 768 && width <= 992) {
      child = { ...this.helperForCount(6, children) }
    }
    if (width >= 580 && width <= 768) {
      child = { ...this.helperForCount(4, children) }
    }
    if (width >= 0 && width <= 580) {
      child = { ...this.helperForCount(4, children) }
    }
    return child
  }

  helperForCount (nums, children) {
    let topChild = []
    let bottomChild = []

    if (children.length > nums && children.length <= (nums * 2)) {
      topChild = children.slice(0, nums)
      bottomChild = children.slice(nums, (nums * 2))
    } else if (children.length > (nums * 2)) {
      for (let i = 0; i < children.length; i += 2) {
        topChild.push(children[i])
        if (children[i + 1]) {
          bottomChild.push(children[i + 1])
        }
      }
    } else if (children.length <= nums) {
      topChild = children
    }
    return { topChild, bottomChild }
  }

  render () {
    scrollBehave()
    return this.renderComponent()
  }
}

export default component

const scrollBehave = () => {
  const scrollDetect = (scrollleft) => {
    const tabwidth = $('#tablist').width()
    const scrollwidth = $('#tablist').get(0).scrollWidth
    const offset = 14
    const count = scrollwidth - scrollleft - tabwidth
    if (count > offset) {
      $('#btnnext').addClass('active')
    } else {
      $('#btnnext').removeClass('active')
    }
    if (scrollleft > 0) {
      $('#btnprev').addClass('active')
    } else {
      $('#btnprev').removeClass('active')
    }
  }
  $(() => {
    const buttonPrev = document.getElementById('btnprev')
    const buttonNext = document.getElementById('btnnext')
    if(buttonPrev){
      buttonPrev.onclick = function () {
        document.getElementById('tablist').scrollLeft -= 100
        scrollDetect(document.getElementById('tablist').scrollLeft - 100)
      }
    }
    if(buttonNext){
      buttonNext.onclick = function () {
        document.getElementById('tablist').scrollLeft += 100
        scrollDetect(document.getElementById('tablist').scrollLeft + 100)
      }
    }
  })
}
