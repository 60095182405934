/* global jwplayer */
const colors = {
  Reset: '\x1b[0m',
  Bright: '\x1b[1m',
  Dim: '\x1b[2m',
  Underscore: '\x1b[4m',
  Blink: '\x1b[5m',
  Reverse: '\x1b[7m',
  Hidden: '\x1b[8m',

  FgBlack: '\x1b[30m',
  FgRed: '\x1b[31m',
  FgGreen: '\x1b[32m',
  FgYellow: '\x1b[33m',
  FgBlue: '\x1b[34m',
  FgMagenta: '\x1b[35m',
  FgCyan: '\x1b[36m',
  FgWhite: '\x1b[37m',

  BgBlack: '\x1b[40m',
  BgRed: '\x1b[41m',
  BgGreen: '\x1b[42m',
  BgYellow: '\x1b[43m',
  BgBlue: '\x1b[44m',
  BgMagenta: '\x1b[45m',
  BgCyan: '\x1b[46m',
  BgWhite: '\x1b[47m'
}

global.G = {
  ...global.G,
  zc: require('/app/zconfig'), // eslint-disable-line
  zparent: {}
}

global.G = {
  ...global.G,
  zu: require('./zutil').default,
  zq: require('./zrequest').default,
  zd: require('./zdictionary').default,
  colors
}

require('./zplugin/zpromt')
require('./zplugin/zjsonshow')
const ObservableSlim = require('./zplugin/zobservable')
global.ObservableSlim = ObservableSlim

// PATCH
G.zu.log = (color, ...params) => { console.log(color, ...params, '\x1b[0m') }
const capitalize = (s) => { if (typeof s !== 'string') return ''; return s.charAt(0).toUpperCase() + s.slice(1) }
G.zu.translate = (text, lang = G.zc.DefaultLang) => {
  return capitalize(G.zu.get(G.zd, [lang, text], text))
}

// Recommend Video
function controlRecommendVideo () {
  setTimeout(() => {
    if (localStorage.getItem('aria-autoPlay') === 'On') {
      jwplayer().on('complete', function () {
        $('.vod_recommend').addClass('active')
        $('.jw-icon-display[aria-label="Replay"]').on('click', function () {
          $('.vod_recommend.active').removeClass('active')
          $('.customButton').css('opacity', 1)
        })
        $('.customButton').css('opacity', 0)
        const timeLoadVideo = setTimeout(() => {
          const attrHref = $('.recommend_link a.active').attr('href')
          location.href = attrHref
          if ($('.recommend_link').hasClass('relations_link')) {
            const attrHref = $('.recommend_link.relations_link a').first().attr('href')
            location.href = attrHref
          }
        }, 5000)
        $('.recommend_skip').on('click', function () {
          $('.vod_recommend.active').removeClass('active')
          $('.customButton').css('opacity', 1)
          clearTimeout(timeLoadVideo)
        })
        $('.recommend_link a').on('click', function () {
          $('.vod_recommend.active').removeClass('active')
          $('.customButton').css('opacity', 1)
          clearTimeout(timeLoadVideo)
        })
      })
    }
  }, 10)
}

function customSettingButton () {
  const tabQuality = '<div class="tabMenuCus tabQuality"><h3>Chất lượng</h3> <span>Auto</span></div>'

  const checkQuality = '<div class="jw-icon jw-icon-inline jw-button-color jw-reset jw-settings-quality noClick"></div>'
  // Check Subtitle Hard code
  const checkSubtitle = '<div class="jw-icon jw-icon-inline jw-button-color jw-reset jw-settings-captions noClick"><div class="tabMenuCus tabSubtitle"><h3>Phụ đề</h3> <span>Không có phụ đề</span></div></div>'
  // Check Subtitle Hard code

  const currentCaptions = jwplayer().getCurrentCaptions()
  const captionLst = jwplayer().getCaptionsList()
  const captionText = (currentCaptions === 0 || currentCaptions === undefined) ? 'Tắt' : captionLst[currentCaptions].label
  const tabSubTitle2 = '<div class="tabMenuCus tabSubtitle"><h3>Phụ đề (bật)</h3> <span>' + captionText + '</span></div></div>'

  const tabplaybackRates = '<div class="tabMenuCus tabplaybackRates"><h3>Tốc độ</h3> <span>1.0x (Chuẩn)</span></div>'
  $(document).click((event) => {
    if (!$(event.target).closest('#jw-settings-menu').length) {
      $('.jw-settings-menu-show').removeClass('jw-settings-menu-show')
    }
  })
  $('.jw-settings-submenu-button').on('click', function () {
    if ($('.jw-settings-submenu-quality').length === 0) {
      if ($('.noClick').length === 0) {
        $(checkQuality).prependTo($('.jw-settings-topbar-buttons'))
        if ($('.tabQuality').length === 0) {
          $('.noClick').append(tabQuality)
        }
      }
    }

    $('.jw-settings-menu').addClass('checkHeight')
    if ($('.showContain').length === 1) {
      $('.checkHeight').removeClass('checkHeight')
    }
    if ($('.jw-controls').hasClass('jw-settings-menu-show')) {
      setTimeout(() => {
        $('.jw-settings-open').removeClass('jw-settings-open')
        $('.jw-settings-menu-show').removeClass('jw-settings-menu-show')
      }, 10)
    }
    if ($('.tabQuality').length === 0) {
      $('.jw-submenu-quality').append(tabQuality)
    }
    if ($('.tabSubtitle').length === 0) {
      // tabsutitle
      $('.jw-submenu-captions').append(tabSubTitle2)
    }
    if ($('.tabplaybackRates').length === 0) {
      $('.jw-submenu-playbackRates').append(tabplaybackRates)
    }

    $('.jw-settings-submenu.jw-settings-submenu-active').removeClass('jw-settings-submenu-active')
    $('.jw-settings-topbar-buttons .jw-icon').attr('aria-expanded', 'false')

    // Quality Submenu
    const qualityValueActive = $('#jw-settings-submenu-quality .jw-settings-submenu-items button.jw-settings-item-active').attr('aria-label')
    const qualityValueAuto = $('#jw-settings-submenu-quality .jw-settings-submenu-items button[aria-label="Auto"]').attr('aria-label')
    const qualityValue = !qualityValueActive ? qualityValueAuto : qualityValueActive
    $('.jw-settings-quality .tabQuality>span').html(qualityValue)
    $('.jw-submenu-quality').on('click', function () {
      $('.hideContain').removeClass('hideContain')
      $('.showContain').removeClass('showContain')
      $('.checkHeight').removeClass('checkHeight')
      $(this).parents('.jw-settings-topbar').addClass('hideContain')
      $('.jw-settings-submenu-quality').addClass('showContain')
    })
    const addTitle = '<div class="titleSubItems"><h3>Chất lượng</h3></div>'
    if ($('#jw-settings-submenu-quality .titleSubItems').length === 0) {
      $(addTitle).prependTo($('.jw-settings-submenu-quality'))
    }
    $('#jw-settings-submenu-quality .titleSubItems').on('click', function () {
      $('.jw-settings-topbar').removeClass('hideContain')
      $('.jw-settings-submenu-quality').removeClass('showContain')
      $(this).parents('.jw-controls').addClass('jw-settings-menu-show')
      $('.jw-settings-menu').addClass('checkHeight')
    })

    $('#jw-settings-submenu-quality .jw-settings-submenu-items button').on('click', function () {
      $('.jw-settings-menu-show').removeClass('jw-settings-menu-show')
    })

    // Sub Title
    $('.jw-submenu-captions').on('click', function () {
      $('.hideContain').removeClass('hideContain')
      $('.showContain').removeClass('showContain')
      $('.checkHeight').removeClass('checkHeight')
      $(this).parents('.jw-settings-topbar').addClass('hideContain')
      $('.jw-settings-submenu-captions').addClass('showContain')
    })
    // Check Subtitle Hard code
    if ($('.jw-submenu-captions').length === 0 && $('.jw-settings-captions.noClick').length === 0) {
      $(checkSubtitle).insertBefore('.jw-submenu-playbackRates')
    }

    // const toggle = '<label class="switch"><input type="checkbox"><span class="slider round"></span></label>'
    const toggleSub = '<div class="toggle__subtitle" aria-sub="On"></div>'
    const addTitleCaptions = '<div class="titleSubItems"><h3>Phụ đề</h3>' + toggleSub + '  </div>'
    $('.jw-submenu-captions').on('click', function () {
      if ($('#jw-settings-submenu-captions .titleSubItems').length === 0) {
        $(addTitleCaptions).prependTo($('.jw-settings-submenu-captions'))
      }
    })
    $('#jw-settings-submenu-captions .titleSubItems').on('click', function () {
      $('.jw-settings-topbar').removeClass('hideContain')
      $('.jw-settings-submenu-captions').removeClass('showContain')
      $(this).parents('.jw-controls').addClass('jw-settings-menu-show')
      $('.jw-settings-menu').addClass('checkHeight')
      const currentCaptions = jwplayer().getCurrentCaptions()
      const captionLst = jwplayer().getCaptionsList()
      const captionText = currentCaptions === 0 ? 'Tắt' : captionLst[currentCaptions].label
      $('.tabMenuCus.tabSubtitle > span').text(captionText)
    })

    // Captions toggleSub
    $('.toggle__subtitle').unbind().on('click', function (event) {
      const curCaption = jwplayer().getCurrentCaptions()
      if (curCaption === 0) {
        jwplayer().setCurrentCaptions(1)
      } else {
        jwplayer().setCurrentCaptions(0)
      }
    })
    jwplayer().on('captionsChanged', (track) => {
      $('.toggle__subtitle').attr('aria-sub', track.track === 0 ? 'Off' : 'On')
    })

    // playbackRates Submenu
    $('.jw-submenu-playbackRates').on('click', function () {
      $('.hideContain').removeClass('hideContain')
      $('.showContain').removeClass('showContain')
      $('.checkHeight').removeClass('checkHeight')
      $(this).parents('.jw-settings-topbar').addClass('hideContain')
      $('.jw-settings-submenu-playbackRates').addClass('showContain')
    })
    const addTitlePlaybackRates = '<div class="titleSubItems"><h3>Tốc độ</h3></div>'
    $('.jw-submenu-playbackRates').on('click', function () {
      if ($('#jw-settings-submenu-playbackRates .titleSubItems').length === 0) {
        $(addTitlePlaybackRates).prependTo($('.jw-settings-submenu-playbackRates'))
      }
    })
    $('#jw-settings-submenu-playbackRates .titleSubItems').on('click', function () {
      $('.jw-settings-topbar').removeClass('hideContain')
      $('.jw-settings-submenu-playbackRates').removeClass('showContain')
      $(this).parents('.jw-controls').addClass('jw-settings-menu-show')
      $('.jw-settings-menu').addClass('checkHeight')
    })
    const playbackRatesValue = $('#jw-settings-submenu-playbackRates .jw-settings-submenu-items button.jw-settings-item-active').attr('aria-label')
    $('#jw-settings-submenu-playbackRates .jw-settings-submenu-items button').on('click', function () {
      $('.jw-settings-menu-show').removeClass('jw-settings-menu-show')
    })
    $('.jw-settings-playbackRates .tabplaybackRates>span').html(playbackRatesValue)
  })
}

function loadingBar () {
  const loading = $(`<div class="spinner__wrapper"><svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
  <circle class="bg__path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
 <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
</svg></div>`)
  const videoWrapper = $('.video_wrapper_custom')
  const tvWrapper = $('.custom_control_video_viewtv')
  if ($('#videoPlayer .spinner__wrapper').length === 0) {
    loading.prependTo(videoWrapper)
    loading.prependTo(tvWrapper)
  }

  // Show at first time play video
  jwplayer().on('ready', () => {
    $('div.jw-controlbar.jw-reset > div.customButton').hidde()
    $('.spinner__wrapper').show()
  })

  // Show when video buffer complete
  jwplayer().on('play', () => {
    $('.spinner__wrapper').hide()
    $('.jw-controlbar.jw-reset > div.customButton').show()
  })

  // Show when video buffer complete
  jwplayer().on('beforePlay', () => {
    $('.spinner__wrapper').show()
    $('.jw-controlbar.jw-reset > div.customButton').hide()
  })

  // Show when video is loading or buffering
  jwplayer().on('buffer', () => {
    $('.spinner__wrapper').show()
    $('div.jw-controlbar.jw-reset > div.customButton').hide()
  })

  jwplayer().on('bufferChange', () => {
  })

  jwplayer().on('error', () => {
    $('.spinner__wrapper').hide()
    $('#videoPlayer').addClass('videoPlayerErrors')
  })
}

// Init player
global.zinitPlayer = function (defaultOptions, videoElement, videoOptions = {}) {
  try {
    $('body').animate({ scrollTop: 0 }, 0)
    global.jwplayerObj = global.jwplayer(videoElement).setup({
      ...defaultOptions
    })
    global.jwplayer(videoElement).setConfig({
      autostart: true,
      horizontalVolumeSlider: true,
      playbackRateControls: true,
      playbackRates: [0.5, 1, 1.5],
      seek: true,
      pipIcon: 'enabled'
    })
    window.initJWPlayerMux(global.jwplayerObj, {
      debug: false,
      data: {
        env_key: '0rgona6hua9u8lt2uk2h7qo21', // required

        // Site Metadata
        viewer_user_id: '', // ex: '12345'
        experiment_name: 'Main_Player_Test_A', // ex: 'player_test_A'
        sub_property_id: 'htvgo-1', // ex: 'cus-1'

        // Player Metadata
        player_name: 'Main Player', // ex: 'My Main Player'
        player_version: '1.0.0', // ex: '1.0.0'
        player_init_time: Date.now(), // ex: 1451606400000

        // Video Metadata
        video_id: '', // ex: 'abcd123'
        video_title: videoOptions.name, // ex: 'My Great Video'
        video_series: videoOptions.series, // ex: 'Weekly Great Videos'
        video_duration: videoOptions.duration, // in milliseconds, ex: 120000
        video_stream_type: videoOptions.type || 'on-demand', // 'live' or 'on-demand'
        video_cdn: '' // ex: 'Fastly', 'Akamai',
      }
    })

    jwplayer().on('ready', () => {
      const numTime = Number(videoOptions.seek) / 1000
      jwplayer().seek(numTime)
    })

    setTimeout(() => {
      jwplayer().setMute(true)
      // define button
      const rewind = $('.custom_control_video #videoPlayer .jw-controlbar').find('.jw-icon-rewind')
      const btnPlay = $('.custom_control_video #videoPlayer').find('.jw-icon-playback')
      // define div contain button
      if ($('.jw-controlbar .customButton').length === 0) {
        $('.custom_control_video #videoPlayer').find('.jw-controlbar').append('<div class="customButton"></div>')
      }

      const displayContainer = $('.jw-controlbar').find('.customButton')
      // move button rewind to Container
      if ($('.customButton .jw-icon-rewind').length === 0) {
        rewind.appendTo(displayContainer)
      }
      // move button play
      if ($('.customButton .jw-icon-playback').length === 0) {
        btnPlay.appendTo(displayContainer)
      }

      // define and handle seek button
      const seek = $('<div class="jw-icon-seek jw-icon jw-icon-inline jw-button-color jw-reset"><div class="jw-reset-text jw-tooltip jw-tooltip-play" dir="auto"><div class="jw-text">Seek 10 second</div></div></div>')
      if ($('.customButton .jw-icon-seek').length === 0) {
        seek.appendTo(displayContainer)
      }
      $('.jw-icon-seek').on('click', function () {
        jwplayer().seek(jwplayer().getPosition() + 10)
      })
      $('.jw-icon-seek').on('mouseover', function () {
        $('.jw-icon-seek .jw-reset-text').addClass('jw-open')
      })
      $('.jw-icon-seek').on('mouseleave', function () {
        $('.jw-icon-seek .jw-reset-text').removeClass('jw-open')
      })

      // Show label volume
      const btnVolume = $('.custom_control_video #videoPlayer').find('.jw-icon-volume')
      const labelVolume = $('<div class="jw-reset-text jw-tooltip jw-tooltip-play" dir="auto"><div class="jw-text">Volume</div></div>')
      if ($('.jw-icon-volume .jw-reset-text').length === 0) {
        labelVolume.appendTo(btnVolume)
      }

      // move text live
      const textLive = $('.custom_control_video #videoPlayer').find('.jw-text-live')
      const textLiveContain = $('.custom_control_video #videoPlayer').find('.jw-wrapper')
      if ($('.jw-wrapper .jw-text-live').length === 1) {
        textLive.html('Đang phát')
        textLive.appendTo(textLiveContain)
      }

      // Control tabs click
      $('.nav_tabs_item').on('click', function () {
        $('.nav_tabs_item.active').removeClass('active')
        $(this).addClass('active')
      })

      // Control Replay
      const btnReplay = $('.custom_control_video #videoPlayer').find('.jw-display-icon-display')
      const jwButtonContainer = $('.custom_control_video #videoPlayer').find('.jw-button-container')
      if ($('.jw-button-container .jw-display-icon-display').length === 0) {
        btnReplay.prependTo(jwButtonContainer)
      }

      // Control Eps
      const linkEps = location.href.split('/')
      const epsId = linkEps[linkEps.length - 1]
      const epsHref = $('.eps_info .eps_link').attr('data-href')
      $('.eps_info .eps_link[data-id="' + epsId + '"]').addClass('active')
      if (epsHref === epsId) {
        $('.eps_info .eps_link').first().addClass('active')
      }
      $('.eps_info .eps_link').on('click', function () {
        $('.eps_info .eps_link.active').removeClass('active')
        $(this).addClass('active')
      })

      // Control Auto Play Video
      const autoPlay = $('<div class="jw-icon jw-icon-inline jw-button-color jw-reset jw-auto-play" aria-autoplay="On"><div class="jw-reset-text jw-tooltip jw-tooltip-play" dir="auto"><div class="jw-text">Auto Play</div></div></div>')
      const btnSetting = $('.custom_control_video #videoPlayer').find('.jw-icon-settings')
      if ($('.jw-auto-play').length === 0) {
        autoPlay.insertBefore(btnSetting)
      }
      if (localStorage.getItem('aria-autoplay') === null) {
        const valueAutoPlay = $('.jw-auto-play').attr('aria-autoplay')
        localStorage.setItem('aria-autoplay', valueAutoPlay)
      }
      $('.jw-auto-play').attr('aria-autoplay', localStorage.getItem('aria-autoplay'))

      // Auto Recommend Video
      controlRecommendVideo()
      $('.jw-auto-play').on('click', function () {
        if (localStorage.getItem('aria-autoplay') === 'On') {
          localStorage.setItem('aria-autoplay', 'Off')
          $('.jw-auto-play').attr('aria-autoplay', localStorage.getItem('aria-autoplay'))
          jwplayer().off(controlRecommendVideo())
        } else {
          localStorage.setItem('aria-autoplay', 'On')
          $('.jw-auto-play').attr('aria-autoplay', localStorage.getItem('aria-autoplay'))
          controlRecommendVideo()
        }
      })

      // Custom next button
      $('.custom_control_video #videoPlayer').find('.jw-icon-next').remove()
      const nextCustom = $('<div class="jw-icon jw-icon-inline jw-button-color jw-reset jw-next-custom" role="button"><div class="jw-reset-text jw-tooltip jw-tooltip-next-custom" dir="auto"><div class="jw-text">Next</div></div></div>')
      if ($('.jw-next-custom').length === 0) {
        nextCustom.insertBefore(btnVolume)
      }
      $('.jw-next-custom').on('click', function () {
        setTimeout(() => {
          const attrHref = $('.recommend_link a.active').attr('href')
          location.href = attrHref
          if ($('.recommend_link').hasClass('relations_link')) {
            const attrHref = $('.recommend_link.relations_link a').first().attr('href')
            location.href = attrHref
          }
        }, 10)
      })

      // Custom Setting Button
      customSettingButton()
      $('.jw-icon-fullscreen').on('click', function () {
        $('.custom_control_video').removeClass('videoFullScreen')
        controlMouseInactive()
      })
      controlMouseInactive()

      loadingBar()

      // Check height textarea before action
      $('#boxComment').on('input', function () {
        $(this).css('height', this.scrollHeight + 'px')
      })
    }, 10)
  } catch (e) {
    console.log(e)
  }
}
function controlMouseInactive () {
  let i = null
  $('.custom_control_video').mousemove(function () {
    clearTimeout(i)
    $('.custom_control_video .jw-controls').show()
    i = setTimeout(function () {
      $('.custom_control_video .jw-controls').hide()
    }, 3000)
  }).mouseleave(function () {
    clearTimeout(i)
    i = setTimeout(function () {
      $('.custom_control_video .jw-controls').hide()
    }, 3000)
  })
}
// Scroll show hide header
$(document).ready(function () {
  $(window).on('wheel', function (event) {
    if (event.originalEvent.deltaY !== 0) {
      if (event.originalEvent.deltaY < 0) {
        $('body').removeClass('controlHeader')
      } else {
        $('body').addClass('controlHeader')
      }
    }
    if ($('.page_body').offset().top === 90) {
      $('body').removeClass('controlHeader')
    }
  })
})

// Global
global.zflag = {}
global.zjson = G.zu.json
global.zlog = G.zu.log
global.zget = G.zu.get
global.zsafe = G.zu.runSafe
global.zarray = G.zu.array
global.zto = G.zu.to
global.ztr = G.zu.tr
global.zt = G.zu.translate
global.zbaseUrl = `${G.zc.serverAddr}:${G.zc.serverPort}`
global.zprevent = (e) => { e.preventDefault(); e.stopPropagation() }

global.zremoveClass = (element, name) => { const pattern = new RegExp('(?:^|\\s)' + name + '(?:\\s|$)', 'g'); element.className = element.className.replace(pattern, '') }
global.zaddClass = (element, name) => { element.classList.add(name) }

global.jwplayer = window.jwplayer
global.totop = () => { $('body').animate({ scrollTop: 0 }, 0) }
export default {}
