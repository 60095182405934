import { Link } from 'react-router-dom'
import React from 'react'
import Paging from './../../others/paging'
import * as displayUlti from './../../displayType/displayUlti.jsx'

export const renderComponent = (vm) => {
  const length = vm.state.dataWatch.length
  return (
    <div className='pageWatching'>
      <div className='watchTitle'>Bạn đang xem</div>
      <div className='bodyWatching'>
        {vm.renderDeleteVodMessage()}
        {vm.renderDeleteAll()}
        <div className='search_result'>
          {length === 0 ? vm.renderEmpty() : ''}
          {vm.state.dataWatch.map((el, i) => {
            return (
              <div className='search_result_list' key={i}>
                <div className='watchingAction'>
                  <img src='static/img/more-vertical.png' />
                  <div className='watchingActionHover'>
                    <div className='item' onClick={() => vm.deleteVodWatching(el.video.id, el.episode.id)}>Xóa</div>
                    <div className='item' onClick={() => vm.deleteVodWatchingAll()}>Xóa tất cả</div>
                  </div>
                </div>
                <Link className='result_link' to={getLink(el)}>
                  {displayUlti.getTitleBartime(el)}
                </Link>
              </div>
            )
          })}
        </div>
      </div>
      {G.zu.clone(vm.state.meta).last_page > 1 && <Paging meta={G.zu.clone(vm.state.meta)} onPage={(page) => vm.handleChangePage(page)} />}
    </div>
  )
}

export const renderEmpty = () => {
  return (
    <div className='resultEmpty'>
      <img src='static/img/resultEmpty.svg' alt='resultEmpty' />
      <p>Chưa có video trong danh sách đang xem</p>
    </div>
  )
}

export const renderDeleteVodMessage = (vm) => {
  return (
    <>
      {vm.state.deleteMessage &&
        <div className='pp_registerEpg'>
          {vm.state.isDeleteMessage ?
            <div className='pp_registerEpgItem'>
              <img src='static/img/registerSuccess.png' alt='registerSuccess' />
              <span>Đã xoá video.</span>
            </div> :
            <div className='pp_registerEpgItem'>
              <img src='static/img/registerFail.png' alt='registerFail' />
              <span>Xoá video không thành công.</span>
            </div>}
        </div>}
      {vm.state.deleteAllMessage &&
        <div className='pp_registerEpg'>
          {vm.state.isDeleteAllMessage ?
            <div className='pp_registerEpgItem'>
              <img src='static/img/registerSuccess.png' alt='registerSuccess' />
              <span>Đã xoá tất cả video.</span>
            </div> :
            <div className='pp_registerEpgItem'>
              <img src='static/img/registerFail.png' alt='registerFail' />
              <span>Xoá tất cả video không thành công.</span>
            </div>}
        </div>}
    </>
  )
}

export const renderDeleteAll = (vm) => {
  return (
    <>
      {vm.state.deleteAll &&
        <div className='showWarning'>
          <div className='packpopup_curtain' onClick={e => vm.handleClosePopupWarning()} />
          <div className='packpopup_outer'>
            <div className='packpopup_wrap'>
              <img className='pp_close' src='static/img/icon_close.svg' alt='close' onClick={() => vm.handleClosePopupWarning()} />
              <div className='packpopup_body packpopup_body_noti'>
                <div className='packpopup_body_description'>Bạn muốn xoá tất cả video trong danh sách đang xem? </div>
                <img className='packpopup_body_image' src='/static/img/deleteVod.png' />
                <div className='pack_btns'>
                  <div className='pack_submit notActive' onClick={e => vm.handleClosePopupWarning()}>Trở về</div>
                  <div className='pack_submit' onClick={e => vm.deleteWatchingAll()}>Đồng ý</div>
                </div>
              </div>
            </div>
          </div>
        </div>}
    </>
  )
}

export const getLink = (data) => {
  return `../xem-phim/${G.zu.rxChangeSlug(G.zu.get(data, 'video.slug', ''))}-${data.episode.id}`
}
