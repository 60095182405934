import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export default function ScrollToTop () {
  const { pathname } = useLocation()

  useEffect(() => {
    $('body').animate({ scrollTop: 0 }, 0)
    $('body').removeClass('controlHeader')
  }, [pathname])

  return null
}
