import * as Jsx from './view.jsx'
// eslint-disable-next-line
import RxComponent from '/app/zcore/zcomponent'
import { actionAuth } from '../../../zcore/zredux.js'
import { connect } from 'react-redux'

const videoElement = 'videoPlayer'
class component extends RxComponent {
  constructor (props, context) {
    super(props, context, Jsx)
    this.state = {
      slug: '',
      videoID: '',
      channelName: {},
      toggle: 'none',
      videoData: {},
      videoDataOrigin: {},
      dateTime: new Date().getTime(),
      epgs: [],
      epgsbackup: [],
      playing: {},
      listChannels: [],
      isRegister: '',
      isClickRegister: false,
      isShare: false
    }
  }

  componentDidMount () {
    this.slug = G.zu.get(this.props, 'match.params.slug').split('-')
    this.fetchData(this.slug)
    this.fetchEPGs()
    this.fetchDataChannels()
    setInterval(this.refreshEPG.bind(this), 30000)
  }

  componentWillUnmount () {
    G.zu.runSafe(() => global.jwplayer(videoElement).remove())
    clearInterval(setInterval(this.refreshEPG(), 30000))
  }

  componentWillReceiveProps (nextProps) {
    if (G.zu.get(nextProps, 'match.params.slug') !== G.zu.get(this.props, 'match.params.slug')) {
      this.slug = G.zu.get(nextProps, 'match.params.slug').split('-')
      this.fetchData()
      this.fetchEPGs()
      this.fetchDataChannels()
    }
  }

  fetchData () {
    this.videoID = this.slug[this.slug.length - 1]
    this.setState({ slug: this.slug, videoID: this.videoID })
    this.fetchDataFilm()
  }

  fetchDataFilm () {
    G.zq.get('/api/v1/channels/detail', {
      channel_id: this.videoID
    }, (json) => {
      if (json.success) {
        this.setState({ videoData: G.zu.clone(json.data), videoDataOrigin: G.zu.clone(json.data), playing: G.zu.clone(json.data) }, () => this.initVideo())
      } else {
        const authchecked = G.zq.checklogin('/api/v1/videos/detail', json)
        if (authchecked) {
          this.props.actionAuth(authchecked)
        }
      }
    })
  }

  fetchDataChannels () {
    G.zq.get('/api/v1/channels', { per_page: 99 }, (json) => {
      if (json.success) {
        this.setState({ listChannels: G.zu.array(json.data) })
        this.setState({ channelName: this.state.listChannels.filter(el => el.id === parseInt(this.videoID))[0] })
      }
    })
  }

  toggleListChannel () {
    if (this.state.toggle === 'none') {
      this.setState({ toggle: 'flex' })
    } else {
      this.setState({ toggle: 'none' })
    }
  }

  handleSelectChannel (channel) {
    this.setState({ toggle: 'none' })
  }

  onClickCoppyHref () {
    $('.shareHref').select()
    document.execCommand('copy')
  }

  onClickPopupShare () {
    this.setState({ isShare: true })
  }

  onClickClosePopupShare () {
    this.setState({ isShare: false })
  }

  onclickFavouritesPost (channelId) {
    G.zq.post('/api/v1/favourite-channels', {
      channel_id: channelId
    }, (json) => {
      const videoData = this.state.videoData
      this.setState({ videoData: { ...videoData, is_favourite: true } })
    })
  }

  onclickFavouritesDelete (channelId) {
    G.zq.delete('/api/v1/favourite-channels', {
      channel_id: channelId
    }, (json) => {
      const videoData = this.state.videoData
      this.setState({ videoData: { ...videoData, is_favourite: false } })
    })
  }

  initVideo () {
    const url = G.zu.get(this.state, 'playing.url_cdn')

    if (url) {
      setTimeout(() => {
        const sources = [{
          file: url,
          overrideNative: true,
          mute: false,
          autostart: true // onXhrOpen: function (xhr, url) {}
        }]

        const defaultOptions = {
          playlist: [{ sources: sources }],
          aspectratio: '16:9',
          width: '100%',
          captions: { color: '#ffb800', fontSize: 30, backgroundOpacity: 0 },
          cast: 'Chormecast'
        }

        const videoOptions = {
          type: 'live',
          series: this.state.playing.name,
          name: this.state.playing.name
        }
        global.zinitPlayer(defaultOptions, videoElement, videoOptions)
      }, 500)
    }
  }

  fetchEPGs () {
    const pathname = window.location.pathname.split('/')[2]
    const pathlength = pathname.split('-').length
    const channelid = pathname.split('-')[pathlength - 1]
    const date = new Date(this.state.dateTime)
    // const re = /[/|-]/g
    const api = '/api/v1/epgs'
    let fromdate = ''
    fromdate = `${date.getFullYear()}-${date.getMonth() < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}`

    G.zq.get(api, { channel_id: channelid, from_date: fromdate, to_date: fromdate }, (json) => {
      if (json.success) {
        const epgsbackup = G.zu.array(json.data)
        this.setState({ epgsbackup })
        const epgsdata = this.helperLive(G.zu.clone(json.data))
        this.setState({ epgs: epgsdata })
      }
    })
  }

  helperLive (epgs) {
    const epgsbackup = G.zu.array(this.state.epgsbackup)
    const timenow = new Date().getTime()
    let start = new Date().getTime()
    let end = new Date().getTime()
    const newEpgs = []

    // neu co du lieu, thi lay du lieu backup ra su dung
    epgs = G.zu.clone(epgsbackup)
    if (!epgs.length) {
      const fromdate = new Date().setHours(0, 0, 0, 0)
      const todate = new Date().setHours(23, 59, 59, 99)
      if (this.state.dateTime >= fromdate && this.state.dateTime <= todate) {
        const newEpg = {
          isLive: true,
          name: 'Kênh truyền hình',
          url_cdn: zget(this.state, ['videoData', 'url_cdn'], '')
        }
        newEpgs.push(newEpg)
      }
    } else {
      for (let i = 0; i < epgs.length; i++) {
        start = new Date(this.helperReDate(epgs[i].start_time)).getTime()
        end = new Date(this.helperReDate(epgs[i].end_time)).getTime()

        if (timenow >= start) {
          if (timenow <= end) {
            // START < TIMENOW < END => [CURRENT EPG [TIMENOW]] [NEXT EPG] - LIVE
            epgs[i].isLive = true
            epgs[i].url_cdn = zget(this.state, ['videoData', 'url_cdn'], '')
            newEpgs.push(epgs[i])
          } else {
            if (epgs[i + 1]) {
              const nextStart = new Date(epgs[i + 1].start_time).getTime()
              if (timenow > end && timenow < nextStart) {
                // TIMENOW > END && TIMENOW < NEXTSTART => [CURRENT EPG] [TIMENOW] [NEXT EPG]
                epgs[i].isLive = false
                // Comment code! Sau khi có lỗi
                // const _url = zget(this.state, ['videoData', 'url_cdn'], '')
                // const newEpg = {
                //   isLive: true,
                //   name: 'Kênh truyền hình',
                //   url_cdn: _url
                // }
                newEpgs.push(epgs[i])
                // newEpgs.push(newEpg)
              } else {
                // if (timenow > end && timenow > nextStart) {
                // TIMENOW > END && TIMENOW > NEXTSTART => [CURRENT EPG] [NEXT EPG] ... [TIMENOW]
                epgs[i].isLive = false
                let _url = zget(epgs[i], 'url_cdn', '')
                if (_url.indexOf('https://') === -1 && _url.indexOf('http://') === -1) {
                  _url = zget(this.state.videoDataOrigin, 'url_cdn') + '?' + _url
                }
                epgs[i].url_cdn = _url
                newEpgs.push(epgs[i])
              }
            }
          }
        } else {
          // TIMENOW > START => [TIMENOW] ... [CURRENT EPG]
          epgs[i].isLive = false
          if (start > timenow) {
            epgs[i].url_cdn = ''
          }
          newEpgs.push(epgs[i])
        }
      }
    }
    return newEpgs
  }

  helperReDate (dateString) {
    // const [date, time] = !!(dateString) && dateString.split(' ')
    // const [day, month, year] = !!(date) && date.split('-')
    // const newDateString = `${month}-${day}-${year} ${time}`
    // return newDateString
    if (dateString.length > 0) {
      return dateString.replace(/(\d{2})-(\d{2})-(\d{4})/, '$2/$1/$3')
    }
    return dateString
  }

  registerEpg (id) {
    const apiRegister = '/api/v1/epg/register'
    G.zq.post(apiRegister, { epgId: id }, (json) => {
      this.setState({ isRegister: json.success, isClickRegister: true })
      this.fetchEPGs()
      setTimeout(() => {
        this.setState({ isClickRegister: false })
      }, 3000)
    })
  }

  onClickEPGs (urlcdn) {
    const playing = G.zu.clone(this.state.playing)
    playing.url_cdn = urlcdn
    this.setState({ playing }, () => this.initVideo())
  }

  compareFuture (startfuture) {
    const future = new Date(this.helperReDate(startfuture)).getTime() - (1000 * 60 * 15)
    const now = new Date().getTime()
    return now < future
  }

  refreshEPG () {
    const epgs = G.zu.clone(this.state.epgsbackup)
    if (epgs.length !== 0) {
      const newEpgs = this.helperLive(epgs)
      this.setState({ epgs: newEpgs })
    }
    // const newEpgs = this.helperLive(epgs)
    // this.setState({ epgs: newEpgs })
  }

  onBlurDate (date) {
    const selecteddate = new Date(date).getTime()
    const dateTime = new Date(selecteddate).setHours(0, 0, 0, 0)
    const fromdate = new Date().setHours(0, 0, 0, 0)
    const todate = new Date().setHours(23, 59, 59, 99)
    this.setState({ dateTime, fromdate, todate }, () => {
      this.fetchEPGs()
    })
  }

  render () { return this.renderComponent() }
}

const mapStateToProps = (state, ownProps) => ({
  auth: state.auth
})

const mapDispatchToProps = {
  actionAuth
}

const ViewChannelComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(component)

export default ViewChannelComponent
