import fetch from 'isomorphic-fetch'

const baseUrl = `${G.zc.serverAddr}`
const osIdUrl = ['/login-sso', '/login-sso-with-phone', '/login', '/login-qr-code', '/homepages?parent_id=1&', '/homepages?parent_id=2&', '/homepages?parent_id=3&', '/homepages?parent_id=4&', '/packages', '/using', '/buy', '/transactions', '/epgs', '/register', '/count-view', '/video-viewed']

class zrequest {
  static get baseUrl () {
    return this._baseUrl || baseUrl
  }

  static set baseUrl (url) {
    this._baseUrl = url
  }

  static get (url, params, callback) {
    if (!url) return []

    const headers = G.zu.get(params, 'headers', {})

    if (osIdUrl.indexOf(url.substring(url.lastIndexOf('/'))) !== -1) {
      headers['os-id'] = 4
      headers['platform-id'] = 1
    }

    let tempBody = ''
    for (const prop in params) {
      if (prop === 'headers') { continue }
      // eslint-disable-next-line
      if (params.hasOwnProperty(prop)) {
        let value = params[prop]
        if (G.zu.isObj(value)) {
          value = JSON.stringify(value)
        }
        tempBody += `${prop}=${value}&`
      }
    }
    // add ?
    const match = document.cookie.match(new RegExp('access_token_zfilm' + '=([^;]+)'))
    let token = ''
    if (match) {
      token = match[1]
    }
    // add ?

    // tempBody += `timeseed=${Date.now()}`
    let fullURL = `${baseUrl}${url}`
    let flagCORS = false
    if (url.indexOf('https://') === 0 || url.indexOf('http://') === 0) {
      fullURL = url
      flagCORS = true
    }

    const reqHeaders = {
      ...headers,
      Accept: 'application/json'
    }
    if (!flagCORS) {
      reqHeaders.Authorization = 'Bearer ' + (token || '')
    }

    fetch(`${fullURL}?${tempBody}`, {
      method: 'GET',
      headers: reqHeaders,
      body: null
    }).then(res => res.json())
      .then(json => {
        this.handleCallback(json, callback)
      })
  }

  static post (url, params, callback) {
    const match = document.cookie.match(new RegExp('access_token_zfilm' + '=([^;]+)'))
    let token = ''
    if (match) {
      token = match[1]
    }
    let headers = G.zu.get(params, 'headers', {})
    headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Bearer ' + (token || ''),
      lang: 'vi'
    }
    if (osIdUrl.indexOf(url.substring(url.lastIndexOf('/'))) !== -1) {
      headers['os-id'] = 4
      headers['platform-id'] = 1
    }

    if (!url) return []
    fetch(`${baseUrl}${url}`, {
      method: 'POST',
      headers: {
        // authorization: authorization,
        ...headers,
        Accept: 'application/json'
      },
      body: JSON.stringify(params)
    }).then(res => res.json())
      .then(json => this.handleCallback(json, callback))
  }

  static postFormData (url, formData, callback) {
    const match = document.cookie.match(new RegExp('access_token_zfilm' + '=([^;]+)'))
    let token = ''
    if (match) {
      token = match[1]
    }
    // let headers = G.zu.get(params, 'headers', {})
    const headers = {
      // 'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + (token || ''),
      lang: 'vi'
    }
    if (osIdUrl.indexOf(url.substring(url.lastIndexOf('/'))) !== -1) {
      headers['os-id'] = 4
      headers['platform-id'] = 1
    }

    if (!url) return []
    fetch(`${baseUrl}${url}`, {
      method: 'POST',
      headers: headers,
      body: formData
    }).then(res => res.json())
      .then(json => this.handleCallback(json, callback))
  }

  static put (url, params, callbacks) {
    const match = document.cookie.match(new RegExp('access_token_zfilm' + '=([^;]+)'))
    let token = ''
    if (match) {
      token = match[1]
    }
    let headers = G.zu.get(params, 'headers', {})
    headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Bearer ' + (token || ''),
      lang: 'vi'
    }
    if (osIdUrl.indexOf(url.substring(url.lastIndexOf('/'))) !== -1) {
      headers['os-id'] = 4
      headers['platform-id'] = 1
    }

    url = url || ''
    params = (typeof (params) === 'object') ? params : {}

    // params['timeseed'] = Date.now()
    fetch(`${baseUrl}${url}`, {
      method: 'PUT',
      body: JSON.stringify(params),
      headers: {
        ...headers,
        Accept: 'application/json'
      }
    })
      .then(res => res.json())
      .then(json => this.handleCallback(json, callbacks)).catch(error => console.log(error, 'err'))
  }

  static delete (url, params, callbacks) {
    const match = document.cookie.match(new RegExp('access_token_zfilm' + '=([^;]+)'))
    let token = ''
    if (match) {
      token = match[1]
    }
    let headers = G.zu.get(params, 'headers', {})
    headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Bearer ' + (token || ''),
      lang: 'vi'
    }
    // '/homepages?parent_id=4&', '/packages', '/packages/using', '/packages/buy'
    if (osIdUrl.indexOf(url.substring(url.lastIndexOf('/'))) !== -1) {
      headers['os-id'] = 4
      headers['platform-id'] = 1
    }

    url = url || ''
    params = (typeof (params) === 'object') ? params : {}

    // params['timeseed'] = Date.now()
    fetch(`${baseUrl}${url}`, {
      method: 'DELETE',
      body: JSON.stringify(params),
      headers: {
        ...headers,
        Accept: 'application/json'
      }
    })
      .then(res => res.json())
      .then(json => this.handleCallback(json, callbacks)).catch(error => console.log(error, 'err'))
  }

  static callapi (method, url, params, callbacks) {
    const match = document.cookie.match(new RegExp('access_token_zfilm' + '=([^;]+)'))
    let token = ''
    if (match) {
      token = match[1]
    }
    let headers = G.zu.get(params, 'headers', {})
    headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Bearer ' + (token || ''),
      lang: 'vi'
    }
    if (osIdUrl.indexOf(url.substring(url.lastIndexOf('/'))) !== -1) {
      headers['os-id'] = 4
      headers['platform-id'] = 1
    }

    url = url || ''
    params = (typeof (params) === 'object') ? params : {}

    // params['timeseed'] = Date.now()
    fetch(`${baseUrl}${url}`, {
      method: method,
      body: JSON.stringify(params),
      headers: {
        ...headers,
        Accept: 'application/json'
      }
    })
      .then(res => res.json())
      .then(json => this.handleCallback(json, callbacks)).catch(error => console.log(error, 'err'))
  }

  static handleCallback (json, callback) {
    if (G.zu.isFunc(callback)) {
      callback(json)
    } else {
      if (G.zu.isFunc(zget(callback, `${json.status}`))) {
        callback[`${json.status}`](json)
      }
    }
  }

  static localSave (key, obj) {
    localStorage.setItem(key, JSON.stringify(obj))
  }

  static localLoad (key) {
    return G.zu.json(localStorage.getItem(key)) || {}
  }

  static rxsetCookie (cname, cvalue, minutes) {
    if (typeof (document) !== 'undefined') {
      const d = new Date()
      d.setTime(d.getTime() + (minutes * 60 * 1000))
      const expires = 'expires=' + d.toUTCString()
      document.cookie = cname + '=' + cvalue + '; ' + expires
      document.cookie = cname + '=' + cvalue + '; Path=/xem-phim; ' + expires
      document.cookie = cname + '=' + cvalue + '; Path=/truyen-hinh; ' + expires
    }
  }

  static rxdelCookie (cname) {
    if (typeof (document) !== 'undefined') {
      document.cookie = cname + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
      document.cookie = cname + '=; Path=/xem-phim; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
      document.cookie = cname + '=; Path=/truyen-hinh; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
      G.zq.localSave('member', JSON.stringify([]))
    }
  }

  static rxgetCookie (cname) {
    if (typeof (document) !== 'undefined') {
      const value = '; ' + document.cookie
      const parts = value.split('; ' + cname + '=')
      if (parts.length === 2) return parts.pop().split(';').shift()
    }
  }

  static checklogin (url, response) {
    const newUrl = url.replace(/\//g, '')
    const pathname = window.location.pathname.split('/')
    const [, Type, Detail] = pathname
    const pathNeed = ['xem-phim', 'truyen-hinh', 'tai-khoan']
    const apiNeed = ['apiv1videosdetail', 'apiv1channelsdetail', 'apiv1users']
    console.log('call request')

    const authenticator = G.zu.json(G.zq.localLoad('authenticator'))
    if (!authenticator[0]) {
      G.zq.localSave('authenticator', JSON.stringify([{ code: 0 }]))
    }
    if (Type === 'tai-khoan') {
      if (typeof response.errors !== 'undefined' && response.errors.length) {
        if (response.errors[0].code === 10 || response.errors[0].code === 11 || response.errors[0].code === 23) {
          G.zq.localSave('authenticator', JSON.stringify(response.errors))
          return response.errors
        }
      } else {
        G.zq.localSave('authenticator', JSON.stringify([{ code: 0 }]))
        return [{ code: 0 }]
      }
    }
    if (pathNeed.indexOf(Type) !== -1 && Detail && apiNeed.indexOf(newUrl) !== -1) {
      if (typeof response.errors !== 'undefined' && response.errors.length) {
        if (response.errors[0].code === 10 || response.errors[0].code === 11 || response.errors[0].code === 23) {
          G.zq.localSave('authenticator', JSON.stringify(response.errors))
          return response.errors
        }
      } else {
        G.zq.localSave('authenticator', JSON.stringify([{ code: 0 }]))
        return [{ code: 0 }]
      }
    }
    if (!Detail) {
      G.zq.localSave('authenticator', JSON.stringify([{ code: 0 }]))
      return [{ code: 0 }]
    }
  }

  static deleteLocal () {
    const keys = ['ages', 'member', 'user', 'password', 'memberlogged', 'auth']
    keys.forEach(key => {
      localStorage.removeItem(key)
    })
  }

  static isLogin () {
    const api = '/api/v1/users'
    this.get(api, {}, (json) => {
      if (json.errors && json.errors[0].code === 10) {
        this.deleteLocal()
        G.zq.localSave('user', 'null')
      } else {
        G.zq.localSave('user', G.zu.clone(json.data))
        G.zq.localSave('imgSsoFails', false)
        const checkSsoAvt = G.zq.localLoad('user')
        if(Object.keys(checkSsoAvt).length != 0){
          if (checkSsoAvt.sso_avatar !== null) {
            if (checkSsoAvt.sso_avatar.match(/\.(jpeg|jpg|gif|png)$/) === null) {
              console.log('Check image errors')
              G.zq.localSave('imgSsoFails', true)
            }
          }
        }
      }
    })
  }

  static postLogin (url, params, callback) {
    // Send the token to your server and update the UI if necessary
    const match = document.cookie.match(new RegExp('access_token_zfilm' + '=([^;]+)'))
    let token = ''
    if (match) {
      token = match[1]
    }
    let headers = G.zu.get(params, 'headers', {})
    headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Bearer ' + (token || ''),
      lang: 'vi'
    }
    if (osIdUrl.indexOf(url.substring(url.lastIndexOf('/'))) !== -1) {
      headers['os-id'] = 4
      headers['platform-id'] = 1
      headers['push-token'] = G.zq.localLoad('pushToken')
    }
    if (!url) return []
    fetch(`${baseUrl}${url}`, {
      method: 'POST',
      headers: {
        // authorization: authorization,
        ...headers,
        Accept: 'application/json'
      },
      body: JSON.stringify(params)
    }).then(res => res.json())
      .then(json => this.handleCallback(json, callback))
  }
}

export default zrequest
