import * as Account from './accountAccount.jsx'
import * as Package from './accountPackage.jsx'
import * as Sale from './accountSale.jsx'
// import * as Payment from './accountPayment.jsx'
import * as Device from './accountDevice.jsx'
// import * as Support from './accountSupport.jsx'
import * as About from './accountAbout.jsx'
import * as History from './accountHistory.jsx'
import * as Password from './accountPassword.jsx'
import * as Calendar from './accountCalendar.jsx'

export const renderAccountCenter = (vm) => {
  return (
    <div className='account__center'>
      {vm.state.section === 'account' && Account.renderAccount(vm)}
      {vm.state.section === 'calendar' && Calendar.renderCalendar(vm)}
      {vm.state.section === 'package' && Package.renderPackage(vm)}
      {vm.state.section === 'device' && Device.renderDevice(vm)}
      {vm.state.section === 'history' && History.renderHistory(vm)}
      {vm.state.section === 'password' && Password.renderPassword(vm)}
      {vm.state.section === 'about' && About.renderAbout(vm)}
      {vm.state.section === 'sale' && Sale.renderSale(vm)}
    </div>
  )
}
