import * as Jsx from './view.jsx'
// eslint-disable-next-line
import ZComponent from '/app/zcore/zcomponent'
import { connect } from 'react-redux'
import { actionAuth, actionSearch, actionSection, actionScrollComment, actionShowWaring, actionUsernameProfile, actionAvtProfile } from '../../../zcore/zredux.js'
import * as Form from './forms'

import { getMessaging, onMessage } from 'firebase/messaging'
import jwt_decode from "jwt-decode";

const apiLogin = '/api/v1/login'
const apiGetMember = '/api/v1/members'
const apiLoginMember = '/api/v1/members/login'
const apiAges = '/api/v1/ages'

class component extends ZComponent {
  constructor (props, context) {
    super(props, context, Jsx)
    this.state = {
      isNoti: false,
      notifications: [],
      pushNotification: true,
      notiNumber: 0,
      showBreakingPopup: false,
      pushNotiData: {
        enum: ''
      },
      epgPush: {},
      listNotifications: [],
      flagMobileMenuOpen: false,
      member: G.zu.array(G.zq.localLoad('member')) || [],
      auth: G.zu.json(G.zq.localLoad('auth')) || { status: false, user: {} },
      ages: [],
      validate: {
        msgErr: {
          phone: '',
          password: '',
          repassword: '',
          otp: ''
        },
        isPhoneValid: true,
        isPasswordValid: true,
        isRepasswordValid: true,
        otpCheck: true
      },
      form: {
        isLogin: true,
        isRegister: false,
        isOTP: false,
        isOTPSSo: false,
        isOTPForget: false,
        isOTPRegister: false,
        isAddpassword: false,
        isRegisterSuccess: false,
        isUpdatePassword: false,
        isChangePasswordSuccess: false,
        isForget: false,
        isSelectMember: false,
        isTypeMemberPassword: false,
        isNewMember: false,
        isSSOWithPhone: false,
        isSSOSuccess: false
      },
      editingDataLogin: {
        phone: '',
        password: '',
        repassword: '',
        showPassword: false,
        otp: [1, 2, 3, 4, 5, 6]
      },
      editingDataMember: {
        member_id: '',
        is_ask_password: false,
        name: '',
        password: '',
        age_id: ''
      },
      editingDataRegister: {},
      editingDataOtp: {},
      showNoti: false,
      section: this.props.section,
      user: {
        avatar: {},
        sso_avatar: null
      }
    }
    this.responseGoogle = this.responseGoogle.bind(this)
    this.responseFacebook = this.responseFacebook.bind(this)
  }

  componentDidMount () {
    G.zq.isLogin()
    setTimeout(() => {
      this.setState({ user: { ...(typeof G.zq.localLoad('user') !== 'string') ? G.zq.localLoad('user') : G.zu.json(G.zq.localLoad('user')) } })
    }, 1000)
    this.fetchDataAge()
    const messaging = getMessaging()
    onMessage(messaging, (payload) => {
      console.log('payload', payload)
      if (payload.notification !== undefined) {
        this.setState({ isNoti: true, pushNotification: true, notifications: [...this.state.notifications, payload.notification] })
        if (payload.data !== undefined) {
          this.setState({ pushNotiData: payload.data, epgPush: JSON.parse(payload.data.epg) })
          const startTime = G.zu.dateViaObj(new Date(this.state.epgPush.start_time))
          const startMilisecond = new Date(this.helperReDate(startTime)).getTime()
          G.zq.localSave('startTimeEpgsLocal', startMilisecond)
          G.zq.localSave('epgsIdLocal', this.state.epgPush.id)
        }
      } else {
        this.setState({ pushNotification: false, isNoti: false, notifications: [] })
      }
      // Auto +1
      this.setState({ notiNumber: ++this.state.notiNumber })
      setTimeout(() => {
        this.setState({ isNoti: false, notifications: [], pushNotiData: {}, epgPush: { enum: '' } })
      }, 20000)
    })
    if (G.zq.localLoad('loginby') !== 'logout') {
      G.zq.post('/api/v1/devices/update', {}, (json) => {
        console.log('Post time ok')
      })
    }
    this.fetchNumberNotification()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.auth[0].code !== prevProps.auth[0].code) {
      if (this.props.auth[0].code === 10) {
        this.setState({ form: G.zu.clone(Form.openLogin) })
      } else if (this.props.auth[0].code === 11 || this.props.auth[0].code === 23) {
        this.setState({ form: G.zu.clone(Form.openSelectMember) })
      }
    }
  }

  helperReDate (dateString) {
    if (dateString.length > 0) {
      return dateString.replace(/(\d{2})-(\d{2})-(\d{4})/, '$2/$1/$3')
    }
    return dateString
  }

  fetchDataAge () {
    G.zq.get(apiAges, {}, (json) => {
      if (json.success) {
        G.zq.localSave('ages', G.zu.array(json.data))
        this.setState({ ages: json.data })
      }
    })
  }

  onClickOpenBreakingPopup () {
    this.setState({ showBreakingPopup: true })
  }

  onClickCloseBreakingPopup () {
    this.setState({ showBreakingPopup: false })
  }

  linkTopage () {
    this.handleCloseForm()
    this.props.history.push('/dieu-khoan-su-dung')
  }

  // HANDLE Notification
  fetchNumberNotification () {
    G.zq.get('/api/v1/messages-unread', {}, (json) => {
      if (json.success) {
        this.setState({ notiNumber: json.data.count })
      }
    })
  }

  onClickShowNotification () {
    this.setState({ showNoti: !this.state.showNoti, notiNumber: 0 })
    this.fetchDataNoitificationInApp()
    this.onClickResetNumberNotification()
  }

  onClickCloseNotification () {
    this.setState({ showNoti: !this.state.showNoti })
  }

  onClickClosePushNoti () {
    this.setState({ isNoti: false, notifications: [] })
  }

  onClickResetNumberNotification () {
    G.zq.get('/api/v1/messages-received', {}, (json) => {
      if (json.success) {
        this.setState({ notiNumber: 0 })
      }
    })
  }

  fetchDataNoitificationInApp () {
    const body = {
      per_page: 10,
      page: 1
    }
    G.zq.get('/api/v1/messages', body, (json) => {
      if (json.success) {
        this.setState({ listNotifications: json.data })
      }
    })
  }
  // HANDLE Notification

  onClickCommentNotification () {
    this.props.actionScrollComment(1)
    this.setState({ showNoti: !this.state.showNoti })
  }

  // Handle click Warning
  onClickWarning (item) {
    this.props.actionSection('device')
    this.props.history.push('/tai-khoan')
    this.setState({ showNoti: !this.state.showNoti })
    this.props.actionShowWaring(true)
  }

  // used
  fetchDataMember () {
    G.zq.get(apiGetMember, {}, (json) => {
      if (json.success) {
        G.zq.localSave('member', G.zu.array(json.data))
        this.makeDate('member', json.data)
        const loginby = G.zq.localLoad('loginby')
        if (json.data.length === 1) {
          if (loginby !== 'sso') {
            this.onClickLoginMemberDirectly(json.data[0])
          } else {
            this.setState({ form: Form.openSelectMember })
          }
        }
        if (json.data.length > 1) {
          console.log('load member: ', json.data)
          this.setState({ form: Form.openSelectMember })
        }
      }
    })
  }

  // used
  makeDate (name, data) {
    this.setState({ [`${name}`]: data })
  }

  // used
  onClickSelectMember (e, member) {
    if (member) {
      const editingDataMember = this.state.editingDataMember
      if (member.id === editingDataMember.member_id) {
        editingDataMember.member_id = ''
      } else { editingDataMember.member_id = member.id }
      this.setState({ editingDataMember })
    }
  }

  // used
  onClickSelectAge (e, age) {
    if (age) {
      const editingDataMember = G.zu.clone(this.state.editingDataMember)
      if (age.id === editingDataMember.age_id) {
        editingDataMember.age_id = ''
      } else { editingDataMember.age_id = age.id }
      console.log('select age: ', age)
      this.setState({ editingDataMember })
    }
  }

  // used
  onClickBack (form) {
    this.setState({ form: G.zu.clone(Form[form]) })
  }

  // used
  handleOpenFormName (name) {
    this.setState({ form: G.zu.clone(Form[name]) })
  }

  // used
  handleOpenLogin () {
    this.setState({ form: G.zu.clone(Form.openLogin) })
  }

  // used
  handleShowPassword () {
    const editingDataLogin = G.zu.clone(this.state.editingDataLogin)
    editingDataLogin.showPassword = !this.state.editingDataLogin.showPassword
    this.setState({ editingDataLogin })
  }

  // used
  handleRegister () {
    this.helperValidatePhone()
    const phone = zget(this.state, ['editingDataLogin', 'phone'], '')
    const validate = this.validatePhone(phone)
    const api = '/api/v1/register'

    if (validate.isPhoneValid) {
      G.zq.post(api, { phone }, (json) => {
        if (json.success) {
          G.zq.rxsetCookie('access_token_zfilm', json.data.access_token)
          this.setState({ form: Form.openOTPRegisterForm })
        } else {
          const errorcode = json.errors[0].code
          if (errorcode === 1) {
            validate.msgErr.phone = 'Tài khoản đã được đăng ký'
            validate.isPhoneValid = false
          } else {
            validate.msgErr.phone = json.errors[0].message
            validate.isPhoneValid = false
          }
          this.setState({ validate })
        }
      })
    }
  }

  // used
  handleCheckInfor () {
    const password = this.state.editingDataLogin.password
    const repassword = this.state.editingDataLogin.repassword
    let msgErr = this.state.msgErr
    const isPhoneValid = this.state.isPhoneValid
    console.log('msgErr: ', msgErr)
    console.log('isPhoneValid: ', isPhoneValid)

    if (msgErr.phone === '' && isPhoneValid) {
      if (password.length < 6) {
        msgErr = { phone: 'Mật khẩu ít nhất 6 ký tự' }
      } else if (password !== repassword) {
        msgErr = { phone: 'Mật khẩu chưa khớp nhau' }
      }
      this.setState({ msgErr })
    }
  }

  // used
  handleLogin () {
    this.helperValidatePhone()
    const editingDataLogin = G.zu.clone(this.state.editingDataLogin)
    const validate = this.validatePhone(editingDataLogin.phone)
    const params = {
      phone: editingDataLogin.phone || '',
      password: editingDataLogin.password || ''
    }

    if (validate.isPhoneValid) {
      if (editingDataLogin.password.length < 8) {
        validate.msgErr.password = 'Mật khẩu ít nhất 8 ký tự'
        validate.isPasswordValid = false
        validate.isPhoneValid = true
        this.setState({ validate })
      } else {
        console.log('Du lieu hop le, goi api login')
        this.handleCallLogin(params)
      }
    }
  }

  // used
  handleCallLogin (params) {
    console.log('calling ============ api login')
    G.zq.postLogin(apiLogin, params, (json) => {
      if (json.success) {
        console.log('login thanh cong')
        if (json.data && json.data.access_token) {
          console.log('Dang nhap thanh cong, tiep theo la chon thanh vien')

          G.zq.rxsetCookie('access_token_zfilm', json.data.access_token)
          G.zq.localSave('auth', JSON.stringify(json.data))
          G.zq.localSave('authenticator', JSON.stringify([{ code: 0 }]))
          G.zq.localSave('loginby', 'phone')
          G.zq.localSave('password', params.password)
          this.setState({ msg: { success: true } })
          this.fetchDataMember()
        }
      } else {
        console.log('co loi xay ra')
        if (json && json.errors && json.errors[0]) {
          console.log('Sai mat khau hoac so dien thoai')
          const validate = G.zu.clone(this.state.validate)
          if (json.errors[0].code === 'phone') {
            validate.msgErr.phone = json.errors[0].message || ''
          } else {
            validate.msgErr.password = json.errors[0].message || ''
          }
          if (json.errors[0].code === 'phone') {
            validate.isPasswordValid = true
            validate.isPhoneValid = false
          } else {
            validate.isPasswordValid = false
            validate.isPhoneValid = true
          }
          this.setState({ validate })
        }
      }
    })
  }

  // used
  handleConfirmOTPRegister () {
    const api = '/api/v1/register-verify-otp'
    const otp = this.state.editingDataLogin.otp.join('')
    const params = { otp }
    const validate = G.zu.clone(this.state.validate)
    G.zq.post(api, params, (json) => {
      console.log('Xac nhan otp')
      if (json.success) {
        console.log('Xác nhận OTP đăng ký thành công')
        console.log('Mở form nhập mật khẩu mới cho user')
        this.setState({ form: Form.openRegisterAddPasswordForm })
      } else if (json.errors) {
        console.log('Xac nhan otp that bai')
        validate.msgErr.otp = json.errors[0].message
        validate.otpCheck = false
        this.setState({ validate })
      }
    })
  }

  // used
  handleConfirmOTPForget () {
    const api = '/api/v1/forgot-password-verify-otp'
    const otp = this.state.editingDataLogin.otp.join('')
    const params = { otp }
    const validate = G.zu.clone(this.state.validate)
    G.zq.post(api, params, (json) => {
      console.log('Xac nhan otp')
      if (json.success) {
        console.log('Xac nhan otp thanh cong')
        console.log('Chon thanh vien hoac tu dong chon member')
        this.setState({ form: Form.openTypeNewPasswordForm })
      } else if (json.errors) {
        console.log('Xac nhan otp that bai')
        validate.msgErr.otp = json.errors[0].message
        validate.otpCheck = false
        this.setState({ validate })
      }
    })
  }

  handleConfirmOTPSSO () {
    const api = '/api/v1/login-sso-verify-otp'
    const otp = this.state.editingDataLogin.otp.join('')
    const params = { otp }
    const validate = G.zu.clone(this.state.validate)
    G.zq.post(api, params, (json) => {
      console.log('Xac nhan otp')
      if (json.success) {
        console.log('Xac nhan otp thanh cong')
        console.log('Chon thanh vien hoac tu dong chon member')
        // this.fetchDataMember()
        this.setState({ form: Form.openSSOSuccessForm })
      } else if (json.errors) {
        console.log('Xac nhan otp that bai')
        validate.msgErr.otp = json.errors[0].message
        validate.otpCheck = false
        this.setState({ validate })
      }
    })
  }

  // used
  handleConfirmOTPNewMember () {
    const api = '/api/v1/members/create-verify-otp'
    const otp = this.state.editingDataLogin.otp.join('')
    const params = { otp, member_id: this.state.editingDataMember.member_id }
    G.zq.post(api, params, (json) => {
      console.log('Xac nhan otp')
      if (json.success) {
        console.log('Xac nhan otp thanh cong')
        console.log('Chon thanh vien hoac tu dong chon member')
        this.fetchDataMember()
      } else if (json.errors) {
        const validate = G.zu.clone(this.state.validate)
        validate.otpCheck = false
        validate.msgErr.otp = json.errors[0].message
        console.log('Xac nhan otp that bai')
        this.setState({ validate })
      }
    })
  }

  // used
  handleResendOTPNewMember () {
    console.log('========== Gui lai otp')
    const api = '/api/v1/members/create-resend-otp'
    const memberid = this.state.editingDataMember.member_id
    const validate = G.zu.clone(this.state.validate)
    G.zq.post(api, { member_id: memberid }, (json) => {
      if (json.success) {
        console.log('json.success: ', json)
        validate.msgErr.otp = ''
        validate.otpCheck = true
        this.setState({ validate })
      }
    })
  }

  // used
  handleResendOTPForget () {
    console.log('========== Gui lai otp quen mat khau')
    const api = '/api/v1/forgot-password-resend-otp'
    const validate = G.zu.clone(this.state.validate)
    G.zq.post(api, {}, (json) => {
      if (json.success) {
        console.log('json.success: ', json)
        validate.msgErr.otp = ''
        validate.otpCheck = true
        this.setState({ validate })
      }
    })
  }

  // used
  handleResendOTPRegister () {
    console.log('========== Gui lai otp quen mat khau')
    const api = '/api/v1/register-resend-otp'
    const validate = G.zu.clone(this.state.validate)
    G.zq.post(api, {}, (json) => {
      if (json.success) {
        console.log('json.success: ', json)
        validate.msgErr.otp = ''
        validate.otpCheck = true
        this.setState({ validate })
      }
    })
  }

  // used
  handleResendOTPSSO () {
    console.log('========== Gui lai otp quen mat khau')
    const api = '/api/v1/login-sso-resend-otp'
    const validate = G.zu.clone(this.state.validate)
    G.zq.post(api, {}, (json) => {
      if (json.success) {
        console.log('json.success: ', json)
        validate.msgErr.otp = ''
        validate.otpCheck = true
        this.setState({ validate })
      }
    })
  }

  // used
  // https://api.mcvgo.vn/docs/#api-T%C3%A0i_kho%E1%BA%A3n_Qu%C3%AAn_m%E1%BA%ADt_kh%E1%BA%A9u-Qu%C3%AAn_m%E1%BA%ADt_kh%E1%BA%A9u
  handleSendOTPRetakePassword () {
    const phone = this.state.editingDataLogin.phone
    const validate = this.validatePhone(phone)
    this.helperValidatePhone()

    if (validate.isPhoneValid) {
      const api = '/api/v1/forgot-password'
      if (validate.msgErr.phone === '' && validate.isPhoneValid) {
        G.zq.post(api, { phone }, (json) => {
          if (json.success) {
            G.zq.rxsetCookie('access_token_zfilm', json.data.access_token)
            this.setState({ form: Form.openOTPRetakePassword })
          } else {
            if (json.errors) {
              validate.msgErr.phone = json.errors[0].message
              validate.isPhoneValid = false
              this.setState({ validate })
            }
          }
        })
      }
    } else {
      console.log('So dien thoai khong hop le')
    }
  }

  // used
  handleCreateNewPassword () {
    const api = '/api/v1/forgot-password-update-password'
    const editingDataLogin = G.zu.clone(this.state.editingDataLogin)
    const password = editingDataLogin.password
    const repassword = editingDataLogin.repassword
    const validate = G.zu.clone(this.state.validate)
    // checkpassword
    if (password.length < 8) {
      console.log('Mật khẩu không đủ 8 ký tự')
      validate.msgErr.password = 'Mật khẩu không đủ 8 ký tự'
      validate.isPasswordValid = false
      this.setState({ validate })
    } else if (password !== repassword) {
      console.log('Mật khẩu nhập lại không đúng')
      validate.msgErr.repassword = 'Mật khẩu nhập lại không đúng'
      validate.isRepasswordValid = false
      this.setState({ validate })
    } else if ((password === repassword) && (password.length >= 8)) {
      G.zq.post(api, { password }, (json) => {
        if (json.success) {
          console.log('ket qua update mat khau: ', json)
          this.setState({ form: Form.openNewPasswordSuccessForm })
        } else {
          console.log('Doi mat khau that bai: ', json)
        }
      })
    }
  }

  // used
  handleRegisterCreateNewPassword () {
    const api = '/api/v1/register-update-password'
    const editingDataLogin = G.zu.clone(this.state.editingDataLogin)
    const password = editingDataLogin.password
    const repassword = editingDataLogin.repassword
    const validate = G.zu.clone(this.state.validate)
    // checkpassword
    if (password.length < 8) {
      console.log('Mật khẩu không đủ 8 ký tự')
      validate.msgErr.password = 'Mật khẩu không đủ 8 ký tự'
      validate.isPasswordValid = false
      this.setState({ validate })
    } else if (password !== repassword) {
      console.log('Mật khẩu nhập lại không đúng')
      validate.msgErr.repassword = 'Mật khẩu nhập lại không đúng'
      validate.isRepasswordValid = false
      this.setState({ validate })
    } else if ((password === repassword) && (password.length >= 8)) {
      console.log(1111, editingDataLogin)
      G.zq.post(api, { password }, (json) => {
        if (json.success) {
          console.log('ket qua update mat khau: ', json)
          // this.setState({ form: Form.openRegisterSuccessForm })
          this.handleLogin()
        } else {
          console.log('Doi mat khau that bai: ', json)
        }
      })
    }
  }

  handleEnterLogin (event) {
    if (event.key === 'Enter') {
      this.handleLogin()
    }
  }

  // used
  handleOpenForm () {
    const member = G.zq.localLoad('member')
    const user = G.zu.array(member)[0]
    if (!user) {
      this.setState({ form: Form.openLogin })
      this.props.actionAuth([{ code: 10 }])
    }
    this.props.actionSection('account')
  }

  handleCloseForm () {
    this.setState({ form: Form.closeForm })
    this.props.actionAuth([{ code: 0 }])
  }

  onClickLoginMemberDirectly (mem) {
    const password = G.zq.localLoad('password') || G.zu.get(this.state.editingDataLogin, 'password', '')
    const params = { member_id: mem.id, password }
    G.zq.post(apiLoginMember, params, (json) => {
      if (json.success) {
        G.zq.localSave('authenticator', JSON.stringify([{ code: 0 }]))
        G.zq.localSave('memberlogged', params.member_id)
        this.setState({ form: Form.closeForm })
        window.location.href = window.location.pathname
      }
    })
  }

  onClickLoginMember () {
    console.log('Login member')
    const member = G.zu.clone(this.state.member)
    const memberid = this.state.editingDataMember.member_id
    const memberSelected = member.find(mem => mem.id === memberid)
    const params = {
      member_id: memberid,
      password: ''
    }

    if (memberSelected.is_ask_password === 0) {
      G.zq.post(apiLoginMember, params, (json) => {
        if (json.data) {
          this.props.actionAuth([{ code: 0 }])
          G.zq.localSave('authenticator', JSON.stringify([{ code: 0 }]))
          G.zq.localSave('memberlogged', params.member_id)
          this.setState({ form: Form.closeForm })
          window.location.href = window.location.pathname
        }
      })
    } else if (memberSelected.is_ask_password === 1) {
      this.setState({ form: Form.openEnterMemberPassword })
    }
  }

  // used
  onSubmitMember () {
    console.log('Submit mat khau member')
    const params = { member_id: this.state.editingDataMember.member_id, password: G.zu.get(this.state.editingDataLogin, 'password', '') }
    G.zq.post(apiLoginMember, params, (json) => {
      if (json.success) {
        G.zq.localSave('authenticator', JSON.stringify([{ code: 0 }]))
        G.zq.localSave('memberlogged', params.member_id)
        this.props.actionAuth([{ code: 0 }])
        this.setState({ form: Form.closeForm })
        window.location.href = window.location.pathname
        console.log('Dang nhap thanh cong')
      } else {
        const validate = G.zu.clone(this.state.validate)
        validate.isPasswordValid = false
        validate.msgErr.password = 'Mật khẩu không đúng, sử dụng mật khẩu tải khoản hoặc thử lại với mật khẩu khác'
        this.setState({ validate })
      }
    })
  }

  // used
  onClickOpenAddMember () {
    this.setState({ form: Form.openAddNewMember })
  }

  // used
  onSubmitCreateMember () {
    console.log('Tao member moi')
    const editingDataMember = G.zu.clone(this.state.editingDataMember)
    const params = {
      name: editingDataMember.name,
      is_ask_password: G.zu.get(editingDataMember, 'is_ask_password', false) ? 1 : 0,
      age_id: G.zu.get(editingDataMember, 'age_id', ''),
      password: editingDataMember.password
    }

    if (params.password.length < 8 && params.is_ask_password) {
      const validate = G.zu.clone(this.state.validate)
      validate.isPasswordValid = false
      validate.msgErr.password = 'Mật khẩu ít nhất 8 ký tự'
      this.setState({ validate })
    } else {
      const api = '/api/v1/members'
      G.zq.post(api, params, (json) => {
        if (json.success) {
          console.log('calling =========== api create member')
          console.log('data new member: ', json.data)
          const editingDataMember = G.zu.clone(this.state.editingDataMember)
          editingDataMember.member_id = json.data.id
          this.setState({ form: Form.openOTP, editingDataMember })
        } else {
          const validate = G.zu.clone(this.state.validate)
          validate.isPasswordValid = false
          validate.msgErr.password = json.errors[0].message
          this.setState({ validate })
        }
      })
    }
  }

  onClickLogout () {
    G.zq.localSave('auth', JSON.stringify({}))
    G.zq.localSave('user', JSON.stringify({}))
    G.zq.localSave('loginby', '')
    G.zq.localSave('imgSsoFails', '')
    console.log('logout')
    G.zq.rxdelCookie('access_token_zfilm')
  }

  // used
  handleClearPhone () {
    this.setState({ editingDataLogin: { phone: '' } })
    $('.login_clear').hide()
  }

  onBlurDataLogin (e, name) {
    const value = e.target.value
    const editingDataLogin = this.state.editingDataLogin
    const validate = {
      msgErr: {
        phone: '',
        password: '',
        repassword: '',
        otp: ''
      },
      isPhoneValid: true,
      isPasswordValid: true,
      isRepasswordValid: true,
      otpCheck: true
    }
    if (name === 'phone' && value.length <= 10) {
      editingDataLogin[name] = value.replace(/\D/g, '')
    }
    if (name === 'password' || name === 'repassword') { editingDataLogin[name] = value }
    this.setState({ editingDataLogin, validate })
  }

  // used
  onBlurAskPassword (e) {
    console.log('e.target.value: ', e.target.value)
    const editingDataMember = G.zu.clone(this.state.editingDataMember)
    editingDataMember.is_ask_password = !this.state.editingDataMember.is_ask_password
    editingDataMember.password = ''
    this.setState({ editingDataMember })
  }

  // used
  onBlurDataMemberName (e, name) {
    const value = e.target.value
    const editingDataMember = this.state.editingDataMember
    if (value.length <= 32) { editingDataMember[name] = value }
    this.setState({ editingDataMember })
  }

  // used
  onBlurOtpCode (e, pos) {
    const value = e.target.value
    const otp = G.zu.clone(G.zu.array(this.state.editingDataLogin.otp))
    const editingDataLogin = G.zu.clone(this.state.editingDataLogin)
    if (value.length <= 1) {
      otp[pos] = value[0] || ''
      editingDataLogin.otp = otp
      this.setState({ editingDataLogin })
    }
  }

  // used
  helperValidatePhone () {
    const validate = G.zu.clone(this.state.validate)
    if (!this.state.editingDataLogin.phone || this.state.editingDataLogin.phone === '') {
      validate.msgErr.phone = 'Số điện thoại không được bỏ trống'
      validate.isPhoneValid = false
    } else {
      const re = /((09|03|07|08|05)+([0-9]{8})\b)/g
      if (!re.test(G.zu.get(this.state, ['editingDataLogin', 'phone'], ''))) {
        validate.msgErr.phone = 'Số điện thoại không hợp lệ'
        validate.isPhoneValid = false
      } else {
        validate.msgErr.phone = ''
        validate.isPhoneValid = true
      }
    }
    this.setState({ validate })
  }

  // used
  validatePhone (phone) {
    const validate = G.zu.clone(this.state.validate)
    if (!phone || phone === '') {
      validate.msgErr.phone = 'Số điện thoại không được bỏ trống'
      validate.isPhoneValid = false
    } else {
      const re = /((09|03|07|08|05)+([0-9]{8})\b)/g
      if (!re.test(G.zu.get(this.state, ['editingDataLogin', 'phone'], ''))) {
        validate.msgErr.phone = 'Số điện thoại không hợp lệ'
        validate.isPhoneValid = false
      } else {
        validate.msgErr.phone = ''
        validate.isPhoneValid = true
      }
    }
    return validate
  }

  // used
  helperChangePhone (phone) {
    const format = [4, 7]
    let newPhone = ''
    for (let i = 0; i < phone.length; i++) {
      if (format.indexOf(i + 1) !== -1) {
        newPhone += `${phone[i]} `
      } else {
        newPhone += phone[i]
      }
    }
    return newPhone
  }

  // GOOGLE and FACEBOOK LOGIN
  responseFacebook (response) {
    console.log('response FB', response)
    if (response && response.error === undefined) {
      let params = {}
      if (response && response.accessToken) {
        params = {
          email: G.zu.get(response, 'email', ''),
          sso_token: response.accessToken,
          sso_type: 2,
          // phone: '',
          // otp: '',
          sso_id: G.zu.get(response, 'userID', ''),
          sso_name: G.zu.get(response, 'name', ''),
          sso_avatar_url: G.zu.get(response, 'picture.data.url', '')
        }
        this.onSubmitSSO(params)
      } else {
        this.setState({ msgErr: { phone: 'Đăng nhập tài khoản thất bại!' } })
      }
    } else {
      const errors = [{ message: 'Không tìm thấy địa chỉ email ở tàì khoản facebook này. Vui lòng sử dụng tài khoản khác!' }]
      this.setState({ errors: errors, loading: false, msgErr: { phone: true } })
    }
  }

  responseGoogle (response) {
      const decoded = jwt_decode(response.credential);

      const params = {
          email: decoded.email,
          sso_token: response.credential, // accessToken,
          sso_type: 1,
          sso_id: decoded.sub,
          // phone: '',
          // otp: '',
          sso_name: `${decoded.family_name} ${decoded.given_name}`,
          sso_avatar_url: decoded.picture
      }
      this.onSubmitSSO(params)
  }

  onSubmitSSO (params) {
    const apiSSO = '/api/v1/login-sso'

    G.zq.localSave('loginby', 'sso')
    G.zq.post(apiSSO, params, (json) => {
      console.log('json sso: ', json)
      if (json.success) {
        if (G.zu.get(json.data, 'phone_required')) {
          console.log('chua co tai khoan, dang ky voi so dien thoai')
          // nếu yêu cầu số đt thì chuyển qua tab đăng ký
          this.setState({ form: Form.openSSOWithPhoneForm, editingData: params })
        } else if (G.zu.get(json.data, 'access_token')) {
          this.setState({ msg: { success: true } }, () => {
            console.log('dang nhap thanh cong', json.data)
            G.zq.rxsetCookie('access_token_zfilm', json.data.access_token, 10080)
            this.fetchDataMember()
          })
        }
      } else {
        if (json && json.errors) {
          if (G.zu.get(json.errors, '0.code') === 21) {
            console.log('co loi xay ra', json)
            const validate = G.zu.clone(this.state.validate)
            validate.msgErr.password = 'Không thể đăng nhập bằng tài khoản này'
            this.setState({ validate })
          }
        }
      }
    })
  }

  onClickLoginSSOSubmit (e) {
    console.log('click nhan otp')
    e.preventDefault()
    const params = {
      email: G.zu.get(this.state, 'editingData.email', ''),
      sso_token: G.zu.get(this.state, 'editingData.sso_token', ''),
      sso_type: G.zu.get(this.state, 'editingData.sso_type', ''),
      phone: G.zu.get(this.state, 'editingDataLogin.phone', ''),
      password: G.zu.get(this.state, 'editingDataLogin.password', ''),
      sso_id: G.zu.get(this.state, 'editingData.sso_id', ''),
      sso_name: G.zu.get(this.state, 'editingData.sso_name', ''),
      sso_avatar_url: G.zu.get(this.state, 'editingData.sso_avatar_url', '')
    }

    const phone = zget(this.state, ['editingDataLogin', 'phone'], '')
    const password = zget(this.state, ['editingDataLogin', 'password'], '')
    const validate = this.validatePhone(phone)
    const api = '/api/v1/login-sso-with-phone'

    if (validate.isPhoneValid && password.length >= 8) {
      G.zq.post(api, params, (json) => {
        console.log('json sso with phone: ', json)
        if (json.data && json.data.access_token) {
          console.log('Đăng nhập thành công, mở form xác thực OTP')
          this.setState({ msg: { success: true } }, () => {
            G.zq.rxsetCookie('access_token_zfilm', json.data.access_token, 10080) // 10080: 7 ngay
            this.setState({ form: Form.openSSOOTPForm })
          })
        } else {
          if (json && json.errors) {
            console.log('co loi xay ra', json)
            if (G.zu.get(json.errors, '0.code') === 37) {
              validate.msgErr.otp = 'Xác thực OTP trước đó thất bại, gửi lại OTP để tiếp tục xác thực'
              validate.otpCheck = false
              this.setState({ form: Form.openSSOOTPForm, validate })
            }
          }
        }
      })
    } else if (validate.isPhoneValid && password.length < 8) {
      validate.isPasswordValid = false
      validate.msgErr.password = 'Mật khẩu ít nhất 8 ký tự'
      this.setState({ validate })
    }
  }

  onClickMobileToggle () {
    this.setState({ flagMobileMenuOpen: !this.state.flagMobileMenuOpen })
  }

  handleSearchKeyword (value) {
    this.props.actionSearch(value)
    G.zq.localSave('keyword', value)
  }

  render () { return this.renderComponent() }
}

const mapStateToProps = (state, ownProps) => ({
  auth: state.auth,
  keyword: state.keyword,
  section: state.section,
  scrollComment: state.scrollComment,
  showWarning: state.showWarning,
  usernameProfile: state.usernameProfile,
  avtProfile: state.avtProfile
})

const mapDispatchToProps = {
  actionAuth,
  actionSearch,
  actionSection,
  actionScrollComment,
  actionShowWaring,
  actionUsernameProfile,
  actionAvtProfile
}

const ComponentLayout = connect(
  mapStateToProps,
  mapDispatchToProps
)(component)

export default ComponentLayout
