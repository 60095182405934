import Layout from './layout'
import Home from './home'
import HomeFilm from './homefilm'
import HomeTv from './hometv'
import Checkout from './others/checkout'
import Watching from './others/watching'

import ViewFilm from './viewfilm'
import ViewTv from './viewtv'

import HomeHot from './homehot'
import BreakingNews from './breakingnews'
import BuyPack from './pack'
import Account from './account'
import Search from './search'
import YourChannel from './yourchannel'
import Notification from './notification'
import Policy from './policy'
import TermsOfUser from './termsOfUser'
import Support from './support'
import Introduction from './introduction'

G.routes.unshift({
  path: '/',
  component: Layout,
  name: zt('Layout'),
  routes: [{
    path: '/',
    exact: true,
    component: Home
  }, {
    path: '/chinh-sach-bao-mat',
    exact: true,
    component: Policy
  }, {
    path: '/ho-tro',
    exact: true,
    component: Support
  }, {
    path: '/gioi-thieu',
    exact: true,
    component: Introduction
  }, {
    path: '/dieu-khoan-su-dung',
    exact: true,
    component: TermsOfUser
  }, {
    path: '/xem-phim',
    exact: true,
    component: HomeFilm
  }, {
    path: '/xem-phim/:slug',
    exact: true,
    component: ViewFilm
  }, {
    path: '/xem-phim/:slug/:eps',
    exact: true,
    component: ViewFilm
  }, {
    path: '/truyen-hinh',
    exact: true,
    component: HomeTv
  }, {
      path: '/truyen-hinh/:slug',
      component: ViewTv
    },
  // }, {
  //   path: '/mua-goi',
  //   exact: true,
  //   component: BuyPack
  // }, {
  //   path: '/mua-goi/:slug',
  //   exact: true,
  //   component: BuyPack
  // }, {
    {
    path: '/tai-khoan',
    exact: true,
    component: Account
  }, {
    path: '/tim-kiem',
    exact: true,
    component: Search
  }, {
      path: '/tim-kiem?:slug',
      exact: true,
      component: Search
    },
  // }, {
  //   path: '/hot',
  //   exact: true,
  //   component: HomeHot
  // }, {
  //   path: '/hot/:slug',
  //   exact: true,
  //   component: HomeHot
  // }, {
  //   path: '/breaking-new',
  //   exact: true,
  //   component: BreakingNews
  // }, {
  //   path: '/thanh-toan',
  //   exact: true,
  //   component: Checkout
  // }, {
  //   path: '/dang-xem',
  //   exact: true,
  //   component: Watching
  // }, {
    {
    path: '/kenh-cua-ban/:slug',
    exact: true,
    component: YourChannel
  }, {
    path: '/kenh-cua-ban/:slug/:epgs',
    exact: true,
    component: YourChannel
  }, {
    path: '/thong-bao',
    exact: true,
    component: Notification
  }]
})
