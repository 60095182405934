export const CloseForm = {
  isSelectMember: false,
  isNewPassword: false,
  isNewMember: false,
  isOTP: false,
  isTypePassword: false
}

export const SelectMemberForm = {
  isSelectMember: true,
  isNewPassword: false,
  isNewMember: false,
  isOTP: false,
  isTypePassword: false
}

export const NewPasswordForm = {
  isSelectMember: false,
  isNewPassword: true,
  isNewMember: false,
  isOTP: false,
  isTypePassword: false
}

export const NewMemberForm = {
  isSelectMember: false,
  isNewPassword: false,
  isNewMember: true,
  isOTP: false,
  isTypePassword: false
}

export const OTPForm = {
  isSelectMember: false,
  isNewPassword: false,
  isNewMember: false,
  isOTP: true,
  isTypePassword: false
}

export const TypePasswordForm = {
  isSelectMember: false,
  isNewPassword: false,
  isNewMember: false,
  isOTP: false,
  isTypePassword: true
}
