export const renderNotificationPaymentSuccess = (vm) => {
  return (
    <>
      <div className='packpopup_curtain' onClick={e => vm.handleCloseNotiSuccess()} />
      <div className='packpopup_outer'>
        <div className='packpopup_wrap'>
          <img className='pp_close' src='static/img/icon_close.svg' alt='close' onClick={() => vm.handleCloseNotiSuccess()} />
          <div className='packpopup_head'>Thanh toán thành công</div>
          <div className='packpopup_body packpopup_body_noti'>
            <div className='packpopup_body_description'>Bạn đã đăng ký thành công gói <strong>{G.zq.localLoad('packageName')}</strong> của <strong>MCVGo.</strong></div>
            <img className='packpopup_body_image' src='/images/static/payment/payment_success.svg' />
            <div className='pack_btns'>
              <div className='pack_submit' onClick={e => vm.handleCloseNotiSuccess()}>OK</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export const renderNotificationPaymentError = (vm) => {
  return (
    <>
      <div className='packpopup_curtain' onClick={e => vm.handleCloseNotiFail()} />
      <div className='packpopup_outer'>
        <div className='packpopup_wrap'>
          <img className='pp_close' src='static/img/icon_close.svg' alt='close' onClick={() => vm.handleCloseNotiFail()} />
          <div className='packpopup_head'>Thanh toán không thành công</div>
          <div className='packpopup_body packpopup_body_noti'>
            <div className='packpopup_body_description'>Đã xảy ra lỗi [<strong>{G.zq.localLoad('parLocal').errorCode}</strong>] trong quá trình thanh toán.</div>
            <img className='packpopup_body_image' src='/images/static/payment/payment_error.svg' />
            <div className='pack_btns' packpopup_body_noti>
              <div className='pack_submit' onClick={e => vm.handleCloseNotiFail()}>Thử lại</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export const renderNotificationPaymentExpired = (vm) => {
  return (
    <>
      <div className='packpopup_curtain' onClick={e => vm.handleCloseNotiFail()} />
      <div className='packpopup_outer'>
        <div className='packpopup_wrap'>
          <img className='pp_close' src='static/img/icon_close.svg' alt='close' onClick={() => vm.handleCloseNotiFail()} />
          <div className='packpopup_head'>Gói dịch vụ đã hết hạn</div>
          <div className='packpopup_body packpopup_body_noti'>
            <div className='packpopup_body_description'>Gói XYZ đã hết hạn. Vui lòng gia hạn để tiếp tục sử dụng.</div>
            <img className='packpopup_body_image' src='/images/static/payment/payment_expired.svg' alt='expired payment' />
            <div className='pack_btns'>
              <div className='pack_submit' onClick={e => vm.handleCloseNotiFail()}>Gia hạn</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
