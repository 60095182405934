import * as Jsx from './view.jsx'
// eslint-disable-next-line
import RxComponent from '/app/zcore/zcomponent'

const api = '/api/v1/homepages?parent_id=2&'
class component extends RxComponent {
  constructor (props, context) {
    super(props, context, Jsx)
    this.state = {
      datahome: G.zu.json(G.zq.localLoad(api))
    }
  }

  fetchData () {
    G.zq.get(api, this.state.filter, (json) => {
      if (json.success) {
        this.makeDate(json.data)
      }
    })
  }

  makeDate (data) {
    G.zq.localSave(api, JSON.stringify(data))
    this.setState({ datahome: data })
  }

  componentDidMount () {
    this.fetchData()
  }

  render () { return this.renderComponent() }
}

export default component
