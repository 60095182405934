import * as Jsx from './view.jsx'
// eslint-disable-next-line
import RxComponent from '/app/zcore/zcomponent'
import * as Form from './form'
import { actionAuth, actionSection, actionShowWaring, actionUsernameProfile, actionAvtProfile } from '../../../zcore/zredux.js'
import { connect } from 'react-redux'

const api = '/api/v1/users'
const apiMember = '/api/v1/members'
const apiAges = '/api/v1/ages'
const apiDevice = '/api/v1/devices'
const apiPackageUsing = '/api/v1/packages/using'
const apiPackage = '/api/v1/packages'
const apiUploadAvatar = '/api/v1/users/upload-avatar'

// Config Submenu
const sizeSubmenu = {
  open: '40%',
  close: '52px'
}

class component extends RxComponent {
  constructor (props, context) {
    super(props, context, Jsx)
    this.state = {
      sider: {
        isMobile: false,
        width: sizeSubmenu.close,
        background: 'transparent',
        showName: 'block',
        classSider: 'chevron-arrow-right'
      },
      showWarning: this.props.showWarning,
      user: {},
      section: this.props.section === '' ? 'account' : this.props.section,
      // section: 'account',
      editingData: {
        profile: {}
      },
      showCreate: { member: false },
      ages: G.zu.json(G.zq.localLoad('ages')),
      member: G.zu.array(G.zq.localLoad('member')),
      memberlogged: G.zq.localLoad('memberlogged'),
      device: [],
      sale: [],
      profile: {},
      pack: {},
      payment: '1',
      errMsg: '',
      sccMsg: '',
      isOTP: '',
      paging: { page: 1, per_page: 10 },
      timeLeft: { otp: 10, lock_otp: 9, login_qr: 8, lock_login: 7 },
      timeLeft_default: { otp: 10, lock_otp: 9, login_qr: 8, lock_login: 7 },
      countdown: {
        otp: false,
        lock_otp: false,
        login_qr: false,
        lock_login: false
      },
      loading: true,
      checkData: false,
      isUpdate: false,
      popup: false,
      otp: {
        form: false,
        code: [1, 2, 3, 4, 5, 6],
        otpCheck: true,
        msg: ''
      },
      editingDataMember: {
        name: '',
        is_ask_password: false,
        age_id: '',
        member_id: '',
        password: ''
      },
      form: {
        isSelectMember: false,
        isNewPassword: false,
        isNewMember: false,
        isOTP: false,
        isTypePassword: false
      },
      validate: {
        msgErr: {
          phone: '',
          password: '',
          repassword: '',
          otp: '',
          member: ''
        },
        isPhoneValid: true,
        isPasswordValid: true,
        isRepasswordValid: true,
        otpCheck: true,
        isMemberNameValid: true
      },
      editingDataLogin: {
        // phone: G.zu.json(G.zq.localLoad('user')).phone,
        phone: '',
        password: '',
        repassword: '',
        otp: [1, 2, 3, 4, 5, 6],
        showPassword: false
      },
      notify: {
        msg: {
          update: '',
          delete: ''
        },
        isUpdate: false,
        isDelete: false,
        timeout: 3000
      },
      notiPopup: {
        renew: false,
        stop: false
      },
      keyword: '',
      historyTransactions: [],
      accountCalendar: [],
      accountVodCalendar: [],
      meta: {
        current_page: 0,
        from: 0,
        last_page: 0,
        per_page: 0,
        to: 0,
        total: 0
      },
      tabCalendar: true,
      emailErr: false,
      deviceWarning: false,
      deviceIPV4: '',
      usernameProfile: this.props.usernameProfile,
      avtProfile: this.props.avtProfile
    }
    this.props.actionSection(this.state.section)
    this.props.actionUsernameProfile(this.state.usernameProfile)
    this.props.actionAvtProfile(this.state.avtProfile)
  }

  componentDidMount () {
    this.fetchData()
    this.fetchDataDevice(1)
    this.initSider()
    window.addEventListener('resize', this.handleResize.bind(this))
    this.handleResize()
    this.fetchDataPackage()
    // this.fetchDataTransactions()
    // this.fetchDataCalendar()
    G.zq.get('https://geolocation-db.com/json/', {}, (json) => {
      this.setState({ deviceIPV4: json.IPv4 })
    })
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.section !== this.props.section) {
      this.setState({ section: this.props.section })
    }
  }

  initSider () {
    const sider = this.state.sider
    if (window.innerWidth <= 580) {
      console.log('mobile')
      sider.isMobile = true
      sider.showName = sider.width === 200 ? 'block' : 'none'
      this.setState({ sider })
    }
  }

  notiOff (form) {
    const notify = {
      msg: {
        update: '',
        delete: ''
      },
      isUpdate: false,
      isDelete: false,
      timeout: 3000
    }
    setTimeout(() => {
      if (form) {
        this.setState({ notify, form: Form[form] })
      } else {
        this.setState({ notify })
      }
    }, 3000)
  }

  /*  Fetch
  */

  fetchData () {
    this.fetchDataAge()
    this.fetchDataMember()
    G.zq.get(api, this.state.filter, (json) => {
      if (json.success) {
        const editingData = {
          profile: G.zu.clone(json.data)
        }
        const loginby = G.zq.localLoad('loginby')
        this.setState({ profile: G.zu.clone(json.data), editingData })
        this.makeDate(G.zu.clone(json.data), 'user')
      } else {
        const authchecked = G.zq.checklogin('/api/v1/users', json)
        if (authchecked) {
          G.zq.localSave('loginby', 'logout')
          this.props.actionAuth(authchecked)
        }
      }
    })
    // this.fetchDataTransactions()
    // this.fetchDataCalendar()
  }

  fetDataWithSection () {
    switch (this.state.section) {
      case 'device': // quan ly thiet bi
        this.fetchDataDevice(1)
        break
      case 'package': // mua dich vu
        this.fetchDataPackage(1)
        break
      case 'history': // lich su giao dich
        this.fetchDataTransactions(1)
        break
      case 'calendar': // Calendar
        this.state.tabCalendar ? this.fetchDataVodCalendar(1) : this.fetchDataCalendar(1)
        break
      // case 'sale': // mua dich vu
      //   this.fetchDataSale()
      //   break
      default:
        break
    }
  }

  fetchDataAge () {
    G.zq.get(apiAges, {}, (json) => {
      if (json.success) {
        this.setState({ ages: json.data })
        this.makeDate(json.data, 'ages')
      }
    })
  }

  fetchDataMember () {
    G.zq.get(apiMember, {}, (json) => {
      if (json.success) {
        G.zq.localSave('member', json.data)
        this.setState({ member: json.data })
      }
    })
  }

  fetchDataDevice (changepage) {
    const params = {
      per_page: 10,
      page: changepage || this.state.meta.current_page
    }
    G.zq.get(apiDevice, params, (json) => {
      if (json.success) {
        setTimeout(() => {
          this.setState({ device: json.data, meta: { ...G.zu.clone(json.meta) } })
        })
      }
    })
  }

  fetchDataPackage (changepage) {
    const params = {
      per_page: 10,
      page: changepage || this.state.meta.current_page
    }
    G.zq.get(apiPackageUsing, params, (json) => {
      if (json.success) {
        // const packages = zarray(json.data).reverse()
        const packages = zarray(json.data)
        // const packageList = packages.filter(el => !el.video)
        packages.map(el => {
          if (el.expired_at !== null) {
            const ex = new Date(el.expired_at.replace(/(\d{2})-(\d{2})-(\d{4})/, '$2/$1/$3'))
            el.dayLeft = Math.round((ex - new Date()) / (24 * 60 * 60 * 1000))
            el.expired = el.dayLeft < 0
          }
          return el
        })
        // const videoList = packages.filter(el => !!el.video)
        this.setState({
          package_using: {
            package: packages
            // videos: videoList
          },
          meta: { ...G.zu.clone(json.meta) }
        })
      }
    })
  }

  fetchDataSale () {
    G.zq.get(apiPackage, {}, (json) => {
      if (json.success) {
        this.setState({ sale: json.data })
      }
    })
  }

  makeDate (data, name) {
    G.zq.localSave(name, JSON.stringify(data))
    this.setState({ [`${name}`]: data })
  }

  /*
  Handle
  */

  /*
  HANDLE HISTORY ACCOUNT
  */
  onKeyPressSearch (e) {
    if (e.key === 'Enter') {
      console.log('xxx')
    }
  }

  onChangeSearch (e) {
    this.setState({ keyword: e.target.value })
  }

  onClickRefreshInput () {
    console.log('xxx')
  }

  fetchDataTransactions (changepage) {
    const params = {
      page_size: 1,
      page: changepage || this.state.meta.current_page
    }
    const apiTransactions = '/api/v1/transactions'
    G.zq.get(apiTransactions, params, (json) => {
      if (json.success) {
        this.setState({ historyTransactions: json.data, meta: { ...G.zu.clone(json.meta) } })
      }
    })
  }

  fetchDataCalendar (changepage) {
    const params = {
      page_size: 1,
      page: changepage || this.state.meta.current_page
    }
    const apiCalendar = '/api/v1/epg/detail'
    G.zq.get(apiCalendar, params, (json) => {
      if (json.success) {
        this.setState({ accountCalendar: json.data, meta: { ...G.zu.clone(json.meta) } })
      }
    })
  }

  fetchDataVodCalendar (changepage) {
    const params = {
      page_size: 1,
      page: changepage || this.state.meta.current_page
    }
    const apiVodCalendar = '/api/v1/video/detail'
    G.zq.get(apiVodCalendar, params, (json) => {
      if (json.success) {
        this.setState({ accountVodCalendar: json.data, meta: { ...G.zu.clone(json.meta) } })
      }
    })
  }

  handleChangePage (page) {
    const meta = G.zu.clone(this.state.meta)
    meta.current_page = page
    this.setState({ meta })
    switch (this.state.section) {
      case 'history': // transactions
        this.fetchDataTransactions(page)
        break
      case 'device': // Device
        this.fetchDataDevice(page)
        break
      case 'package': // mua dich vu
        this.fetchDataPackage(page)
        break
      case 'calendar': // Calendar
        this.state.tabCalendar ? this.fetchDataVodCalendar(page) : this.fetchDataCalendar(page)
        break
      default:
        break
    }
  }

  handleCoppyCode () {
    $('.codeTransaction').select()
    document.execCommand('copy')
  }

  onClickTabsCalendar () {
    this.setState({ tabCalendar: !this.state.tabCalendar }, () => this.fetDataWithSection())
  }

  /*
  HANDLE HISTORY ACCOUNT
  */

  handleConfirmOTPMain () {
    if (this.state.isOTP === 'new') {
      this.handleConfirmOTPNewMember()
    } else {
      this.handleConfirmOTPDelMember()
    }
  }

  handleOpenFormName (name) {
    this.setState({ form: Form[name] })
  }

  handleCloseForm () {
    this.setState({ popup: !this.state.popup })
  }

  handleResize () {
    const sider = this.state.sider
    if (window.innerWidth <= 580) {
      sider.isMobile = true
      sider.showName = sider.width === '40%' ? 'block' : 'none'
      this.setState({ sider })
    } else {
      sider.isMobile = false
      sider.showName = 'block'
      this.setState({ sider })
    }
  }

  handleShowPassword () {
    const editingDataLogin = G.zu.clone(this.state.editingDataLogin)
    editingDataLogin.showPassword = !this.state.editingDataLogin.showPassword
    const resetValidate = this.resetValidate()
    this.setState({ editingDataLogin, validate: resetValidate })
  }

  handleResendOTPNewMember () {
    console.log('========== Gui lai otp')
    const api = '/api/v1/members/create-resend-otp'
    const memberid = this.state.editingDataMember.member_id
    const validate = {
      ...G.zu.clone(this.state.validate)
    }
    G.zq.post(api, { member_id: memberid }, (json) => {
      if (json.success) {
        console.log('json.success: ', json)
        validate.msgErr.otp = ''
        validate.otpCheck = true
        this.setState({ validate })
      }
    })
  }

  handleConfirmOTPNewMember () {
    const api = '/api/v1/members/create-verify-otp'
    const otp = this.state.editingDataLogin.otp.join('')
    const params = { otp, member_id: this.state.editingDataMember.member_id }
    G.zq.post(api, params, (json) => {
      console.log('Xac nhan otp')
      if (json.success) {
        console.log('Xac nhan otp thanh cong')
        console.log('Chon thanh vien hoac tu dong chon member')
        const notify = G.zu.clone(this.state.notify)
        notify.msg.update = 'Tạo thành viên mới thành công!'
        notify.isUpdate = true
        this.setState({ notify, form: Form.SelectMemberForm }, () => {
          this.fetchDataMember()
          this.notiOff()
        })
      } else if (json.errors) {
        const validate = {
          ...G.zu.clone(this.state.validate),
          otpCheck: false,
          msgErr: {
            otp: json.errors[0].message
          }
        }
        console.log('Xac nhan otp that bai')
        this.setState({ validate })
      }
    })
  }

  handleDeleteMember () {
    console.log('logggg')
    this.setState({ isOTP: 'del' })
    console.log(this.state.editingDataMember.member_id)
    const apiUrl = apiMember + '/' + this.state.editingDataMember.member_id
    console.log(apiUrl)
    G.zq.delete(apiUrl, {}, (json) => {
      console.log('Call api delete member')
      const editingDataMember = G.zu.clone(this.state.editingDataMember)
      // editingDataMember.member_id = json.data.id
      this.setState({ form: Form.OTPForm, editingDataMember })
      // if (json.success) {
      //   console.log('Call api delete member')
      //   const editingDataMember = G.zu.clone(this.state.editingDataMember)
      //   editingDataMember.member_id = json.data.id
      //   this.setState({ form: Form.OTPForm, editingDataMember })
      // }
    })
  }

  handleConfirmOTPDelMember () {
    const api = '/api/v1/members/delete-verify-otp'
    const otp = this.state.editingDataLogin.otp.join('')
    const params = { otp, member_id: this.state.editingDataMember.member_id }
    G.zq.post(api, params, (json) => {
      console.log('Xac nhan otp')
      if (json.success) {
        console.log('Xac nhan otp thanh cong')
        console.log('Chon thanh vien hoac tu dong chon member')
        const notify = G.zu.clone(this.state.notify)
        notify.msg.update = 'Xóa thành viên thành công!'
        notify.isUpdate = true
        this.setState({ notify, form: Form.SelectMemberForm }, () => {
          this.fetchDataMember()
          this.notiOff()
        })
      } else if (json.errors) {
        const validate = {
          ...G.zu.clone(this.state.validate),
          otpCheck: false,
          msgErr: {
            otp: json.errors[0].message
          }
        }
        console.log('Xac nhan otp that bai')
        this.setState({ validate })
      }
    })
  }

  handleUploadAvatar (e) {
    console.log(e.target.files[0])
    const formData = new window.FormData()
    formData.append('avatar_img', e.target.files[0])
    G.zq.postFormData(apiUploadAvatar, formData, (json) => {
      if (json.success) {
        console.log('success---------------', json)
        setTimeout(() => {
          this.fetchData()
          setTimeout(() => {
            this.props.actionAvtProfile(this.state.user.avatar.thumb_180x180)
          }, 400)
        }, 1500)
      }
    })
  }

  handleConfirmOTP () {
    const api = '/api/v1/members/create-verify-otp'
    const otp = this.state.otp.code.join('')
    const params = { otp, member_id: this.state.otp.member_id }

    G.zq.post(api, params, (json) => {
      console.log('Xac nhan otp')
      if (json.success) {
        const otp = this.state.otp
        otp.form = false
        otp.msg = 'Xác thực OTP thành công'
        this.setState({ otp, selectmember: true }, () =>
          this.fetchDataMember()
        )
      } else if (json.errors) {
        console.log('Xac nhan otp that bai')
        this.setState({ otpCheck: false })
      }
    })
  }

  handleRenewPopup (key) {
    const statusPopup = {}
    statusPopup[key] = true
    this.setState({ notiPopup: statusPopup })
  }

  handleClosePopup () {
    this.setState({
      notiPopup: {
        renew: false,
        stop: false
      }
    })
  }

  handleClosePopupWarning () {
    this.props.actionShowWaring(false)
  }

  /*
  onEvent
  */

  onBlurData (e, data) {
    const editingData = this.state.editingData
    const value = e.target.value
    console.log(value.length)
    editingData.profile[data] = value
    this.checkUpdate()
    this.setState({ editingData })
  }

  onClickLogout () {
    G.zq.localSave('user', JSON.stringify({}))
    G.zq.localSave('loginby', 'logout')
    G.zq.rxdelCookie('access_token_zfilm')
    window.location.href = '/'
  }

  onClickCloseMsg (e) {
    e.preventDefault()
    clearTimeout(this.timerMessage)
    this.setState({ msg: {}, errors: [], success: [] })
  }

  onClickSelectAge (e, age) {
    if (age) {
      const editingDataMember = this.state.editingDataMember
      if (age.id === editingDataMember.age_id) {
        editingDataMember.age_id = ''
      } else {
        editingDataMember.age_id = age.id
      }
      this.setState({ editingDataMember })
    }
  }

  onClickBack (form) {
    this.setState({ form: G.zu.clone(Form[form]) })
  }

  onClickInfoPackage (pack) {
    const slug = `${G.zu.rxChangeSlug(pack.package.name)}-${pack.package.id}`
    window.location.href = 'mua-goi/' + slug
  }

  onClickYourChanel (channel) {
    const slug = `${G.zu.rxChangeSlug(channel.channel_name) + '-' + channel.channel_id}`
    const epgs = `${G.zu.rxChangeSlug(channel.name)}`
    const epgsId = `${channel.epgId}`
    const startTime = `${channel.start_time}`
    const startTimeEpgsLocal = new Date(this.helperReDate(startTime).replaceAll(/-/g, '/')).getTime()
    G.zq.localSave('startTimeEpgsLocal', startTimeEpgsLocal)
    G.zq.localSave('epgsIdLocal', epgsId)
    window.location.href = 'kenh-cua-ban/' + slug + '/' + epgs
  }

  onClickViewFilm (vod) {
    const slug = `${G.zu.rxChangeSlug(vod.videoSlug)}-${vod.videoId}`
    const epgId = `${vod.episodeId}`
    window.location.href = 'xem-phim/' + slug + '/' + epgId
  }

  helperReDate (dateString) {
    if (dateString.length > 0) {
      return dateString.replace(/(\d{2})-(\d{2})-(\d{4})/, '$2/$1/$3')
    }
    return dateString
  }

  onClickTransaction () {
    this.setState({ section: 'history' })
  }

  /* Delete device */
  onClickDelDevice (e, device) {
    console.log(e)
    console.log(device)
    const deviceIp = device.ip
    if (this.state.deviceIPV4 !== deviceIp) {
      G.zq.delete(apiDevice + '/' + device.id, {}, (json) => {
        if (json.success) {
          console.log('success---------------')
          this.fetchDataDevice(this.state.meta.current_page)
        }
      })
    } else {
      this.setState({ deviceWarning: true })
    }
  }

  handleCloseDelDeviceWarning () {
    this.setState({ deviceWarning: false })
  }

  onClickExpand () {
    const sider = this.state.sider
    if (this.state.sider.width === sizeSubmenu.close) {
      sider.width = sizeSubmenu.open
      sider.background = 'rgba(0,0,0,0.7)'
      sider.classSider = 'chevron-arrow-left'
      sider.showName = 'block'
      this.setState({ sider })
    } else {
      sider.width = sizeSubmenu.close
      sider.background = 'transparent'
      sider.classSider = 'chevron-arrow-right'
      sider.showName = 'none'
      this.setState({ sider })
    }
  }

  onClickChangeTab (e, name) {
    let section = this.state.section
    if (this.state.section !== name) {
      section = name
      this.setState({ section: section }, () => this.fetDataWithSection())
      // this.fetchAllData())
    }
  }

  onClickUpdateProfile () {
    const editingData = this.state.editingData
    const api = '/api/v1/users'
    // const loginby = G.zq.localLoad('loginby')
    console.log('editingdata update: ', editingData)
    const params = {
      name: G.zu.get(this.state, 'editingData.profile.name', ''),
      gender: 2,
      address: G.zu.get(this.state, 'editingData.profile.address', ''),
      email: G.zu.get(this.state, 'editingData.profile.email', '')
    }
    G.zq.put(api, params, (json) => {
      if (json.success) {
        editingData.profile = {}
        this.setState(
          {
            editingData: editingData,
            msg: { success: true },
            success: [{ message: 'Cập nhật thành công' }],
            emailErr: false
          },
          () => {
            this.fetchData()
            this.props.actionUsernameProfile(params.name == '' ? 'Ẩn danh' : params.name)
          }
        )
        clearTimeout(this.timerMessage)
        this.timerMessage = setTimeout((e, name) => {
          this.setState({ msg: {}, errors: [], success: [] })
        }, 3000)
      } else {
        this.setState({ emailErr: true })
      }
    })
  }

  onClickDataEdit (e, data, profile) {
    const editingDataMember = {
      ...G.zu.clone(this.editingDataMember),
      member_id: data.id,
      is_ask_password: data.is_ask_password === 1,
      name: data.name,
      age_id: zget(data, 'age.id')
    }
    this.setState({ editingDataMember }, () =>
      this.handleOpenFormName('SelectMemberForm')
    )
  }

  onClickUploadAvatar () {
    $(() => {
      document.getElementById('inputAvatar').click()
    })
  }

  onBlurOtpCode (e, pos) {
    const value = e.target.value
    const otp = G.zu.clone(G.zu.array(this.state.editingDataLogin.otp))
    const editingDataLogin = G.zu.clone(this.state.editingDataLogin)
    if (value.length <= 1) {
      otp[pos] = value[0] || ''
      editingDataLogin.otp = otp
      this.setState({ editingDataLogin })
    }
  }

  onBlurAskPassword (e) {
    const editingDataMember = this.state.editingDataMember
    const value = editingDataMember.is_ask_password
    editingDataMember.is_ask_password = !value
    this.setState({ editingDataMember })
  }

  /* Member */

  onSubmitCreateMember () {
    console.log('Tao member moi')
    this.setState({ isOTP: 'new' })
    const editingDataMember = G.zu.clone(this.state.editingDataMember)
    const params = {
      name: editingDataMember.name,
      is_ask_password: G.zu.get(editingDataMember, 'is_ask_password', false)
        ? 1
        : 0,
      age_id: G.zu.get(editingDataMember, 'age_id', ''),
      password: editingDataMember.password || ''
    }

    if (params.password.length < 8 && params.is_ask_password) {
      const validate = {
        ...G.zu.clone(this.state.validate),
        isPasswordValid: false,
        msgErr: {
          password: 'Mật khẩu ít nhất 8 ký tự'
        }
      }
      this.setState({ validate })
    } else {
      const api = '/api/v1/members'
      G.zq.post(api, params, (json) => {
        if (json.success) {
          console.log('calling =========== api create member')
          console.log('data new member: ', json.data)
          const editingDataMember = G.zu.clone(this.state.editingDataMember)
          editingDataMember.member_id = json.data.id
          this.setState({ form: Form.OTPForm, editingDataMember })
        } else {
          const validate = {
            ...G.zu.clone(this.state.validate),
            isPasswordValid: false,
            msgErr: {
              password: json.errors[0].message
            }
          }
          this.setState({ validate })
        }
      })
    }
  }

  onClickSelectMember (e, member) {
    if (member) {
      console.log('member: ', member)
      const editingDataMember = G.zu.clone(this.state.editingDataMember)
      const resetValidate = this.resetValidate()
      console.log('resetValidate: ', resetValidate)
      if (member.id === editingDataMember.member_id) {
        editingDataMember.member_id = ''
        editingDataMember.is_ask_password = false
        editingDataMember.name = ''
        editingDataMember.age_id = ''
      } else {
        editingDataMember.member_id = member.id
        editingDataMember.is_ask_password = !!(member.is_ask_password === 1)
        editingDataMember.name = member.name
        editingDataMember.age_id = member.age.id
      }
      this.setState({ editingDataMember, validate: resetValidate })
    }
  }

  onClickUpdateMember (member) {
    // validate data
    const editingDataMember = G.zu.clone(this.state.editingDataMember)
    const api = `/api/v1/members/${editingDataMember.member_id}`
    const validate = this.validateDataMember(editingDataMember)
    console.log('editingdatamember: ', editingDataMember)
    const params = {
      id: editingDataMember.member_id,
      name: editingDataMember.name,
      is_ask_password: editingDataMember.is_ask_password ? 1 : 0,
      password: editingDataMember.password,
      age_id: editingDataMember.age_id
    }
    if (validate.isMemberNameValid && validate.isPasswordValid) {
      G.zq.put(api, params, (json) => {
        console.log('params: ', params)
        console.log('json: ', json)
        if (json.success) {
          const notify = G.zu.clone(this.state.notify)
          notify.msg.update = 'Cập nhật thành công!'
          notify.isUpdate = true
          this.setState({ notify }, () => {
            this.notiOff()
            this.fetchDataMember()
          })
        } else if (json.errors) {
          const validate = {
            ...G.zu.clone(this.state.validate)
          }
          validate.msgErr.member = json.message
          validate.isMemberNameValid = false
          this.setState({ validate })
        }
      })
    }
  }

  onSwitchMember () {
    const api = '/api/v1/members/login'
    const editingDataMember = G.zu.clone(this.state.editingDataMember)
    const params = {
      member_id: editingDataMember.member_id,
      password: ''
    }

    console.log('editingDataMember: ', editingDataMember)
    if (editingDataMember.is_ask_password) {
      this.setState({ form: Form.TypePasswordForm })
    } else {
      G.zq.post(api, params, (json) => {
        if (json.success) {
          const notify = G.zu.clone(this.state.notify)
          notify.msg.update = 'Chuyển thành viên thành công!'
          notify.isUpdate = true
          console.log('switch member response: ', json)
          this.setState({ notify }, () => {
            this.fetchDataMember()
            this.notiOff('CloseForm')
          })
        } else if (json.errors) {
          const validate = {
            ...G.zu.clone(this.state.validate)
          }
          validate.msgErr.member = json.message
          this.setState({ validate })
        }
      })
    }
  }

  onSubmitMemberPassword () {
    const api = '/api/v1/members/login'
    const editingDataMember = G.zu.clone(this.state.editingDataMember)
    const params = {
      member_id: editingDataMember.member_id,
      password: ''
    }
    const validate = {
      ...G.zu.clone(this.state.validate)
    }

    if (!editingDataMember.password || editingDataMember.password.length < 8) {
      validate.msgErr.password = 'Mật khẩu ít nhất 8 ký tự'
      validate.isPasswordValid = false
      this.setState({ validate })
    } else {
      params.password = editingDataMember.password
      G.zq.post(api, params, (json) => {
        if (json.success) {
          const notify = G.zu.clone(this.state.notify)
          notify.msg.update = 'Chuyển thành viên thành công!'
          notify.isUpdate = true
          console.log('switch member response: ', json)
          this.setState({ form: Form.SelectMemberForm })
          this.setState({ notify }, () => {
            G.zq.localSave('memberlogged', params.member_id)
            this.fetchDataMember()
            this.notiOff('CloseForm')
          })
        } else if (json.errors) {
          validate.msgErr.password = 'Mật khẩu không đúng'
          validate.isPasswordValid = false
          this.setState({ validate })
        }
      })
    }
  }

  onClickOpenAddMember () {
    const otp = this.state.otp
    otp.form = false
    this.setState({ otp, selectmember: false })
  }

  onBlurDataMemberName (e) {
    const editingDataMember = this.state.editingDataMember
    const resetValidate = this.resetValidate()
    const value = e.target.value
    if (value.length <= 32) {
      editingDataMember.name = value
      this.setState({ editingDataMember, validate: resetValidate })
    }
  }

  onBlurDataMemberPassword (e) {
    const editingDataMember = this.state.editingDataMember
    const resetValidate = this.resetValidate()
    const value = e.target.value
    if (value.length <= 32) {
      editingDataMember.password = value
      this.setState({ editingDataMember, validate: resetValidate })
    }
  }

  /*  Others
  */
  checkUpdate () {
    const profile = this.state.editingData.profile
    const rawProfile = this.state.profile
    let isUpdate = this.state.isUpdate
    const isName = profile.name === rawProfile.name
    const isEmail = profile.email === rawProfile.email
    const isPhone = profile.phone === rawProfile.phone
    const isAddress = profile.address === rawProfile.address
    if (isName && isEmail && isPhone && isAddress) {
      isUpdate = false
    } else {
      isUpdate = true
    }

    this.setState({ isUpdate })
  }

  helperChangePhone (phone) {
    const format = [4, 7]
    let newPhone = ''
    for (let i = 0; i < phone.length; i++) {
      if (format.indexOf(i + 1) !== -1) {
        newPhone += `${phone[i]} `
      } else {
        newPhone += phone[i]
      }
    }
    return newPhone
  }

  validateDataMember (member) {
    const validate = {
      ...G.zu.clone(this.state.validate)
    }
    const editingDataMember = G.zu.clone(this.state.editingDataMember)
    if (!editingDataMember.name) {
      validate.msgErr.member = 'Không để trống trường'
      validate.isMemberNameValid = false
    }
    if (
      editingDataMember.is_ask_password &&
      (!editingDataMember.password || editingDataMember.password.length < 8)
    ) {
      validate.msgErr.password = 'Mật khẩu ít nhất 8 ký tự'
      validate.isPasswordValid = false
    }
    this.setState({ validate })
    return validate
  }

  resetValidate () {
    return {
      msgErr: {
        phone: '',
        password: '',
        repassword: '',
        otp: '',
        member: ''
      },
      isPhoneValid: true,
      isPasswordValid: true,
      isRepasswordValid: true,
      otpCheck: true,
      isMemberNameValid: true
    }
  }

  render () {
    return this.renderComponent()
  }
}

const mapStateToProps = (state, ownProps) => ({
  auth: state.auth,
  section: state.section,
  showWarning: state.showWarning,
  usernameProfile: state.usernameProfile,
  avtProfile: state.avtProfile
})

const mapDispatchToProps = {
  actionAuth,
  actionSection,
  actionShowWaring,
  actionUsernameProfile,
  actionAvtProfile
}

const AccountComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(component)

export default AccountComponent
