export const renderPaging = (vm) => {
  const meta = G.zu.clone(vm.props.meta)
  const buttons = []
  for (let i = 1; i <= meta.last_page; i++) {
    if (i <= (vm.state.meta.current_page + 9) && i >= (vm.state.meta.current_page - 9) && vm.state.meta.last_page > 1) {
      buttons.push(<div key={i} onClick={() => vm.handleChangePage(i)} className={`btn_paging ${vm.state.meta.current_page === i ? 'active' : ''}`}>{i}</div>)
    }
  }
  return (
    <div className='search_paging'>
      <div className='result_paging'>
        {vm.state.meta.current_page !== 1 &&
          <div onClick={() => vm.handleChangePage(vm.state.meta.current_page - 1)} className='btn_paging'>
            <span className='icon icon-arrow-left' />
          </div>}
        {buttons.map(button => button)}
        {(vm.state.meta.current_page !== vm.state.meta.last_page && vm.state.meta.total !== 0) &&
          <div onClick={() => vm.handleChangePage(vm.state.meta.current_page + 1)} className='btn_paging'>
            <span className='icon icon-arrow-right' />
          </div>}
      </div>
    </div>
  )
}
