import React from 'react'
import {} from './style/newmember.css'

export const renderNewMember = (vm) => {
  return (
    <div className='popup'>
      {renderNewMemberForm(vm)}
    </div>
  )
}

const renderNewMemberForm = (vm) => {
  const nameTab = 'Tạo thành viên'
  const showPassword = zget(vm.state, ['editingDataLogin', 'showPassword'], false)
  const ages = G.zu.json(G.zq.localLoad('ages')).length ? G.zu.json(G.zq.localLoad('ages')) : zget(vm.state, 'ages', [])
  const ageSelected = zget(vm.state, ['editingDataMember', 'age_id'], '')
  const active = !!(ageSelected && vm.state.editingDataMember.name)

  return (
    <div className='popup_newmember_modal newmember_modal'>
      <div className='newmember_top'>
        <span className='newmember_cancel_btn' onClick={() => vm.handleCloseForm()} />
      </div>
      <div className='newmember_header'>{nameTab}</div>
      <div className='newmember_body'>
        <div className='newmember_form'>
          <label className='newmember_label'>Tên thành viên</label>
          <div className='newmember_age_list'>
            {ages.map((age, index) => {
              return (
                <div key={index} onClick={(e) => vm.onClickSelectAge(e, age)} className={`newmember_age ${age.id === ageSelected ? 'selected' : ''}`}>{age.name}</div>
              )
            })}
          </div>
        </div>
        <div className='newmember_form fullwidth'>
          <label>Tên thành viên</label>
          <input type='text' placeholder='Nhập tên' value={vm.state.editingDataMember.name} onChange={(e) => vm.onBlurDataMemberName(e, 'name')} />
        </div>
        <div className={`newmember_form fullwidth ${vm.state.validate.isPasswordValid ? '' : 'error'}`}>
          <input
            className='newmember_radio'
            type='radio'
            value={vm.state.editingDataMember.is_ask_password}
            checked={vm.state.editingDataMember.is_ask_password}
            onClick={(e) => vm.onBlurAskPassword(e)}
          />
          <label className='newmember_radio'>Đặt mật khẩu</label>
          {vm.state.editingDataMember.is_ask_password && <input type={showPassword ? 'text' : 'password'} placeholder='Ít nhất 8 ký tự' value={vm.state.editingDataMember.password} onChange={(e) => vm.onBlurDataMemberName(e, 'password')} />}
          {vm.state.editingDataMember.is_ask_password && <span className='newmember_eye' onClick={() => vm.handleShowPassword()} />}
          {vm.state.editingDataMember.is_ask_password && <label className='error show'>{vm.state.validate.msgErr.password}</label>}
        </div>
      </div>
      <div className='newmember_button_group'>
        <div className='newmember_button' onClick={() => vm.onClickBack('openSelectMember')}>Trở về</div>
        <div className={`newmember_button ${active ? 'active' : 'disable'}`} onClick={() => active && vm.onSubmitCreateMember()}>Xác nhận</div>
      </div>
    </div>
  )
}
