import * as Jsx from './view.jsx'
// eslint-disable-next-line
import RxComponent from '/app/zcore/zcomponent'
import { connect } from 'react-redux'
import { actionPayment, actionSection } from '../../../zcore/zredux.js'

const api = '/api/v1/homepages?parent_id=4&'
const apiPackage = '/api/v1/packages'
const apiPackageUsing = '/api/v1/packages/using'
const apiCheckout = '/api/v1/packages/buy'
class component extends RxComponent {
  constructor (props, context) {
    super(props, context, Jsx)
    this.state = {
      slug: '',
      packageID: '',
      paymentMethodID: 1,
      promotion: {
        code: '',
        selected: {},
        applied: {},
        list: [
          { id: 1, code: 'GIAMGIA', price: 1000, description: 'Giảm 1 nghìn để dòng text đẹp hơn' },
          { id: 2, code: 'MCVGO', price: 8000, description: 'Giảm 8 nghìn cho vui' },
          { id: 3, code: 'MCVGO100', price: 100000, description: 'Giảm 100 nghìn vì sếp thích thế' },
          { id: 4, code: 'MCVGO50', price: 50000, description: 'Giảm 50 nghìn vì rãnh' }
        ]
      },
      datahome: G.zu.json(G.zq.localLoad(api)),
      dataPackage: G.zu.json(G.zq.localLoad(apiPackage)),
      curPackage: {},
      curEditing: {},
      form: {
        detailpayment: false,
        payment: false,
        noti: {
          success: false,
          error: false
        }
      },
      errorCode: this.props.errorCode,
      package_using: {},
      isActive: false,
      activeItem: null,
      section: ''
    }
    this.props.actionPayment(this.state.errorCode)
    this.props.actionSection(this.state.section)
  }

  fetchData () {
    G.zq.get(api, {}, (json) => {
      if (json.success) {
        this.makeData(json.data)
        const packagesComMap = {}
        for (const i of json.data) {
          packagesComMap[i.id] = i
        }

        G.zq.get(apiPackage, {}, json => {
          if (json.success) {
            const packageMap = {}
            try {
              for (const i of json.data) {
                packageMap[i.id] = { ...i, extra: packagesComMap[i.id]}
              }
            } catch (_e) {}
            this.makeDataPackage(packageMap)
          }
        })
      }
    })
  }

  fetchDataPackage () {
    G.zq.get(apiPackageUsing, {}, (json) => {
      if (json.success) {
        // const packages = zarray(json.data).reverse()
        // const packageList = packages.filter(el => !el.video)
        // packageList.map(el => {
        //   const ex = new Date(el.expired_at.replace(/(\d{2})-(\d{2})-(\d{4})/, '$2/$1/$3'))
        //   el.dayLeft = Math.round((ex - new Date()) / (24 * 60 * 60 * 1000))
        //   el.expired = el.dayLeft < 0
        //   return el
        // })
        // const videoList = packages.filter(el => !!el.video)
        // console.log(packageList)
        // this.setState({
        //   package_using: {
        //     package: packageList,
        //     videos: videoList
        //   }
        // })
        const packages = zarray(json.data).reverse()
        console.log(packages)
        // const packageList = packages.filter(el => !el.video)
        packages.map(el => {
          if (el.expired_at !== null) {
            const ex = new Date(el.expired_at.replace(/(\d{2})-(\d{2})-(\d{4})/, '$2/$1/$3'))
            el.dayLeft = Math.round((ex - new Date()) / (24 * 60 * 60 * 1000))
            el.expired = el.dayLeft < 0
          }
          return el
        })
        // const videoList = packages.filter(el => !!el.video)
        this.setState({
          package_using: {
            package: packages
            // videos: videoList
          }
        })
      }
    })
  }

  makeData (data) {
    G.zq.localSave(api, JSON.stringify(data))
    this.setState({ datahome: data })
  }

  makeDataPackage (data) {
    G.zq.localSave(apiPackage, JSON.stringify(data))
    this.setState({ dataPackage: data })
  }

  componentDidMount () {
    this.slug = G.zu.get(this.props, 'match.params.slug').split('-')
    this.componentUpdateSlug()
    this.fetchData()
    this.fetchDataPackage()

    const status = G.zq.localLoad('parLocal')
    if (JSON.stringify(status).length !== 2) {
      if (status.errorCode === '0') {
        this.setState({ form: { noti: { success: true } } })
      } else {
        this.setState({ form: { noti: { error: true } } })
      }
    }
  }

  componentWillReceiveProps (nextProps) {
    if (G.zu.get(nextProps, 'match.params.slug') !== G.zu.get(this.props, 'match.params.slug')) {
      this.slug = G.zu.get(nextProps, 'match.params.slug').split('-')
      this.componentUpdateSlug()
    }
  }

  componentUpdateSlug () {
    this.packageID = this.slug[this.slug.length - 1]
    this.setState({ packageID: this.packageID, curPackage: G.zu.get(this.state.dataPackage, [this.packageID], {}) })
    setTimeout(() => {
      if (this.state.curPackage.id) {
        this.setState({ form: { detailpayment: true } })
      }
    }, 10)
  }

  handleActive (id) {
    this.setState({ isActive: !this.state.isActive })
  }

  handleActiveItem (id) {
    this.setState({ activeItem: id === this.state.activeItem ? null : id })
  }

  onClickPackage (id) {
    this.setState({ curPackage: G.zu.get(this.state.dataPackage, [id], {}) })
    this.setState({ form: { detailpayment: true } })
  }

  onClickClosePopup () {
    this.props.history.push('/mua-goi')
    localStorage.removeItem('parLocal')
    localStorage.removeItem('packageName')
    window.sessionStorage.removeItem('videoNeedBuy')
    this.setState({ form: { noti: {} } })
    this.fetchDataPackage()
  }

  onClickPackPrice (item) {
    this.setState({ curEditing: { ...this.state.curEditing, pack: this.state.curPackage, priceID: item.id, price: item.price, totalPrice: item.price, priceMSG: '' } })
  }

  onClickBuyPack () {
    this.setState({
      form: { payment: true, detailpayment: false }
    })
  }

  onClickCloseBuyPack () {
    this.setState({
      form: { payment: false, detailpayment: false }
    })
  }

  onClickPackCheckout () {
    if (!this.state.curEditing.priceID) {
      this.setStateNested('curEditing.priceMSG', 'Vui lòng chọn mức giá')
    } else {
      this.onClickBuyPack()
    }
  }

  onClickNoti () {
    this.setState({
      form: { payment: false, detailpayment: false, noti: true }
    })
  }

  onClickSelectMethod (e) {
    this.setState({ paymentMethodID: e.id })
  }

  onClickApplyPromo (e) {
    this.setState({ curEditing: { ...this.state.curEditing, applied: e } })
    this.applyCode(e)
  }

  onClickCancelPromo () {
    this.setState({ promotion: { ...this.state.promotion, applied: {} } })
    this.setState({ curEditing: { ...this.state.curEditing, totalPrice: this.state.curEditing.price } })
  }

  processCheckout () {
    if (this.state.curPackage.id && this.state.curEditing.priceID) {
      G.zq.post(apiCheckout, { package_id: this.state.curPackage.id, price_id: this.state.curEditing.priceID, payment_gateway: 1 }, (json) => {
        if (json.success) {
          const payUrl = G.zu.get(json, 'data.payUrl')
          if (payUrl) {
            window.open(payUrl, '_self/_blank')
          }
          G.zq.localSave('hisPath', window.location.pathname)
          G.zq.localSave('packageName', this.state.curPackage.name)
        // this.onClickClosePopup()
        }
      })
    }
  }

  handleCloseNotiFail () {
    this.props.history.push(G.zq.localLoad('hisPath'))
    localStorage.removeItem('parLocal')
    localStorage.removeItem('packageName')
    this.setState({ form: { payment: true, detailpayment: false, noti: { error: false, success: false } } })
    this.fetchDataPackage()
  }

  handleCloseNotiSuccess () {
    localStorage.removeItem('parLocal')
    localStorage.removeItem('packageName')
    this.setState({ form: { noti: { error: false, success: false } } })
    if (window.sessionStorage.getItem('videoNeedBuy') !== null) {
      this.props.history.push(window.sessionStorage.getItem('videoNeedBuy'))
      window.sessionStorage.removeItem('videoNeedBuy')
    } else {
      this.props.actionSection('package')
      this.props.history.push('/tai-khoan')
    }
  }

  onClickTransaction () {
    // this.setState({ section: 'history' }, () => {
    //   console.log('ola', this.state.section)
    //   this.props.actionSection(this.state.section)
    // })
    this.props.actionSection('history')
    this.props.history.push('/tai-khoan')
  }

  handleApplyPromotion (e) {
    const value = e.target.value
    this.setState({ promotion: { ...this.state.promotion, code: value } })
    if (!this.state.promotion.list.map(el => el.code).includes(value)) {
      this.setState({ promotion: { ...this.state.promotion, applied: {}, selected: {}, code: value } })
      this.setState({ curEditing: { ...this.state.curEditing, totalPrice: this.state.curEditing.price } })
    }
  }

  handleApplied () {
    const applied = this.state.promotion.list.filter(el => el.code === this.state.promotion.code)[0]
    if (applied) {
      this.applyCode(applied)
    }
  }

  applyCode (applied) {
    this.setState({ promotion: { ...this.state.promotion, applied: applied } })
    setTimeout(() => {
      const total = this.state.curEditing.price - this.state.promotion.applied.price
      this.setState({ curEditing: { ...this.state.curEditing, totalPrice: total > 0 ? total : 0 } })
      this.setState({ promotion: { ...this.state.promotion, code: '' } })
    }, 10)
  }

  render () { return this.renderComponent() }
}

const mapStateToProps = (state, ownProps) => ({
  errorCode: state.errorCode,
  section: state.section
})

const mapDispatchToProps = { actionPayment, actionSection }

const ComponentPayment = connect(
  mapStateToProps,
  mapDispatchToProps
)(component)

export default ComponentPayment
