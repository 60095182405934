
export const renderSale = (vm) => {
  const salelist = vm.state.sale || []
  return (
    <div className='package_list'>
      {salelist && salelist.map((sale, index) => {
        return (
          <div id={'item' + (sale.id || 's')} className={'product__item ' + (sale.id || 's')} key={`${index}_${rxu.get(sale, 'id')}_${Math.random(1000)}`}>
            <a href={`mua-goi/${rxu.rxChangeSlug(rxu.get(sale, 'name', ''))}-${sale.id}`} style={{ height: '70%' }}>
              <div className='rx__espect--img'>
                <img style={{ height: 'unset' }} className='img__landscape' src='https://cdnstatic.mcvgo.vn/11152/conversions/60d6e5f0b3812_deal1-420_237.png' onError={() => rxu.imgError(index, 'img__landscape')} alt={sale.name} />
              </div>
              <div className='product__info' style={{ textTransform: 'uppercase' }}>
                <div className='product__title' style={{ textTransform: 'uppercase' }}>{sale.name}</div>
              </div>
              <div className='product__desc' style={{ paddingBottom: '5%' }}><span style={{ textTransform: 'uppercase' }}>{rxu.get(sale, 'short_description', '')}</span></div>
            </a>
            <div onClick={(e) => vm.onClickBuyPack(e, sale)} className='button_buy'>
              <div>Mua gói</div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
