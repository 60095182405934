import React from 'react'
import { Link } from 'react-router-dom'
import Actor from './../others/actor'
import { } from './styles.css'
import { } from './../others/controlVideo/styleControlVideo.css'
import MultiCarousel from 'react-multi-carousel'
import { Prompt } from 'react-router'
import 'react-multi-carousel/lib/styles.css'
import { } from './../pack/style.css'
import * as FilmPackageDetail from './filmpackage/filmPackageDetail'
import * as FilmPackagePayment from './filmpackage/filmPackagePayment'
import Paging from './../others/paging'

const zget = G.zu.get
const imgDefault = '/static/img/logo.svg'
const imgDefaultVideo = 'static/img/Landscape_thumb.png'
const avtDefault = '/static/img/avtDefault.png'

export const renderComponent = (vm) => {
  return (
    <div className='page_film_detail'>
      {zget(vm.state, 'videoData.name') &&
        <>
          <div className='video_wrapper_custom custom_control_video'>
            <div className='film_wrapper'>
              {vm.state.videoDataCurrentEps.is_youtube ?
                <div className='ytIframe'>
                  <iframe width='100%' height='100%' src={'https://www.youtube.com/embed/' + vm.state.videoDataCurrentEps.embeded_code + '?autoplay=1&mute=1&rel=0'} title='YouTube video player' frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullScreen />
                </div> :
                <div id='videoPlayer'>
                  {vm.state.buyNeed &&
                    <div className='vod_packages'>
                      <div className='vod_packages_content'>
                        <div className='vod_packages_content_detail'>
                          <div className='vod_packages_tt'>
                            <h3>Nội dung có thu phí</h3>
                            <span>Vui lòng thanh toán để xem</span>
                          </div>
                          <button className='btn_show_popup_packages' onClick={() => vm.onClickShowPopupPackages()}>Xem chi tiết</button>
                        </div>
                      </div>
                      {vm.state.showPackageDetail && FilmPackageDetail.renderPackageDetail(vm)}
                      {/* vm.state.showPackagePayment && FilmPackagePayment.renderPackagePayment(vm) */}
                    </div>}
                </div>}
              {vm.state.ppKeepVideo &&
                <div className='showPopupWatchVideo'>
                  <div className='packpopup_curtain' onClick={e => vm.onClickClosePopupKeepVideo(false)} />
                  <div className='packpopup_outer'>
                    <div className='packpopup_wrap'>
                      <img className='pp_close' src='static/img/icon_close.svg' alt='close' onClick={() => vm.onClickClosePopupKeepVideo(false)} />
                      <div className='packpopup_body'>
                        <div className='fil_pp_txt'>Bạn đã xem phim này trước đây. Bạn có muốn xem tiếp phim này không?</div>
                        <img className='keepFilm' src='static/img/keepFilm.png' alt='keepFilm' />
                        <div className='keepFilmButton'>
                          <div className='keepFilmBtn' data-time='0' onClick={() => vm.onClickClosePopupKeepVideo(false)}>Xem từ đầu</div>
                          <div className='keepFilmBtn active' data-time={vm.state.log_time} onClick={() => vm.onClickClosePopupKeepVideo(true)}>Xem tiếp</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>}
            </div>
            {vm.state.isClickRegister && (
              <div className='pp_registerEpg'>
                {vm.state.isRegister ?
                  <div className='pp_registerEpgItem'>
                    <img src='static/img/registerSuccess.png' alt='registerSuccess' />
                    <span>Thêm nội dung vào Lịch cá nhân thành công.</span>
                  </div> :
                  <div className='pp_registerEpgItem'>
                    <img src='static/img/registerFail.png' alt='registerFail' />
                    <span>Thêm nội dung vào Lịch cá nhân thất bại.</span>
                  </div>}
              </div>
            )}
            {!vm.state.buyAlert && vm.renderRecommendVideo()}
          </div>
          {vm.renderVideoData()}
          {vm.renderEpisodes()}
          {vm.renderActor()}
          {vm.renderRelatives()}
          {vm.renderCommentList()}
        </>}
      <Prompt
        message={() => vm.fetchDataVideoViewed()}
      />
    </div>
  )
}

export const renderVideoData = (vm) => {
  // const isFavourites = zget(vm.state, 'isFavourites')
  const isShare = zget(vm.state, 'isShare')
  // const checkRegist = vm.state.videoData.registered.find(el => el.id === vm.state.videoDataCurrentEps.id)
  return (
    <div className='vod_infomation_box'>
      {!!zget(vm.state, 'videoData.name') &&
        <div className='vod_lastviewed'>
          <span>{vm.state.videoData.name}</span> - <span>{vm.state.videoDataCurrentEps.name}</span>
        </div>}
      <div className='vod_rate'>
        {[0, 0, 0, 0, 0].map((e, i) => <span key={i} className={`fa fa-star ${i < parseInt(zget(vm.state, 'videoData.user_rate')) ? 'active' : ''}`} />)}
      </div>
      <div className='vod_viewed'>{zget(vm.state, 'videoData.viewed')} lượt xem</div>
      <div className='vod_detail'>
        {!!zget(vm.state, 'videoData.director') && <div className='vod_director vod_item'><span>Đạo diễn: </span>{zget(vm.state, 'videoData.director')}</div>}
        {!!zget(vm.state, 'videoData.duration') && <div className='vod_duration vod_item'><span>Thời lượng: </span>{zget(vm.state, 'videoData.duration')}</div>}
        {!!zget(vm.state, 'videoData.year') && <div className='vod_year vod_item'><span>Năm sản xuất: </span>{zget(vm.state, 'videoData.year')}</div>}
        {zget(vm.state, 'videoData.categories', []).length > 0 && <div className='vod_categories vod_item'><span>Thể loại: </span>{zget(vm.state, 'videoData.categories', []).map(e => e.name).join(', ')}</div>}
        {!!zget(vm.state, 'videoData.description') && <div className='vod_description vod_item' dangerouslySetInnerHTML={{__html: zget(vm.state, 'videoData.description')}}/>}
      </div>

      <div className='vod_action'>
        {/* (G.zu.get(checkRegist, 'id', '') === vm.state.videoDataCurrentEps.id || vm.state.addVodStatus) ? */}
        {/*{(vm.state.videoDataCurrentEps.registered || vm.state.addVodStatus) ?*/}
        {/*  <div className='vod_iconAdd'>*/}
        {/*    <img src='static/img/vodAddIconHide.png' alt='vodAddIconHide' />*/}
        {/*  </div> :*/}
        {/*  <div className='vod_iconAdd' onClick={() => vm.registVod(vm.state.videoDataCurrentEps.id)}>*/}
        {/*    <img src='static/img/vodAddIconActive.png' alt='vodAddIconActive' />*/}
        {/*  </div>}*/}
        {vm.state.videoDataCurrentEps.is_favourite ?
          <div className='vod_action_item'>
            <img onClick={() => vm.onclickFavouritesDelete(vm.state.videoDataCurrentEps.id)} src='/static/img/like_video_active.svg' alt='like' />
            <div className='label_txt'>Bỏ thích</div>
          </div> :
          <div className='vod_action_item'>
            <img onClick={() => vm.onclickFavouritesPost(vm.state.videoDataCurrentEps.id)} src='/static/img/like_video.svg' alt='like' />
            <div className='label_txt'>Thích</div>
          </div>}
        <div className='vod_action_item vod_action_item_share'>
          <img onClick={() => vm.onClickPopupShare()} src='/static/img/share_video.svg' alt='share' />
          <div className='label_txt'>Chia sẻ</div>
          {!!isShare &&
            <div className='popup_wrap popup_wrap_custom'>
              <div className='popup_curtain' />
              <div className='popup_inner pp_share'>
                <img className='pp_close' src='static/img/icon_close.svg' alt='close' onClick={() => vm.onClickClosePopupShare()} />
                <div className='pp_share_tt'>Chia sẻ</div>
                <div className='pp_share_content'>
                  <div className='pp_share_list'>
                    <div className='pp_share_item' onClick={() => vm.onClickClosePopupShare()}>
                      <a href={'https://www.facebook.com/sharer/sharer.php?u=' + location.href} target='__blank'>
                        <img src='static/img/icon_fb.svg' alt='icon share' />
                        <span>Facebook</span>
                      </a>
                    </div>
                    <div className='pp_share_item'>
                      <div className='zalo-share-button' data-href={location.href} data-oaid='861700571076591645' data-layout='4' data-color='blue' data-customize='true'>
                        <img src='static/img/icon_zalo.svg' alt='icon share' />
                      </div>
                      <span>Zalo</span>
                    </div>
                  </div>
                  <div className='share_href_div'>
                    <input className='shareHref' type='text' readOnly value={location.href} />
                    <div className='share_href_btn' onClick={() => vm.onClickCoppyHref()}>Copy</div>
                  </div>
                </div>
              </div>
            </div>}
        </div>
        <div className='vod_action_item'>
          <img src='/static/img/view_more.svg' alt='view_more' />
          <div className='label_txt'>Xem thêm</div>
        </div>
      </div>
    </div>
  )
}

export const renderRecommendVideo = (vm) => {
  const episodes = zarray(zget(vm.state, 'videoEpisodes'))
  const relations = G.zu.array(zget(vm.state, 'videoRelation'))
  return (
    <div className='vod_recommend'>
      <div className='recommend_container'>
        <div className='vod_suggest_tt'>Xem tiếp</div>
        {episodes.length > 1 ?
          <div>
            {vm.state.videoDataCurrentEps.position === episodes.length ?
              <div className='recommend_link relations_link'>
                {relations.map((e, i) =>
                  <Link
                    key={i}
                    to={'/xem-phim/' + e.slug + '-' + e.id}
                    className=''
                  >
                    <div className='recommend_title'>{e.name}</div>
                    <div className='progress blue'>
                      <span className='progress-left'>
                        <span className='progress-bar' />
                      </span>
                      <span className='progress-right'>
                        <span className='progress-bar' />
                      </span>
                    </div>
                  </Link>
                )}
              </div> :
              <div className='recommend_link'>
                {episodes.map((e, i) =>
                  <Link
                    key={i}
                    to={`/xem-phim/${zget(vm.state, 'videoData.slug')}-${zget(vm.state, 'videoData.id')}/${e.id}`}
                    className={(vm.state.videoDataCurrentEps.position + 1) === e.position ? 'active' : ''}
                  >
                    <div className='recommend_title'>{vm.state.videoData.name} | {vm.state.videoEpisodes[i].name}</div>
                    <div className='progress blue'>
                      <span className='progress-left'>
                        <span className='progress-bar' />
                      </span>
                      <span className='progress-right'>
                        <span className='progress-bar' />
                      </span>
                    </div>
                  </Link>
                )}
              </div>}
          </div> :
          <div className='recommend_link relations_link'>
            {relations.map((e, i) =>
              <Link
                key={i} to={'/xem-phim/' + e.slug + '-' + e.id}
                className=''
              >
                <div className='recommend_title'>{e.name}</div>
                <div className='progress blue'>
                  <span className='progress-left'>
                    <span className='progress-bar' />
                  </span>
                  <span className='progress-right'>
                    <span className='progress-bar' />
                  </span>
                </div>
              </Link>
            )}
          </div>}
        <div className='recommend_skip'>Bỏ qua</div>
      </div>
    </div>
  )
}

export const renderEpisodes = (vm) => {
  const episodes = zarray(zget(vm.state, 'videoEpisodes'))
  const lastPage = zget(vm.state, 'metaEpisodes.last_page')
  const totalPage = zget(vm.state, 'metaEpisodes.total')
  const setNumTabs = vm.state.setNumTabs
  const list = []
  for (let i = 0; i <= lastPage - 1; i++) {
    list.push(
      <div
        className={(vm.state.activeTab == (i + 1)) ? 'nav_tabs_item active' : 'nav_tabs_item'}
        id={i + 1}
        key={'tabs' + i + 1}
        onClick={(e) => {
          let page = e.target.id
          vm.onClickTabsMenu({ page: page })
        }}
      >
        Tập {setNumTabs * i + 1}-{(setNumTabs * i + setNumTabs) > totalPage ? totalPage : (setNumTabs * i + setNumTabs)}
      </div>
    )
  }
  return (
    <div>
      {episodes.length > 1 &&
        <div className='eps_blocks'>
          <div className='vod_bigtext2'>Các tập phim</div>

          <div className='nav_tabs_eps'>
            {list.map((list) => list)}
          </div>
          <div className='eps_wrap clearfix'>
            {episodes.map((e, i) =>
              <div key={i} className='eps_info' onClick={() => vm.onClickEpisode(e)}>
                <div className='eps_link' data-id={e.id}>
                  <div className='eps_name'>
                    {e.name}
                  </div>
                  <div className='eps_name_hover'>{e.name}</div>
                </div>
              </div>
            )}
          </div>
        </div>}
    </div>
  )
}

export const renderActor = (vm) =>
  <div>
    {zget(vm.state, 'videoData.actors', []).length > 0 &&
      <div className='blocktype blocktype_actors'>
        <div className='vod_bigtext2'>Diễn viên </div>
        <div className='vod_actors clearfix'>
          <MultiCarousel
            {...defaultCarouselConfig}
            responsive={{
              desktop1920: { breakpoint: { max: 90000, min: 1400 }, items: 9, partialVisibilityGutter: 40 },
              desktop1400: { breakpoint: { max: 1400, min: 1200 }, items: 6, partialVisibilityGutter: 40 },
              desktop1200: { breakpoint: { max: 1200, min: 992 }, items: 6, partialVisibilityGutter: 40 },
              desktop992: { breakpoint: { max: 992, min: 768 }, items: 5, partialVisibilityGutter: 40 },
              desktop768: { breakpoint: { max: 768, min: 580 }, items: 4, partialVisibilityGutter: 40 },
              desktop580: { breakpoint: { max: 580, min: 0 }, items: 4, partialVisibilityGutter: 40 }
            }}
          >
            {zget(vm.state, 'videoData.actors', []).map((e, i) => <Actor key={i} data={e} />)}
          </MultiCarousel>
        </div>
      </div>}
  </div>

export const renderRelatives = (vm) => {
  const relations = G.zu.array(zget(vm.state, 'videoRelation'))
  return (
    <div>
      {relations.length > 1 &&
        <div className='blocktype bt6'>
          <div className='vod_bigtext2'>Phim cùng thể loại <Link to='/tim-kiem' className='btitle_viewall'>Tất cả</Link></div>
          <div className='clearfix'>
            <MultiCarousel
              {...defaultCarouselConfig}
              responsive={{
                desktop1920: { breakpoint: { max: 90000, min: 1400 }, items: 6, partialVisibilityGutter: 40 },
                desktop1400: { breakpoint: { max: 1400, min: 1200 }, items: 6, partialVisibilityGutter: 40 },
                desktop1200: { breakpoint: { max: 1200, min: 992 }, items: 6, partialVisibilityGutter: 40 },
                desktop992: { breakpoint: { max: 992, min: 768 }, items: 6, partialVisibilityGutter: 40 },
                desktop768: { breakpoint: { max: 768, min: 580 }, items: 4, partialVisibilityGutter: 40 },
                desktop580: { breakpoint: { max: 580, min: 0 }, items: 4, partialVisibilityGutter: 40 }
              }}
            >
              {relations.map((e, i) =>
                <div key={i} className='relation_info'>
                  <Link className='bitem_link' to={'/xem-phim/' + e.slug + '-' + e.id}>
                    <img src={zget(e, 'photo_portrait.thumb_390x561') || imgDefaultVideo} onError={(e) => { e.target.onerror = null; e.target.src = imgDefaultVideo }} />
                    <div className='relation_name'>{e.name}</div>
                  </Link>
                </div>)}
            </MultiCarousel>
          </div>
        </div>}
    </div>
  )
}

export const formCommentView = (...params) => {
  let otherParams, vm
  if (params.length > 1) {
    otherParams = params[0]
    vm = params[1]
  } else {
    vm = params[0]
  }

  return (
    <div className='viewFormComment'>
      <form
        className={`comment_form ${vm.state.lenghtComment ? '' : 'disable'}`}
        onSubmit={(e) => {
          if (otherParams) {
            vm.parentId = otherParams.parentId
          }
          vm.onSubmitComment(e)
        }}
        onKeyPress={(e) => {
          if (otherParams) {
            vm.parentId = otherParams.parentId
          }
          vm.onKeyPressComment(e)
        }}
      >
        <textarea
          type='text'
          id='boxComment'
          onKeyPress={(e) => {
            if (otherParams) {
              vm.parentId = otherParams.parentId
            }
            vm.onKeyPressComment(e)
          }}
          // maxlength='500'
          className='comment_input'
          placeholder='Bình luận tại đây nhé'
          name='content'
          required='required'
          onChange={(e) => vm.onChangeComment(e)}
        />
        <button className='comment_input_send' type='submit'><img src='static/img/btn_send.svg' alt='' /></button>
      </form>
      {!vm.state.lenghtComment && <div className='validateLenght'>Bạn đã nhập quá 500 kí tự. Vui lòng rút ngắn bình luận của bạn.</div>}
    </div>
  )
}

export const RenderComment = ({ vm, idItem, comment, parentId }) => {
  const numComment = comment.children.length
  const avatarUrl = zget(vm.state, 'user.avatar.thumbnail')
  return (
    <div className={`comment_item ${vm.state.idReply === idItem ? 'active' : ''}`}>
      <div className='item'>
        <div className='comment_avatar'>
          <img src={zget(comment.avatar, 'thumbnail') || avtDefault} alt='avatar' />
        </div>
        <div className='comment_info'>
          <div className='comment_name'>{comment.member_name}</div>
          <div className='comment_time'>{comment.updated_at}</div>
          <div className='comment_text'>{comment.content}</div>
          <div className='comment_action'>
            <div className='item'>
              {comment.liked ?
                <div className='actionComment' onClick={() => vm.onClickActionComment(idItem)}>
                  <img src='static/img/icon_like_active.svg' alt='icon' />
                </div> :
                <div className='actionComment' onClick={() => vm.onClickActionComment(idItem)}>
                  <img src='static/img/icon_like.svg' alt='icon' />
                </div>}
              <span>{comment.like}</span>
            </div>
            <div className='item comment_child' onClick={() => vm.onClickShowFormReply(idItem)}>
              <img src='static/img/icon_comment.svg' alt='icon' />
              <span>{numComment > 0 ? numComment : 'Phản hồi'}</span>
            </div>
          </div>
          <div className='form_comment_child'>
            {/* {vm.formCommentView({ parentId: comment.id })} */}
            {vm.state.idReply === idItem &&
              <>
                <div className='comment_wrap'>
                  <div className='comment_avatar'>
                    <img src={!avatarUrl ? avtDefault : avatarUrl} alt='avatar' />
                  </div>
                  {vm.formCommentView({ parentId: parentId })}
                </div>
              </>}
          </div>
        </div>
      </div>
    </div>
  )
}

export const renderCommentPopupMessage = (vm) => {
  return (
    <>
      {vm.state.actionSubmit &&
        <div className='pp_registerEpg'>
          {vm.state.isSubmit ?
            <div className='pp_registerEpgItem'>
              <img src='static/img/registerSuccess.png' alt='registerSuccess' />
              <span>Gửi bình luận thành công.</span>
            </div> :
            <div className='pp_registerEpgItem'>
              <img src='static/img/registerFail.png' alt='registerFail' />
              <span>Gửi bình luận không thành công.</span>
            </div>}
        </div>}
    </>
  )
}

export const renderCommentList = (vm) => {
  const commentList = zarray(zget(vm.state, 'videoCommentList'))
  const avatarUrl = zget(vm.state, 'user.avatar.thumbnail')
  return (
    <div className='vod_comment' id='vodComment'>
      {vm.renderCommentPopupMessage()}
      <div className='vod_bigtext2 vod_bigtext2_comment'>Bình luận</div>
      {!!zget(vm.state, 'meta.total') && <div className='comments'>Có {zget(vm.state, 'meta.total')} bình luận</div>}
      <div className='comment_wrap'>
        <div className='comment_avatar'>
          <img src={!avatarUrl ? avtDefault : avatarUrl} alt='avatar' />
        </div>
        {vm.formCommentView()}
      </div>
      {commentList && commentList.length > 0 && (
        <div className='comment_list'>
          {commentList.map((commentChildFirst, index) => {
            return (
              <div className='comment_list_item' key={index}>
                {commentChildFirst && (
                  <RenderComment vm={vm} idItem={commentChildFirst.id} comment={commentChildFirst} parentId={commentChildFirst.id} />
                )}
                {
                  commentChildFirst.children &&
                  commentChildFirst.children.length > 0 &&
                  commentChildFirst.children.map(
                    (commentChildSecond, index) => {
                      return (
                        <div className='item_comment_child' key={index}>
                          {commentChildSecond && (
                            <RenderComment vm={vm} idItem={commentChildSecond.id} comment={commentChildSecond} parentId={commentChildFirst.id} />
                          )}
                          {commentChildSecond.children &&
                            commentChildSecond.children.length > 0 && (
                              <div className='item_comment_child' key={index}>
                                {commentChildSecond.children.map((comment, index) => (
                                  <RenderComment key={index} idItem={commentChildSecond.id} vm={vm} comment={comment} parentId={commentChildFirst.id} />
                              ))}
                              </div>
                          )}
                        </div>
                      )
                    })
                }
              </div>)
          })}
        </div>
      )}
      {G.zu.clone(vm.state.meta).last_page > 1 && <Paging meta={G.zu.clone(vm.state.meta)} onPage={(page) => vm.handleChangePage(page)} />}
    </div>
  )
}

export const backup = (vm) =>
  <div>
    <div className='vod_slug'>({zget(vm.state, 'videoData.slug')})</div>
  </div>

export const defaultCarouselConfig = {
  arrows: true,
  centerMode: false,
  infinite: true,
  showDots: false,
  slidesToSlide: 1,
  fade: false,
  swipeable: false,
  itemClass: '',
  sliderClass: '',
  dotListClass: '',
  renderButtonGroupOutsidedotListClass: ''
}
