import React from 'react'

const imgDefault = 'static/img/Landscape_thumb.png'

class component extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      flagLoaded: 0
    }
  }

  onLoadImg (e) {
    if (!this.state.flagLoaded) {
      this.setState({ flagLoaded: 1 })
    }
  }

  render () {
    return (
      <div className='zimg_wrap'>
        {!this.state.flagLoaded && <img className={this.props.class + ' zimg'} src={this.props.thumb || imgDefault} alt={this.props.alt} />}
        <img className={`${this.props.class} ${this.state.flagLoaded ? 'zimg_loaded' : 'zimg_notload'}`} src={this.props.src} alt={this.props.alt} onLoad={e => this.onLoadImg(e)} />
      </div>
    )
  }
}

export default component
