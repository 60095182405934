// let crypto = require('crypto')
let zu = {}

// U L T I L I T Y //
zu.json = function (str) {
  let result = {}
  try { result = JSON.parse(str) } catch (e) { result = {} }
  return result
}

zu.json2str = (data) => {
  return JSON.stringify(data)
}

zu.clone = function (obj) {
  let result = {}
  try { result = JSON.parse(JSON.stringify(obj)) } catch (e) {}
  return result
}

zu.filter = function (params, filter) {
  for (let index in params) {
    if (filter.indexOf(index) !== -1) { params.set(index, undefined, { strict: false }) }
  }
  return params
}

zu.rxChangeSlug = function (x) {
  let str = x
  if (x && typeof (x) !== 'undefined') {
    str = str.toLowerCase()
    str = str.replace(/[`~!@#$%^&*()_|+=?;:'",.<>{}[\]\\/]/gi, '')
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
    str = str.replace(/đ/g, 'd')
    str = str.replace(/ + /g, ' ')
    // eslint-disable-next-line
    str = str.replace(/ \- /g, '-')
    str = str.replace(/ /g, '-')
    str = str.trim()
  }
  return str
}

zu.get = function (variable, keys, defaultVal) {
  defaultVal = defaultVal || ''
  let resultVal = defaultVal

  try {
    // Keys is array of index
    if (Array.isArray(keys)) {
      let tempResult = variable
      for (let i in keys) {
        tempResult = tempResult[keys[i]]
      }
      resultVal = tempResult

    // Keys is a string
    } else {
      keys = keys.split('.')
      let tempResult = variable
      for (let i in keys) {
        tempResult = tempResult[keys[i]]
      }
      resultVal = tempResult
    }

  // Case exception, access undefined variable
  } catch (e) { resultVal = defaultVal }

  // Case undefined after all
  if (resultVal === undefined) { resultVal = defaultVal }

  // HAPPYENDING
  return resultVal
}

zu.getBrowser = function (user_agent) {
  let ua = user_agent
  var tem
  var M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || []
    return { name: 'IE', version: (tem[1] || '') }
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\bOPR|Edge\/(\d+)/)
    if (tem != null) { return { name: 'Opera', version: tem[1] } }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
  if ((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, tem[1]) }
  return M[0] + ' ' + M[1].substr(0, 4)
}

zu.getDate = function (date) {
  let u = Date.now()
  if (date && date !== 1) { u = new Date(date * 1000) } else { u = new Date() }
  const tempstr = ('0' + u.getUTCDate()).slice(-2) + '/' + ('0' + (u.getUTCMonth() + 1)).slice(-2) + '/' + u.getUTCFullYear()
  return tempstr
}

zu.compareTimeDate = function (time) {
  const ex = new Date(time.replace(/(\d{2})-(\d{2})-(\d{4})/, '$2/$1/$3'))
  return Math.round((ex > new Date()))
}

// zu.compareInTime = function (start, end) {
//   const startTime = new Date(start.replace(/(\d{2})-(\d{2})-(\d{4})/, '$2/$1/$3')).getTime()
//   const endTime = new Date(end.replace(/(\d{2})-(\d{2})-(\d{4})/, '$2/$1/$3')).getTime()
//   const noww = new Date().getTime()
//
//   if (noww > startTime && noww < endTime) return 0
//   if (noww > startTime) return -1
//   if (startTime > noww) return 1
// }

// CHANGE YYYY-MM-DD to DD-MM-YYYYY
zu.formatDate = function (dateString) {
  const allDate = dateString.split(' ')
  const thisDate = allDate[0].split('-')
  const thisTime = allDate[1].split(':')
  const newDate = [thisDate[2], thisDate[1], thisDate[0]].join('-')

  const hour = thisTime[0] > 24 ? thisTime[0] - 24 : thisTime[0]
  // const hours = hour < 10 ? '0' + hour : hour
  const min = thisTime[1]
  const sec = thisTime[2]
  const newTime = hour + ':' + min + ':' + sec

  return newDate + ' ' + newTime
}
// S T R I N G //
zu.strCapitalize = function (str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

zu.strToMoney = function (x) {
  return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : ''
}

zu.strGen = function (length, source) {
  length = length || 8
  source = source || '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'

  let result = ''
  for (let i = length; i > 0; --i) result += source[Math.floor(Math.random() * source.length)]

  return result
}

zu.strToSlug = function (str) {
  str = str.replace(/^\s+|\s+$/g, '')
  str = str.toLowerCase()

  let from = 'âấầậẫẩăằắặẵẳàáäâẩạãêềếệễèéëêẽểẻẹìíïîỉịòóöôỏọơờớợỡởôồốộỗổùúüûủụưừứựữửñçđ·/_,:;'
  let to = 'aaaaaaaaaaaaaaaaaaaeeeeeeeeeeeeeiiiiiioooooooooooooooooouuuuuuuuuuuuncd------'
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }

  str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-') // collapse dashes

  return str
}

zu.strRgbaAddAlpha = function (color, alpha) {
  return color.slice(0, color.length - 1) + ', ' + alpha + ')'
}

zu.strGetFilename = function (str) {
  return str.split('\\').pop().split('/').pop()
}

// D A T E T I M E //
zu.now = function (unit) {
  if (typeof process.hrtime !== 'undefined') {
    const hrTime = typeof process.hrtime !== 'undefined' ? process.hrtime() : console.time()
    switch (unit) {
      case 'milli':
        return hrTime[0] * 1000 + hrTime[1] / 1000000
      case 'micro':
        return hrTime[0] * 1000000 + hrTime[1] / 1000
      case 'nano':
        return hrTime[0] * 1000000000 + hrTime[1]
      default:
        return hrTime[0] * 1000 + hrTime[1] / 1000000
    }
  } else {
    return console.time('debug')
  }
}

zu.date = function (timestamp, format) {
  format = format || 'yyyy/mm/dd hh:mm:ss'

  let dateObj = timestamp ? new Date(timestamp * 1000) : new Date()
  let year = dateObj.getFullYear()
  let month = ('0' + (dateObj.getMonth() + 1)).slice(-2)
  let date = ('0' + dateObj.getDate()).slice(-2)
  let hour = ('0' + dateObj.getHours()).slice(-2)
  let minute = ('0' + dateObj.getMinutes()).slice(-2)
  let second = ('0' + dateObj.getSeconds()).slice(-2)

  let formats = format.split(' ')
  formats[0] = zu.get(formats, [0]).split('dd').join(date).split('mm').join(month).split('yyyy').join(year)
  formats[1] = zu.get(formats, [1]).split('ss').join(second).split('mm').join(minute).split('hh').join(hour)

  return `${formats[0]} ${formats[1]}`.trim()
}

zu.dateConvert = function (timestamp, format) {
  format = format || 'dd/mm/yyyy'

  const dateObj = timestamp ? new Date(timestamp * 1000) : new Date()
  const year = dateObj.getFullYear()
  const month = ('0' + (dateObj.getMonth() + 1)).slice(-2)
  const date = ('0' + dateObj.getDate()).slice(-2)

  const formats = format.split(' ')
  formats[0] = zu.get(formats, [0]).split('dd').join(date).split('mm').join(month).split('yyyy').join(year)

  return `${formats[0]}`.trim()
}

zu.dateViaObj = function (dateobj, format) {
  format = format || 'yyyy/mm/dd hh:mm:ss'

  let dateObj = dateobj || new Date()

  let year = dateObj.getFullYear()
  let month = ('0' + (dateObj.getMonth() + 1)).slice(-2)
  let date = ('0' + dateObj.getDate()).slice(-2)
  let hour = ('0' + dateObj.getHours()).slice(-2)
  let minute = ('0' + dateObj.getMinutes()).slice(-2)
  let second = ('0' + dateObj.getSeconds()).slice(-2)

  let formats = format.split(' ')
  formats[0] = zu.get(formats, [0]).split('dd').join(date).split('mm').join(month).split('yyyy').join(year)
  formats[1] = zu.get(formats, [1]).split('ss').join(second).split('mm').join(minute).split('hh').join(hour)

  return `${formats[0]} ${formats[1]}`.trim()
}

zu.getDateString = function (date) {
  let u = Date.now()
  if (date && date !== 1) { u = new Date(date * 1000) } else { u = new Date() }
  const currentday = u.getDay()

  let dayname = ''
  switch (currentday) {
    case 0:
      dayname = 'Chủ nhật'
      break
    case 1:
      dayname = 'Thứ hai'
      break
    case 2:
      dayname = 'Thứ ba'
      break
    case 3:
      dayname = 'Thứ tư'
      break
    case 4:
      dayname = 'Thứ năm'
      break
    case 5:
      dayname = 'Thứ sau'
      break
    case 6:
      dayname = 'Thứ bảy'
      break
    default:
  }

  const tempstr = dayname + ', ' + ('0' + u.getDate()).slice(-2) + '/' + ('0' + (u.getMonth() + 1)).slice(-2) + '/' + u.getFullYear()
  return tempstr
}

zu.getUTCDateString = function (date) {
  let u = Date.now()
  if (date && date !== 1) { u = new Date(date * 1000) } else { u = new Date() }
  const currentday = u.getDay()

  let dayname = ''
  switch (currentday) {
    case 0:
      dayname = 'Chủ nhật'
      break
    case 1:
      dayname = 'Thứ hai'
      break
    case 2:
      dayname = 'Thứ ba'
      break
    case 3:
      dayname = 'Thứ tư'
      break
    case 4:
      dayname = 'Thứ năm'
      break
    case 5:
      dayname = 'Thứ sau'
      break
    case 6:
      dayname = 'Thứ bảy'
      break
    default:
  }

  const tempstr = dayname + ', ' + ('0' + u.getUTCDate()).slice(-2) + '/' + ('0' + (u.getUTCMonth() + 1)).slice(-2) + '/' + u.getUTCFullYear()
  return tempstr
}

zu.convertTimestamp = function (timestamp) {
  let d = new Date(timestamp * 1000)
  let yyyy = d.getFullYear()
  let mm = ('0' + (d.getMonth() + 1)).slice(-2)
  let dd = ('0' + d.getDate()).slice(-2)
  let hh = d.getHours()
  let h = hh

  let min = ('0' + d.getMinutes()).slice(-2)
  let ampm = 'AM'

  let time

  if (hh > 12) {
    h = hh - 12
    ampm = 'PM'
  } else if (hh === 12) {
    h = 12
    ampm = 'PM'
  } else if (hh === 0) {
    h = 12
  }

  // ie: 2013-02-18, 8:35 AM
  time = yyyy + '-' + mm + '-' + dd + ', ' + h + ':' + min + ' ' + ampm

  return time
}

zu.timeFromTimestamp = function (timestamp = 0 , timezone = 7 , showSecond = 0) {
  timestamp += timezone * 3600
  timestamp = timestamp % 86400

  // Get hours
  let hours = Math.floor(timestamp / 3600)

  // Get minutes
  let minutes = Math.floor((timestamp - (hours * 3600)) / 60)

  // Get seconds
  let seconds = timestamp - (hours * 3600) - (minutes * 60)
  let returnSecond = showSecond ? ':' + seconds.toString().padStart(2, '0') : ''

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}${returnSecond}`
}

zu.durationFromTimestamp = function (timestamp = 0 , showSecond = 0) {
  timestamp = timestamp >= 0 ? timestamp : (24 * 3600) + timestamp

  // Get hours
  let hours = Math.floor(timestamp / 3600)

  // Get minutes
  let minutes = Math.floor((timestamp - (hours * 3600)) / 60)

  // Get seconds
  let seconds = timestamp - (hours * 3600) - (minutes * 60)
  let returnSecond = showSecond ? seconds.toString().padStart(2, '0') + 's' : ''

  return `${hours.toString()}h${minutes.toString()}'${returnSecond}`
}

// A S Y N C //
zu.to = function (promise) {
  return promise.then(data => {
    return [null, data]
  }).catch(err => [err])
}

zu.toex = function (obj, func, ...params) {
  return zu.to(new Promise((resolve, reject) => {
    obj[func](...params, (result) => {
      resolve(result)
    })
  }))
}

zu.tr = function (str) {
  if (typeof str === 'boolean' || typeof str === 'number' || typeof str === 'string') {
    return str
  } else {
    return JSON.stringify(str)
  }
}

zu.runAsync = function (orm, func, ...params) {
  return new Promise((resolve, reject) => {
    orm[func](...params, (err, result) => {
      if (err) { reject(err) } else { resolve(result) }
    })
  })
}

zu.runAsyncTo = function (orm, func, ...params) {
  return zu.to(zu.runAsync(orm, func, ...params))
}

zu.runSafe = (func) => {
  try {
    return func()
  } catch (ne) {}
}

zu.runMakeFunc = (func) => {
  let result = e => e
  try {
    result = Function(`return ${func}`)()
  } catch (e) {}
  return result
}

// H A S H //
// zu.md5 = function (strsource) {
//   let passHash = crypto.createHash('md5').update(strsource).digest('hex')
//   return passHash
// }

// zu.genhex = function () {
//   let newToken = crypto.randomBytes(64).toString('hex')
//   return newToken
// }

// E N C R Y P T  -  D E C R Y P T //
// S Y S T E M //
zu.log = function (...msg) {
  console.log(...msg)
}

zu.emit = function (reqObj, e) {
  e = e || {}
  setImmediate(() => {
    reqObj.res.inthis.emit('error', reqObj.req, reqObj.res, {}, e)})
}

zu.exit = (res) => {
  if (res) {
    try {
      res.writeHead(200, { 'Content-Type': 'text/plain' })
      res.end()
    } catch (ne) {}
  }
  throw new Error('EXIT')
}

zu.safe = (func) => {
  try {
    func()
  } catch (ne) {}
}

zu.isFunc = (func) => {
  return (typeof func === 'function')
}

zu.isObj = (func) => {
  return (typeof func === 'object')
}

// A R R A Y
zu.arrayFromTo = function (rxfrom, rxto) {
  let result = []
  for (let i = rxfrom; i <= rxto; i++) {
    result.push(i)
  }

  return result
}

zu.array = function (rxarray) {
  let result = Array.isArray(rxarray) ? rxarray : []
  return result
}

// C L A S S E S //

// B U I L D I N //
export default zu
